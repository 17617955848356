export const province = [
  {
    id: "110000",
    name: "北京",
    fullname: "北京市",
    pinyin: ["bei", "jing"],
    location: {
      lat: 40.905023,
      lng: 116.724502,
    },
    polygon: [
      [
        116.812385, 39.61591399, 116.700589, 39.62101801, 116.705101, 39.587976,
        116.565926, 39.61978001, 116.44383, 39.509883, 116.401698, 39.52802301,
        116.434394, 39.44275799, 116.257938, 39.500525, 116.204049, 39.58880599,
        115.978445, 39.59568999, 115.957547, 39.56091899, 115.910193,
        39.60083301, 115.828686, 39.50705, 115.752073, 39.511725, 115.667167,
        39.615571, 115.514304, 39.59199401, 115.492432, 39.73870899, 115.424868,
        39.77452999, 115.569194, 39.814196, 115.51431, 39.83772001, 115.520526,
        39.90217999, 115.426158, 39.95035201, 115.454527, 40.02970399,
        115.777043, 40.177573, 115.851912, 40.14804299, 115.898088, 40.23642099,
        115.968939, 40.26394501, 115.918311, 40.35389301, 115.770838,
        40.44259799, 115.755055, 40.540038, 115.907801, 40.617298, 115.983444,
        40.57876601, 116.121721, 40.62914999, 116.247637, 40.791805, 116.308902,
        40.75191801, 116.461689, 40.769753, 116.334206, 40.92124999, 116.370337,
        40.94366301, 116.474046, 40.89601399, 116.456187, 40.98131899,
        116.601791, 40.97574899, 116.647699, 41.05930001, 116.71342,
        40.91030999, 116.875881, 40.82136299, 116.969622, 40.70636301,
        117.514461, 40.660786, 117.421186, 40.63543299, 117.421949, 40.56948499,
        117.311833, 40.578023, 117.208495, 40.50093701, 117.264052, 40.44146701,
        117.224118, 40.37123399, 117.390024, 40.22796799, 117.354549, 40.139093,
        117.209739, 40.08224946, 117.021766, 40.029635, 116.777809, 40.03244701,
        116.7872167, 39.88672373, 116.917439, 39.84691199, 116.953596,
        39.787569, 116.901818, 39.76362301, 116.905951, 39.687918,
      ],
    ],
  },
  {
    id: "120000",
    name: "天津",
    fullname: "天津市",
    pinyin: ["tian", "jin"],
    location: {
      lat: 39.085318,
      lng: 118.201509,
    },
    polygon: [
      [
        117.390024, 40.22796799, 117.564266, 40.22905099, 117.649584,
        40.09127601, 117.77619, 40.05926399, 117.781852, 39.96631899,
        117.535317, 39.995978, 117.539015, 39.760715, 117.646277, 39.70057799,
        117.621258, 39.59217899, 117.705857, 39.576491, 117.71595, 39.53008801,
        117.767534, 39.599743, 117.933988, 39.57414801, 117.865706, 39.37827299,
        117.803371, 39.36204201, 118.026999, 39.29141401, 118.036481,
        39.22260601, 117.949559, 39.213199, 117.8906896, 39.14035133,
        117.832644, 39.172513, 117.792235, 39.13575901, 117.849807, 39.10666301,
        117.824176, 39.07394599, 117.72781, 39.10166201, 117.8408136,
        39.0011082, 117.800208, 38.985334, 117.768613, 39.05213201, 117.795947,
        38.97842601, 117.7206053, 38.9892951, 117.886469, 38.929488, 117.724953,
        38.96753499, 117.863077, 38.91968901, 117.851527, 38.873944, 117.689852,
        38.89843201, 117.753144, 38.85686, 117.624401, 38.842173, 117.614652,
        38.715192, 117.694036, 38.75878201, 117.656453, 38.709145, 117.710278,
        38.70889301, 117.726115, 38.75347001, 117.725538, 38.67460299,
        117.656364, 38.702041, 117.656079, 38.66555601, 117.705567, 38.66528199,
        117.5927915, 38.67461619, 117.596852, 38.61399301, 117.25318,
        38.55617699, 117.229706, 38.64281201, 117.098196, 38.5869, 117.045245,
        38.70578099, 116.877288, 38.68069599, 116.867264, 38.746001, 116.745083,
        38.75252799, 116.708162, 38.93185581, 116.756621, 39.05031199,
        116.871653, 39.05466799, 116.924157, 39.11935401, 116.863498, 39.154276,
        116.889607, 39.33816101, 116.818145, 39.373515, 116.875886, 39.434549,
        116.785094, 39.46589299, 116.812385, 39.61591399, 116.905951, 39.687918,
        116.950851, 39.706825, 116.974563, 39.636793, 117.177897, 39.64297001,
        117.153656, 39.73631801, 117.205901, 39.76384499, 117.156336,
        39.81748499, 117.259961, 39.843392, 117.152851, 39.87548499, 117.135969,
        39.91993801, 117.209739, 40.08224946, 117.354549, 40.139093,
      ],
      [
        117.6014036, 39.41949768, 117.5361859, 39.33804816, 117.6580872,
        39.31539483, 117.6709332, 39.35645724, 117.78457, 39.37701414,
      ],
    ],
  },
  {
    id: "130000",
    name: "河北",
    fullname: "河北省",
    pinyin: ["he", "bei"],
    location: {
      lat: 38.037707,
      lng: 114.530399,
    },
    polygon: [
      [
        117.390024, 40.22796799, 117.224118, 40.37123399, 117.264052,
        40.44146701, 117.208495, 40.50093701, 117.311833, 40.578023, 117.421949,
        40.56948499, 117.421186, 40.63543299, 117.514461, 40.660786, 116.969622,
        40.70636301, 116.875881, 40.82136299, 116.71342, 40.91030999,
        116.647699, 41.05930001, 116.601791, 40.97574899, 116.456187,
        40.98131899, 116.474046, 40.89601399, 116.370337, 40.94366301,
        116.334206, 40.92124999, 116.461689, 40.769753, 116.308902, 40.75191801,
        116.247637, 40.791805, 116.121721, 40.62914999, 115.983444, 40.57876601,
        115.907801, 40.617298, 115.755055, 40.540038, 115.770838, 40.44259799,
        115.918311, 40.35389301, 115.968939, 40.26394501, 115.898088,
        40.23642099, 115.851912, 40.14804299, 115.777043, 40.177573, 115.454527,
        40.02970399, 115.426158, 39.95035201, 115.520526, 39.90217999,
        115.51431, 39.83772001, 115.569194, 39.814196, 115.424868, 39.77452999,
        115.492432, 39.73870899, 115.514304, 39.59199401, 115.667167, 39.615571,
        115.752073, 39.511725, 115.828686, 39.50705, 115.910193, 39.60083301,
        115.957547, 39.56091899, 115.978445, 39.59568999, 116.204049,
        39.58880599, 116.257938, 39.500525, 116.434394, 39.44275799, 116.401698,
        39.52802301, 116.44383, 39.509883, 116.565926, 39.61978001, 116.705101,
        39.587976, 116.700589, 39.62101801, 116.812385, 39.61591399, 116.785094,
        39.46589299, 116.875886, 39.434549, 116.818145, 39.373515, 116.889607,
        39.33816101, 116.863498, 39.154276, 116.924157, 39.11935401, 116.871653,
        39.05466799, 116.756621, 39.05031199, 116.708162, 38.93185581,
        116.745083, 38.75252799, 116.867264, 38.746001, 116.877288, 38.68069599,
        117.045245, 38.70578099, 117.098196, 38.5869, 117.229706, 38.64281201,
        117.25318, 38.55617699, 117.596852, 38.61399301, 117.767985, 38.328348,
        117.988066, 38.396555, 117.869314, 38.328501, 117.90635, 38.321681,
        117.854766, 38.26220931, 117.708055, 38.077938, 117.556506, 38.0572,
        117.434717, 37.85175, 116.803487, 37.85040699, 116.434129, 37.47335099,
        116.319704, 37.580193, 116.27863, 37.468298, 116.224456, 37.47973201,
        116.285543, 37.40373299, 116.236382, 37.361904, 116.1667095,
        37.38484191, 115.975751, 37.337297, 115.969398, 37.239483, 115.905268,
        37.20786999, 115.88282, 37.09908899, 115.686319, 36.808587, 115.47874,
        36.75933199, 115.272848, 36.497386, 115.361248, 36.31073, 115.422104,
        36.32384101, 115.467828, 36.267884, 115.4835482, 36.14900803,
        115.405082, 36.159917, 115.318903, 36.08696299, 115.242119, 36.19124,
        115.124977, 36.209969, 114.998437, 36.069714, 114.920162, 36.048207,
        114.912447, 36.14042499, 114.771318, 36.12452999, 114.7344184,
        36.15578345, 114.588758, 36.11846399, 114.345149, 36.2557988,
        114.068083, 36.272858, 114.02468, 36.35483801, 113.976966, 36.35769,
        113.993464, 36.314398, 113.953183, 36.35817899, 113.91119, 36.31478299,
        113.88194, 36.353858, 113.731467, 36.36337301, 113.708361, 36.42333999,
        113.546687, 36.48773601, 113.588144, 36.562721, 113.479116, 36.64064799,
        113.507045, 36.70485499, 113.465369, 36.707781, 113.680953, 36.790132,
        113.702581, 36.885195, 113.743841, 36.85058499, 113.791142, 36.874991,
        113.767369, 37.144616, 113.886426, 37.23914501, 114.139713, 37.67589999,
        114.068033, 37.72154301, 113.993796, 37.70687401, 114.045717,
        37.77222701, 113.862203, 38.00081101, 113.833916, 38.16688299,
        113.731129, 38.16834301, 113.712968, 38.213001, 113.54483, 38.27051099,
        113.562044, 38.55831499, 113.612869, 38.64601701, 113.709808,
        38.65529801, 113.713536, 38.70930499, 113.839575, 38.758387, 113.855542,
        38.82895401, 113.776203, 38.885651, 113.776737, 38.986957, 114.043415,
        39.13734499, 114.108714, 39.052266, 114.345864, 39.07527101, 114.388176,
        39.176993, 114.465612, 39.188541, 114.430374, 39.30654499, 114.568923,
        39.573958, 114.515353, 39.56500199, 114.414872, 39.64036299, 114.395428,
        39.86722501, 114.225483, 39.851422, 114.216097, 39.91768099, 114.047371,
        39.91612999, 114.024718, 39.989817, 113.910663, 40.011732, 113.979842,
        40.11228201, 114.043822, 40.05687699, 114.091151, 40.075312, 114.078817,
        40.18604, 114.451102, 40.25952399, 114.529215, 40.33114401, 114.314421,
        40.36959699, 114.282953, 40.59080099, 114.134689, 40.737352, 114.045014,
        40.83105, 114.055357, 40.929246, 113.819304, 41.09754299, 114.016236,
        41.23206101, 113.901413, 41.31100799, 113.948583, 41.39205401,
        113.871183, 41.413341, 114.040219, 41.533239, 114.230781, 41.513377,
        114.259633, 41.62336899, 114.200644, 41.789791, 114.352561, 41.953898,
        114.510703, 41.973319, 114.466081, 42.03417699, 114.510953, 42.11091601,
        114.75489, 42.11574399, 114.799259, 42.14963401, 114.858598,
        42.10719099, 114.939183, 41.84617399, 114.868848, 41.81353, 114.902903,
        41.68933699, 114.863706, 41.59163699, 115.099151, 41.623946, 115.20414,
        41.57139799, 115.281087, 41.625568, 115.366083, 41.596104, 115.326636,
        41.702252, 115.828839, 41.936989, 115.916381, 41.94513299, 116.016629,
        41.77706999, 116.098689, 41.77648799, 116.106672, 41.849564, 116.199307,
        41.864283, 116.327239, 42.005692, 116.409095, 41.994003, 116.393127,
        41.943017, 116.496423, 41.979711, 116.553226, 41.92856499, 116.868139,
        42.00290801, 116.88461, 42.11048401, 116.789255, 42.200251, 116.914244,
        42.19698399, 116.893688, 42.38784701, 117.09544, 42.484121, 117.390726,
        42.46206701, 117.396385, 42.53631401, 117.474409, 42.60252701,
        117.687498, 42.58289301, 117.792891, 42.618988, 118.019413, 42.395183,
        118.059974, 42.29828201, 117.968781, 42.24208701, 118.106326, 42.171966,
        118.091737, 42.10976399, 118.155189, 42.08115701, 118.116548,
        42.03718199, 118.204202, 42.03485799, 118.239311, 42.09280499,
        118.296233, 42.05775, 118.23761, 42.02288301, 118.314011, 41.987796,
        118.264925, 41.92016, 118.33529, 41.845417, 118.270834, 41.762209,
        118.165899, 41.81329099, 118.130675, 41.742294, 118.221267, 41.58896901,
        118.312198, 41.566541, 118.269828, 41.47544399, 118.348685, 41.428327,
        118.36528, 41.322161, 118.528125, 41.355057, 118.755019, 41.32543301,
        118.843723, 41.374537, 118.890713, 41.30072299, 119.200688, 41.28231299,
        119.23943, 41.31451899, 119.184282, 41.18272599, 119.081202,
        41.13143599, 119.073778, 41.084223, 118.936859, 41.05260701, 119.020422,
        40.997868, 118.902001, 40.960761, 118.849108, 40.80094401, 119.054544,
        40.66473501, 119.17623, 40.69016601, 119.157673, 40.604013, 119.230809,
        40.603862, 119.276503, 40.535009, 119.571003, 40.540861, 119.624342,
        40.225385, 119.745955, 40.20796199, 119.736696, 40.104858, 119.770884,
        40.04879699, 119.85371, 40.03332901, 119.8536786, 39.99059713,
        119.855475, 39.95084801, 119.83546, 39.98800101, 119.561824,
        39.90451401, 119.535179, 39.810545, 119.370248, 39.747897, 119.264344,
        39.52211701, 119.296927, 39.39930901, 119.191662, 39.36678401,
        118.999579, 39.183317, 119.038014, 39.17863999, 118.919444, 39.123111,
        118.963657, 39.182207, 118.685879, 39.126904, 118.542868, 39.152444,
        118.5770045, 39.10236245, 118.477157, 39.08553699, 118.413545,
        38.962599, 118.373521, 39.03782, 118.229234, 39.03473901, 118.129361,
        39.18347401, 118.036481, 39.22260601, 118.026999, 39.29141401,
        117.803371, 39.36204201, 117.865706, 39.37827299, 117.933988,
        39.57414801, 117.767534, 39.599743, 117.71595, 39.53008801, 117.705857,
        39.576491, 117.621258, 39.59217899, 117.646277, 39.70057799, 117.539015,
        39.760715, 117.535317, 39.995978, 117.781852, 39.96631899, 117.77619,
        40.05926399, 117.649584, 40.09127601, 117.564266, 40.22905099,
      ],
      [
        116.905951, 39.687918, 116.901818, 39.76362301, 116.953596, 39.787569,
        116.917439, 39.84691199, 116.7872167, 39.88672373, 116.777809,
        40.03244701, 117.021766, 40.029635, 117.209739, 40.08224946, 117.135969,
        39.91993801, 117.152851, 39.87548499, 117.259961, 39.843392, 117.156336,
        39.81748499, 117.205901, 39.76384499, 117.153656, 39.73631801,
        117.177897, 39.64297001, 116.974563, 39.636793, 116.950851, 39.706825,
      ],
      [
        117.5937679, 39.41193305, 117.78457, 39.37701414, 117.6709332,
        39.35645724, 117.6580872, 39.31539483, 117.5361859, 39.33804816,
      ],
      [
        118.4740685, 39.01251212, 118.457982, 38.943188, 118.423508,
        39.00909001, 118.492941, 39.05086301, 118.592176, 38.96861301, 118.5111,
        38.917125,
      ],
      [118.756581, 39.03010999, 118.706185, 39.015889, 118.745612, 39.06144],
    ],
  },
  {
    id: "140000",
    name: "山西",
    fullname: "山西省",
    pinyin: ["shan", "xi"],
    location: {
      lat: 36.812182,
      lng: 111.579116,
    },
    polygon: [
      [
        113.731467, 36.36337301, 113.698435, 36.18287201, 113.650921,
        36.17348199, 113.713757, 36.13297501, 113.65536, 36.126437, 113.69904,
        36.02244599, 113.637827, 35.98846401, 113.656873, 35.836643, 113.582829,
        35.82195901, 113.624855, 35.632013, 113.547986, 35.65755399, 113.506603,
        35.51650399, 113.312347, 35.48126099, 113.30328, 35.426686, 113.190025,
        35.44905899, 113.136925, 35.335621, 112.996485, 35.36217099, 112.988276,
        35.28930699, 112.908786, 35.24599801, 112.816017, 35.25763299,
        112.712651, 35.187008, 112.634798, 35.26546901, 112.637229, 35.22567801,
        112.567848, 35.21174499, 112.289961, 35.217068, 112.300789, 35.253775,
        112.058815, 35.27989601, 112.053542, 35.044238, 111.952126, 35.082114,
        111.815586, 35.067432, 111.665078, 34.985793, 111.681233, 34.94976899,
        111.570791, 34.84331701, 111.228098, 34.78914399, 111.160725,
        34.81459401, 111.122787, 34.75916599, 110.976295, 34.70644501,
        110.919788, 34.73024001, 110.883488, 34.64244, 110.824756, 34.625171,
        110.761427, 34.65418701, 110.706391, 34.60398401, 110.37918, 34.600531,
        110.2601051, 34.64105273, 110.233708, 34.83739301, 110.389536,
        35.26229001, 110.609331, 35.63215499, 110.450067, 36.13481101,
        110.50218, 36.53096199, 110.390629, 36.68522599, 110.435758, 36.681487,
        110.452453, 36.73267501, 110.382865, 36.770229, 110.42575, 36.95723401,
        110.376818, 37.015128, 110.447904, 37.00933699, 110.418016, 37.03513201,
        110.690037, 37.28706001, 110.64245, 37.429991, 110.748192, 37.452461,
        110.795678, 37.562192, 110.796149, 37.66304601, 110.70354, 37.71174401,
        110.75868, 37.752641, 110.518712, 37.96045701, 110.507865, 38.184914,
        110.567256, 38.217556, 110.584129, 38.30372, 110.746761, 38.36612601,
        110.786495, 38.44986201, 110.877045, 38.45688, 110.920655, 38.57800699,
        110.880796, 38.62693901, 111.010108, 38.84159799, 110.981042,
        38.98014901, 111.138341, 39.06429399, 111.2479258, 39.30068792,
        111.125579, 39.36645, 111.174354, 39.42410301, 111.35245, 39.426732,
        111.431514, 39.50877499, 111.4365653, 39.64327603, 111.497305,
        39.66183799, 111.78359, 39.58891201, 111.931959, 39.612194, 111.970531,
        39.79640999, 112.3103092, 40.25641603, 112.462413, 40.299095,
        112.744449, 40.167384, 112.84867, 40.20705801, 112.892957, 40.32640201,
        113.033133, 40.368917, 113.251357, 40.413391, 113.316641, 40.319925,
        113.537297, 40.34138799, 113.794802, 40.517985, 113.86343, 40.45646701,
        113.948684, 40.517335, 114.061926, 40.52883099, 114.06429, 40.70986301,
        114.134689, 40.737352, 114.282953, 40.59080099, 114.314421, 40.36959699,
        114.529215, 40.33114401, 114.451102, 40.25952399, 114.078817, 40.18604,
        114.091151, 40.075312, 114.043822, 40.05687699, 113.979842, 40.11228201,
        113.910663, 40.011732, 114.024718, 39.989817, 114.047371, 39.91612999,
        114.216097, 39.91768099, 114.225483, 39.851422, 114.395428, 39.86722501,
        114.414872, 39.64036299, 114.515353, 39.56500199, 114.568923, 39.573958,
        114.430374, 39.30654499, 114.465612, 39.188541, 114.388176, 39.176993,
        114.345864, 39.07527101, 114.108714, 39.052266, 114.043415, 39.13734499,
        113.776737, 38.986957, 113.776203, 38.885651, 113.855542, 38.82895401,
        113.839575, 38.758387, 113.713536, 38.70930499, 113.709808, 38.65529801,
        113.612869, 38.64601701, 113.562044, 38.55831499, 113.54483,
        38.27051099, 113.712968, 38.213001, 113.731129, 38.16834301, 113.833916,
        38.16688299, 113.862203, 38.00081101, 114.045717, 37.77222701,
        113.993796, 37.70687401, 114.068033, 37.72154301, 114.139713,
        37.67589999, 113.886426, 37.23914501, 113.767369, 37.144616, 113.791142,
        36.874991, 113.743841, 36.85058499, 113.702581, 36.885195, 113.680953,
        36.790132, 113.465369, 36.707781, 113.507045, 36.70485499, 113.479116,
        36.64064799, 113.588144, 36.562721, 113.546687, 36.48773601, 113.708361,
        36.42333999,
      ],
    ],
  },
  {
    id: "150000",
    name: "内蒙古",
    fullname: "内蒙古自治区",
    pinyin: ["nei", "meng", "gu"],
    location: {
      lat: 40.818233,
      lng: 109.765226,
    },
    polygon: [
      [
        114.134689, 40.737352, 114.06429, 40.70986301, 114.061926, 40.52883099,
        113.948684, 40.517335, 113.86343, 40.45646701, 113.794802, 40.517985,
        113.537297, 40.34138799, 113.316641, 40.319925, 113.251357, 40.413391,
        113.033133, 40.368917, 112.892957, 40.32640201, 112.84867, 40.20705801,
        112.744449, 40.167384, 112.462413, 40.299095, 112.3103092, 40.25641603,
        111.970531, 39.79640999, 111.931959, 39.612194, 111.78359, 39.58891201,
        111.497305, 39.66183799, 111.4365653, 39.64327603, 111.431514,
        39.50877499, 111.35245, 39.426732, 111.174354, 39.42410301, 111.125579,
        39.36645, 111.053843, 39.42992699, 111.144685, 39.523114, 111.136796,
        39.58701101, 110.868272, 39.493281, 110.688357, 39.267494, 110.60414,
        39.277539, 110.521731, 39.38318399, 110.430059, 39.3793309, 110.385231,
        39.31051601, 110.275546, 39.4248, 110.145527, 39.45720901, 110.130457,
        39.398255, 110.217957, 39.27974201, 110.010524, 39.208498, 109.884038,
        39.26295001, 109.961235, 39.191178, 109.893339, 39.14694299, 109.922102,
        39.107038, 109.851503, 39.122806, 109.672035, 38.99705401, 109.625749,
        38.847144, 109.549751, 38.80570601, 109.511178, 38.83374599, 109.336137,
        38.69895599, 109.362531, 38.62241699, 109.276717, 38.62299899,
        109.052058, 38.432058, 108.938506, 38.20743801, 109.063578, 38.10018501,
        109.069533, 38.02303201, 108.938372, 37.92087999, 108.871138,
        38.02727901, 108.797628, 38.04789501, 108.777708, 37.683536, 108.619254,
        37.652022, 108.532666, 37.690617, 108.332977, 37.63304399, 108.246498,
        37.66576399, 108.133918, 37.62201001, 108.022373, 37.65225499,
        107.981971, 37.78724899, 107.692705, 37.88812901, 107.650221, 37.864609,
        107.419398, 37.940688, 107.432844, 38.00507499, 107.1881, 38.15534,
        106.945096, 38.131733, 106.482788, 38.31681699, 106.601851, 38.39250599,
        106.702837, 38.708252, 106.954323, 38.94117599, 106.971383, 39.016986,
        106.795933, 39.21431999, 106.787197, 39.366932, 106.602614, 39.375329,
        106.509072, 39.270722, 106.284605, 39.27030701, 106.285072, 39.146314,
        106.146072, 39.153225, 106.060788, 38.96851199, 105.968876, 38.90508,
        106.002449, 38.871902, 105.897439, 38.78870901, 105.821023, 38.364372,
        105.866546, 38.29499801, 105.76793, 38.13444899, 105.840122,
        38.00400801, 105.76068, 37.79990901, 105.623118, 37.77860701,
        105.598948, 37.699392, 105.314785, 37.70205799, 105.024199, 37.579889,
        104.41966, 37.511821, 104.407574, 37.464642, 104.287231, 37.42784301,
        104.183248, 37.40677999, 103.676298, 37.78407099, 103.402308, 37.861617,
        103.368223, 37.98561799, 103.368751, 38.08891599, 103.534877, 38.156705,
        103.415599, 38.404553, 103.859736, 38.64448299, 104.044397, 38.89506499,
        104.196351, 38.98823699, 104.177535, 39.152157, 104.047635, 39.29769199,
        104.090074, 39.41984301, 103.83893, 39.460372, 103.356899, 39.336581,
        103.009954, 39.10003699, 102.605032, 39.171313, 102.453633, 39.25527399,
        101.833439, 39.09490299, 102.075015, 38.89153901, 101.777154,
        38.66058899, 101.672083, 38.691056, 101.605805, 38.65312001, 101.561021,
        38.713777, 101.31664, 38.783714, 101.335446, 38.846903, 101.242123,
        38.86122199, 101.198553, 38.94325101, 101.227118, 39.02178399,
        100.969248, 38.946989, 100.961556, 39.00608399, 100.829624, 39.035559,
        100.84209, 39.40574099, 100.499105, 39.40053899, 100.500543,
        39.48137201, 100.326254, 39.50911701, 100.250052, 39.68507901,
        99.90438999, 39.78579401, 99.82198999, 39.86007701, 99.44107199,
        39.885888, 99.87324, 40.02594101, 99.98830401, 40.18695601, 100.169624,
        40.277601, 100.169713, 40.54132201, 100.242573, 40.618667, 100.237267,
        40.71682399, 100.108021, 40.87554501, 99.67320401, 40.932951,
        99.56601101, 40.84675801, 99.17474499, 40.85831999, 99.17311101,
        40.747287, 99.10208899, 40.67638901, 98.985034, 40.78287401,
        98.79095199, 40.705541, 98.80273301, 40.60708299, 98.69760902,
        40.68026301, 98.66857999, 40.77291499, 98.57006201, 40.74687399,
        98.627986, 40.67792399, 98.34429701, 40.56836801, 98.33319401,
        40.91912301, 97.97206, 41.097929, 97.61648099, 41.464275, 97.846956,
        41.656602, 97.17276299, 42.79516301, 98.195396, 42.653304, 99.507556,
        42.56808, 99.969108, 42.64786501, 100.26091, 42.63712289, 100.325468,
        42.69002401, 100.862759, 42.671242, 101.803732, 42.50371099,
        102.0797898, 42.22838377, 102.44899, 42.14406199, 102.712017,
        42.15280301, 103.418502, 41.88235899, 103.857, 41.80079201, 104.530151,
        41.87489001, 104.524287, 41.661916, 104.923081, 41.654156, 105.009684,
        41.58321499, 105.230836, 41.751132, 105.741761, 41.94925501, 106.785906,
        42.29146601, 107.271773, 42.36409299, 107.304146, 42.41265399,
        107.466245, 42.458832, 107.574543, 42.413035, 107.939274, 42.40373501,
        108.2381, 42.46034401, 108.845642, 42.395742, 109.025756, 42.45855,
        109.291583, 42.435784, 109.5442419, 42.47235, 109.6866204, 42.56306296,
        109.90616, 42.63576491, 110.1078775, 42.64594874, 110.437191,
        42.78138899, 111.0205255, 43.33004255, 111.456601, 43.49439799,
        111.564588, 43.49033299, 111.794261, 43.67190599, 111.950835, 43.693112,
        111.96671, 43.80330001, 111.88409, 43.923678, 111.662998, 44.061196,
        111.526201, 44.27622301, 111.416015, 44.357165, 111.764521, 44.969337,
        112.002761, 45.09078501, 112.428788, 45.074722, 112.599892, 44.93058,
        112.8021669, 44.85936567, 113.631182, 44.745487, 113.907322,
        44.91514799, 114.116785, 44.956919, 114.459702, 45.21339701, 114.551156,
        45.387578, 114.745125, 45.43831399, 114.974127, 45.37703499, 115.36467,
        45.39236001, 115.6948573, 45.46243201, 116.035424, 45.68511501,
        116.174347, 45.68858001, 116.286866, 45.775145, 116.27171, 45.966749,
        116.585242, 46.29231701, 116.834544, 46.38409701, 117.372046, 46.360196,
        117.447691, 46.52818799, 117.420104, 46.58207101, 117.596007,
        46.60724691, 117.719356, 46.51673899, 118.316403, 46.739505, 118.787964,
        46.68704499, 118.84527, 46.771876, 118.914449, 46.77490701, 118.927499,
        46.72441599, 119.012093, 46.75034331, 119.104668, 46.641933, 119.262809,
        46.64886101, 119.313342, 46.61084901, 119.6514804, 46.62870603,
        119.677501, 46.58473101, 119.783559, 46.625887, 119.8025424,
        46.68785138, 119.911789, 46.66976391, 119.928799, 46.90376999,
        119.859728, 46.917128, 119.716015, 47.195627, 119.565702, 47.24923699,
        119.560632, 47.302421, 119.323922, 47.42525789, 119.3658418,
        47.47740699, 119.1521928, 47.540567, 119.1342138, 47.66449, 118.7680692,
        47.77266958, 118.5680298, 47.992146, 118.1781718, 48.050548,
        117.8134728, 48.01619, 117.3841698, 47.64122899, 117.0947628, 47.823946,
        116.8179098, 47.89760899, 116.4528568, 47.83755401, 116.2657608,
        47.87670999, 115.9390578, 47.683109, 115.5805348, 47.921773,
        115.5293168, 48.1552, 115.8228438, 48.25942199, 115.8018028,
        48.52289499, 116.0781438, 48.82243901, 116.0485568, 48.87345101,
        116.7251998, 49.855546, 117.0690798, 49.695539, 117.2781128, 49.636393,
        117.4851208, 49.63302899, 117.8158548, 49.52067399, 117.8678348,
        49.59280199, 118.0799088, 49.615054, 118.2246158, 49.73344399,
        118.3891508, 49.780831, 118.3852928, 49.82687399, 118.4843298,
        49.82986301, 118.5730138, 49.93096699, 119.0920618, 49.985948,
        119.1907638, 50.08830701, 119.2935328, 50.11087, 119.3700218, 50.350117,
        119.1835138, 50.34813201, 119.1258288, 50.39043599, 119.2163388,
        50.412137, 119.2825958, 50.6048, 119.4987998, 50.74740599, 119.5019578,
        50.89068701, 119.7640838, 51.09219599, 119.7604258, 51.21374099,
        119.8231488, 51.215739, 119.8114148, 51.281211, 119.9463628, 51.360742,
        119.9100008, 51.389941, 119.9712158, 51.400363, 120.0881488, 51.679323,
        120.1751598, 51.68095599, 120.4784948, 51.88466399, 120.6565468,
        51.926216, 120.7866118, 52.15768999, 120.7592308, 52.255598,
        120.6198548, 52.35358499, 120.7294818, 52.54501099, 120.4624148,
        52.645237, 120.1948478, 52.57892401, 120.0660488, 52.58909799,
        120.0324998, 52.77510399, 120.2888448, 52.86305099, 120.8831138,
        53.29455301, 121.2315608, 53.28071499, 121.499487, 53.337178,
        121.678901, 53.24134501, 121.657693, 53.17687, 121.75321, 53.14732199,
        121.817758, 53.06173101, 121.608949, 52.89110599, 121.591273,
        52.82467499, 121.18222, 52.59632399, 121.648009, 52.44247799,
        121.714104, 52.31812501, 121.835204, 52.283711, 121.947653, 52.298392,
        122.166227, 52.51491099, 122.207868, 52.469267, 122.310499, 52.47528899,
        122.411784, 52.375345, 122.447209, 52.39397, 122.478168, 52.296252,
        122.787285, 52.252665, 122.738039, 52.15350199, 122.628151, 52.135944,
        122.625161, 52.066468, 122.72616, 51.97880799, 122.74981, 51.74384099,
        122.885565, 51.547638, 122.854602, 51.47766501, 123.002656, 51.31213701,
        123.278021, 51.25405101, 123.440152, 51.270912, 123.661968, 51.31913799,
        123.718481, 51.39919801, 123.926142, 51.30064099, 124.224699,
        51.34990501, 124.406442, 51.272045, 124.490196, 51.38039499, 124.635593,
        51.32661901, 124.772384, 51.39012, 124.876659, 51.383276, 124.927285,
        51.49689301, 125.059509, 51.53880701, 125.120885, 51.66088301,
        125.369976, 51.613847, 125.695456, 51.33779399, 125.757161, 51.227448,
        125.851501, 51.21337401, 125.935606, 51.107517, 125.993649, 51.118931,
        126.075586, 50.96514301, 125.997069, 50.87203399, 125.960311, 50.900864,
        125.951036, 50.858487, 125.894143, 50.85730199, 125.839911, 50.75627001,
        125.759391, 50.75023499, 125.825145, 50.70490801, 125.822724,
        50.54842901, 125.564127, 50.441641, 125.579035, 50.40216801, 125.513258,
        50.409385, 125.546464, 50.358891, 125.463354, 50.295766, 125.464369,
        50.229013, 125.33435, 50.16498899, 125.378839, 50.13883999, 125.257468,
        50.102944, 125.33814, 50.05747301, 125.252177, 50.04554499, 125.29651,
        50.009505, 125.183881, 49.95687099, 125.245293, 49.87213101, 125.177819,
        49.82946099, 125.22091, 49.67221201, 125.191277, 49.63121099,
        125.127412, 49.655016, 125.2346, 49.59199699, 125.270568, 49.45427701,
        125.220965, 49.191047, 125.117488, 49.12599201, 125.039906, 49.17626599,
        124.906595, 49.18394099, 124.809461, 49.115896, 124.823349, 49.046559,
        124.518656, 48.55426001, 124.518822, 48.37791499, 124.580262, 48.302034,
        124.530683, 48.14788599, 124.416199, 48.087937, 124.477783, 48.16919801,
        124.409196, 48.19198801, 124.421511, 48.24661001, 124.317684,
        48.34885701, 124.314204, 48.503845, 124.259306, 48.53658899, 123.578509,
        48.04495701, 123.300356, 47.95368701, 123.173247, 47.78746299,
        122.574653, 47.53536699, 122.506811, 47.401394, 122.418106, 47.350441,
        122.613868, 47.12483901, 122.852721, 47.071692, 122.774714, 46.97403899,
        122.802417, 46.937357, 122.8952481, 46.96027972, 122.906991, 46.807415,
        123.015469, 46.72100701, 123.171242, 46.74386901, 123.235346, 46.863364,
        123.374547, 46.83762701, 123.404606, 46.93549101, 123.301725,
        46.99981701, 123.415705, 46.935734, 123.527039, 46.95913399, 123.506551,
        46.827062, 123.566189, 46.82774, 123.608835, 46.88804199, 123.604347,
        46.68976501, 123.277399, 46.661554, 123.22841, 46.58823599, 123.048549,
        46.619308, 123.002725, 46.574898, 123.010678, 46.435802, 123.1786,
        46.24809401, 123.112412, 46.130079, 122.793141, 46.07320299, 122.826714,
        45.89798901, 122.753058, 45.83708599, 122.792416, 45.76624499,
        122.742035, 45.70513799, 122.671323, 45.700612, 122.558228, 45.82108999,
        122.505637, 45.78635099, 122.445967, 45.91692499, 122.362059,
        45.91743399, 122.373105, 45.85612101, 122.258789, 45.794853, 122.091549,
        45.88197699, 122.004581, 45.984623, 121.812898, 46.02586, 121.759121,
        45.993845, 121.81715, 45.87561199, 121.644364, 45.75248999, 121.756861,
        45.69074699, 121.949073, 45.71102599, 122.002478, 45.507885, 122.166707,
        45.441551, 122.147285, 45.295589, 122.239008, 45.276301, 122.230085,
        45.20685699, 122.109868, 45.14205601, 122.044908, 44.89948601,
        122.099887, 44.782377, 122.169124, 44.77023299, 122.098874, 44.744809,
        122.161397, 44.72826601, 122.103047, 44.67396201, 122.131672,
        44.57753001, 122.202773, 44.554633, 122.228107, 44.48014901, 122.286112,
        44.477767, 122.271386, 44.25566601, 122.482683, 44.236925, 122.675548,
        44.28578101, 122.760645, 44.36971599, 123.133246, 44.50513001,
        123.123472, 44.36428, 123.390478, 44.16278201, 123.320799, 44.052038,
        123.458176, 43.87491901, 123.537201, 43.649698, 123.510693, 43.59229101,
        123.42378, 43.612354, 123.453113, 43.54592399, 123.304512, 43.550715,
        123.31603, 43.49201301, 123.419844, 43.41007201, 123.487006,
        43.44549701, 123.608581, 43.36628001, 123.703737, 43.37068201,
        123.586484, 43.01264099, 123.471867, 43.04271101, 123.259423,
        42.99329399, 123.184017, 42.925524, 123.1707, 42.852124, 123.221885,
        42.825324, 123.058479, 42.769087, 122.887309, 42.77011699, 122.855406,
        42.707094, 122.731312, 42.78696699, 122.625065, 42.77323301, 122.566437,
        42.82502599, 122.416766, 42.84234201, 122.349981, 42.825888, 122.373322,
        42.775449, 122.458772, 42.76966699, 122.395969, 42.68420699, 122.33702,
        42.670505, 122.204976, 42.73265501, 122.1962062, 42.6790482, 122.063991,
        42.72344, 121.964922, 42.700538, 121.870262, 42.528281, 121.703248,
        42.44008801, 121.60684, 42.51639299, 121.415444, 42.486049, 121.069232,
        42.25275801, 120.887178, 42.2714939, 120.481956, 42.115793, 120.45633,
        42.015409, 120.272135, 41.92559201, 120.300368, 41.88812101, 120.188431,
        41.84823101, 120.096197, 41.69706599, 120.035288, 41.70887601,
        120.048976, 41.827625, 119.843605, 42.10008501, 119.852903, 42.209805,
        119.616997, 42.25280699, 119.539428, 42.29409699, 119.572178, 42.359486,
        119.502331, 42.388031, 119.237934, 42.20088, 119.385008, 42.089542,
        119.289765, 41.78574601, 119.313007, 41.64008799, 119.419897,
        41.58151601, 119.361641, 41.565927, 119.404986, 41.48220201, 119.305171,
        41.40242201, 119.326265, 41.329391, 119.23943, 41.31451899, 119.200688,
        41.28231299, 118.890713, 41.30072299, 118.843723, 41.374537, 118.755019,
        41.32543301, 118.528125, 41.355057, 118.36528, 41.322161, 118.348685,
        41.428327, 118.269828, 41.47544399, 118.312198, 41.566541, 118.221267,
        41.58896901, 118.130675, 41.742294, 118.165899, 41.81329099, 118.270834,
        41.762209, 118.33529, 41.845417, 118.264925, 41.92016, 118.314011,
        41.987796, 118.23761, 42.02288301, 118.296233, 42.05775, 118.239311,
        42.09280499, 118.204202, 42.03485799, 118.116548, 42.03718199,
        118.155189, 42.08115701, 118.091737, 42.10976399, 118.106326, 42.171966,
        117.968781, 42.24208701, 118.059974, 42.29828201, 118.019413, 42.395183,
        117.792891, 42.618988, 117.687498, 42.58289301, 117.474409, 42.60252701,
        117.396385, 42.53631401, 117.390726, 42.46206701, 117.09544, 42.484121,
        116.893688, 42.38784701, 116.914244, 42.19698399, 116.789255, 42.200251,
        116.88461, 42.11048401, 116.868139, 42.00290801, 116.553226,
        41.92856499, 116.496423, 41.979711, 116.393127, 41.943017, 116.409095,
        41.994003, 116.327239, 42.005692, 116.199307, 41.864283, 116.106672,
        41.849564, 116.098689, 41.77648799, 116.016629, 41.77706999, 115.916381,
        41.94513299, 115.828839, 41.936989, 115.326636, 41.702252, 115.366083,
        41.596104, 115.281087, 41.625568, 115.20414, 41.57139799, 115.099151,
        41.623946, 114.863706, 41.59163699, 114.902903, 41.68933699, 114.868848,
        41.81353, 114.939183, 41.84617399, 114.858598, 42.10719099, 114.799259,
        42.14963401, 114.75489, 42.11574399, 114.510953, 42.11091601,
        114.466081, 42.03417699, 114.510703, 41.973319, 114.352561, 41.953898,
        114.200644, 41.789791, 114.259633, 41.62336899, 114.230781, 41.513377,
        114.040219, 41.533239, 113.871183, 41.413341, 113.948583, 41.39205401,
        113.901413, 41.31100799, 114.016236, 41.23206101, 113.819304,
        41.09754299, 114.055357, 40.929246, 114.045014, 40.83105,
      ],
      [
        124.342589, 50.18814301, 124.345196, 50.33191, 124.43988, 50.38554299,
        124.433434, 50.54668699, 124.076773, 50.56631099, 123.983613, 50.51021,
        124.004973, 50.432083, 123.920311, 50.37302099, 123.796748, 50.45298401,
        123.766224, 50.37453101, 123.878919, 50.208701, 123.948766, 50.185298,
        124.174184, 50.23735001, 124.228295, 50.16877, 124.233733, 50.22922001,
      ],
    ],
  },
  {
    id: "210000",
    name: "辽宁",
    fullname: "辽宁省",
    pinyin: ["liao", "ning"],
    location: {
      lat: 41.4,
      lng: 123.435093,
    },
    polygon: [
      [
        119.23943, 41.31451899, 119.326265, 41.329391, 119.305171, 41.40242201,
        119.404986, 41.48220201, 119.361641, 41.565927, 119.419897, 41.58151601,
        119.313007, 41.64008799, 119.289765, 41.78574601, 119.385008, 42.089542,
        119.237934, 42.20088, 119.502331, 42.388031, 119.572178, 42.359486,
        119.539428, 42.29409699, 119.616997, 42.25280699, 119.852903, 42.209805,
        119.843605, 42.10008501, 120.048976, 41.827625, 120.035288, 41.70887601,
        120.096197, 41.69706599, 120.188431, 41.84823101, 120.300368,
        41.88812101, 120.272135, 41.92559201, 120.45633, 42.015409, 120.481956,
        42.115793, 120.887178, 42.2714939, 121.069232, 42.25275801, 121.415444,
        42.486049, 121.60684, 42.51639299, 121.703248, 42.44008801, 121.870262,
        42.528281, 121.964922, 42.700538, 122.063991, 42.72344, 122.1962062,
        42.6790482, 122.204976, 42.73265501, 122.33702, 42.670505, 122.395969,
        42.68420699, 122.458772, 42.76966699, 122.373322, 42.775449, 122.349981,
        42.825888, 122.416766, 42.84234201, 122.566437, 42.82502599, 122.625065,
        42.77323301, 122.731312, 42.78696699, 122.855406, 42.707094, 122.887309,
        42.77011699, 123.058479, 42.769087, 123.221885, 42.825324, 123.1707,
        42.852124, 123.184017, 42.925524, 123.259423, 42.99329399, 123.471867,
        43.04271101, 123.586484, 43.01264099, 123.703737, 43.37068201,
        123.797804, 43.48979, 123.872333, 43.451337, 123.895861, 43.36152799,
        124.030726, 43.28206999, 124.283218, 43.229437, 124.292473, 43.15430599,
        124.425948, 43.07629901, 124.33342, 42.99734201, 124.442311,
        42.95865201, 124.3665512, 42.89185681, 124.439525, 42.878132,
        124.455617, 42.823768, 124.660409, 42.97391101, 124.692865, 43.05608199,
        124.895635, 43.13067601, 124.840025, 43.02874, 124.872255, 42.79169,
        124.974191, 42.80338699, 124.965111, 42.677416, 125.030006, 42.61676699,
        125.097273, 42.62239599, 125.066803, 42.50278799, 125.196242,
        42.41059899, 125.175371, 42.30831999, 125.299234, 42.289481, 125.305973,
        42.14627401, 125.368843, 42.18279401, 125.489906, 42.13624701,
        125.292667, 41.963466, 125.351712, 41.928259, 125.296325, 41.887095,
        125.346313, 41.76223901, 125.317009, 41.67730599, 125.450639,
        41.67380601, 125.548469, 41.40086099, 125.79146, 41.166873, 125.713372,
        41.10462799, 125.681927, 40.980171, 125.577504, 40.90199501, 125.655425,
        40.91596099, 125.707742, 40.865785, 125.636484, 40.80791801, 125.685301,
        40.76914, 125.585464, 40.78845599, 125.420741, 40.63406899, 125.290167,
        40.65822699, 125.047743, 40.55048501, 125.001822, 40.513383, 125.044291,
        40.46645499, 124.902963, 40.484014, 124.7243161, 40.32266772,
        124.527233, 40.23105801, 124.347456, 40.08036099, 124.3541607,
        39.97703249, 124.172976, 39.856377, 124.146378, 39.747711, 124.104039,
        39.779611, 124.142284, 39.84208001, 124.099862, 39.79047599, 124.121278,
        39.83067599, 124.00955, 39.80260199, 123.634269, 39.83418301,
        123.547489, 39.75049899, 123.537005, 39.789455, 123.393101, 39.72705499,
        123.398878, 39.770135, 123.246867, 39.752831, 123.284121, 39.69509899,
        123.137085, 39.647628, 123.008096, 39.67908701, 122.968999, 39.59133901,
        122.938265, 39.62295901, 122.686592, 39.51642699, 122.652119,
        39.54089601, 122.550662, 39.39788101, 122.556698, 39.442042, 122.34102,
        39.38529399, 122.212446, 39.244178, 122.11754, 39.21948201, 122.109908,
        39.17017799, 122.169604, 39.15409701, 122.040668, 39.11234201,
        122.068091, 39.063791, 122.007999, 39.07742899, 121.967465, 39.03196101,
        121.911221, 39.05947799, 121.916668, 39.01488699, 121.842586,
        39.04423299, 121.910627, 38.96574401, 121.838415, 38.95202201,
        121.766335, 39.03335199, 121.661304, 39.016508, 121.611614, 38.93231899,
        121.719453, 38.91804701, 121.697928, 38.86526899, 121.574178,
        38.87936999, 121.495104, 38.814132, 121.234312, 38.80441001, 121.198071,
        38.72333199, 121.137853, 38.72769901, 121.114814, 38.776916, 121.11697,
        38.94959401, 121.333077, 38.971461, 121.378368, 39.061836, 121.506132,
        39.029963, 121.617061, 39.094028, 121.56482, 39.13035701, 121.620874,
        39.1175, 121.591622, 39.152825, 121.641204, 39.11218801, 121.592519,
        39.065387, 121.639079, 39.077083, 121.685294, 39.123915, 121.602701,
        39.16882001, 121.591164, 39.22540899, 121.643187, 39.24145, 121.590702,
        39.26853001, 121.736296, 39.30744599, 121.741705, 39.383401, 121.926648,
        39.40005001, 121.709327, 39.408699, 121.689169, 39.34961399, 121.525552,
        39.33156901, 121.484897, 39.28433901, 121.475119, 39.32799501,
        121.429424, 39.30362099, 121.444685, 39.43066401, 121.458804,
        39.37645101, 121.404632, 39.363353, 121.321754, 39.38058001, 121.370162,
        39.44789199, 121.248682, 39.38956401, 121.236356, 39.45752301,
        121.271461, 39.421617, 121.241666, 39.45189801, 121.407404, 39.512429,
        121.288822, 39.48751501, 121.277801, 39.51555899, 121.261579,
        39.47529701, 121.229279, 39.54622299, 121.302908, 39.60393901,
        121.456796, 39.622601, 121.514946, 39.67040899, 121.478255, 39.81102901,
        121.699831, 39.93482901, 121.782162, 39.933926, 121.8295058,
        40.00290979, 121.767984, 39.992982, 122.006411, 40.13067001,
        121.9414844, 40.23694529, 122.105207, 40.23839101, 122.051365,
        40.239912, 122.043871, 40.31752601, 122.11734, 40.310257, 122.112758,
        40.36466001, 122.17919, 40.34611, 122.2583938, 40.44531895, 122.230753,
        40.47853301, 122.284248, 40.48798099, 122.135445, 40.61725101,
        122.1585267, 40.6850626, 121.981944, 40.673721, 122.00702, 40.736529,
        121.965607, 40.75685499, 121.970972, 40.67712899, 121.956968,
        40.79822001, 121.848801, 40.823416, 121.824117, 40.951767, 121.757306,
        40.84434699, 121.683936, 40.83249901, 121.612421, 40.862879, 121.549885,
        40.82095799, 121.560746, 40.856791, 121.348511, 40.92337799, 121.238921,
        40.86014849, 121.217282, 40.89067, 121.096463, 40.875037, 121.084417,
        40.79780601, 121.0206535, 40.77992246, 121.013808, 40.83145401,
        120.979306, 40.82208999, 120.979734, 40.74521201, 120.9402, 40.744572,
        121.03708, 40.713234, 120.833958, 40.67853399, 120.716112, 40.51493899,
        120.599462, 40.47047899, 120.608098, 40.37724499, 120.503198,
        40.32677299, 120.521655, 40.25813799, 120.472494, 40.20828701,
        120.319146, 40.18750099, 120.121243, 40.102221, 120.13417, 40.07486101,
        119.961142, 40.06066101, 119.8536786, 39.99059713, 119.85371,
        40.03332901, 119.770884, 40.04879699, 119.736696, 40.104858, 119.745955,
        40.20796199, 119.624342, 40.225385, 119.571003, 40.540861, 119.276503,
        40.535009, 119.230809, 40.603862, 119.157673, 40.604013, 119.17623,
        40.69016601, 119.054544, 40.66473501, 118.849108, 40.80094401,
        118.902001, 40.960761, 119.020422, 40.997868, 118.936859, 41.05260701,
        119.073778, 41.084223, 119.081202, 41.13143599, 119.184282, 41.18272599,
      ],
      [120.806864, 40.522554, 120.832924, 40.49407399, 120.784547, 40.474433],
      [122.9622838, 39.55035149, 123.036486, 39.534023, 122.995605, 39.495828],
      [
        122.509512, 39.30198201, 122.688624, 39.26917399, 122.586901,
        39.25082299,
      ],
      [
        122.6358211, 39.24053073, 122.754011, 39.23399601, 122.692428,
        39.20077399, 122.704703, 39.23267499,
      ],
      [
        122.393006, 39.21395301, 122.402875, 39.164442, 122.336692, 39.146532,
        122.316286, 39.18560099,
      ],
      [
        123.1740301, 39.10110962, 123.203251, 39.05753699, 123.163575,
        39.02770501,
      ],
    ],
  },
  {
    id: "220000",
    name: "吉林",
    fullname: "吉林省",
    pinyin: ["ji", "lin"],
    location: {
      lat: 42.896942,
      lng: 127.325802,
    },
    polygon: [
      [
        123.1786, 46.24809401, 123.248153, 46.273312, 123.373586, 46.22297,
        123.499432, 46.25965201, 123.569483, 46.22360401, 123.849869, 46.302363,
        123.905358, 46.300545, 123.909919, 46.25749999, 123.9583, 46.289954,
        124.041015, 46.02520599, 123.991402, 46.017416, 123.967534, 45.941191,
        124.07067, 45.855399, 124.001964, 45.770663, 124.134968, 45.69045301,
        124.147226, 45.623056, 124.226626, 45.63342901, 124.283704, 45.54132,
        124.358707, 45.54484101, 124.3988, 45.44060801, 124.473884, 45.45872201,
        124.549717, 45.41137601, 124.58106, 45.45342501, 124.768415, 45.469227,
        124.876282, 45.438304, 124.923563, 45.54120901, 125.028365, 45.49221899,
        125.071373, 45.38400099, 125.317186, 45.42285499, 125.362286,
        45.39266501, 125.422988, 45.48485001, 125.497355, 45.469333, 125.628329,
        45.52195999, 125.701966, 45.501824, 125.695365, 45.35227401, 125.751867,
        45.28725701, 125.99816, 45.16222201, 126.235653, 45.140216, 126.292192,
        45.192256, 126.572507, 45.25268701, 126.792609, 45.135374, 126.96,
        45.13714914, 127.093726, 44.94592, 126.984281, 44.8246726, 127.049188,
        44.567237, 127.210444, 44.64815801, 127.556469, 44.57603501, 127.543098,
        44.526342, 127.465661, 44.51653, 127.483575, 44.40187199, 127.622088,
        44.28214801, 127.626167, 44.18809901, 127.681163, 44.166866, 127.719668,
        44.202441, 127.728945, 44.09922501, 127.784014, 44.071821, 127.912779,
        44.064801, 128.101444, 44.13574399, 128.050311, 44.350449, 128.167859,
        44.344193, 128.223178, 44.44329601, 128.372791, 44.514347, 128.48169,
        44.37565501, 128.471938, 44.15769599, 128.636335, 43.891509, 128.758956,
        43.862121, 128.727617, 43.74088001, 128.877987, 43.54014499, 129.012787,
        43.523079, 129.224925, 43.587663, 129.21123, 43.78216201, 129.255306,
        43.81962001, 129.357412, 43.79874299, 129.467967, 43.87458701,
        129.742876, 43.87598401, 129.782588, 43.963014, 129.939724, 44.02792699,
        130.079493, 43.83498699, 130.347261, 44.05132301, 130.41329,
        43.65217299, 130.630889, 43.622202, 130.625249, 43.585641, 130.79264,
        43.52763599, 130.861703, 43.438051, 131.026747, 43.50875401, 131.142397,
        43.425811, 131.304308, 43.50222901, 131.317602, 43.39742999, 131.205896,
        43.236626, 131.218488, 43.14705299, 131.102554, 43.02316001, 131.14549,
        42.93645901, 131.034334, 42.929135, 131.043178, 42.863046, 130.826506,
        42.882624, 130.77901, 42.83884901, 130.667052, 42.848182, 130.401374,
        42.728497, 130.591566, 42.67234501, 130.633457, 42.590592, 130.565411,
        42.507061, 130.640205, 42.422693, 130.576768, 42.43886901, 130.522826,
        42.62239599, 130.441828, 42.54983201, 130.446775, 42.607244, 130.387913,
        42.60306199, 130.257701, 42.71089199, 130.267239, 42.902862, 130.116415,
        42.91328999, 130.115344, 42.98841999, 129.897144, 43.00170301,
        129.764872, 42.72249699, 129.7866717, 42.60679999, 129.710863,
        42.42904199, 129.587662, 42.448478, 129.554464, 42.36022399, 129.452331,
        42.44099701, 129.34431, 42.451579, 129.354805, 42.409465, 129.3148161,
        42.42138653, 129.3261457, 42.38627498, 129.244512, 42.37889501,
        129.256662, 42.323233, 129.204447, 42.31902501, 129.222025, 42.21194001,
        128.96973, 42.096122, 128.909947, 42.01309199, 128.750377, 42.050521,
        128.569174, 41.99643199, 128.0441956, 42.00510371, 128.107461,
        41.94912199, 128.112841, 41.79392601, 128.316425, 41.60358099, 128.245,
        41.451185, 128.117388, 41.36468701, 128.01305, 41.44795901, 127.985519,
        41.41800001, 127.879647, 41.44752599, 127.870101, 41.40396, 127.658147,
        41.40647801, 127.547162, 41.47717199, 127.261768, 41.48090899,
        127.281789, 41.514286, 127.105019, 41.54576, 127.184392, 41.59548901,
        127.039436, 41.672038, 127.08385, 41.70021401, 127.053997, 41.74296001,
        126.936603, 41.775485, 126.936109, 41.81039658, 126.84273, 41.72841901,
        126.809564, 41.74928001, 126.797779, 41.696401, 126.711701, 41.75541801,
        126.687776, 41.67405101, 126.609573, 41.671261, 126.50869, 41.43975999,
        126.536132, 41.357709, 126.438349, 41.35414201, 126.283227, 41.16138401,
        126.1241419, 41.08986511, 126.10842, 41.00668599, 125.976755,
        40.91532499, 126.027512, 40.90189901, 125.707742, 40.865785, 125.655425,
        40.91596099, 125.577504, 40.90199501, 125.681927, 40.980171, 125.713372,
        41.10462799, 125.79146, 41.166873, 125.548469, 41.40086099, 125.450639,
        41.67380601, 125.317009, 41.67730599, 125.346313, 41.76223901,
        125.296325, 41.887095, 125.351712, 41.928259, 125.292667, 41.963466,
        125.489906, 42.13624701, 125.368843, 42.18279401, 125.305973,
        42.14627401, 125.299234, 42.289481, 125.175371, 42.30831999, 125.196242,
        42.41059899, 125.066803, 42.50278799, 125.097273, 42.62239599,
        125.030006, 42.61676699, 124.965111, 42.677416, 124.974191, 42.80338699,
        124.872255, 42.79169, 124.840025, 43.02874, 124.895635, 43.13067601,
        124.692865, 43.05608199, 124.660409, 42.97391101, 124.455617, 42.823768,
        124.439525, 42.878132, 124.3665512, 42.89185681, 124.442311,
        42.95865201, 124.33342, 42.99734201, 124.425948, 43.07629901,
        124.292473, 43.15430599, 124.283218, 43.229437, 124.030726, 43.28206999,
        123.895861, 43.36152799, 123.872333, 43.451337, 123.797804, 43.48979,
        123.703737, 43.37068201, 123.608581, 43.36628001, 123.487006,
        43.44549701, 123.419844, 43.41007201, 123.31603, 43.49201301,
        123.304512, 43.550715, 123.453113, 43.54592399, 123.42378, 43.612354,
        123.510693, 43.59229101, 123.537201, 43.649698, 123.458176, 43.87491901,
        123.320799, 44.052038, 123.390478, 44.16278201, 123.123472, 44.36428,
        123.133246, 44.50513001, 122.760645, 44.36971599, 122.675548,
        44.28578101, 122.482683, 44.236925, 122.271386, 44.25566601, 122.286112,
        44.477767, 122.228107, 44.48014901, 122.202773, 44.554633, 122.131672,
        44.57753001, 122.103047, 44.67396201, 122.161397, 44.72826601,
        122.098874, 44.744809, 122.169124, 44.77023299, 122.099887, 44.782377,
        122.044908, 44.89948601, 122.109868, 45.14205601, 122.230085,
        45.20685699, 122.239008, 45.276301, 122.147285, 45.295589, 122.166707,
        45.441551, 122.002478, 45.507885, 121.949073, 45.71102599, 121.756861,
        45.69074699, 121.644364, 45.75248999, 121.81715, 45.87561199,
        121.759121, 45.993845, 121.812898, 46.02586, 122.004581, 45.984623,
        122.091549, 45.88197699, 122.258789, 45.794853, 122.373105, 45.85612101,
        122.362059, 45.91743399, 122.445967, 45.91692499, 122.505637,
        45.78635099, 122.558228, 45.82108999, 122.671323, 45.700612, 122.742035,
        45.70513799, 122.792416, 45.76624499, 122.753058, 45.83708599,
        122.826714, 45.89798901, 122.793141, 46.07320299, 123.112412, 46.130079,
      ],
    ],
  },
  {
    id: "230000",
    name: "黑龙江",
    fullname: "黑龙江省",
    pinyin: ["hei", "long", "jiang"],
    location: {
      lat: 46.742253,
      lng: 128.661998,
    },
    polygon: [
      [
        124.342589, 50.18814301, 124.233733, 50.22922001, 124.228295, 50.16877,
        124.174184, 50.23735001, 123.948766, 50.185298, 123.878919, 50.208701,
        123.766224, 50.37453101, 123.796748, 50.45298401, 123.920311,
        50.37302099, 124.004973, 50.432083, 123.983613, 50.51021, 124.076773,
        50.56631099, 124.433434, 50.54668699, 124.43988, 50.38554299,
        124.345196, 50.33191,
      ],
      [
        131.304308, 43.50222901, 131.142397, 43.425811, 131.026747, 43.50875401,
        130.861703, 43.438051, 130.79264, 43.52763599, 130.625249, 43.585641,
        130.630889, 43.622202, 130.41329, 43.65217299, 130.347261, 44.05132301,
        130.079493, 43.83498699, 129.939724, 44.02792699, 129.782588, 43.963014,
        129.742876, 43.87598401, 129.467967, 43.87458701, 129.357412,
        43.79874299, 129.255306, 43.81962001, 129.21123, 43.78216201,
        129.224925, 43.587663, 129.012787, 43.523079, 128.877987, 43.54014499,
        128.727617, 43.74088001, 128.758956, 43.862121, 128.636335, 43.891509,
        128.471938, 44.15769599, 128.48169, 44.37565501, 128.372791, 44.514347,
        128.223178, 44.44329601, 128.167859, 44.344193, 128.050311, 44.350449,
        128.101444, 44.13574399, 127.912779, 44.064801, 127.784014, 44.071821,
        127.728945, 44.09922501, 127.719668, 44.202441, 127.681163, 44.166866,
        127.626167, 44.18809901, 127.622088, 44.28214801, 127.483575,
        44.40187199, 127.465661, 44.51653, 127.543098, 44.526342, 127.556469,
        44.57603501, 127.210444, 44.64815801, 127.049188, 44.567237, 126.984281,
        44.8246726, 127.093726, 44.94592, 126.96, 45.13714914, 126.792609,
        45.135374, 126.572507, 45.25268701, 126.292192, 45.192256, 126.235653,
        45.140216, 125.99816, 45.16222201, 125.751867, 45.28725701, 125.695365,
        45.35227401, 125.701966, 45.501824, 125.628329, 45.52195999, 125.497355,
        45.469333, 125.422988, 45.48485001, 125.362286, 45.39266501, 125.317186,
        45.42285499, 125.071373, 45.38400099, 125.028365, 45.49221899,
        124.923563, 45.54120901, 124.876282, 45.438304, 124.768415, 45.469227,
        124.58106, 45.45342501, 124.549717, 45.41137601, 124.473884,
        45.45872201, 124.3988, 45.44060801, 124.358707, 45.54484101, 124.283704,
        45.54132, 124.226626, 45.63342901, 124.147226, 45.623056, 124.134968,
        45.69045301, 124.001964, 45.770663, 124.07067, 45.855399, 123.967534,
        45.941191, 123.991402, 46.017416, 124.041015, 46.02520599, 123.9583,
        46.289954, 123.909919, 46.25749999, 123.905358, 46.300545, 123.849869,
        46.302363, 123.569483, 46.22360401, 123.499432, 46.25965201, 123.373586,
        46.22297, 123.248153, 46.273312, 123.1786, 46.24809401, 123.010678,
        46.435802, 123.002725, 46.574898, 123.048549, 46.619308, 123.22841,
        46.58823599, 123.277399, 46.661554, 123.604347, 46.68976501, 123.608835,
        46.88804199, 123.566189, 46.82774, 123.506551, 46.827062, 123.527039,
        46.95913399, 123.415705, 46.935734, 123.301725, 46.99981701, 123.404606,
        46.93549101, 123.374547, 46.83762701, 123.235346, 46.863364, 123.171242,
        46.74386901, 123.015469, 46.72100701, 122.906991, 46.807415,
        122.8952481, 46.96027972, 122.802417, 46.937357, 122.774714,
        46.97403899, 122.852721, 47.071692, 122.613868, 47.12483901, 122.418106,
        47.350441, 122.506811, 47.401394, 122.574653, 47.53536699, 123.173247,
        47.78746299, 123.300356, 47.95368701, 123.578509, 48.04495701,
        124.259306, 48.53658899, 124.314204, 48.503845, 124.317684, 48.34885701,
        124.421511, 48.24661001, 124.409196, 48.19198801, 124.477783,
        48.16919801, 124.416199, 48.087937, 124.530683, 48.14788599, 124.580262,
        48.302034, 124.518822, 48.37791499, 124.518656, 48.55426001, 124.823349,
        49.046559, 124.809461, 49.115896, 124.906595, 49.18394099, 125.039906,
        49.17626599, 125.117488, 49.12599201, 125.220965, 49.191047, 125.270568,
        49.45427701, 125.2346, 49.59199699, 125.127412, 49.655016, 125.191277,
        49.63121099, 125.22091, 49.67221201, 125.177819, 49.82946099,
        125.245293, 49.87213101, 125.183881, 49.95687099, 125.29651, 50.009505,
        125.252177, 50.04554499, 125.33814, 50.05747301, 125.257468, 50.102944,
        125.378839, 50.13883999, 125.33435, 50.16498899, 125.464369, 50.229013,
        125.463354, 50.295766, 125.546464, 50.358891, 125.513258, 50.409385,
        125.579035, 50.40216801, 125.564127, 50.441641, 125.822724, 50.54842901,
        125.825145, 50.70490801, 125.759391, 50.75023499, 125.839911,
        50.75627001, 125.894143, 50.85730199, 125.951036, 50.858487, 125.960311,
        50.900864, 125.997069, 50.87203399, 126.075586, 50.96514301, 125.993649,
        51.118931, 125.935606, 51.107517, 125.851501, 51.21337401, 125.757161,
        51.227448, 125.695456, 51.33779399, 125.369976, 51.613847, 125.120885,
        51.66088301, 125.059509, 51.53880701, 124.927285, 51.49689301,
        124.876659, 51.383276, 124.772384, 51.39012, 124.635593, 51.32661901,
        124.490196, 51.38039499, 124.406442, 51.272045, 124.224699, 51.34990501,
        123.926142, 51.30064099, 123.718481, 51.39919801, 123.661968,
        51.31913799, 123.440152, 51.270912, 123.278021, 51.25405101, 123.002656,
        51.31213701, 122.854602, 51.47766501, 122.885565, 51.547638, 122.74981,
        51.74384099, 122.72616, 51.97880799, 122.625161, 52.066468, 122.628151,
        52.135944, 122.738039, 52.15350199, 122.787285, 52.252665, 122.478168,
        52.296252, 122.447209, 52.39397, 122.411784, 52.375345, 122.310499,
        52.47528899, 122.207868, 52.469267, 122.166227, 52.51491099, 121.947653,
        52.298392, 121.835204, 52.283711, 121.714104, 52.31812501, 121.648009,
        52.44247799, 121.18222, 52.59632399, 121.591273, 52.82467499,
        121.608949, 52.89110599, 121.817758, 53.06173101, 121.75321,
        53.14732199, 121.657693, 53.17687, 121.678901, 53.24134501, 121.499487,
        53.337178, 121.876007, 53.42668, 122.097023, 53.42322499, 122.339115,
        53.50496201, 122.43529, 53.44470701, 122.844762, 53.45741799,
        123.145064, 53.498366, 123.280405, 53.563624, 123.499075, 53.49787801,
        123.517407, 53.55839401, 123.544757, 53.497926, 123.612935, 53.55149601,
        123.704927, 53.49533899, 123.894077, 53.48130901, 124.12431, 53.348395,
        124.251586, 53.37644599, 124.435901, 53.22391601, 124.688386,
        53.20539601, 124.87255, 53.099096, 124.887615, 53.16437699, 125.141949,
        53.204256, 125.492401, 53.101287, 125.521301, 53.049765, 125.619507,
        53.081589, 125.75195, 52.98549301, 125.67089, 52.863945, 125.836193,
        52.898817, 125.966708, 52.760058, 126.047707, 52.80019601, 126.116371,
        52.768159, 126.045525, 52.74410901, 126.071477, 52.68136601, 125.987044,
        52.67082601, 125.979223, 52.61241601, 126.147359, 52.572906, 126.213394,
        52.52524301, 126.190795, 52.47407399, 126.270515, 52.474354, 126.338093,
        52.41211501, 126.321414, 52.317586, 126.440438, 52.28328201, 126.306981,
        52.20572799, 126.562142, 52.13168201, 126.520962, 52.03989699,
        126.450843, 52.027759, 126.462494, 51.94848899, 126.734272, 51.71150799,
        126.679288, 51.595728, 126.842428, 51.53306299, 126.791306, 51.43258899,
        126.923904, 51.38708901, 126.923487, 51.34782701, 126.819897, 51.329195,
        126.825496, 51.273235, 126.903491, 51.244931, 126.876502, 51.31632899,
        126.981584, 51.326921, 126.899949, 51.20067401, 126.922887, 51.06183301,
        127.301458, 50.74766301, 127.368833, 50.580624, 127.294156, 50.47126301,
        127.365361, 50.42758799, 127.337411, 50.330123, 127.608484, 50.22976201,
        127.49655, 50.04108499, 127.544743, 49.80867299, 127.667893,
        49.77584101, 127.691604, 49.677383, 127.828339, 49.589558, 128.18575,
        49.53936301, 128.538049, 49.60444001, 128.714902, 49.56485401,
        128.784058, 49.589791, 128.761394, 49.482038, 129.013602, 49.45727301,
        129.103003, 49.35631999, 129.232926, 49.40045501, 129.358134, 49.356064,
        129.39317, 49.43425201, 129.518519, 49.423701, 129.573922, 49.28778601,
        129.730438, 49.288302, 129.753864, 49.208703, 129.92965, 49.09730101,
        129.928787, 49.04539199, 130.23794, 48.86874401, 130.444561,
        48.90997999, 130.519952, 48.85860799, 130.680429, 48.880951, 130.538562,
        48.611872, 130.605722, 48.594074, 130.624945, 48.49339001, 130.767467,
        48.50783101, 130.747719, 48.40441101, 130.845879, 48.300756, 130.666369,
        48.105074, 130.891351, 47.92717801, 130.998309, 47.705362, 131.455993,
        47.74715101, 131.543847, 47.73596399, 131.622422, 47.65911699,
        131.713867, 47.71115801, 131.970569, 47.671547, 132.000493, 47.71224299,
        132.24262, 47.71002399, 132.344574, 47.76719799, 132.55825, 47.71845101,
        132.687284, 47.88510399, 132.671725, 47.95566399, 132.818962, 47.937065,
        133.10289, 48.12946701, 133.531075, 48.11633101, 133.580031, 48.187947,
        133.693309, 48.18687201, 133.740669, 48.25475301, 134.203496,
        48.38249001, 134.522303, 48.419546, 134.80417, 48.37174899, 134.909649,
        48.447954, 135.08722, 48.44976999, 135.07277, 48.391317, 134.679404,
        48.256437, 134.679408, 48.15549299, 134.549843, 47.991305, 134.779663,
        47.71593499, 134.567987, 47.47789399, 134.314926, 47.43327099,
        134.177551, 47.32640799, 134.222286, 47.105339, 134.142984, 47.09314701,
        134.064087, 46.980084, 134.014823, 46.64420399, 133.920002, 46.59588799,
        133.849517, 46.475211, 133.950122, 46.39903699, 133.868543, 46.34396601,
        133.922964, 46.33283399, 133.917385, 46.26551599, 133.694137,
        46.15649601, 133.746704, 46.07076, 133.676466, 45.94317601, 133.467802,
        45.835038, 133.504064, 45.77962801, 133.429743, 45.69828, 133.485022,
        45.69163299, 133.47585, 45.620026, 133.202298, 45.51597699, 133.141683,
        45.428514, 133.09394, 45.255963, 133.139519, 45.12758199, 132.953146,
        45.01818301, 132.003401, 45.25459099, 131.887692, 45.342299,
        131.7950603, 45.21166846, 131.681682, 45.215104, 131.650601,
        45.15981101, 131.694096, 45.12675501, 131.493398, 44.96810401,
        131.357781, 44.99066101, 131.2751, 44.919839, 131.10773, 44.938072,
        131.097811, 44.89140399, 130.967305, 44.85394, 131.111529, 44.710111,
        131.310655, 44.04658901, 131.243226, 43.986539, 131.246173, 43.60265,
        131.19373, 43.56116201,
      ],
    ],
  },
  {
    id: "310000",
    name: "上海",
    fullname: "上海市",
    pinyin: ["shang", "hai"],
    location: {
      lat: 31.230525,
      lng: 121.473667,
    },
    polygon: [
      [
        121.6137284, 31.71151072, 121.900221, 31.603502, 121.983315,
        31.52664801, 121.983288, 31.46751601, 121.834378, 31.43575001,
        121.595274, 31.525455, 121.383138, 31.620171, 121.165972, 31.78538801,
        121.283651, 31.87157799, 121.384961, 31.82623283, 121.4374686,
        31.76798347,
      ],
      [
        120.901359, 31.01749899, 120.876429, 31.131489, 121.068445, 31.148263,
        121.062462, 31.267451, 121.161286, 31.283984, 121.107275, 31.355248,
        121.1474177, 31.44396248, 121.33665, 31.50869629, 121.725006, 31.285367,
        121.888378, 31.12412601, 121.978109, 30.91877701, 122.020988,
        30.91907999, 121.89162, 30.84578101, 121.509116, 30.812576, 121.2717079,
        30.6882398, 121.2185533, 30.78603592, 121.122791, 30.779178,
        121.1214795, 30.85033064, 120.9900706, 30.82334842, 120.9917246,
        31.01336761,
      ],
      [
        121.5154785, 31.49267219, 121.769508, 31.387541, 121.796214,
        31.30998099,
      ],
      [
        121.8038382, 31.34498207, 121.818076, 31.37966699, 122.118176,
        31.30842999, 122.093567, 31.261663, 121.852486, 31.293096,
      ],
      [
        121.8829644, 31.22478467, 121.914293, 31.23961299, 121.9202,
        31.19719601,
      ],
      [
        121.948115, 31.23392899, 122.045343, 31.21635601, 122.049983, 31.147035,
        121.966821, 31.15261099,
      ],
    ],
  },
  {
    id: "320000",
    name: "江苏",
    fullname: "江苏省",
    pinyin: ["jiang", "su"],
    location: {
      lat: 33.061377,
      lng: 121.763563,
    },
    polygon: [
      [
        119.302693, 35.07835, 119.31036, 35.02234901, 119.2849461, 35.07568393,
        119.2101811, 35.00382769, 119.193014, 34.852335, 119.233243,
        34.78901301, 119.498294, 34.758372, 119.369954, 34.76057099,
        119.5244501, 34.73238842, 119.456504, 34.67366666, 119.5318473,
        34.62030816, 119.56795, 34.65384601, 119.55696, 34.60948099, 119.645206,
        34.56023699, 119.651449, 34.63063801, 119.655684, 34.522018,
        120.3091609, 34.30410399, 120.277897, 34.27664305, 120.3264343,
        34.10317879, 120.739848, 33.337868, 120.784853, 33.27397, 120.8264339,
        33.2848594, 120.789069, 33.263726, 120.925534, 33.00614399, 120.934928,
        32.879205, 120.97324, 32.87405499, 120.950517, 32.720645, 120.900348,
        32.68487601, 120.9747855, 32.64775951, 120.9545723, 32.60558716,
        121.252137, 32.454175, 121.382295, 32.42041999, 121.401734, 32.447182,
        121.469514, 32.23574, 121.571273, 32.22682201, 121.571334, 32.174425,
        121.537929, 32.22646599, 121.416066, 32.201001, 121.457914, 32.15064801,
        121.51943, 32.15638499, 121.44353, 32.13378199, 121.5405527,
        32.15245912, 121.517992, 32.110586, 121.72932, 32.06243099, 121.85495,
        31.917454, 121.9626144, 31.72903137, 121.874955, 31.683455, 121.622617,
        31.73696201, 121.309803, 31.88312401, 121.1315, 31.79251201, 121.133954,
        31.698261, 121.33665, 31.50869629, 121.1474177, 31.44396248, 121.107275,
        31.355248, 121.161286, 31.283984, 121.062462, 31.267451, 121.068445,
        31.148263, 120.876429, 31.131489, 120.901359, 31.01749899, 120.698204,
        30.970787, 120.703918, 30.87094, 120.58896, 30.85442699, 120.503464,
        30.75795201, 120.435001, 30.920821, 120.364662, 30.88046501, 120.371285,
        30.94866901, 120.132517, 30.943077, 120.001729, 31.02712499, 119.920013,
        31.170916, 119.67261, 31.167996, 119.623785, 31.13026699, 119.581993,
        31.10864801, 119.532592, 31.15909201, 119.391176, 31.17453801,
        119.356623, 31.3033, 119.337546, 31.258876, 119.238573, 31.25466801,
        119.181113, 31.30049, 119.105298, 31.23527101, 118.789131, 31.23318699,
        118.699756, 31.30022901, 118.754607, 31.385071, 118.811103, 31.371385,
        118.86963, 31.42157199, 118.86872, 31.61137601, 118.803076, 31.61924701,
        118.774135, 31.682614, 118.72494, 31.62737499, 118.644846, 31.64692699,
        118.68491, 31.724901, 118.638109, 31.75905601, 118.531116, 31.73630701,
        118.472304, 31.879637, 118.363373, 31.931764, 118.395084, 32.07675101,
        118.500559, 32.121031, 118.509828, 32.19430201, 118.644119, 32.20997601,
        118.70766, 32.334087, 118.691346, 32.47245201, 118.593342, 32.478833,
        118.569119, 32.58598001, 118.907878, 32.59231799, 118.887879,
        32.55484599, 119.039085, 32.516028, 119.085125, 32.45271699, 119.14777,
        32.49266199, 119.176944, 32.59382699, 119.217947, 32.57388399,
        119.230997, 32.60713999, 119.184929, 32.82562, 119.104286, 32.82671801,
        119.015343, 32.90785699, 119.020549, 32.955934, 118.853982, 32.958303,
        118.811708, 32.854988, 118.741829, 32.85338399, 118.756288, 32.73705301,
        118.37533, 32.719003, 118.364818, 32.76954799, 118.299951, 32.78326,
        118.301921, 32.84639301, 118.250596, 32.84838401, 118.232947,
        32.92198601, 118.311026, 32.961418, 118.244824, 32.998325, 118.211624,
        33.19910301, 118.178196, 33.21796001, 118.038623, 33.134802, 117.938451,
        33.22977201, 118.050649, 33.492055, 118.108088, 33.47508699, 118.112271,
        33.617064, 118.185727, 33.74482201, 117.965123, 33.763535, 117.901678,
        33.720115, 117.74467, 33.71128901, 117.758651, 33.88567199, 117.703636,
        33.887793, 117.642169, 34.018559, 117.575766, 33.98289501, 117.510384,
        34.06205301, 117.415622, 34.02590101, 117.35303, 34.089837, 117.151437,
        34.08369499, 117.025288, 34.16722699, 117.049575, 34.242432, 116.970397,
        34.28073301, 116.969315, 34.38883301, 116.828447, 34.38868999,
        116.760669, 34.46259, 116.572892, 34.48964099, 116.595205, 34.51151599,
        116.429363, 34.65286699, 116.37422, 34.63996699, 116.365719,
        34.74277101, 116.444478, 34.89794699, 116.626303, 34.93910199,
        116.771376, 34.91646699, 116.789102, 34.97508801, 116.9498055,
        34.86881947, 116.9846888, 34.80604397, 116.9493007, 34.79421528,
        117.0774044, 34.69599604, 117.0557559, 34.65632794, 117.1481412,
        34.56698596, 117.1662927, 34.4294612, 117.254601, 34.45165634,
        117.2681132, 34.53278729, 117.362547, 34.589423, 117.487997, 34.466213,
        117.590493, 34.46176501, 117.684496, 34.54734301, 117.801676, 34.518758,
        117.793635, 34.651779, 117.957771, 34.67900299, 118.099932, 34.65144501,
        118.078998, 34.56978099, 118.184679, 34.544189, 118.132845, 34.483411,
        118.17042, 34.38135199, 118.213977, 34.37839301, 118.404978, 34.427756,
        118.432501, 34.61955699, 118.523227, 34.71179301, 118.682634,
        34.67824399, 118.783404, 34.72205001, 118.716462, 34.76383099,
        118.859253, 34.94122999, 118.865319, 35.02983999, 119.116726, 35.055292,
        119.24977, 35.12451401,
      ],
      [
        121.6137284, 31.71151072, 121.4374686, 31.76798347, 121.384961,
        31.82623283,
      ],
      [122.026911, 31.664986, 122.063162, 31.65283901, 122.018133, 31.629651],
    ],
  },
  {
    id: "330000",
    name: "浙江",
    fullname: "浙江省",
    pinyin: ["zhe", "jiang"],
    location: {
      lat: 28.866619,
      lng: 121.152575,
    },
    polygon: [
      [
        119.623785, 31.13026699, 119.67261, 31.167996, 119.920013, 31.170916,
        120.001729, 31.02712499, 120.132517, 30.943077, 120.371285, 30.94866901,
        120.364662, 30.88046501, 120.435001, 30.920821, 120.503464, 30.75795201,
        120.58896, 30.85442699, 120.703918, 30.87094, 120.698204, 30.970787,
        120.901359, 31.01749899, 120.9917246, 31.01336761, 120.9900706,
        30.82334842, 121.1214795, 30.85033064, 121.122791, 30.779178,
        121.2185533, 30.78603592, 121.2717079, 30.6882398, 120.962742,
        30.52028699, 120.961737, 30.42805699, 120.809585, 30.29670899,
        120.673118, 30.39571399, 120.694369, 30.289828, 120.74978, 30.21947099,
        120.855869, 30.20830299, 120.986166, 30.245268, 121.180482, 30.38369401,
        121.336065, 30.37262899, 121.523952, 30.22139101, 121.679073, 29.998783,
        121.957057, 29.886092, 122.134115, 29.90268201, 121.713871, 29.563818,
        121.459881, 29.51132301, 121.4368572, 29.45959014, 121.451289,
        29.413502, 121.513949, 29.489063, 121.5173084, 29.4001789, 121.567479,
        29.470906, 121.734128, 29.53958999, 121.784736, 29.53392899, 121.768115,
        29.475185, 121.806691, 29.47280799, 121.834242, 29.54061801, 121.747692,
        29.55492801, 121.932749, 29.64064999, 122.000293, 29.583729, 121.927931,
        29.28478501, 122.001973, 29.26068101, 121.940109, 29.19354399,
        121.814446, 29.15424099, 121.7882609, 29.27252638, 121.760708,
        29.158163, 121.709195, 29.12900099, 121.5441414, 29.21951527,
        121.529233, 29.185626, 121.608937, 29.13829201, 121.503438, 29.123001,
        121.654555, 29.09994301, 121.634485, 29.03930901, 121.560238,
        29.05058399, 121.534383, 28.99899601, 121.558702, 29.04662799,
        121.666629, 29.050974, 121.714705, 29.01062099, 121.669899, 28.973849,
        121.737017, 28.93938874, 121.65558, 28.89566701, 121.5258548,
        28.94024948, 121.685641, 28.862531, 121.669193, 28.72381501, 121.704826,
        28.71637, 121.517329, 28.65908501, 121.635651, 28.51523199, 121.58296,
        28.49083001, 121.661194, 28.469488, 121.599267, 28.426238, 121.667333,
        28.395914, 121.618351, 28.36030399, 121.673872, 28.345344, 121.647471,
        28.290343, 121.589569, 28.24508999, 121.581774, 28.29840699, 121.466248,
        28.326355, 121.407415, 28.210918, 121.370034, 28.22066399, 121.366983,
        28.13842901, 121.28303, 28.14797801, 121.295755, 28.06851101,
        121.158405, 28.03387201, 121.133678, 28.132924, 121.209622, 28.165981,
        121.244319, 28.34026701, 121.186127, 28.38721901, 121.1396496,
        28.24973291, 121.076508, 28.28760299, 121.137455, 28.24393899,
        121.096209, 28.143087, 121.022555, 28.109012, 120.981452, 27.98679199,
        120.829658, 27.984789, 120.917553, 27.879173, 120.834953, 27.76497001,
        120.776714, 27.78126201, 120.653692, 27.589256, 120.610195, 27.58965001,
        120.676563, 27.508347, 120.6345508, 27.48510507, 120.699167, 27.47756,
        120.667979, 27.491579, 120.648901, 27.36113299, 120.547591, 27.39939,
        120.512421, 27.33422899, 120.567343, 27.29196201, 120.522219, 27.273416,
        120.523193, 27.19243699, 120.47938, 27.163594, 120.449573, 27.21862299,
        120.4372091, 27.16524845, 120.3527205, 27.38656136, 120.2751727,
        27.38843421, 120.2502063, 27.43969467, 120.1345197, 27.41984911,
        120.0522626, 27.33867482, 119.9941776, 27.37917954, 119.944881,
        27.31419191, 119.7705279, 27.3057221, 119.6176572, 27.67391498,
        119.5011705, 27.64990611, 119.4665074, 27.52646407, 119.3767772,
        27.53497723, 119.2489374, 27.418622, 118.9906949, 27.50335172,
        118.9554161, 27.4498235, 118.9044364, 27.46197989, 118.8571094,
        27.51806157, 118.910199, 27.57033883, 118.8979649, 27.71891068,
        118.7190894, 28.04949812, 118.8031562, 28.11821373, 118.8031,
        28.16456555, 118.7609478, 28.16740014, 118.8121169, 28.22856239,
        118.7193797, 28.312042, 118.620979, 28.25627499, 118.503519,
        28.27965999, 118.4916227, 28.23819209, 118.4337205, 28.28857679,
        118.487106, 28.32901699, 118.430966, 28.39862301, 118.482152,
        28.47116901, 118.415045, 28.49740001, 118.432844, 28.676516, 118.38322,
        28.78795799, 118.300085, 28.82609001, 118.269842, 28.918631, 118.195259,
        28.90429301, 118.22766, 28.94431401, 118.127647, 29.015935, 118.099938,
        28.990394, 118.038021, 29.09730101, 118.071891, 29.28833601, 118.178064,
        29.298063, 118.193023, 29.39527101, 118.316111, 29.42259699, 118.310552,
        29.496024, 118.495162, 29.518363, 118.500582, 29.575658, 118.744712,
        29.73877299, 118.754619, 29.844733, 118.841336, 29.89130899, 118.838974,
        29.938272, 118.894674, 29.93806201, 118.896798, 30.14807601, 118.845696,
        30.15613701, 118.929873, 30.20339199, 118.87988, 30.31487501,
        118.954185, 30.360342, 119.056755, 30.303873, 119.226217, 30.288851,
        119.247992, 30.341259, 119.399978, 30.36780601, 119.348168, 30.40929599,
        119.327203, 30.53258801, 119.240106, 30.53293, 119.239145, 30.609324,
        119.388464, 30.68567299, 119.444547, 30.65058799, 119.480143, 30.773304,
        119.57664, 30.83145199,
      ],
      [
        122.504267, 30.71212199, 122.53896, 30.70355701, 122.428572,
        30.68444601, 122.428467, 30.74241101,
      ],
      [121.966262, 30.336443, 121.984525, 30.28985199, 121.920719, 30.309653],
      [
        122.181508, 30.34052799, 122.247387, 30.303697, 122.230328, 30.233114,
        122.052861, 30.30063299,
      ],
      [
        122.3442806, 30.42303552, 122.282238, 30.42122901, 122.278529,
        30.47319799, 122.375078, 30.47785499, 122.439368, 30.41259801,
      ],
      [122.191484, 30.19273899, 122.163118, 30.13825299, 122.147247, 30.18729],
      [
        122.04789, 30.17140601, 122.014949, 30.18091401, 122.054462,
        30.19992699,
      ],
      [122.418525, 30.25297801, 122.276427, 30.236038, 122.334269, 30.271704],
      [
        121.892428, 30.08028101, 121.93267, 29.998506, 121.864845, 29.966204,
        121.847845, 30.100276,
      ],
      [
        122.0541931, 30.14569378, 122.295159, 30.099648, 122.254761, 30.064619,
        122.345021, 30.01853401, 122.322249, 29.942124, 122.026845, 29.993154,
        121.937033, 30.16535299,
      ],
      [121.93551, 29.90467358, 121.969025, 29.954492, 122.002123, 29.91762599],
      [
        122.2410679, 29.94487249, 122.191991, 29.976085, 122.2781779,
        29.93316898,
      ],
      [
        122.4146952, 29.87580608, 122.391949, 29.832442, 122.350033,
        29.95772701, 122.425481, 29.943287,
      ],
      [
        122.004195, 29.81516499, 122.034996, 29.807284, 121.999047, 29.762401,
        121.9252269, 29.74590299,
      ],
      [
        122.124664, 29.79123999, 122.2102529, 29.69162579, 122.182518,
        29.651478, 122.1110293, 29.71809575, 122.050223, 29.714967,
      ],
      [122.286709, 29.84180301, 122.3159, 29.76805201, 122.223214, 29.832097],
      [
        121.887084, 29.11940699, 121.84192, 29.08945599, 121.778921,
        29.13887301,
      ],
      [
        121.892155, 29.11341399, 121.87711, 29.143918, 121.938292, 29.18152801,
        121.985662, 29.146278, 121.951678, 29.048718,
      ],
      [
        122.0355033, 29.16028921, 122.038061, 29.207416, 122.074608,
        29.17682201,
      ],
      [121.833002, 29.08060401, 121.818669, 29.052807, 121.791261, 29.10108199],
      [
        121.1522702, 28.26571541, 121.1923112, 28.35187427, 121.2039841,
        28.29239261,
      ],
      [
        120.89625, 27.940375, 120.847763, 27.97047901, 120.977284, 27.93857301,
        120.944443, 27.899529,
      ],
      [121.104291, 27.97957499, 121.151688, 27.95813, 121.042843, 27.94785801],
      [121.089079, 27.86621199, 121.024638, 27.83365499, 121.036956, 27.877805],
      [
        121.125356, 27.847358, 121.196699, 27.849368, 121.172545, 27.81199001,
        121.089601, 27.817813,
      ],
    ],
  },
  {
    id: "340000",
    name: "安徽",
    fullname: "安徽省",
    pinyin: ["an", "hui"],
    location: {
      lat: 31.734559,
      lng: 117.330139,
    },
    polygon: [
      [
        119.623785, 31.13026699, 119.57664, 30.83145199, 119.480143, 30.773304,
        119.444547, 30.65058799, 119.388464, 30.68567299, 119.239145, 30.609324,
        119.240106, 30.53293, 119.327203, 30.53258801, 119.348168, 30.40929599,
        119.399978, 30.36780601, 119.247992, 30.341259, 119.226217, 30.288851,
        119.056755, 30.303873, 118.954185, 30.360342, 118.87988, 30.31487501,
        118.929873, 30.20339199, 118.845696, 30.15613701, 118.896798,
        30.14807601, 118.894674, 29.93806201, 118.838974, 29.938272, 118.841336,
        29.89130899, 118.754619, 29.844733, 118.744712, 29.73877299, 118.500582,
        29.575658, 118.495162, 29.518363, 118.310552, 29.496024, 118.316111,
        29.42259699, 118.193023, 29.39527101, 118.132156, 29.422532, 118.13743,
        29.50626499, 118.01413, 29.57727101, 117.707662, 29.548987, 117.656675,
        29.61337901, 117.542481, 29.58858001, 117.530547, 29.654061, 117.45243,
        29.69158899, 117.420933, 29.84906501, 117.359159, 29.812618, 117.336704,
        29.85017001, 117.258018, 29.83109101, 117.212549, 29.927578, 117.073326,
        29.83223499, 117.135593, 29.78096901, 117.113002, 29.71214701,
        116.996029, 29.68337001, 116.838091, 29.569834, 116.760457, 29.59907901,
        116.721596, 29.564992, 116.654134, 29.69476299, 116.812361, 29.81169599,
        116.900133, 29.94948401, 116.666868, 30.076736, 116.585568, 30.04540499,
        116.54151, 29.90162, 116.26146, 29.78206399, 116.134737, 29.82291658,
        116.0751734, 29.96421096, 116.0661617, 30.21033736, 115.9727329,
        30.30092086, 115.904716, 30.30912592, 115.9229196, 30.51754477,
        115.7616737, 30.68795661, 115.7867489, 30.75615588, 115.8694007,
        30.77443816, 115.8695483, 30.86912754, 116.0744521, 30.95616293,
        116.0592751, 31.01223128, 115.9428161, 31.03960664, 115.8805318,
        31.14776146, 115.7762898, 31.10372858, 115.6617505, 31.21781046,
        115.5852743, 31.14257689, 115.4435908, 31.34597297, 115.3726314,
        31.34915016, 115.3733241, 31.40482837, 115.37158, 31.495844, 115.496556,
        31.67422699, 115.6778, 31.77886701, 115.910232, 31.79233799, 115.94194,
        32.165167, 115.883576, 32.48925599, 115.844527, 32.50431099, 115.929279,
        32.56722299, 115.891566, 32.57624501, 115.783207, 32.465548, 115.769849,
        32.50598201, 115.699163, 32.49432, 115.664963, 32.40843499, 115.562992,
        32.40343401, 115.410428, 32.57615501, 115.310563, 32.55188701,
        115.301153, 32.58838101, 115.199406, 32.59268701, 115.198598, 32.854352,
        114.890331, 32.97451099, 114.89181, 33.02047099, 114.936982, 33.025508,
        114.902936, 33.12956201, 114.954601, 33.15081501, 114.993996, 33.100405,
        115.13654, 33.08319701, 115.301484, 33.14161999, 115.361967,
        33.29981301, 115.312846, 33.37484701, 115.34673, 33.504868, 115.396816,
        33.50199099, 115.421959, 33.55679199, 115.639743, 33.585118, 115.563044,
        33.77216701, 115.61425, 33.77563701, 115.633502, 33.867323, 115.545887,
        33.880818, 115.653585, 34.06010201, 115.777607, 34.07375099, 115.84998,
        34.00472899, 115.887282, 34.03277899, 115.967578, 34.002103, 115.987036,
        33.90118099, 116.161183, 33.70816001, 116.43261, 33.79532899,
        116.437348, 33.84654701, 116.56404, 33.90748699, 116.641082,
        33.89013801, 116.649202, 33.97108099, 116.527377, 34.115649, 116.582428,
        34.27523899, 116.372224, 34.266126, 116.363529, 34.316852, 116.213594,
        34.38221601, 116.162049, 34.45940601, 116.195313, 34.574631, 116.240761,
        34.55239799, 116.37422, 34.63996699, 116.429363, 34.65286699,
        116.595205, 34.51151599, 116.572892, 34.48964099, 116.760669, 34.46259,
        116.828447, 34.38868999, 116.969315, 34.38883301, 116.970397,
        34.28073301, 117.049575, 34.242432, 117.025288, 34.16722699, 117.151437,
        34.08369499, 117.35303, 34.089837, 117.415622, 34.02590101, 117.510384,
        34.06205301, 117.575766, 33.98289501, 117.642169, 34.018559, 117.703636,
        33.887793, 117.758651, 33.88567199, 117.74467, 33.71128901, 117.901678,
        33.720115, 117.965123, 33.763535, 118.185727, 33.74482201, 118.112271,
        33.617064, 118.108088, 33.47508699, 118.050649, 33.492055, 117.938451,
        33.22977201, 118.038623, 33.134802, 118.178196, 33.21796001, 118.211624,
        33.19910301, 118.244824, 32.998325, 118.311026, 32.961418, 118.232947,
        32.92198601, 118.250596, 32.84838401, 118.301921, 32.84639301,
        118.299951, 32.78326, 118.364818, 32.76954799, 118.37533, 32.719003,
        118.756288, 32.73705301, 118.741829, 32.85338399, 118.811708, 32.854988,
        118.853982, 32.958303, 119.020549, 32.955934, 119.015343, 32.90785699,
        119.104286, 32.82671801, 119.184929, 32.82562, 119.230997, 32.60713999,
        119.217947, 32.57388399, 119.176944, 32.59382699, 119.14777,
        32.49266199, 119.085125, 32.45271699, 119.039085, 32.516028, 118.887879,
        32.55484599, 118.907878, 32.59231799, 118.569119, 32.58598001,
        118.593342, 32.478833, 118.691346, 32.47245201, 118.70766, 32.334087,
        118.644119, 32.20997601, 118.509828, 32.19430201, 118.500559, 32.121031,
        118.395084, 32.07675101, 118.363373, 31.931764, 118.472304, 31.879637,
        118.531116, 31.73630701, 118.638109, 31.75905601, 118.68491, 31.724901,
        118.644846, 31.64692699, 118.72494, 31.62737499, 118.774135, 31.682614,
        118.803076, 31.61924701, 118.86872, 31.61137601, 118.86963, 31.42157199,
        118.811103, 31.371385, 118.754607, 31.385071, 118.699756, 31.30022901,
        118.789131, 31.23318699, 119.105298, 31.23527101, 119.181113, 31.30049,
        119.238573, 31.25466801, 119.337546, 31.258876, 119.356623, 31.3033,
        119.391176, 31.17453801, 119.532592, 31.15909201, 119.581993,
        31.10864801,
      ],
    ],
  },
  {
    id: "350000",
    name: "福建",
    fullname: "福建省",
    pinyin: ["fu", "jian"],
    location: {
      lat: 26.101082,
      lng: 118.296194,
    },
    polygon: [
      [
        120.4372091, 27.16524845, 120.371608, 27.24685499, 120.308948,
        27.224941, 120.326611, 27.28460799, 120.276014, 27.25270401, 120.249195,
        27.29759799, 120.235378, 27.248471, 120.192672, 27.27348701,
        120.2001077, 27.20383418, 120.227108, 27.24819999, 120.314112,
        27.20510899, 120.362261, 27.23638401, 120.443921, 27.14664201,
        120.384898, 27.13644999, 120.421738, 27.11860501, 120.377246, 27.11692,
        120.391726, 27.081617, 120.264574, 27.109945, 120.296448, 27.03765101,
        120.260951, 27.06100001, 120.267354, 26.987368, 120.190168, 26.97519399,
        120.247529, 26.962387, 120.231246, 26.910243, 120.144372, 26.92845,
        120.099835, 26.87343299, 120.056037, 26.89190199, 120.053616, 26.824824,
        120.139201, 26.79472599, 120.1021606, 26.74420747, 120.158529,
        26.72435801, 120.105288, 26.69094301, 120.137369, 26.644929, 120.097239,
        26.614852, 120.080287, 26.64545899, 119.95946, 26.606539, 119.886536,
        26.51315701, 119.828504, 26.52480701, 119.859771, 26.59472101,
        119.955231, 26.61391801, 120.078432, 26.79926299, 119.940504,
        26.78903001, 119.871401, 26.644539, 119.843813, 26.707463, 119.789749,
        26.70654, 119.856837, 26.805418, 119.782639, 26.78940701, 119.856172,
        26.84845699, 119.801195, 26.85031099, 119.749379, 26.731062, 119.688931,
        26.815835, 119.734856, 26.70182699, 119.617094, 26.75353201, 119.626101,
        26.79576801, 119.563314, 26.740942, 119.662985, 26.72116999,
        119.5644884, 26.61319675, 119.608279, 26.575203, 119.673377,
        26.61681299, 119.787487, 26.582399, 119.779902, 26.49612399, 119.833285,
        26.44712201, 119.7684736, 26.4051696, 119.751281, 26.451743, 119.624784,
        26.47000701, 119.6748064, 26.34510848, 119.840656, 26.42598199,
        119.862762, 26.34749299, 119.961234, 26.37274401, 119.907134,
        26.31370499, 119.793528, 26.30759399, 119.802917, 26.27285201,
        119.768562, 26.29823099, 119.640687, 26.263474, 119.677413, 26.22962299,
        119.514087, 26.064671, 119.718319, 26.006857, 119.685975, 25.90274501,
        119.620229, 25.87316599, 119.656925, 25.74976, 119.600182, 25.685575,
        119.465446, 25.64428701, 119.515021, 25.559311, 119.601204, 25.586756,
        119.618039, 25.53400999, 119.570481, 25.50297699, 119.643121,
        25.47067899, 119.611677, 25.42891999, 119.656053, 25.421403, 119.664521,
        25.37183399, 119.623483, 25.356232, 119.65668, 25.35176799, 119.5956,
        25.33722699, 119.570176, 25.458188, 119.532954, 25.43051599, 119.57823,
        25.40016301, 119.489569, 25.36316001, 119.50287, 25.4562, 119.4406133,
        25.41355522, 119.460146, 25.49795301, 119.360025, 25.549181, 119.351542,
        25.414514, 119.261763, 25.43120001, 119.258657, 25.48990101, 119.209438,
        25.473752, 119.225245, 25.43717401, 119.153487, 25.43452301, 119.137823,
        25.38490099, 119.372907, 25.24887701, 119.295954, 25.24020499,
        119.275449, 25.16796901, 119.129451, 25.22545399, 119.104346,
        25.19769499, 119.162499, 25.126893, 119.074038, 25.099753, 119.026183,
        25.14630999, 119.073626, 25.23291301, 118.9752, 25.23245199, 119.035031,
        25.27579299, 118.910299, 25.25778901, 118.984051, 25.192828,
        118.9418151, 25.1397875, 118.974523, 25.11695899, 118.861043,
        25.08443099, 118.968553, 25.00702498, 119.015946, 25.05666801,
        118.984137, 24.98362701, 119.0322217, 24.95808977, 118.9046, 24.940733,
        118.986654, 24.87810101, 118.880605, 24.89558001, 118.740465, 24.826427,
        118.682699, 24.954087, 118.657242, 24.791865, 118.732852, 24.81431,
        118.734329, 24.772182, 118.784939, 24.76903299, 118.721418, 24.67582499,
        118.656482, 24.679898, 118.687836, 24.626984, 118.657094, 24.55493701,
        118.560783, 24.51160799, 118.573789, 24.578737, 118.444649, 24.62627699,
        118.443506, 24.689773, 118.399555, 24.579509, 118.293431, 24.58327701,
        118.236907, 24.52680501, 118.194422, 24.648878, 118.160975, 24.63925501,
        118.107818, 24.56458899, 118.034292, 24.54893001, 118.0729924,
        24.50584512, 118.0462959, 24.43837703, 117.944946, 24.46583799,
        117.8531339, 24.42448399, 117.925544, 24.390971, 118.070756,
        24.40634999, 118.05764, 24.351503, 118.104719, 24.35574901, 118.139631,
        24.25901599, 118.072261, 24.24725799, 117.989253, 24.15409999,
        117.95708, 24.20018901, 117.908859, 24.03409199, 117.843819,
        24.02202601, 117.753774, 23.90298199, 117.733548, 23.94033151,
        117.788968, 23.98808801, 117.715828, 24.02382701, 117.596118,
        23.71705001, 117.605212, 23.864891, 117.47384, 23.91825499, 117.510542,
        23.86294, 117.477732, 23.78771599, 117.279548, 23.732897, 117.283244,
        23.62540499, 117.235887, 23.598821, 117.240149, 23.674023, 117.229423,
        23.60791001, 117.1944218, 23.61700763, 117.0555427, 23.69422699,
        117.0224461, 23.83876007, 116.959622, 23.86623496, 116.9818065,
        23.99942159, 116.9266046, 24.1006858, 116.9986204, 24.17898116,
        116.9335981, 24.22003907, 116.9039081, 24.36986316, 116.8283063,
        24.45326945, 116.8607577, 24.46225422, 116.7558758, 24.5519505,
        116.8012366, 24.67829556, 116.5985859, 24.65461351, 116.5259158,
        24.60480097, 116.4884361, 24.71684411, 116.3759536, 24.80334742,
        116.4177776, 24.84105582, 116.394763, 24.87788378, 116.2439389,
        24.79377155, 116.1917437, 24.87712468, 116.0900518, 24.83832051,
        116.0192163, 24.90455404, 115.8925655, 24.93709965, 115.8733425,
        24.97283763, 115.9250326, 24.96076401, 115.8736162, 25.0199261,
        115.9288076, 25.04809211, 115.8555833, 25.20942132, 116.0020337,
        25.30483939, 116.0601718, 25.69489754, 116.1296055, 25.75915731,
        116.175424, 25.74946579, 116.1306777, 25.85869488, 116.3699299,
        25.96328738, 116.360984, 26.00071331, 116.4906851, 26.12112133,
        116.4715014, 26.1751947, 116.3925192, 26.17030608, 116.3971943,
        26.27024258, 116.5149706, 26.4083379, 116.5560187, 26.36443967,
        116.6089475, 26.38386521, 116.6380811, 26.47947615, 116.5392307,
        26.55929362, 116.5687775, 26.64222432, 116.512012, 26.70827879,
        116.548656, 26.84001078, 116.6948869, 26.98665828, 116.936347,
        27.01932355, 117.0528736, 27.10030008, 117.0457621, 27.148052,
        117.166179, 27.26709722, 117.1002827, 27.33915162, 117.1330109,
        27.42493298, 117.102282, 27.54005579, 117.0200177, 27.55707665,
        117.0203565, 27.65304065, 117.0569018, 27.67078901, 117.0988317,
        27.62689615, 117.1178401, 27.69402038, 117.2039578, 27.68368829,
        117.3056079, 27.77548868, 117.279503, 27.87083932, 117.3254203,
        27.8947332, 117.3533933, 27.85851834, 117.5217993, 27.98240914,
        117.5867835, 27.94231769, 117.6036769, 27.8680884, 117.7405613,
        27.80047686, 117.8559852, 27.94587852, 117.998059, 27.99117499,
        118.0950532, 27.96618109, 118.1371017, 28.0575625, 118.3526759,
        28.0870964, 118.3753427, 28.18645403, 118.314871, 28.22286581,
        118.4337205, 28.28857679, 118.4916227, 28.23819209, 118.503519,
        28.27965999, 118.620979, 28.25627499, 118.7193797, 28.312042,
        118.8121169, 28.22856239, 118.7609478, 28.16740014, 118.8031,
        28.16456555, 118.8031562, 28.11821373, 118.7190894, 28.04949812,
        118.8979649, 27.71891068, 118.910199, 27.57033883, 118.8571094,
        27.51806157, 118.9044364, 27.46197989, 118.9554161, 27.4498235,
        118.9906949, 27.50335172, 119.2489374, 27.418622, 119.3767772,
        27.53497723, 119.4665074, 27.52646407, 119.5011705, 27.64990611,
        119.6176572, 27.67391498, 119.7705279, 27.3057221, 119.944881,
        27.31419191, 119.9941776, 27.37917954, 120.0522626, 27.33867482,
        120.1345197, 27.41984911, 120.2502063, 27.43969467, 120.2751727,
        27.38843421, 120.3527205, 27.38656136,
      ],
      [
        120.3434101, 26.96954241, 120.395047, 26.927962, 120.332035,
        26.93230199,
      ],
      [119.909264, 26.68266099, 119.950146, 26.691268, 119.946638, 26.66080601],
      [119.6659512, 26.67730397, 119.761238, 26.669642, 119.670033, 26.633402],
      [119.993607, 26.23700199, 120.016254, 26.213756, 119.966478, 26.19404401],
      [
        119.916257, 26.17458599, 119.964998, 26.16597499, 119.924366,
        26.13764801,
      ],
      [119.5937598, 26.1273235, 119.641369, 26.057893, 119.543273, 26.08113501],
      [
        119.780609, 25.66416, 119.787437, 25.61388701, 119.889557, 25.56401899,
        119.816157, 25.53723401, 119.863594, 25.46760301, 119.763936,
        25.43667401, 119.774968, 25.39422901, 119.676682, 25.472538, 119.699471,
        25.510272, 119.767989, 25.50370399, 119.714571, 25.52157501, 119.686953,
        25.60004399,
      ],
      [
        119.468495, 25.251607, 119.576499, 25.20716301, 119.554041, 25.164764,
        119.446472, 25.20381099,
      ],
      [119.1559045, 25.10186668, 119.120051, 25.023241, 119.095327, 25.052469],
      [118.319207, 24.57390201, 118.373808, 24.53915299, 118.318862, 24.540019],
      [
        118.105506, 24.54996701, 118.199273, 24.526134, 118.136642, 24.421131,
        118.0701028, 24.45815757,
      ],
      [
        118.412317, 24.52566801, 118.469303, 24.479336, 118.455042, 24.41639899,
        118.386661, 24.435458, 118.296051, 24.395896, 118.291986, 24.477059,
        118.377671, 24.461372,
      ],
      [
        117.4921573, 23.7599142, 117.540736, 23.73385399, 117.4903361,
        23.71751843, 117.493313, 23.65412001, 117.428378, 23.64813201,
        117.444983, 23.58316101, 117.321625, 23.57089301, 117.403707,
        23.65310201, 117.344826, 23.684044, 117.372439, 23.771519, 117.446025,
        23.73649999,
      ],
    ],
  },
  {
    id: "360000",
    name: "江西",
    fullname: "江西省",
    pinyin: ["jiang", "xi"],
    location: {
      lat: 28.636652,
      lng: 115.816301,
    },
    polygon: [
      [
        118.4337205, 28.28857679, 118.314871, 28.22286581, 118.3753427,
        28.18645403, 118.3526759, 28.0870964, 118.1371017, 28.0575625,
        118.0950532, 27.96618109, 117.998059, 27.99117499, 117.8559852,
        27.94587852, 117.7405613, 27.80047686, 117.6036769, 27.8680884,
        117.5867835, 27.94231769, 117.5217993, 27.98240914, 117.3533933,
        27.85851834, 117.3254203, 27.8947332, 117.279503, 27.87083932,
        117.3056079, 27.77548868, 117.2039578, 27.68368829, 117.1178401,
        27.69402038, 117.0988317, 27.62689615, 117.0569018, 27.67078901,
        117.0203565, 27.65304065, 117.0200177, 27.55707665, 117.102282,
        27.54005579, 117.1330109, 27.42493298, 117.1002827, 27.33915162,
        117.166179, 27.26709722, 117.0457621, 27.148052, 117.0528736,
        27.10030008, 116.936347, 27.01932355, 116.6948869, 26.98665828,
        116.548656, 26.84001078, 116.512012, 26.70827879, 116.5687775,
        26.64222432, 116.5392307, 26.55929362, 116.6380811, 26.47947615,
        116.6089475, 26.38386521, 116.5560187, 26.36443967, 116.5149706,
        26.4083379, 116.3971943, 26.27024258, 116.3925192, 26.17030608,
        116.4715014, 26.1751947, 116.4906851, 26.12112133, 116.360984,
        26.00071331, 116.3699299, 25.96328738, 116.1306777, 25.85869488,
        116.175424, 25.74946579, 116.1296055, 25.75915731, 116.0601718,
        25.69489754, 116.0020337, 25.30483939, 115.8555833, 25.20942132,
        115.9288076, 25.04809211, 115.8736162, 25.0199261, 115.9250326,
        24.96076401, 115.8733425, 24.97283763, 115.8925655, 24.93709965,
        115.900072, 24.874856, 115.824294, 24.909294, 115.78258, 24.86293201,
        115.756302, 24.74723601, 115.843519, 24.562509, 115.688003, 24.54549999,
        115.671649, 24.604741, 115.573645, 24.61704301, 115.526386, 24.71543501,
        115.40645, 24.79338099, 115.36061, 24.73533999, 115.278534, 24.75414,
        115.120731, 24.66435299, 115.05487, 24.709921, 115.024846, 24.669135,
        114.913384, 24.66499399, 114.869032, 24.56235, 114.848924, 24.60219599,
        114.752684, 24.61624301, 114.705163, 24.52608099, 114.665172, 24.583684,
        114.592472, 24.53796999, 114.53163, 24.55881899, 114.428795, 24.486252,
        114.391644, 24.56347899, 114.174564, 24.64559199, 114.169044,
        24.68966099, 114.335792, 24.74908899, 114.423647, 24.97258701,
        114.509836, 25.002784, 114.561609, 25.077582, 114.735183, 25.121292,
        114.679962, 25.19445501, 114.748322, 25.231481, 114.741968, 25.27650801,
        114.53584, 25.416965, 114.381302, 25.315969, 114.132439, 25.309735,
        114.037822, 25.25035799, 114.045076, 25.385309, 113.987708, 25.40352201,
        114.003199, 25.44251999, 113.944764, 25.441704, 113.993286, 25.56333401,
        113.914286, 25.72866399, 114.028304, 25.89332599, 114.008463, 26.015656,
        114.043811, 26.07667699, 114.106914, 26.069455, 114.237154, 26.15657099,
        114.181294, 26.21450399, 113.944511, 26.163759, 114.029306, 26.26637201,
        114.030568, 26.37656875, 114.0886524, 26.41146439, 114.10893, 26.572151,
        113.8610558, 26.65989397, 113.8346978, 26.80130228, 113.9268678,
        26.94759772, 113.7708638, 27.09452719, 113.876183, 27.38205299,
        113.616669, 27.345403, 113.591687, 27.467635, 113.628677, 27.514177,
        113.578186, 27.53806399, 113.767253, 27.80818501, 113.7497852,
        27.93100877, 113.8650443, 28.00778591, 114.0263384, 28.03113854,
        113.9927284, 28.16269578, 114.1050382, 28.18235027, 114.2553694,
        28.32222342, 114.2537375, 28.39468583, 114.1822587, 28.43651972,
        114.2178013, 28.48426182, 114.08618, 28.558336, 114.1321207,
        28.60721734, 114.152371, 28.83456101, 114.060999, 28.84665501,
        114.062854, 28.899998, 114.028352, 28.89115499, 114.008871, 28.95541299,
        113.966726, 28.944279, 113.9454063, 29.04531225, 114.0729465,
        29.20612415, 114.2557023, 29.23529367, 114.2744331, 29.3544949,
        114.5047637, 29.32276767, 114.6708435, 29.39389052, 114.7594396,
        29.36268264, 114.8807856, 29.39113661, 114.9479005, 29.46493463,
        114.9273544, 29.48832647, 114.8856595, 29.43618625, 114.8599786,
        29.47502309, 114.8995147, 29.53095964, 114.9626922, 29.50336931,
        114.9481223, 29.54324226, 114.9995844, 29.57259176, 115.1592205,
        29.50683764, 115.1785199, 29.5926375, 115.1172964, 29.59546677,
        115.1469829, 29.63823678, 115.1141156, 29.68124879, 115.2650846,
        29.65773162, 115.2868918, 29.61878374, 115.360557, 29.64688672,
        115.4715886, 29.73820215, 115.5115236, 29.84022692, 115.6900508,
        29.84410677, 115.9338784, 29.72100876, 116.134737, 29.82291658,
        116.26146, 29.78206399, 116.54151, 29.90162, 116.585568, 30.04540499,
        116.666868, 30.076736, 116.900133, 29.94948401, 116.812361, 29.81169599,
        116.654134, 29.69476299, 116.721596, 29.564992, 116.760457, 29.59907901,
        116.838091, 29.569834, 116.996029, 29.68337001, 117.113002, 29.71214701,
        117.135593, 29.78096901, 117.073326, 29.83223499, 117.212549, 29.927578,
        117.258018, 29.83109101, 117.336704, 29.85017001, 117.359159, 29.812618,
        117.420933, 29.84906501, 117.45243, 29.69158899, 117.530547, 29.654061,
        117.542481, 29.58858001, 117.656675, 29.61337901, 117.707662, 29.548987,
        118.01413, 29.57727101, 118.13743, 29.50626499, 118.132156, 29.422532,
        118.193023, 29.39527101, 118.178064, 29.298063, 118.071891, 29.28833601,
        118.038021, 29.09730101, 118.099938, 28.990394, 118.127647, 29.015935,
        118.22766, 28.94431401, 118.195259, 28.90429301, 118.269842, 28.918631,
        118.300085, 28.82609001, 118.38322, 28.78795799, 118.432844, 28.676516,
        118.415045, 28.49740001, 118.482152, 28.47116901, 118.430966,
        28.39862301, 118.487106, 28.32901699,
      ],
    ],
  },
  {
    id: "370000",
    name: "山东",
    fullname: "山东省",
    pinyin: ["shan", "dong"],
    location: {
      lat: 36.670201,
      lng: 119.020725,
    },
    polygon: [
      [
        117.854766, 38.26220931, 117.9936516, 38.19600576, 118.065616,
        38.265799, 118.00543, 38.20049901, 118.0491679, 38.18006862, 118.136579,
        38.301151, 118.046372, 38.121638, 118.123535, 38.190365, 118.161223,
        38.14019199, 118.493684, 38.09708801, 118.580691, 38.04336601, 118.6967,
        38.14340801, 118.768084, 38.10485901, 118.757646, 38.176697, 118.958508,
        38.09285501, 118.999915, 38.11439901, 118.934947, 38.08015601,
        118.994463, 38.089123, 118.974422, 38.008051, 119.094893, 37.91378301,
        119.134767, 37.85559899, 119.095678, 37.842129, 119.321776, 37.793346,
        119.236812, 37.74463999, 119.29029, 37.64400901, 119.177085,
        37.72321899, 119.024495, 37.722785, 119.034741, 37.671829, 118.91102,
        37.52798299, 118.929604, 37.45787501, 118.979106, 37.45813799,
        118.929326, 37.45705201, 118.92405, 37.381407, 118.986429, 37.376178,
        118.94127, 37.375127, 118.960766, 37.31159501, 119.083571, 37.22812199,
        119.196953, 37.25265, 119.187127, 37.19117901, 119.20972, 37.221726,
        119.287471, 37.205271, 119.325237, 37.08277601, 119.4826657,
        37.11552661, 119.479753, 37.15444001, 119.4829749, 37.11552375,
        119.5567234, 37.1248819, 119.556033, 37.09660201, 119.681325, 37.150008,
        119.679161, 37.116529, 119.754976, 37.12283299, 119.903578, 37.26701999,
        119.844583, 37.372885, 120.17034, 37.48459599, 120.2528606, 37.55521979,
        120.21365, 37.61728007, 120.3149194, 37.62168808, 120.311623,
        37.67048101, 120.219561, 37.690303, 120.366145, 37.694295, 120.741048,
        37.83325099, 120.935123, 37.821954, 121.021488, 37.71668701, 121.1284,
        37.725927, 121.177605, 37.585746, 121.338807, 37.584132, 121.348688,
        37.631824, 121.435633, 37.600769, 121.377662, 37.594331, 121.378336,
        37.555986, 121.5521918, 37.44204905, 121.964729, 37.47474401,
        122.066843, 37.56643199, 122.123009, 37.565353, 122.176922, 37.429199,
        122.25028, 37.470172, 122.33065, 37.409893, 122.4862312, 37.43266954,
        122.710809, 37.396702, 122.591899, 37.359968, 122.553078, 37.28145401,
        122.628037, 37.194449, 122.564395, 37.183442, 122.579689, 37.14799499,
        122.492402, 37.154655, 122.431119, 37.04131201, 122.577663, 37.04279001,
        122.518875, 36.89626901, 122.507298, 36.93507201, 122.432862,
        36.92592299, 122.455121, 36.87075201, 122.270994, 36.82683701,
        122.173513, 36.84754401, 122.193583, 36.91819901, 122.1196991,
        36.89544255, 122.15069, 36.95208199, 122.046452, 36.89469899,
        122.019221, 36.96774099, 121.9728624, 36.96809087, 121.629458,
        36.80425099, 121.647861, 36.735709, 121.567959, 36.77087301, 121.537077,
        36.733194, 121.475357, 36.790613, 121.383709, 36.742066, 121.401348,
        36.70414201, 121.208027, 36.69136899, 121.018107, 36.579513, 120.845968,
        36.62081899, 120.982937, 36.54534601, 120.96512, 36.47112499,
        120.908975, 36.457063, 120.871926, 36.36798301, 120.836198, 36.46897799,
        120.709238, 36.41662899, 120.739116, 36.329144, 120.66985, 36.33848999,
        120.648587, 36.29095699, 120.716863, 36.13403199, 120.287591,
        36.04437099, 120.3717561, 36.17801924, 120.3253244, 36.25332691,
        120.31094, 36.185993, 120.221723, 36.220168, 120.137035, 36.17946,
        120.111547, 36.100602, 120.240184, 36.06081401, 120.193299, 35.998924,
        120.2551086, 36.02373152, 120.247039, 35.97623499, 120.3046168,
        36.01469804, 120.302195, 35.97073901, 120.2007169, 35.89203547,
        120.209887, 35.947912, 120.070884, 35.886896, 120.032922, 35.70662601,
        119.919855, 35.759241, 119.923237, 35.63575679, 119.870659, 35.61099299,
        119.82153, 35.650146, 119.762628, 35.565786, 119.753873, 35.62115301,
        119.65265, 35.59066399, 119.535656, 35.292497, 119.504252, 35.33731399,
        119.413912, 35.26957001, 119.419255, 35.15211199, 119.3801, 35.13561201,
        119.445967, 35.12637, 119.396586, 35.128135, 119.39417, 35.074316,
        119.302693, 35.07835, 119.24977, 35.12451401, 119.116726, 35.055292,
        118.865319, 35.02983999, 118.859253, 34.94122999, 118.716462,
        34.76383099, 118.783404, 34.72205001, 118.682634, 34.67824399,
        118.523227, 34.71179301, 118.432501, 34.61955699, 118.404978, 34.427756,
        118.213977, 34.37839301, 118.17042, 34.38135199, 118.132845, 34.483411,
        118.184679, 34.544189, 118.078998, 34.56978099, 118.099932, 34.65144501,
        117.957771, 34.67900299, 117.793635, 34.651779, 117.801676, 34.518758,
        117.684496, 34.54734301, 117.590493, 34.46176501, 117.487997, 34.466213,
        117.362547, 34.589423, 117.2681132, 34.53278729, 117.254601,
        34.45165634, 117.1662927, 34.4294612, 117.1481412, 34.56698596,
        117.0557559, 34.65632794, 117.0774044, 34.69599604, 116.9493007,
        34.79421528, 116.9846888, 34.80604397, 116.9498055, 34.86881947,
        116.789102, 34.97508801, 116.771376, 34.91646699, 116.626303,
        34.93910199, 116.444478, 34.89794699, 116.365719, 34.74277101,
        116.37422, 34.63996699, 116.240761, 34.55239799, 116.195313, 34.574631,
        116.146869, 34.553188, 116.0028431, 34.62380036, 115.9783545,
        34.58198181, 115.8269838, 34.55786836, 115.703788, 34.599708,
        115.684406, 34.55559499, 115.548609, 34.56881401, 115.461388, 34.637266,
        115.426781, 34.805411, 115.243493, 34.850167, 115.252141, 34.90658301,
        115.189212, 34.91482499, 115.219123, 34.960612, 115.156934, 34.958015,
        115.128089, 35.004362, 114.923795, 34.968728, 114.877117, 35.0252,
        114.824469, 35.01224399, 114.883747, 35.103362, 114.84105, 35.15924,
        114.931468, 35.196343, 115.023883, 35.37218501, 115.356502, 35.49683501,
        115.3319, 35.586265, 115.365478, 35.56050899, 115.511517, 35.727153,
        115.693548, 35.75406799, 115.734851, 35.832928, 115.863087, 35.855536,
        115.911826, 35.96027701, 116.047836, 35.969278, 116.100243, 36.10466799,
        115.64607, 35.92081401, 115.503398, 35.888705, 115.495117, 35.92019399,
        115.363146, 35.77945901, 115.356502, 35.954622, 115.447507, 36.011818,
        115.4835482, 36.14900803, 115.467828, 36.267884, 115.422104,
        36.32384101, 115.361248, 36.31073, 115.272848, 36.497386, 115.47874,
        36.75933199, 115.686319, 36.808587, 115.88282, 37.09908899, 115.905268,
        37.20786999, 115.969398, 37.239483, 115.975751, 37.337297, 116.1667095,
        37.38484191, 116.236382, 37.361904, 116.285543, 37.40373299, 116.224456,
        37.47973201, 116.27863, 37.468298, 116.319704, 37.580193, 116.434129,
        37.47335099, 116.803487, 37.85040699, 117.434717, 37.85175, 117.556506,
        38.0572, 117.708055, 38.077938,
      ],
      [
        120.7470494, 38.20075741, 120.784102, 38.15916199, 120.741145,
        38.16077099,
      ],
    ],
  },
  {
    id: "410000",
    name: "河南",
    fullname: "河南省",
    pinyin: ["he", "nan"],
    location: {
      lat: 33.767052,
      lng: 113.753094,
    },
    polygon: [
      [
        116.195313, 34.574631, 116.162049, 34.45940601, 116.213594, 34.38221601,
        116.363529, 34.316852, 116.372224, 34.266126, 116.582428, 34.27523899,
        116.527377, 34.115649, 116.649202, 33.97108099, 116.641082, 33.89013801,
        116.56404, 33.90748699, 116.437348, 33.84654701, 116.43261, 33.79532899,
        116.161183, 33.70816001, 115.987036, 33.90118099, 115.967578, 34.002103,
        115.887282, 34.03277899, 115.84998, 34.00472899, 115.777607,
        34.07375099, 115.653585, 34.06010201, 115.545887, 33.880818, 115.633502,
        33.867323, 115.61425, 33.77563701, 115.563044, 33.77216701, 115.639743,
        33.585118, 115.421959, 33.55679199, 115.396816, 33.50199099, 115.34673,
        33.504868, 115.312846, 33.37484701, 115.361967, 33.29981301, 115.301484,
        33.14161999, 115.13654, 33.08319701, 114.993996, 33.100405, 114.954601,
        33.15081501, 114.902936, 33.12956201, 114.936982, 33.025508, 114.89181,
        33.02047099, 114.890331, 32.97451099, 115.198598, 32.854352, 115.199406,
        32.59268701, 115.301153, 32.58838101, 115.310563, 32.55188701,
        115.410428, 32.57615501, 115.562992, 32.40343401, 115.664963,
        32.40843499, 115.699163, 32.49432, 115.769849, 32.50598201, 115.783207,
        32.465548, 115.891566, 32.57624501, 115.929279, 32.56722299, 115.844527,
        32.50431099, 115.883576, 32.48925599, 115.94194, 32.165167, 115.910232,
        31.79233799, 115.6778, 31.77886701, 115.496556, 31.67422699, 115.37158,
        31.495844, 115.3733241, 31.40482837, 115.2524019, 31.39226189,
        115.2087176, 31.44658586, 115.237274, 31.55541748, 115.1730709,
        31.60311824, 115.1261083, 31.59825376, 115.0964723, 31.5087934,
        115.023519, 31.52769995, 114.9969816, 31.46962834, 114.9751453,
        31.50024953, 114.8309662, 31.45778461, 114.7839902, 31.52614527,
        114.6977219, 31.52445373, 114.6454641, 31.58166459, 114.5669952,
        31.55638683, 114.5699686, 31.76886214, 114.4501387, 31.72809268,
        114.2974591, 31.74902986, 114.2367374, 31.84126267, 114.1732498,
        31.85474116, 113.9817506, 31.75313447, 113.9584533, 31.85759878,
        113.8404765, 31.85287582, 113.819396, 31.9672805, 113.7564214,
        31.98976226, 113.7926765, 32.03688152, 113.7216466, 32.12299353,
        113.7843462, 32.18532922, 113.7388126, 32.25293208, 113.7720405,
        32.3629953, 113.7245639, 32.41724595, 113.6516413, 32.41398507,
        113.5517695, 32.32979181, 113.5741187, 32.30189363, 113.5297703,
        32.33127217, 113.4282036, 32.2692609, 113.2108262, 32.4328874,
        113.1500443, 32.37667365, 113.0282659, 32.42701516, 112.9901214,
        32.36851503, 112.8730153, 32.39750173, 112.7590747, 32.34381573,
        112.5474867, 32.40598655, 112.4511905, 32.34346488, 112.3640113,
        32.36796722, 112.3272057, 32.322323, 111.7160436, 32.60679822,
        111.6281262, 32.62873603, 111.568908, 32.59061015, 111.425767,
        32.73294514, 111.4602682, 32.7261499, 111.464466, 32.77444183,
        111.4159988, 32.75440065, 111.296284, 32.89112225, 111.2451501,
        32.88295421, 111.2474151, 33.03077081, 111.154997, 33.03983903,
        111.1807755, 33.11482882, 111.0921582, 33.1832988, 111.0381886,
        33.15392889, 111.0619853, 33.19315064, 110.9951358, 33.25214333,
        111.029973, 33.33940801, 111.0037, 33.57867099, 110.837986, 33.666884,
        110.782195, 33.79606601, 110.587859, 33.88581, 110.676916, 33.94925099,
        110.582005, 34.042816, 110.641965, 34.16087499, 110.438041, 34.245579,
        110.42854, 34.287964, 110.502691, 34.34518899, 110.367487, 34.495128,
        110.37918, 34.600531, 110.706391, 34.60398401, 110.761427, 34.65418701,
        110.824756, 34.625171, 110.883488, 34.64244, 110.919788, 34.73024001,
        110.976295, 34.70644501, 111.122787, 34.75916599, 111.160725,
        34.81459401, 111.228098, 34.78914399, 111.570791, 34.84331701,
        111.681233, 34.94976899, 111.665078, 34.985793, 111.815586, 35.067432,
        111.952126, 35.082114, 112.053542, 35.044238, 112.058815, 35.27989601,
        112.300789, 35.253775, 112.289961, 35.217068, 112.567848, 35.21174499,
        112.637229, 35.22567801, 112.634798, 35.26546901, 112.712651, 35.187008,
        112.816017, 35.25763299, 112.908786, 35.24599801, 112.988276,
        35.28930699, 112.996485, 35.36217099, 113.136925, 35.335621, 113.190025,
        35.44905899, 113.30328, 35.426686, 113.312347, 35.48126099, 113.506603,
        35.51650399, 113.547986, 35.65755399, 113.624855, 35.632013, 113.582829,
        35.82195901, 113.656873, 35.836643, 113.637827, 35.98846401, 113.69904,
        36.02244599, 113.65536, 36.126437, 113.713757, 36.13297501, 113.650921,
        36.17348199, 113.698435, 36.18287201, 113.731467, 36.36337301,
        113.88194, 36.353858, 113.91119, 36.31478299, 113.953183, 36.35817899,
        113.993464, 36.314398, 113.976966, 36.35769, 114.02468, 36.35483801,
        114.068083, 36.272858, 114.345149, 36.2557988, 114.588758, 36.11846399,
        114.7344184, 36.15578345, 114.771318, 36.12452999, 114.912447,
        36.14042499, 114.920162, 36.048207, 114.998437, 36.069714, 115.124977,
        36.209969, 115.242119, 36.19124, 115.318903, 36.08696299, 115.405082,
        36.159917, 115.4835482, 36.14900803, 115.447507, 36.011818, 115.356502,
        35.954622, 115.363146, 35.77945901, 115.495117, 35.92019399, 115.503398,
        35.888705, 115.64607, 35.92081401, 116.100243, 36.10466799, 116.047836,
        35.969278, 115.911826, 35.96027701, 115.863087, 35.855536, 115.734851,
        35.832928, 115.693548, 35.75406799, 115.511517, 35.727153, 115.365478,
        35.56050899, 115.3319, 35.586265, 115.356502, 35.49683501, 115.023883,
        35.37218501, 114.931468, 35.196343, 114.84105, 35.15924, 114.883747,
        35.103362, 114.824469, 35.01224399, 114.877117, 35.0252, 114.923795,
        34.968728, 115.128089, 35.004362, 115.156934, 34.958015, 115.219123,
        34.960612, 115.189212, 34.91482499, 115.252141, 34.90658301, 115.243493,
        34.850167, 115.426781, 34.805411, 115.461388, 34.637266, 115.548609,
        34.56881401, 115.684406, 34.55559499, 115.703788, 34.599708,
        115.8269838, 34.55786836, 115.9783545, 34.58198181, 116.0028431,
        34.62380036, 116.146869, 34.553188,
      ],
    ],
  },
  {
    id: "420000",
    name: "湖北",
    fullname: "湖北省",
    pinyin: ["hu", "bei"],
    location: {
      lat: 31.046222,
      lng: 112.341552,
    },
    polygon: [
      [
        116.134737, 29.82291658, 115.9338784, 29.72100876, 115.6900508,
        29.84410677, 115.5115236, 29.84022692, 115.4715886, 29.73820215,
        115.360557, 29.64688672, 115.2868918, 29.61878374, 115.2650846,
        29.65773162, 115.1141156, 29.68124879, 115.1469829, 29.63823678,
        115.1172964, 29.59546677, 115.1785199, 29.5926375, 115.1592205,
        29.50683764, 114.9995844, 29.57259176, 114.9481223, 29.54324226,
        114.9626922, 29.50336931, 114.8995147, 29.53095964, 114.8599786,
        29.47502309, 114.8856595, 29.43618625, 114.9273544, 29.48832647,
        114.9479005, 29.46493463, 114.8807856, 29.39113661, 114.7594396,
        29.36268264, 114.6708435, 29.39389052, 114.5047637, 29.32276767,
        114.2744331, 29.3544949, 114.2557023, 29.23529367, 114.0729465,
        29.20612415, 113.9454063, 29.04531225, 113.8767489, 29.03416857,
        113.8195397, 29.10511132, 113.7440078, 29.05416202, 113.7281336,
        29.10402285, 113.6865109, 29.07840978, 113.6937361, 29.22543893,
        113.6063547, 29.26713575, 113.7551526, 29.44912558, 113.6312056,
        29.52308046, 113.7418532, 29.59083217, 113.6644866, 29.68454092,
        113.5440077, 29.68484982, 113.5715218, 29.84798544, 113.0930874,
        29.43151299, 113.0559971, 29.52324836, 112.953195, 29.47046861,
        112.9126709, 29.60555962, 113.0302643, 29.76892456, 112.9383388,
        29.67715172, 112.9271644, 29.76368738, 112.8764296, 29.78716979,
        112.684681, 29.59113108, 112.4401742, 29.63402474, 112.2828994,
        29.49659185, 112.3033905, 29.58601086, 112.2349108, 29.61508592,
        112.2446396, 29.66053729, 112.1989596, 29.61771056, 112.0676611,
        29.68069812, 112.065044, 29.76830298, 111.9554526, 29.79619398,
        111.9643372, 29.83957235, 111.7484113, 29.92179999, 111.6820105,
        29.88478546, 111.5220115, 29.92880652, 111.3972467, 29.912536,
        111.2462445, 30.0403551, 110.9360775, 30.06212357, 110.9233988,
        30.11181436, 110.8526915, 30.12636182, 110.7461957, 30.11360344,
        110.7565747, 30.0533876, 110.7139412, 30.03319343, 110.6549307,
        30.07763299, 110.4972782, 30.0551325, 110.5598854, 29.98774148,
        110.5063502, 29.9187117, 110.6415874, 29.76795626, 110.5236989,
        29.69260809, 110.4652305, 29.71351347, 110.3722118, 29.63387981,
        110.097412, 29.79294233, 109.7805667, 29.75969055, 109.7067074,
        29.60755662, 109.5154191, 29.62573708, 109.3706433, 29.42000852,
        109.3923594, 29.37401518, 109.3385055, 29.36633021, 109.3572476,
        29.2900203, 109.2644994, 29.22395397, 109.2788075, 29.12179367,
        109.2317338, 29.1155775, 109.1120264, 29.20804932, 109.1417149,
        29.26907958, 109.0706108, 29.39839998, 108.9189275, 29.32671823,
        108.9377182, 29.42863827, 108.8647585, 29.47190178, 108.9080946,
        29.59244085, 108.8293676, 29.67198433, 108.7822861, 29.63462151,
        108.7879465, 29.69161067, 108.7536061, 29.68961674, 108.7656312,
        29.63677534, 108.6838663, 29.69276304, 108.6608062, 29.85430927,
        108.5914341, 29.85764493, 108.5052432, 29.70751532, 108.4376497,
        29.74101198, 108.4036317, 29.83670295, 108.3672646, 29.82030212,
        108.4272878, 29.87978657, 108.5253511, 29.86694485, 108.5522172,
        29.97868605, 108.5134238, 30.05857165, 108.5840339, 30.25633955,
        108.4001487, 30.39161433, 108.427121, 30.49266501, 108.5692675,
        30.46955209, 108.6443574, 30.51889763, 108.6597362, 30.58837597,
        108.7294918, 30.50304014, 108.8087441, 30.49159373, 109.0431564,
        30.65596625, 109.1161768, 30.64337973, 109.0812786, 30.60050009,
        109.1425649, 30.5213623, 109.3268337, 30.61966912, 109.3520753,
        30.48728059, 109.4542062, 30.61335187, 109.5326348, 30.66816426,
        109.5696058, 30.64460486, 109.6544259, 30.76252335, 109.9028672,
        30.90475113, 110.0086411, 30.88488712, 110.0834575, 30.80044661,
        110.1734511, 30.98058975, 110.1363013, 30.98598427, 110.1214565,
        31.08760702, 110.2009877, 31.15873572, 110.145059, 31.38553756,
        109.9462486, 31.46865455, 109.9829148, 31.51314602, 109.7251473,
        31.55019325, 109.7650351, 31.60459974, 109.7349997, 31.69543864,
        109.5843741, 31.727101, 109.6410881, 31.81046373, 109.5838508,
        31.89421818, 109.6328377, 31.9408675, 109.5865821, 32.02850269,
        109.6110366, 32.20173514, 109.4916125, 32.28845064, 109.5519096,
        32.4804075, 109.6387231, 32.53490978, 109.6193419, 32.58988052,
        109.9265692, 32.59072559, 110.0292383, 32.54257583, 110.0919291,
        32.61977105, 110.1537765, 32.58987487, 110.2067017, 32.63596767,
        110.1051334, 32.83290631, 109.8665438, 32.91330635, 109.7896565,
        32.88319911, 109.8020253, 33.06731757, 109.444334, 33.14645011,
        109.5033277, 33.22781609, 109.611106, 33.2314873, 109.6269442,
        33.2713101, 110.3394968, 33.15879555, 110.4721658, 33.17322019,
        110.5368285, 33.25684304, 110.5878243, 33.23434882, 110.5946019,
        33.15529736, 110.655234, 33.16151232, 110.7110177, 33.09509313,
        110.7384216, 33.14226104, 110.822334, 33.15376091, 110.8289214,
        33.20235978, 110.9951358, 33.25214333, 111.0619853, 33.19315064,
        111.0381886, 33.15392889, 111.0921582, 33.1832988, 111.1807755,
        33.11482882, 111.154997, 33.03983903, 111.2474151, 33.03077081,
        111.2451501, 32.88295421, 111.296284, 32.89112225, 111.4159988,
        32.75440065, 111.464466, 32.77444183, 111.4602682, 32.7261499,
        111.425767, 32.73294514, 111.568908, 32.59061015, 111.6281262,
        32.62873603, 111.7160436, 32.60679822, 112.3272057, 32.322323,
        112.3640113, 32.36796722, 112.4511905, 32.34346488, 112.5474867,
        32.40598655, 112.7590747, 32.34381573, 112.8730153, 32.39750173,
        112.9901214, 32.36851503, 113.0282659, 32.42701516, 113.1500443,
        32.37667365, 113.2108262, 32.4328874, 113.4282036, 32.2692609,
        113.5297703, 32.33127217, 113.5741187, 32.30189363, 113.5517695,
        32.32979181, 113.6516413, 32.41398507, 113.7245639, 32.41724595,
        113.7720405, 32.3629953, 113.7388126, 32.25293208, 113.7843462,
        32.18532922, 113.7216466, 32.12299353, 113.7926765, 32.03688152,
        113.7564214, 31.98976226, 113.819396, 31.9672805, 113.8404765,
        31.85287582, 113.9584533, 31.85759878, 113.9817506, 31.75313447,
        114.1732498, 31.85474116, 114.2367374, 31.84126267, 114.2974591,
        31.74902986, 114.4501387, 31.72809268, 114.5699686, 31.76886214,
        114.5669952, 31.55638683, 114.6454641, 31.58166459, 114.6977219,
        31.52445373, 114.7839902, 31.52614527, 114.8309662, 31.45778461,
        114.9751453, 31.50024953, 114.9969816, 31.46962834, 115.023519,
        31.52769995, 115.0964723, 31.5087934, 115.1261083, 31.59825376,
        115.1730709, 31.60311824, 115.237274, 31.55541748, 115.2087176,
        31.44658586, 115.2524019, 31.39226189, 115.3733241, 31.40482837,
        115.3726314, 31.34915016, 115.4435908, 31.34597297, 115.5852743,
        31.14257689, 115.6617505, 31.21781046, 115.7762898, 31.10372858,
        115.8805318, 31.14776146, 115.9428161, 31.03960664, 116.0592751,
        31.01223128, 116.0744521, 30.95616293, 115.8695483, 30.86912754,
        115.8694007, 30.77443816, 115.7867489, 30.75615588, 115.7616737,
        30.68795661, 115.9229196, 30.51754477, 115.904716, 30.30912592,
        115.9727329, 30.30092086, 116.0661617, 30.21033736, 116.0751734,
        29.96421096,
      ],
      [
        112.9206667, 29.79130523, 112.8874371, 29.79665606, 112.9304324,
        29.76249516,
      ],
    ],
  },
  {
    id: "430000",
    name: "湖南",
    fullname: "湖南省",
    pinyin: ["hu", "nan"],
    location: {
      lat: 27.116007,
      lng: 111.982951,
    },
    polygon: [
      [
        112.9206667, 29.79130523, 112.9304324, 29.76249516, 112.8874371,
        29.79665606,
      ],
      [
        113.944764, 25.441704, 113.886871, 25.43661399, 113.821055, 25.33041099,
        113.7581, 25.329978, 113.748046, 25.36565399, 113.582469, 25.30627,
        113.5424797, 25.36802866, 113.445848, 25.359559, 113.313934,
        25.44293501, 113.303355, 25.51585501, 113.1519862, 25.49221702,
        113.131546, 25.414759, 113.023679, 25.34596, 112.968113, 25.34983999,
        112.92417, 25.29657901, 112.852485, 25.336398, 112.868333, 25.24880199,
        112.992553, 25.247214, 113.034695, 25.19968401, 112.9680171,
        25.14159882, 113.018446, 25.083134, 112.97845, 25.02648101, 113.011907,
        24.946107, 112.871628, 24.895922, 112.781156, 24.89655301, 112.659803,
        25.13278001, 112.5077, 25.13672201, 112.4449813, 25.18574539,
        112.4084914, 25.13967868, 112.3648811, 25.19167787, 112.3025349,
        25.15720091, 112.1919525, 25.18557642, 112.121097, 24.98476599,
        112.171069, 24.86272199, 112.0240994, 24.74014517, 111.7056046,
        24.78272488, 111.5601224, 24.63893591, 111.452831, 24.664919, 111.47022,
        24.928946, 111.4308549, 24.96723548, 111.4682248, 25.01975094,
        111.4183683, 25.04148333, 111.4339541, 25.10230539, 111.322024,
        25.10490848, 111.2768387, 25.15146982, 111.103915, 25.037705,
        111.097426, 24.940883, 110.979129, 24.914705, 110.951708, 25.043752,
        110.998948, 25.16160701, 111.112769, 25.21708501, 111.1033169,
        25.28509801, 111.292109, 25.43581399, 111.3432752, 25.60467526,
        111.30785, 25.71848299, 111.4403321, 25.76970509, 111.4307201,
        25.84337996, 111.492075, 25.86860166, 111.3440327, 25.90707055,
        111.2597707, 25.86112464, 111.1896123, 25.95235238, 111.2675861,
        26.05694695, 111.281275, 26.27112001, 111.04088, 26.32163499,
        110.974556, 26.38556601, 110.927194, 26.25401199, 110.7613854,
        26.24909901, 110.741755, 26.313903, 110.6124447, 26.33366467,
        110.507717, 26.17667101, 110.377121, 26.09442101, 110.325554,
        25.97564101, 110.261713, 25.96138099, 110.1965248, 26.06816383,
        110.1563701, 26.01848666, 110.0680657, 26.04342877, 110.0986982,
        26.16914802, 109.9662866, 26.19554921, 109.8690656, 26.03100665,
        109.791809, 26.01728701, 109.826903, 25.916705, 109.779545, 25.86595601,
        109.68522, 25.88035101, 109.710087, 26.01303745, 109.6349429,
        26.0473028, 109.5238808, 25.99575972, 109.482633, 26.02962999,
        109.4497111, 26.10184076, 109.514111, 26.128275, 109.439614,
        26.23884799, 109.4678719, 26.31264186, 109.3397993, 26.26408347,
        109.274487, 26.31541101, 109.4087482, 26.53713368, 109.3565101,
        26.65766135, 109.283744, 26.698584, 109.5222044, 26.74918702, 109.5138,
        26.87427399, 109.48191, 26.897075, 109.439773, 26.85992792, 109.4359661,
        26.88828036, 109.5550378, 26.94695291, 109.5213148, 27.07072867,
        109.455774, 27.06580623, 109.4726623, 27.13489937, 109.400089,
        27.15966199, 109.2805127, 27.12710455, 109.2480421, 27.15339886,
        109.1635956, 27.06581807, 109.101164, 27.06896594, 109.1287426,
        27.12247051, 109.0269183, 27.10283715, 108.8755521, 26.99943292,
        108.7908495, 27.08443541, 108.8847256, 27.10846347, 108.9079364,
        27.20790457, 109.041426, 27.27659047, 109.045922, 27.3347, 109.103703,
        27.33688601, 109.1414787, 27.4478142, 109.156216, 27.416518,
        109.2078133, 27.45039272, 109.299897, 27.42394499, 109.312361,
        27.48616201, 109.461938, 27.565617, 109.470465, 27.680258, 109.3329687,
        27.78055681, 109.3018052, 27.95847414, 109.379162, 28.03309799,
        109.338431, 28.062888, 109.298573, 28.03627099, 109.4003479,
        28.27334911, 109.273081, 28.30944804, 109.2743678, 28.49465684,
        109.3066087, 28.62069163, 109.18062, 28.61966008, 109.300654,
        28.73967901, 109.241306, 28.77652301, 109.2339375, 28.86416641,
        109.319576, 29.04663799, 109.2317338, 29.1155775, 109.2788075,
        29.12179367, 109.2644994, 29.22395397, 109.3572476, 29.2900203,
        109.3385055, 29.36633021, 109.3923594, 29.37401518, 109.3706433,
        29.42000852, 109.5154191, 29.62573708, 109.7067074, 29.60755662,
        109.7805667, 29.75969055, 110.097412, 29.79294233, 110.3722118,
        29.63387981, 110.4652305, 29.71351347, 110.5236989, 29.69260809,
        110.6415874, 29.76795626, 110.5063502, 29.9187117, 110.5598854,
        29.98774148, 110.4972782, 30.0551325, 110.6549307, 30.07763299,
        110.7139412, 30.03319343, 110.7565747, 30.0533876, 110.7461957,
        30.11360344, 110.8526915, 30.12636182, 110.9233988, 30.11181436,
        110.9360775, 30.06212357, 111.2462445, 30.0403551, 111.3972467,
        29.912536, 111.5220115, 29.92880652, 111.6820105, 29.88478546,
        111.7484113, 29.92179999, 111.9643372, 29.83957235, 111.9554526,
        29.79619398, 112.065044, 29.76830298, 112.0676611, 29.68069812,
        112.1989596, 29.61771056, 112.2446396, 29.66053729, 112.2349108,
        29.61508592, 112.3033905, 29.58601086, 112.2828994, 29.49659185,
        112.4401742, 29.63402474, 112.684681, 29.59113108, 112.8764296,
        29.78716979, 112.9271644, 29.76368738, 112.9383388, 29.67715172,
        113.0302643, 29.76892456, 112.9126709, 29.60555962, 112.953195,
        29.47046861, 113.0559971, 29.52324836, 113.0930874, 29.43151299,
        113.5715218, 29.84798544, 113.5440077, 29.68484982, 113.6644866,
        29.68454092, 113.7418532, 29.59083217, 113.6312056, 29.52308046,
        113.7551526, 29.44912558, 113.6063547, 29.26713575, 113.6937361,
        29.22543893, 113.6865109, 29.07840978, 113.7281336, 29.10402285,
        113.7440078, 29.05416202, 113.8195397, 29.10511132, 113.8767489,
        29.03416857, 113.9454063, 29.04531225, 113.966726, 28.944279,
        114.008871, 28.95541299, 114.028352, 28.89115499, 114.062854, 28.899998,
        114.060999, 28.84665501, 114.152371, 28.83456101, 114.1321207,
        28.60721734, 114.08618, 28.558336, 114.2178013, 28.48426182,
        114.1822587, 28.43651972, 114.2537375, 28.39468583, 114.2553694,
        28.32222342, 114.1050382, 28.18235027, 113.9927284, 28.16269578,
        114.0263384, 28.03113854, 113.8650443, 28.00778591, 113.7497852,
        27.93100877, 113.767253, 27.80818501, 113.578186, 27.53806399,
        113.628677, 27.514177, 113.591687, 27.467635, 113.616669, 27.345403,
        113.876183, 27.38205299, 113.7708638, 27.09452719, 113.9268678,
        26.94759772, 113.8346978, 26.80130228, 113.8610558, 26.65989397,
        114.10893, 26.572151, 114.0886524, 26.41146439, 114.030568, 26.37656875,
        114.029306, 26.26637201, 113.944511, 26.163759, 114.181294, 26.21450399,
        114.237154, 26.15657099, 114.106914, 26.069455, 114.043811, 26.07667699,
        114.008463, 26.015656, 114.028304, 25.89332599, 113.914286, 25.72866399,
        113.993286, 25.56333401,
      ],
      [109.534195, 26.744557, 109.549122, 26.717913, 109.597756, 26.75797401],
    ],
  },
  {
    id: "440000",
    name: "广东",
    fullname: "广东省",
    pinyin: ["guang", "dong"],
    location: {
      lat: 23.133306,
      lng: 113.266887,
    },
    polygon: [
      [
        112.0240994, 24.74014517, 112.171069, 24.86272199, 112.121097,
        24.98476599, 112.1919525, 25.18557642, 112.3025349, 25.15720091,
        112.3648811, 25.19167787, 112.4084914, 25.13967868, 112.4449813,
        25.18574539, 112.5077, 25.13672201, 112.659803, 25.13278001, 112.781156,
        24.89655301, 112.871628, 24.895922, 113.011907, 24.946107, 112.97845,
        25.02648101, 113.018446, 25.083134, 112.9680171, 25.14159882,
        113.034695, 25.19968401, 112.992553, 25.247214, 112.868333, 25.24880199,
        112.852485, 25.336398, 112.92417, 25.29657901, 112.968113, 25.34983999,
        113.023679, 25.34596, 113.131546, 25.414759, 113.1519862, 25.49221702,
        113.303355, 25.51585501, 113.313934, 25.44293501, 113.445848, 25.359559,
        113.5424797, 25.36802866, 113.582469, 25.30627, 113.748046, 25.36565399,
        113.7581, 25.329978, 113.821055, 25.33041099, 113.886871, 25.43661399,
        113.944764, 25.441704, 114.003199, 25.44251999, 113.987708, 25.40352201,
        114.045076, 25.385309, 114.037822, 25.25035799, 114.132439, 25.309735,
        114.381302, 25.315969, 114.53584, 25.416965, 114.741968, 25.27650801,
        114.748322, 25.231481, 114.679962, 25.19445501, 114.735183, 25.121292,
        114.561609, 25.077582, 114.509836, 25.002784, 114.423647, 24.97258701,
        114.335792, 24.74908899, 114.169044, 24.68966099, 114.174564,
        24.64559199, 114.391644, 24.56347899, 114.428795, 24.486252, 114.53163,
        24.55881899, 114.592472, 24.53796999, 114.665172, 24.583684, 114.705163,
        24.52608099, 114.752684, 24.61624301, 114.848924, 24.60219599,
        114.869032, 24.56235, 114.913384, 24.66499399, 115.024846, 24.669135,
        115.05487, 24.709921, 115.120731, 24.66435299, 115.278534, 24.75414,
        115.36061, 24.73533999, 115.40645, 24.79338099, 115.526386, 24.71543501,
        115.573645, 24.61704301, 115.671649, 24.604741, 115.688003, 24.54549999,
        115.843519, 24.562509, 115.756302, 24.74723601, 115.78258, 24.86293201,
        115.824294, 24.909294, 115.900072, 24.874856, 115.8925655, 24.93709965,
        116.0192163, 24.90455404, 116.0900518, 24.83832051, 116.1917437,
        24.87712468, 116.2439389, 24.79377155, 116.394763, 24.87788378,
        116.4177776, 24.84105582, 116.3759536, 24.80334742, 116.4884361,
        24.71684411, 116.5259158, 24.60480097, 116.5985859, 24.65461351,
        116.8012366, 24.67829556, 116.7558758, 24.5519505, 116.8607577,
        24.46225422, 116.8283063, 24.45326945, 116.9039081, 24.36986316,
        116.9335981, 24.22003907, 116.9986204, 24.17898116, 116.9266046,
        24.1006858, 116.9818065, 23.99942159, 116.959622, 23.86623496,
        117.0224461, 23.83876007, 117.0555427, 23.69422699, 117.1944218,
        23.61700763, 117.087166, 23.54287001, 117.06757, 23.64418799,
        116.989842, 23.62805099, 116.9705367, 23.54800306, 117.024429,
        23.56026701, 116.997978, 23.50732701, 116.893004, 23.552066, 116.871145,
        23.41341699, 116.7913, 23.331434, 116.687481, 23.350782, 116.812535,
        23.23498, 116.774816, 23.21312601, 116.726721, 23.26010501, 116.652895,
        23.16309101, 116.56813, 23.167821, 116.538321, 23.10869499, 116.575335,
        23.01581899, 116.501918, 22.93586099, 116.369645, 22.91767901,
        116.290107, 22.95473201, 116.140629, 22.835613, 115.998667, 22.832098,
        115.820964, 22.735253, 115.774497, 22.837764, 115.61109, 22.86103699,
        115.5262555, 22.75073155, 115.574353, 22.65136399, 115.473481,
        22.700728, 115.386638, 22.68624101, 115.347279, 22.770951, 115.404091,
        22.74577699, 115.416402, 22.78497099, 115.23507, 22.77836101,
        115.253844, 22.82093799, 115.217977, 22.83186501, 115.183548,
        22.77487901, 115.152625, 22.810406, 115.052494, 22.788012, 115.000399,
        22.68718601, 114.877258, 22.76343299, 114.974673, 22.67585101,
        114.888269, 22.54147001, 114.871756, 22.596277, 114.738204, 22.59414499,
        114.747712, 22.739668, 114.828885, 22.78718499, 114.786989, 22.834054,
        114.772017, 22.78442499, 114.692903, 22.789225, 114.560105, 22.726001,
        114.580454, 22.68787599, 114.54298, 22.713437, 114.514868, 22.680013,
        114.506262, 22.64428399, 114.599934, 22.651149, 114.503609, 22.55277901,
        114.610173, 22.541435, 114.615056, 22.49491299, 114.503178, 22.449406,
        114.491284, 22.53307099, 114.414786, 22.60949099, 114.235933,
        22.54500301, 114.235881, 22.54474401, 114.235492, 22.54342899,
        114.235226, 22.54291201, 114.235153, 22.54283901, 114.230511,
        22.54304199, 114.230964, 22.54374699, 114.230163, 22.546588, 114.22975,
        22.546906, 114.229408, 22.54728399, 114.22917, 22.54784799, 114.228994,
        22.54798899, 114.228361, 22.54800799, 114.22815, 22.54801699,
        114.227823, 22.54834601, 114.22756, 22.54848099, 114.226041,
        22.54929601, 114.225939, 22.54986401, 114.225868, 22.55008099,
        114.225761, 22.55028, 114.225519, 22.550484, 114.225033, 22.55078599,
        114.223976, 22.55178999, 114.223285, 22.55276399, 114.223007,
        22.55298301, 114.222196, 22.552999, 114.221927, 22.55288199, 114.221876,
        22.55254499, 114.221641, 22.55227199, 114.221082, 22.552076, 114.22071,
        22.551935, 114.22032, 22.55188999, 114.217504, 22.55194999, 114.217019,
        22.55244499, 114.216309, 22.55326901, 114.215322, 22.55394701,
        114.214975, 22.55399099, 114.214507, 22.55397699, 114.213286, 22.553739,
        114.212619, 22.55362299, 114.212142, 22.55361501, 114.210835, 22.55383,
        114.209414, 22.55373199, 114.207985, 22.55359599, 114.207292,
        22.55352001, 114.207032, 22.55353, 114.206807, 22.55358401, 114.206548,
        22.553871, 114.206314, 22.554331, 114.206158, 22.55459, 114.206037,
        22.55467299, 114.205829, 22.55475099, 114.205595, 22.55473401,
        114.20356, 22.554008, 114.203066, 22.55390201, 114.202521, 22.55398,
        114.202209, 22.55414099, 114.201248, 22.554108, 114.200893, 22.553498,
        114.200521, 22.553139, 114.20007, 22.55294, 114.198494, 22.552816,
        114.197897, 22.55292801, 114.196823, 22.552884, 114.196277, 22.552449,
        114.193767, 22.551926, 114.193368, 22.55233701, 114.192468, 22.552625,
        114.192252, 22.55239999, 114.191152, 22.551985, 114.189139, 22.552416,
        114.188649, 22.552449, 114.187848, 22.55250601, 114.186797, 22.55267401,
        114.186595, 22.55258801, 114.186408, 22.552367, 114.186112, 22.55175399,
        114.18613, 22.55144701, 114.185666, 22.55145399, 114.185301, 22.551711,
        114.184492, 22.55214099, 114.18271, 22.55280101, 114.182626,
        22.55291799, 114.182754, 22.55337799, 114.182578, 22.554804, 114.182573,
        22.555331, 114.182161, 22.556946, 114.180945, 22.557268, 114.180688,
        22.55721201, 114.179739, 22.557245, 114.179168, 22.55704401, 114.178236,
        22.55684199, 114.17726, 22.556905, 114.175509, 22.55689801, 114.175138,
        22.557122, 114.174783, 22.557436, 114.174439, 22.55805, 114.174222,
        22.558222, 114.173964, 22.55837601, 114.173687, 22.558451, 114.173305,
        22.55848799, 114.1728, 22.558514, 114.171984, 22.55826, 114.17158,
        22.55787101, 114.170993, 22.55714801, 114.169522, 22.55634, 114.168954,
        22.556373, 114.168534, 22.556422, 114.16819, 22.55657501, 114.168033,
        22.557009, 114.167872, 22.557163, 114.167412, 22.55826999, 114.167094,
        22.55877499, 114.166675, 22.55909299, 114.164436, 22.557572, 114.164003,
        22.556773, 114.16342, 22.55617799, 114.162237, 22.554049, 114.161077,
        22.552626, 114.160592, 22.552203, 114.159925, 22.55203099, 114.158349,
        22.55208301, 114.157674, 22.55207401, 114.15738, 22.552016, 114.156774,
        22.55123301, 114.156739, 22.550866, 114.156774, 22.54942301, 114.156722,
        22.548961, 114.156401, 22.54839899, 114.156185, 22.54824101, 114.13222,
        22.53691401, 114.132064, 22.536921, 114.131492, 22.53704201, 114.120694,
        22.52863699, 114.116713, 22.52704801, 114.099228, 22.533142, 114.083571,
        22.52689801, 114.083485, 22.526113, 114.068256, 22.513208, 114.066796,
        22.51227001, 114.03697, 22.50336499, 114.034943, 22.50384699,
        113.955126, 22.51855299, 113.89176, 22.443353, 113.889708, 22.538057,
        113.862705, 22.52190799, 113.760079, 22.728988, 113.58969, 22.80487501,
        113.624553, 22.75072299, 113.550668, 22.74257701, 113.663762,
        22.59769699, 113.574843, 22.46522301, 113.571819, 22.384007, 113.631486,
        22.37177801, 113.552133, 22.21382701, 113.549457, 22.21400401,
        113.546643, 22.21038399, 113.540832, 22.210632, 113.539386, 22.205763,
        113.481899, 22.15024099, 113.408999, 22.176202, 113.4525131,
        22.08181469, 113.3815492, 22.12706462, 113.410843, 22.00469101,
        113.3597301, 21.98215225, 113.255762, 22.04245701, 113.300526,
        21.91769901, 113.252159, 21.86570901, 113.237067, 21.94220701,
        113.114078, 22.05655099, 113.096546, 22.217789, 113.0897639,
        22.14367522, 113.020464, 22.114905, 113.034576, 21.94703101, 112.933,
        21.852552, 112.841046, 21.950979, 112.795787, 21.94584401, 112.654359,
        21.83819601, 112.649485, 21.783134, 112.5700054, 21.75193988,
        112.436659, 21.81291501, 112.404458, 21.89593999, 112.4150811,
        21.74193474, 112.351098, 21.70760199, 112.259383, 21.69509401,
        112.161292, 21.80389, 112.026366, 21.78892501, 112.061019, 21.77318199,
        111.838921, 21.662839, 111.794456, 21.76184, 111.682359, 21.78343601,
        111.81301, 21.69358401, 111.763566, 21.64121801, 111.791337,
        21.61659101, 111.696356, 21.59269, 111.681168, 21.52879501, 111.481814,
        21.50185, 111.440371, 21.567805, 111.455465, 21.529806, 111.296937,
        21.40354001, 111.253046, 21.450488, 111.285854, 21.437675, 111.291228,
        21.48992599, 111.210345, 21.51039099, 111.241291, 21.478803, 111.106665,
        21.45604699, 111.0363952, 21.5299289, 111.0182083, 21.47191082,
        111.090727, 21.464952, 110.891405, 21.383176, 110.773503, 21.39143601,
        110.618538, 21.215431, 110.436242, 21.18803199, 110.437923, 21.38954901,
        110.388012, 21.395803, 110.408324, 21.143493, 110.193133, 21.010013,
        110.172086, 20.90692301, 110.20808, 20.844941, 110.390157, 20.817751,
        110.392054, 20.71278099, 110.333213, 20.76926299, 110.304482, 20.675227,
        110.373923, 20.63588901, 110.467049, 20.67594599, 110.487765,
        20.57722901, 110.428337, 20.63507101, 110.400379, 20.60450099,
        110.47689, 20.577059, 110.537224, 20.47113501, 110.445223, 20.32902601,
        110.287735, 20.238447, 110.225044, 20.27696199, 110.130974, 20.22586901,
        110.061618, 20.28930199, 109.992309, 20.287827, 109.923459, 20.22087701,
        109.937833, 20.30459299, 109.866591, 20.40120401, 109.9105344,
        20.43355787, 109.983417, 20.358078, 110.0151326, 20.42400464,
        109.967708, 20.46115001, 109.94932, 20.433547, 109.942691, 20.48878999,
        109.923751, 20.43273999, 109.902484, 20.490603, 109.818832, 20.497428,
        109.843324, 20.563404, 109.746299, 20.63212699, 109.748585, 20.674835,
        109.838618, 20.70737899, 109.776235, 20.70330599, 109.757818,
        20.80788601, 109.731846, 20.79018799, 109.678518, 20.867598, 109.714626,
        21.05697701, 109.680128, 21.11812001, 109.775416, 21.215444, 109.758101,
        21.34283699, 109.920923, 21.35560601, 109.923506, 21.467844, 109.793935,
        21.467808, 109.753738, 21.56961099, 109.769525, 21.67213201, 109.80169,
        21.62732099, 109.91098, 21.660611, 109.986331, 21.87919799, 110.254869,
        21.87991401, 110.2903776, 21.91795674, 110.38888, 21.89076099,
        110.348518, 22.195841, 110.381059, 22.16421501, 110.430783, 22.20622501,
        110.503897, 22.142973, 110.559023, 22.19628399, 110.629092, 22.14910601,
        110.678882, 22.17268499, 110.654803, 22.23993101, 110.723049, 22.295242,
        110.789682, 22.28668599, 110.683477, 22.47339, 110.747758, 22.47300599,
        110.760673, 22.58284701, 110.800662, 22.55703299, 111.055607, 22.648497,
        111.089663, 22.69083599, 111.05812, 22.729879, 111.218463, 22.74809099,
        111.35876, 22.88922901, 111.363124, 22.96800901, 111.43424, 23.03859999,
        111.375771, 23.08607601, 111.398673, 23.159842, 111.349613, 23.27276401,
        111.399771, 23.469388, 111.480231, 23.53259199, 111.487051, 23.62663199,
        111.615645, 23.638989, 111.665286, 23.69995099, 111.61791, 23.733242,
        111.654855, 23.83329601, 111.810593, 23.80700101, 111.822893,
        23.91122601, 111.941257, 23.982106, 111.871202, 24.176578, 111.877906,
        24.228928, 111.986156, 24.25695301, 112.059405, 24.33973501, 111.985188,
        24.46793799, 112.007379, 24.53454699, 111.927434, 24.629413,
        111.9612792, 24.72116224,
      ],
      [
        117.123921, 23.48444499, 117.154932, 23.443034, 117.126399, 23.390036,
        116.944421, 23.425938,
      ],
      [115.563168, 22.753095, 115.605665, 22.74895099, 115.578769, 22.71056601],
      [113.585455, 22.71789999, 113.648096, 22.712333, 113.701186, 22.57058501],
      [113.627014, 22.44169499, 113.665902, 22.428739, 113.630266, 22.38436299],
      [
        113.4307551, 22.16621564, 113.53566, 22.16682799, 113.557874, 22.074565,
        113.4978317, 22.05763402,
      ],
      [
        111.9306526, 21.66971582, 112.023693, 21.631562, 111.815447, 21.55842,
        111.844829, 21.649522,
      ],
      [
        112.606444, 21.69938099, 112.663853, 21.71344501, 112.660623, 21.633403,
        112.526369, 21.58756401,
      ],
      [113.136421, 21.864005, 113.20443, 21.86697, 113.143137, 21.83306099],
      [
        112.783666, 21.76524799, 112.877523, 21.770343, 112.810441, 21.70402799,
        112.815644, 21.588466, 112.775391, 21.565789, 112.732635, 21.61306999,
        112.785881, 21.67734, 112.705886, 21.68190199,
      ],
      [
        110.489464, 21.204258, 110.626902, 21.211783, 110.569937, 21.08948801,
        110.434874, 21.168547,
      ],
      [
        110.393738, 21.090017, 110.4329, 21.05254, 110.552747, 21.06390499,
        110.530238, 20.92279499, 110.4149436, 20.99911979, 110.205093,
        20.94271099, 110.325024, 21.05198299, 110.3065071, 21.08345709,
      ],
      [
        110.5957243, 20.94658608, 110.642919, 20.922076, 110.589, 20.86299799,
        110.552633, 20.90011201,
      ],
    ],
  },
  {
    id: "450000",
    name: "广西",
    fullname: "广西壮族自治区",
    pinyin: ["guang", "xi"],
    location: {
      lat: 23.816659,
      lng: 108.327537,
    },
    polygon: [
      [108.574902, 21.88118, 108.629281, 21.88164499, 108.587561, 21.834001],
      [108.509829, 21.743206, 108.553305, 21.74950301, 108.522382, 21.71748599],
      [108.359061, 21.66009099, 108.394421, 21.659371, 108.357103, 21.55424699],
      [109.0926, 21.05064301, 109.144601, 21.05065601, 109.102612, 21.00569499],
      [
        109.753738, 21.56961099, 109.75565, 21.45645599, 109.65156, 21.513977,
        109.6765, 21.609008, 109.640503, 21.55760199, 109.636092, 21.605608,
        109.604834, 21.58762999, 109.557696, 21.68978301, 109.598231, 21.747959,
        109.512815, 21.69996801, 109.540516, 21.652904, 109.468401, 21.67372199,
        109.573494, 21.610769, 109.563096, 21.537403, 109.603613, 21.531547,
        109.503305, 21.477457, 109.536979, 21.442345, 109.502663, 21.47681801,
        109.256574, 21.42810701, 109.231438, 21.45951999, 109.229033, 21.418365,
        109.1642, 21.39729201, 109.046911, 21.447292, 109.165358, 21.51496999,
        109.15476, 21.57826901, 109.059256, 21.58611801, 109.070974,
        21.63362299, 108.974439, 21.593763, 108.913071, 21.614238, 108.852877,
        21.69588799, 108.869989, 21.74259601, 108.94622, 21.760777, 108.885927,
        21.74484, 108.906461, 21.787685, 108.869427, 21.745845, 108.815246,
        21.83311701, 108.871754, 21.62130499, 108.746778, 21.598906, 108.721796,
        21.74199899, 108.677262, 21.59030399, 108.691341, 21.68945399,
        108.667433, 21.64403099, 108.638632, 21.69004301, 108.691926,
        21.81118999, 108.659139, 21.77952899, 108.657755, 21.82546901,
        108.643796, 21.75553099, 108.625685, 21.78279399, 108.610555,
        21.69772999, 108.565186, 21.787713, 108.629631, 21.88583401, 108.536295,
        21.896052, 108.495547, 21.84416101, 108.458538, 21.86484501, 108.518155,
        21.776432, 108.504949, 21.70381899, 108.548868, 21.72333101, 108.532562,
        21.68594499, 108.565392, 21.70357699, 108.57237, 21.662912, 108.479695,
        21.556765, 108.405639, 21.537194, 108.391276, 21.59730801, 108.436106,
        21.597304, 108.407588, 21.60616101, 108.474708, 21.67148701, 108.416237,
        21.647034, 108.445089, 21.688617, 108.395932, 21.65966299, 108.342849,
        21.71027601, 108.32784, 21.583955, 108.22158, 21.49678699, 108.271778,
        21.58873201, 108.219687, 21.62321201, 108.140863, 21.57406699,
        108.192369, 21.52962099, 108.127634, 21.514183, 108.090268, 21.54883999,
        107.9578735, 21.53451162, 107.8614843, 21.65195077, 107.5467146,
        21.58647723, 107.4864826, 21.59579065, 107.4701782, 21.65954845,
        107.3649405, 21.59846345, 107.3064602, 21.7374642, 107.2162244,
        21.71038417, 107.0176967, 21.81942419, 107.0616244, 21.89379404,
        107.0272638, 21.94125544, 106.9583806, 21.92060769, 106.9264728,
        21.96750034, 106.76536, 22.0116891, 106.6859489, 21.97728884,
        106.6943931, 22.27420412, 106.6633114, 22.33113542, 106.5624306,
        22.34575929, 106.5603195, 22.45559683, 106.6143083, 22.60204978,
        106.7320325, 22.59106085, 106.8315209, 22.81046572, 106.7697168,
        22.80773309, 106.7167611, 22.88145336, 106.6531604, 22.86448593,
        106.5263183, 22.94657205, 106.3617469, 22.8564567, 106.2869622,
        22.86701047, 106.2067426, 22.97845804, 106.0859192, 22.99640349,
        105.8843373, 22.91509226, 105.7244371, 23.06212808, 105.5683549,
        23.06878142, 105.5426533, 23.18427673, 105.527653, 23.24448201,
        105.593254, 23.31254901, 105.700604, 23.32840299, 105.63141,
        23.40209999, 105.69987, 23.40143301, 105.852408, 23.52693199,
        105.986223, 23.48959599, 105.994313, 23.44789401, 106.067968, 23.49054,
        106.141459, 23.569672, 106.136878, 23.79542501, 106.196206, 23.87523301,
        106.007667, 24.12487843, 105.919457, 24.122193, 105.898774, 24.043413,
        105.799097, 24.02357, 105.772634, 24.07328699, 105.64942, 24.03308501,
        105.598003, 24.13798099, 105.528837, 24.12824, 105.493031, 24.01723199,
        105.31938, 24.116781, 105.258389, 24.06184699, 105.181884, 24.13286999,
        105.243065, 24.20672, 105.159568, 24.28008599, 105.196231, 24.33705701,
        105.052835, 24.44066801, 104.935181, 24.408363, 104.755614, 24.45752,
        104.703756, 24.42136101, 104.69512, 24.32277799, 104.578008,
        24.42017401, 104.504989, 24.62837001, 104.45086, 24.63828099,
        104.529438, 24.73139699, 104.738226, 24.619846, 104.875744, 24.73985899,
        105.033355, 24.787809, 105.075159, 24.916711, 105.209768, 24.99560801,
        105.267322, 24.92954799, 105.443162, 24.92186601, 105.500323,
        24.80798801, 105.599063, 24.807056, 105.805468, 24.70154199, 105.93883,
        24.72655201, 106.023202, 24.63237399, 106.19732, 24.81345, 106.153045,
        24.96096501, 106.438513, 25.017484, 106.590655, 25.08778, 106.653668,
        25.168306, 106.888311, 25.18219499, 106.921837, 25.24836, 106.997628,
        25.240643, 107.014307, 25.34480599, 106.963737, 25.437331, 107.07011,
        25.511737, 107.064541, 25.55952, 107.211187, 25.60919299, 107.327713,
        25.49878501, 107.314863, 25.402637, 107.4241388, 25.39006321,
        107.432685, 25.28912899, 107.488405, 25.28474, 107.472163, 25.213622,
        107.664994, 25.31531099, 107.697377, 25.195333, 107.762151, 25.22895201,
        107.765974, 25.117713, 108.114938, 25.21025001, 108.142774, 25.390642,
        108.193289, 25.40551901, 108.158015, 25.440664, 108.249053, 25.42712399,
        108.332162, 25.53771099, 108.427738, 25.43685099, 108.500351, 25.447821,
        108.622989, 25.30632599, 108.587687, 25.421385, 108.632856, 25.518907,
        108.690465, 25.518931, 108.658204, 25.551841, 108.689004, 25.6232,
        108.784039, 25.62828099, 108.812718, 25.526359, 108.867314, 25.55856299,
        109.025101, 25.51249001, 109.077328, 25.53621499, 109.031189,
        25.63241601, 109.079108, 25.72069799, 109.000967, 25.73632099,
        108.945875, 25.676468, 108.897642, 25.71616101, 109.117968, 25.80939101,
        109.148262, 25.74184599, 109.177225, 25.80516999, 109.207765,
        25.73934399, 109.340084, 25.731741, 109.339294, 25.83377999, 109.482633,
        26.02962999, 109.5238808, 25.99575972, 109.6349429, 26.0473028,
        109.710087, 26.01303745, 109.68522, 25.88035101, 109.779545,
        25.86595601, 109.826903, 25.916705, 109.791809, 26.01728701,
        109.8690656, 26.03100665, 109.9662866, 26.19554921, 110.0986982,
        26.16914802, 110.0680657, 26.04342877, 110.1563701, 26.01848666,
        110.1965248, 26.06816383, 110.261713, 25.96138099, 110.325554,
        25.97564101, 110.377121, 26.09442101, 110.507717, 26.17667101,
        110.6124447, 26.33366467, 110.741755, 26.313903, 110.7613854,
        26.24909901, 110.927194, 26.25401199, 110.974556, 26.38556601,
        111.04088, 26.32163499, 111.281275, 26.27112001, 111.2675861,
        26.05694695, 111.1896123, 25.95235238, 111.2597707, 25.86112464,
        111.3440327, 25.90707055, 111.492075, 25.86860166, 111.4307201,
        25.84337996, 111.4403321, 25.76970509, 111.30785, 25.71848299,
        111.3432752, 25.60467526, 111.292109, 25.43581399, 111.1033169,
        25.28509801, 111.112769, 25.21708501, 110.998948, 25.16160701,
        110.951708, 25.043752, 110.979129, 24.914705, 111.097426, 24.940883,
        111.103915, 25.037705, 111.2768387, 25.15146982, 111.322024,
        25.10490848, 111.4339541, 25.10230539, 111.4183683, 25.04148333,
        111.4682248, 25.01975094, 111.4308549, 24.96723548, 111.47022,
        24.928946, 111.452831, 24.664919, 111.5601224, 24.63893591, 111.7056046,
        24.78272488, 112.0240994, 24.74014517, 111.9612792, 24.72116224,
        111.927434, 24.629413, 112.007379, 24.53454699, 111.985188, 24.46793799,
        112.059405, 24.33973501, 111.986156, 24.25695301, 111.877906, 24.228928,
        111.871202, 24.176578, 111.941257, 23.982106, 111.822893, 23.91122601,
        111.810593, 23.80700101, 111.654855, 23.83329601, 111.61791, 23.733242,
        111.665286, 23.69995099, 111.615645, 23.638989, 111.487051, 23.62663199,
        111.480231, 23.53259199, 111.399771, 23.469388, 111.349613, 23.27276401,
        111.398673, 23.159842, 111.375771, 23.08607601, 111.43424, 23.03859999,
        111.363124, 22.96800901, 111.35876, 22.88922901, 111.218463,
        22.74809099, 111.05812, 22.729879, 111.089663, 22.69083599, 111.055607,
        22.648497, 110.800662, 22.55703299, 110.760673, 22.58284701, 110.747758,
        22.47300599, 110.683477, 22.47339, 110.789682, 22.28668599, 110.723049,
        22.295242, 110.654803, 22.23993101, 110.678882, 22.17268499, 110.629092,
        22.14910601, 110.559023, 22.19628399, 110.503897, 22.142973, 110.430783,
        22.20622501, 110.381059, 22.16421501, 110.348518, 22.195841, 110.38888,
        21.89076099, 110.2903776, 21.91795674, 110.254869, 21.87991401,
        109.986331, 21.87919799, 109.91098, 21.660611, 109.80169, 21.62732099,
        109.769525, 21.67213201,
      ],
    ],
  },
  {
    id: "460000",
    name: "海南",
    fullname: "海南省",
    pinyin: ["hai", "nan"],
    location: {
      lat: 19.018639,
      lng: 110.348781,
    },
    polygon: [
      [
        115.9688178, 20.98616169, 115.9990302, 20.92267948, 115.9386054,
        20.94640831, 115.929679, 20.99193147,
      ],
      [
        115.9832374, 21.04000423, 115.9468452, 21.10471769, 116.0227195,
        21.12457459, 116.0659781, 21.04128596,
      ],
      [
        116.7567082, 20.61225794, 116.8561001, 20.62462922, 116.8964405,
        20.68470392, 116.8785877, 20.74266792, 116.7714711, 20.76803022,
        116.9029637, 20.75823896, 116.9273396, 20.68084958, 116.8933506,
        20.61884537, 116.8035717, 20.58622681,
      ],
      [
        108.6593332, 19.20552204, 108.624313, 19.26456501, 108.671787,
        19.28669501, 108.644217, 19.301374, 108.688209, 19.370946, 109.172362,
        19.644558, 109.2160407, 19.72031719, 109.303814, 19.70833099,
        109.279634, 19.783281, 109.155144, 19.705874, 109.154955, 19.80067899,
        109.292178, 19.911672, 109.466694, 19.867591, 109.4773714, 19.82334922,
        109.539656, 19.86675999, 109.540384, 19.83502699, 109.571335, 19.854664,
        109.522308, 19.872251, 109.539334, 19.94400799, 109.71504, 20.010715,
        109.812769, 19.93973299, 109.8202787, 19.99040494, 109.862502,
        19.93658801, 109.882717, 19.98732801, 109.944839, 19.99116501,
        110.0258862, 19.94392823, 109.971834, 19.91756901, 110.012172,
        19.902138, 110.010587, 19.95827199, 110.122763, 19.99376999, 110.165829,
        20.06739001, 110.28672, 20.020594, 110.370811, 20.08936101, 110.551257,
        20.01739099, 110.562212, 19.975562, 110.592045, 19.995364, 110.615033,
        19.92085499, 110.5734108, 20.08628979, 110.689056, 20.159122,
        110.785655, 20.01111599, 110.932312, 20.01316699, 110.957611,
        19.97341901, 111.04704, 19.640843, 110.98511, 19.63925501, 110.869715,
        19.52235301, 110.824618, 19.59602301, 110.873542, 19.61390899,
        110.805313, 19.609433, 110.84089, 19.52761901, 110.765459, 19.424604,
        110.680715, 19.388197, 110.685913, 19.32433801, 110.560002, 19.14017801,
        110.56383, 19.09549899, 110.588539, 19.15061699, 110.523606,
        18.88144199, 110.44028, 18.831579, 110.517027, 18.799837, 110.511852,
        18.89026399, 110.543456, 18.77953301, 110.469304, 18.767741, 110.418,
        18.65875699, 110.330983, 18.66234799, 110.418284, 18.681394, 110.397004,
        18.701741, 110.196007, 18.61120899, 110.105665, 18.52518599, 110.070959,
        18.403862, 110.044136, 18.43025601, 110.053894, 18.381715, 109.971797,
        18.387639, 110.027702, 18.44519701, 109.826908, 18.375939, 109.780592,
        18.395042, 109.741703, 18.270382, 109.677985, 18.27219799, 109.748516,
        18.25316499, 109.71543, 18.19527799, 109.649314, 18.23012201,
        109.578084, 18.15755801, 109.546177, 18.222371, 109.573261, 18.25646701,
        109.485949, 18.18676199, 109.500731, 18.247544, 109.462197, 18.28021201,
        109.180096, 18.28482201, 109.110047, 18.36046799, 108.992018,
        18.35549001, 108.958371, 18.40285899, 108.692356, 18.50283801,
        108.698844, 18.733921, 108.626999, 18.83421501, 108.662599, 18.92832599,
        108.61635, 19.098167, 108.677446, 19.16035199,
      ],
      [
        112.993188, 19.47227, 112.984978, 19.51449101, 113.029104, 19.52887699,
        113.048194, 19.49348001,
      ],
      [
        113.832164, 19.15839699, 113.806772, 19.22319099, 113.920323,
        19.22349999, 113.914687, 19.17214301,
      ],
      [
        111.46307, 17.07768701, 111.519757, 17.11939499, 111.563235,
        17.09956599,
      ],
      [112.232677, 16.996307, 112.299076, 16.973528, 112.203119, 16.96774999],
      [111.740355, 16.548647, 111.788407, 16.51269301, 111.743529, 16.452066],
      [
        111.64947, 16.25564499, 111.682732, 16.260632, 111.600918, 16.18118601,
        111.700659, 16.198434, 111.568243, 16.162786,
      ],
      [
        111.734445, 16.197351, 111.789703, 16.249942, 111.684245, 16.262331,
        111.813151, 16.261772, 111.800473, 16.20810801,
      ],
      [111.761706, 16.06173, 111.832963, 16.061975, 111.78348, 16.02819299],
      [112.226538, 16.759349, 112.210816, 16.79596599, 112.262119, 16.77812899],
      [112.409321, 16.295275, 112.54045, 16.299093, 112.379209, 16.269184],
      [
        112.476578, 16.001374, 112.462208, 16.04407201, 112.60767, 16.06681899,
        112.583765, 16.014946,
      ],
      [114.703184, 16.17031699, 114.723814, 16.205896, 114.819298, 16.20761499],
      [114.926178, 16.03666101, 114.911009, 16.002038, 114.897868, 16.04681401],
      [116.694826, 16.34552501, 116.745115, 16.365961, 116.73594, 16.30064301],
      [112.233596, 15.69601599, 112.203596, 15.71421101, 112.25179, 15.743054],
      [
        117.708267, 15.18257699, 117.742913, 15.234347, 117.844648, 15.127293,
        117.732118, 15.21844299, 117.72522, 15.131258, 117.829262, 15.110333,
        117.726851, 15.10544801,
      ],
      [115.361845, 13.94897501, 115.438723, 13.961039, 115.397613, 13.92503399],
      [
        114.619788, 11.431992, 114.619838, 11.51581099, 114.657335, 11.527504,
        114.650929, 11.431709,
      ],
      [114.688824, 11.469163, 114.726699, 11.48841, 114.734136, 11.43233501],
      [
        114.69717, 11.00449101, 114.766516, 11.11022601, 114.801172,
        11.10348199,
      ],
      [114.469335, 10.83599501, 114.597655, 10.898872, 114.583113, 10.83934701],
      [114.910634, 10.86307201, 114.95774, 10.90497301, 114.938724, 10.84826],
      [
        117.021644, 11.06474199, 117.068789, 11.07666001, 117.067455,
        11.03714301,
      ],
      [117.213877, 10.734887, 117.206826, 10.707759, 117.18103, 10.73468899],
      [
        117.266845, 10.69169199, 117.32518, 10.744353, 117.418513, 10.70253,
        117.404756, 10.670848,
      ],
      [117.299397, 11.07763199, 117.28444, 11.025245, 117.264289, 11.06279099],
      [
        117.770836, 10.77350201, 117.831536, 10.83874499, 117.822722,
        10.77360701,
      ],
      [113.846059, 10.01879999, 113.861595, 10.12688499, 113.86075, 9.992062],
      [114.234372, 10.14888501, 114.27422, 10.16980101, 114.26584, 10.13358001],
      [114.689726, 10.345732, 114.747197, 10.371954, 114.725939, 10.31911],
      [114.617358, 9.965476, 114.685933, 9.97894301, 114.655111, 9.91797699],
      [115.513879, 9.931765, 115.585164, 9.89640999, 115.513108, 9.877062],
      [115.427961, 9.37857001, 115.456196, 9.41693201, 115.470048, 9.359079],
      [
        116.045498, 10.09544901, 116.073372, 10.131098, 116.096033, 10.093595,
        116.070264, 10.06400099,
      ],
      [115.837513, 9.709603, 115.918028, 9.790651, 115.89482, 9.65743401],
      [
        116.514914, 10.349366, 116.647805, 10.34277601, 116.467035, 10.308897,
        116.526382, 10.427087,
      ],
      [117.257883, 10.320607, 117.294091, 10.35237699, 117.295875, 10.31042299],
      [
        117.348224, 10.09036601, 117.38734, 10.10121001, 117.387509,
        10.06656099,
      ],
      [111.658143, 8.672772, 111.717895, 8.64989601, 111.665727, 8.622843],
      [113.939228, 8.87498199, 113.925374, 8.838268, 113.893594, 8.862575],
      [113.977002, 8.87279399, 114.051635, 8.831624, 114.054606, 8.778694],
      [
        114.194275, 8.76448, 114.222054, 8.78470199, 114.248852, 8.724398,
        114.214775, 8.760552, 114.210279, 8.71234301,
      ],
      [114.598772, 8.84603999, 114.637213, 8.89525, 114.680912, 8.88454799],
      [115.509494, 8.49072399, 115.560748, 8.52159901, 115.558237, 8.46294801],
      [115.167365, 8.386298, 115.315694, 8.356124, 115.266444, 8.31323101],
      [
        115.977286, 9.32216901, 115.995777, 9.277625, 115.942967, 9.269682,
        115.922927, 9.306044,
      ],
      [116.457317, 9.17461599, 116.494619, 9.179815, 116.487089, 9.139303],
      [116.273488, 8.87962501, 116.32865, 8.909655, 116.298814, 8.86016299],
      [116.749533, 9.05662801, 116.70885, 9.02408399, 116.705903, 9.05661001],
      [
        109.463996, 7.344631, 109.682282, 7.57081101, 109.948716, 7.52299101,
        109.709372, 7.51090399, 109.51325, 7.32024199, 109.467399, 7.30991,
      ],
      [
        109.936338, 7.848327, 110.078031, 7.94940699, 110.050192, 7.84647501,
        109.976515, 7.811705,
      ],
      [
        110.459757, 8.11633799, 110.594768, 8.17307499, 110.513883, 8.07511999,
        110.471214, 8.07214301,
      ],
      [110.609034, 8.01098, 110.637938, 8.039661, 110.640695, 7.985298],
      [111.553746, 7.80776099, 111.613032, 7.85882801, 111.585183, 7.77138201],
      [111.638813, 7.90707101, 111.683593, 7.936173, 111.725091, 7.90872601],
      [111.925445, 8.07079199, 111.978118, 8.106519, 112.01873, 8.06555399],
      [111.539125, 7.544228, 111.60682, 7.60738599, 111.5835, 7.543232],
      [
        111.670502, 7.65131101, 111.691233, 7.711787, 111.744255, 7.72373699,
        111.707408, 7.64879999,
      ],
      [112.892055, 7.84424701, 112.934698, 7.86200499, 112.929571, 7.827693],
      [
        113.266007, 8.12621501, 113.375415, 8.309317, 113.34983, 8.172414,
        113.290372, 8.11317901, 113.332552, 8.19712101,
      ],
      [113.76943, 7.636926, 113.837312, 7.625367, 113.778698, 7.600359],
      [113.894743, 8.00495301, 113.93996, 8.018268, 113.970619, 7.94474199],
      [
        113.896965, 7.607029, 114.41466, 7.952783, 114.54076, 7.94556399,
        114.554398, 7.87923901, 114.433847, 7.787621, 114.368451, 7.638626,
        114.058944, 7.537879, 113.978342, 7.53779899,
      ],
      [114.791766, 8.161002, 114.811959, 8.110448, 114.777092, 8.114082],
      [
        114.868325, 7.98375501, 114.897644, 8.01264, 114.922361, 7.980752,
        114.900086, 7.94926599,
      ],
      [114.665818, 7.58992301, 114.724243, 7.60686901, 114.68298, 7.56276699],
      [114.949112, 7.508998, 115.016931, 7.52311799, 114.989716, 7.47644799],
      [114.854118, 7.24432699, 114.869623, 7.19881801, 114.833553, 7.18333399],
      [113.59973, 6.962097, 113.621377, 6.92924, 113.580111, 6.92060401],
      [112.527498, 5.794361, 112.561845, 5.820674, 112.562868, 5.75911499],
      [112.523183, 5.65611901, 112.556579, 5.685526, 112.555895, 5.61845499],
      [112.462654, 5.49329599, 112.516882, 5.50899801, 112.529118, 5.477189],
      [112.583194, 5.56154599, 112.639621, 5.51518101, 112.620884, 5.462676],
      [112.611849, 5.36781601, 112.691015, 5.40671299, 112.658037, 5.354195],
      [112.557138, 5.10951599, 112.613618, 5.102849, 112.569202, 5.071893],
      [
        112.650995, 5.106796, 112.684064, 5.11843299, 112.718868, 5.06254801,
        112.656055, 5.05557701,
      ],
      [
        112.907255, 4.99283299, 112.914234, 5.04316199, 112.955889, 5.04537901,
        112.943688, 4.991639,
      ],
      [112.221999, 3.88055099, 112.264237, 3.89812099, 112.249326, 3.859812],
    ],
  },
  {
    id: "500000",
    name: "重庆",
    fullname: "重庆市",
    pinyin: ["chong", "qing"],
    location: {
      lat: 30.563707,
      lng: 107.550483,
    },
    polygon: [
      [
        106.374298, 28.525625, 106.227454, 28.88934099, 106.0483, 28.906726,
        105.986567, 28.97888299, 105.914027, 28.90101001, 105.797586,
        28.93593299, 105.703839, 29.176979, 105.715466, 29.29558, 105.65797,
        29.25149999, 105.475582, 29.27946399, 105.457877, 29.32866, 105.420208,
        29.31152501, 105.427279, 29.418862, 105.324552, 29.448636, 105.289761,
        29.552629, 105.320966, 29.60970401, 105.378387, 29.62275401, 105.389379,
        29.676518, 105.477599, 29.67528001, 105.485143, 29.719328, 105.538857,
        29.694874, 105.618574, 29.84661199, 105.737495, 29.86275799, 105.702881,
        29.924773, 105.747566, 30.032857, 105.536424, 30.16484399, 105.661124,
        30.20822999, 105.62505, 30.275838, 105.734408, 30.259672, 105.714823,
        30.32278099, 105.820165, 30.43750499, 105.846209, 30.392435, 106.030909,
        30.37425801, 106.171362, 30.306207, 106.242069, 30.176783, 106.300558,
        30.23854201, 106.427851, 30.25377199, 106.441061, 30.30892801,
        106.557428, 30.31586499, 106.634842, 30.26568499, 106.607169,
        30.23107501, 106.732414, 30.02682, 106.91297, 30.024751, 106.982321,
        30.08523, 107.023557, 30.03531101, 107.074225, 30.05000999, 107.516666,
        30.644226, 107.424345, 30.740679, 107.498543, 30.809598, 107.480226,
        30.83853599, 107.63544, 30.81803399, 107.717849, 30.88831, 107.76395,
        30.817036, 107.849846, 30.792597, 107.954223, 30.87242499, 107.994717,
        30.908684, 107.944442, 30.924354, 107.942394, 30.98879501, 108.059421,
        31.04945999, 108.009385, 31.109596, 108.089908, 31.20187699, 108.019087,
        31.24430201, 108.180459, 31.32579601, 108.154138, 31.37123199,
        108.225566, 31.45301101, 108.1905, 31.491484, 108.339055, 31.50849,
        108.423641, 31.622027, 108.546712, 31.66564699, 108.505552, 31.73264999,
        108.535275, 31.757698, 108.34358, 31.86104, 108.25924, 31.96748599,
        108.369463, 31.986832, 108.346937, 32.070109, 108.450441, 32.07440299,
        108.370211, 32.176289, 108.5097471, 32.20120777, 108.890695,
        31.98989299, 109.039046, 31.96086, 109.194061, 31.81848501, 109.275694,
        31.799616, 109.281591, 31.71688801, 109.5843741, 31.727101, 109.7349997,
        31.69543864, 109.7650351, 31.60459974, 109.7251473, 31.55019325,
        109.9829148, 31.51314602, 109.9462486, 31.46865455, 110.145059,
        31.38553756, 110.2009877, 31.15873572, 110.1214565, 31.08760702,
        110.1363013, 30.98598427, 110.1734511, 30.98058975, 110.0834575,
        30.80044661, 110.0086411, 30.88488712, 109.9028672, 30.90475113,
        109.6544259, 30.76252335, 109.5696058, 30.64460486, 109.5326348,
        30.66816426, 109.4542062, 30.61335187, 109.3520753, 30.48728059,
        109.3268337, 30.61966912, 109.1425649, 30.5213623, 109.0812786,
        30.60050009, 109.1161768, 30.64337973, 109.0431564, 30.65596625,
        108.8087441, 30.49159373, 108.7294918, 30.50304014, 108.6597362,
        30.58837597, 108.6443574, 30.51889763, 108.5692675, 30.46955209,
        108.427121, 30.49266501, 108.4001487, 30.39161433, 108.5840339,
        30.25633955, 108.5134238, 30.05857165, 108.5522172, 29.97868605,
        108.5253511, 29.86694485, 108.4272878, 29.87978657, 108.3672646,
        29.82030212, 108.4036317, 29.83670295, 108.4376497, 29.74101198,
        108.5052432, 29.70751532, 108.5914341, 29.85764493, 108.6608062,
        29.85430927, 108.6838663, 29.69276304, 108.7656312, 29.63677534,
        108.7536061, 29.68961674, 108.7879465, 29.69161067, 108.7822861,
        29.63462151, 108.8293676, 29.67198433, 108.9080946, 29.59244085,
        108.8647585, 29.47190178, 108.9377182, 29.42863827, 108.9189275,
        29.32671823, 109.0706108, 29.39839998, 109.1417149, 29.26907958,
        109.1120264, 29.20804932, 109.2317338, 29.1155775, 109.319576,
        29.04663799, 109.2339375, 28.86416641, 109.241306, 28.77652301,
        109.300654, 28.73967901, 109.18062, 28.61966008, 109.3066087,
        28.62069163, 109.2743678, 28.49465684, 109.153215, 28.41664, 109.086363,
        28.184696, 109.026206, 28.22014201, 108.987446, 28.16029799, 108.922604,
        28.21738701, 108.846046, 28.20073101, 108.821756, 28.245125, 108.762639,
        28.191075, 108.724999, 28.27516001, 108.782632, 28.42759399, 108.72337,
        28.49196099, 108.640768, 28.45761399, 108.697018, 28.40382501,
        108.66887, 28.335386, 108.579395, 28.344202, 108.611124, 28.420987,
        108.57325, 28.52899, 108.634336, 28.636798, 108.566481, 28.662565,
        108.473769, 28.627268, 108.333589, 28.67629101, 108.389197, 28.79236001,
        108.306576, 29.07917499, 108.270614, 29.09090599, 108.232045, 29.027397,
        108.19639, 29.07162299, 108.068497, 29.08649701, 107.883455,
        29.00762201, 107.866976, 28.95856799, 107.810438, 28.984306, 107.810667,
        29.13939099, 107.751604, 29.19962501, 107.700465, 29.14144, 107.598423,
        29.14777999, 107.561973, 29.22127599, 107.473089, 29.170995, 107.401581,
        29.18470099, 107.427568, 29.12734099, 107.369753, 29.09344901,
        107.364155, 29.00892701, 107.441041, 28.94377, 107.385449, 28.84924,
        107.248419, 28.76182199, 107.194167, 28.888262, 107.1479224,
        28.89789145, 107.016101, 28.8825, 106.986178, 28.773606, 106.924448,
        28.81004699, 106.843562, 28.780017, 106.828345, 28.737694, 106.889558,
        28.69494499, 106.867177, 28.62482699, 106.812602, 28.58919799,
        106.759046, 28.61109999, 106.782341, 28.56817199, 106.7282, 28.54199999,
        106.726951, 28.45469601, 106.632756, 28.50353501, 106.564762,
        28.48526299, 106.651139, 28.651266, 106.469778, 28.83429499, 106.525146,
        28.57536099, 106.474372, 28.59923401, 106.502483, 28.53518899,
        106.399105, 28.57116501,
      ],
    ],
  },
  {
    id: "510000",
    name: "四川",
    fullname: "四川省",
    pinyin: ["si", "chuan"],
    location: {
      lat: 30.651696,
      lng: 103.076452,
    },
    polygon: [
      [
        105.308413, 27.70514201, 105.273854, 27.795232, 105.315972, 27.818239,
        105.244204, 27.82297399, 105.232684, 27.89292399, 105.284184, 27.934445,
        105.271548, 27.99662301, 105.186367, 27.995498, 105.177056, 28.066861,
        105.059145, 28.098113, 104.977121, 28.061083, 104.872958, 27.90594199,
        104.576858, 27.84070901, 104.50675, 27.878896, 104.298099, 28.045332,
        104.45104, 28.11635301, 104.457402, 28.169399, 104.401161, 28.197709,
        104.449364, 28.26996599, 104.370664, 28.33714, 104.296603, 28.30812899,
        104.254311, 28.403572, 104.24828, 28.528353, 104.356813, 28.55568699,
        104.425286, 28.62670999, 104.374093, 28.649329, 104.308107, 28.614909,
        104.245914, 28.663721, 104.09612, 28.603451, 103.950411, 28.601082,
        103.851123, 28.66708301, 103.781992, 28.524566, 103.874826, 28.30619701,
        103.711577, 28.19711601, 103.632403, 28.259998, 103.452394, 28.12303301,
        103.432505, 28.04126501, 103.489522, 28.03144001, 103.515563,
        27.96554399, 103.564246, 27.97612499, 103.502969, 27.91179899,
        103.489533, 27.797414, 103.279645, 27.632748, 103.294891, 27.56572801,
        103.221901, 27.566317, 103.142902, 27.42192499, 102.985324, 27.36737999,
        102.948834, 27.41272699, 102.88254, 27.29651199, 102.896638, 26.911408,
        102.970139, 26.83243001, 103.056464, 26.54570901, 102.989862,
        26.47993801, 102.987477, 26.34849399, 102.880265, 26.36611801,
        102.674533, 26.20539199, 102.600856, 26.250378, 102.629412, 26.33686,
        102.551606, 26.36214001, 102.247963, 26.215176, 102.107791, 26.06824401,
        101.917587, 26.10799301, 101.8354, 26.045866, 101.808141, 26.15968199,
        101.594434, 26.26152799, 101.597455, 26.30344801, 101.661079,
        26.30938499, 101.636749, 26.395035, 101.399229, 26.54242601, 101.513317,
        26.768584, 101.460605, 26.78657699, 101.457078, 26.729752, 101.389689,
        26.723177, 101.365406, 26.88367699, 101.267756, 26.90300199, 101.264465,
        26.955517, 101.137425, 27.02234499, 101.170422, 27.19561601, 101.042393,
        27.221659, 101.020052, 27.34480899, 100.936753, 27.469216, 100.901292,
        27.45338201, 100.912034, 27.52170001, 100.827906, 27.614609, 100.848237,
        27.672006, 100.78921, 27.684174, 100.707047, 27.80136901, 100.680726,
        27.923506, 100.546392, 27.80859401, 100.432531, 27.86515199, 100.327695,
        27.720463, 100.308787, 27.860886, 100.168749, 27.896924, 100.191931,
        27.94646099, 100.021131, 28.14741401, 100.033298, 28.18461099,
        100.16816, 28.220717, 100.176489, 28.3248, 100.055418, 28.370452,
        100.074793, 28.42528101, 99.99320601, 28.47246899, 99.94867099,
        28.578151, 99.733159, 28.719507, 99.71780699, 28.846288, 99.62558301,
        28.814674, 99.614734, 28.74024599, 99.53168, 28.67998999, 99.54077001,
        28.62352199, 99.402927, 28.54573799, 99.437373, 28.402044, 99.39297001,
        28.31861399, 99.40238101, 28.15070101, 99.30671399, 28.22760801,
        99.16514899, 28.42521199, 99.18376699, 28.588909, 99.10432399,
        28.814229, 99.11377401, 29.221362, 99.06653101, 29.30053601,
        99.05203801, 29.56398799, 98.99186301, 29.6609, 99.069016, 29.92727999,
        98.98630701, 30.157535, 98.90475599, 30.69123299, 98.95781001, 30.76492,
        98.78032699, 30.89334899, 98.79844201, 31.008358, 98.60155101,
        31.189594, 98.637613, 31.336169, 98.77595001, 31.248673, 98.88701101,
        31.374601, 98.61910599, 31.591585, 98.41571101, 31.830295, 98.434794,
        32.00782699, 98.219147, 32.23421101, 98.21886401, 32.34235501,
        98.02353901, 32.452506, 97.73106799, 32.527308, 97.42930399, 32.699083,
        97.348109, 32.895111, 97.373862, 32.95589, 97.52373399, 32.988613,
        97.48722499, 33.167294, 97.57622498, 33.221912, 97.62489199, 33.339159,
        97.75878301, 33.40320999, 97.55258301, 33.46566701, 97.52521599,
        33.57578399, 97.41577301, 33.60550299, 97.42323701, 33.75492601,
        97.368769, 33.82411999, 97.39214101, 33.88893301, 97.63836001,
        33.92103401, 97.702336, 34.036747, 97.66561401, 34.12650399,
        97.81069999, 34.207453, 97.944855, 34.19859, 98.02859201, 34.122938,
        98.20723701, 34.078773, 98.40518, 34.08524201, 98.44060801, 33.99222499,
        98.40683199, 33.86717499, 98.656961, 33.65145399, 98.61770499,
        33.624366, 98.742927, 33.47645099, 98.758352, 33.27905099, 98.90492001,
        33.12452401, 99.196092, 33.035425, 99.268187, 32.878719, 99.38572399,
        32.90020899, 99.73038601, 32.72519401, 99.79203701, 32.883101,
        99.77040701, 32.94542901, 99.852818, 32.94329201, 99.90306099,
        33.04704401, 99.95657102, 32.948096, 100.037225, 32.93020099,
        100.123185, 32.837277, 100.110153, 32.63926601, 100.214955, 32.60652301,
        100.258778, 32.742315, 100.334503, 32.71826401, 100.400097, 32.75634001,
        100.378736, 32.69844799, 100.420326, 32.731775, 100.544799, 32.57006899,
        100.663601, 32.52242, 100.663727, 32.62075501, 100.718395, 32.621494,
        100.687152, 32.67713999, 100.930017, 32.600019, 101.067747, 32.68109599,
        101.120957, 32.63274399, 101.190151, 32.686583, 101.237748, 32.82772099,
        101.12315, 32.91133701, 101.129113, 32.98951701, 101.185888, 32.986044,
        101.125312, 33.22185999, 101.183514, 33.270078, 101.296976, 33.26223,
        101.3827, 33.15287901, 101.40232, 33.224627, 101.487733, 33.22689901,
        101.631954, 33.10061999, 101.740401, 33.269124, 101.654317, 33.31538,
        101.662766, 33.381632, 101.776171, 33.45193601, 101.769177, 33.538671,
        101.844346, 33.60245199, 101.946323, 33.442776, 101.877336, 33.31470401,
        101.767605, 33.26339999, 101.841331, 33.18910501, 101.825518, 33.119461,
        101.867311, 33.10381599, 101.935639, 33.186869, 102.08339, 33.194631,
        102.107696, 33.298491, 102.209317, 33.22518401, 102.186988, 33.332739,
        102.263408, 33.417117, 102.368686, 33.39338399, 102.482578, 33.457439,
        102.445831, 33.529427, 102.478124, 33.54220499, 102.339449, 33.61190201,
        102.34127, 33.727555, 102.285674, 33.71762, 102.32314, 33.75753501,
        102.235533, 33.792272, 102.253329, 33.86151101, 102.139636, 33.97126001,
        102.39138, 33.970834, 102.437602, 34.08719301, 102.657725, 34.08139601,
        102.607931, 34.160391, 102.91182, 34.31294699, 102.977038, 34.19834199,
        103.109319, 34.177038, 103.179117, 34.079947, 103.118918, 34.03645299,
        103.159237, 34.00652201, 103.123059, 33.941601, 103.181184, 33.900721,
        103.157748, 33.80914301, 103.272278, 33.80956201, 103.349618, 33.743506,
        103.5065, 33.81014301, 103.536715, 33.670941, 103.625892, 33.72735099,
        103.768654, 33.65871301, 104.052296, 33.685282, 104.168589, 33.611739,
        104.220772, 33.40461399, 104.295709, 33.33288801, 104.437445,
        33.32254699, 104.393529, 33.310927, 104.412581, 33.281768, 104.302819,
        33.30257401, 104.377865, 33.10914099, 104.335642, 33.04189199,
        104.426915, 33.01445999, 104.366559, 32.943654, 104.286771, 32.94091401,
        104.300247, 32.83090499, 104.571726, 32.72865299, 104.644788,
        32.66119601, 104.82915, 32.66261001, 104.881774, 32.601178, 105.030251,
        32.650453, 105.110848, 32.59411101, 105.455224, 32.73724699, 105.381001,
        32.87930699, 105.425533, 32.93112299, 105.499054, 32.911883, 105.596357,
        32.699265, 105.822516, 32.770143, 105.8257, 32.82538899, 106.011564,
        32.82971401, 106.042155, 32.86508799, 106.111786, 32.721858,
        106.3506008, 32.67213633, 106.403336, 32.617188, 106.733257,
        32.73937899, 106.783371, 32.735981, 106.785838, 32.696781, 107.012571,
        32.72155099, 107.099063, 32.649475, 107.08534, 32.529505, 107.220961,
        32.42174101, 107.263932, 32.403273, 107.302074, 32.48232199, 107.429056,
        32.532905, 107.457005, 32.41776499, 107.52525, 32.38243099, 107.679451,
        32.39829701, 107.832161, 32.225819, 107.978235, 32.14626901, 108.031321,
        32.22073901, 108.185493, 32.22115099, 108.241933, 32.27488801,
        108.312045, 32.232194, 108.4718007, 32.27060568, 108.5097471,
        32.20120777, 108.370211, 32.176289, 108.450441, 32.07440299, 108.346937,
        32.070109, 108.369463, 31.986832, 108.25924, 31.96748599, 108.34358,
        31.86104, 108.535275, 31.757698, 108.505552, 31.73264999, 108.546712,
        31.66564699, 108.423641, 31.622027, 108.339055, 31.50849, 108.1905,
        31.491484, 108.225566, 31.45301101, 108.154138, 31.37123199, 108.180459,
        31.32579601, 108.019087, 31.24430201, 108.089908, 31.20187699,
        108.009385, 31.109596, 108.059421, 31.04945999, 107.942394, 30.98879501,
        107.944442, 30.924354, 107.994717, 30.908684, 107.954223, 30.87242499,
        107.849846, 30.792597, 107.76395, 30.817036, 107.717849, 30.88831,
        107.63544, 30.81803399, 107.480226, 30.83853599, 107.498543, 30.809598,
        107.424345, 30.740679, 107.516666, 30.644226, 107.074225, 30.05000999,
        107.023557, 30.03531101, 106.982321, 30.08523, 106.91297, 30.024751,
        106.732414, 30.02682, 106.607169, 30.23107501, 106.634842, 30.26568499,
        106.557428, 30.31586499, 106.441061, 30.30892801, 106.427851,
        30.25377199, 106.300558, 30.23854201, 106.242069, 30.176783, 106.171362,
        30.306207, 106.030909, 30.37425801, 105.846209, 30.392435, 105.820165,
        30.43750499, 105.714823, 30.32278099, 105.734408, 30.259672, 105.62505,
        30.275838, 105.661124, 30.20822999, 105.536424, 30.16484399, 105.747566,
        30.032857, 105.702881, 29.924773, 105.737495, 29.86275799, 105.618574,
        29.84661199, 105.538857, 29.694874, 105.485143, 29.719328, 105.477599,
        29.67528001, 105.389379, 29.676518, 105.378387, 29.62275401, 105.320966,
        29.60970401, 105.289761, 29.552629, 105.324552, 29.448636, 105.427279,
        29.418862, 105.420208, 29.31152501, 105.457877, 29.32866, 105.475582,
        29.27946399, 105.65797, 29.25149999, 105.715466, 29.29558, 105.703839,
        29.176979, 105.797586, 28.93593299, 105.914027, 28.90101001, 105.986567,
        28.97888299, 106.0483, 28.906726, 106.227454, 28.88934099, 106.374298,
        28.525625, 106.379094, 28.48005401, 106.330709, 28.48215501, 105.967787,
        28.761634, 105.889474, 28.670513, 105.904772, 28.60638101, 105.737721,
        28.61419801, 105.609351, 28.47219301, 105.64152, 28.30940199,
        105.731923, 28.27207, 105.785694, 28.336499, 105.889019, 28.23847101,
        105.879497, 28.123144, 105.945267, 28.14354401, 106.033887, 28.10696401,
        106.122091, 28.167049, 106.207077, 28.134265, 106.266696, 28.06653701,
        106.245455, 28.01292401, 106.328349, 27.957953, 106.300332, 27.908696,
        106.341818, 27.82016899, 106.22465, 27.760958, 106.063731, 27.777145,
        105.641062, 27.65915799, 105.483104, 27.77411001,
      ],
    ],
  },
  {
    id: "520000",
    name: "贵州",
    fullname: "贵州省",
    pinyin: ["gui", "zhou"],
    location: {
      lat: 26.600328,
      lng: 106.705251,
    },
    polygon: [
      [
        109.482633, 26.02962999, 109.339294, 25.83377999, 109.340084, 25.731741,
        109.207765, 25.73934399, 109.177225, 25.80516999, 109.148262,
        25.74184599, 109.117968, 25.80939101, 108.897642, 25.71616101,
        108.945875, 25.676468, 109.000967, 25.73632099, 109.079108, 25.72069799,
        109.031189, 25.63241601, 109.077328, 25.53621499, 109.025101,
        25.51249001, 108.867314, 25.55856299, 108.812718, 25.526359, 108.784039,
        25.62828099, 108.689004, 25.6232, 108.658204, 25.551841, 108.690465,
        25.518931, 108.632856, 25.518907, 108.587687, 25.421385, 108.622989,
        25.30632599, 108.500351, 25.447821, 108.427738, 25.43685099, 108.332162,
        25.53771099, 108.249053, 25.42712399, 108.158015, 25.440664, 108.193289,
        25.40551901, 108.142774, 25.390642, 108.114938, 25.21025001, 107.765974,
        25.117713, 107.762151, 25.22895201, 107.697377, 25.195333, 107.664994,
        25.31531099, 107.472163, 25.213622, 107.488405, 25.28474, 107.432685,
        25.28912899, 107.4241388, 25.39006321, 107.314863, 25.402637,
        107.327713, 25.49878501, 107.211187, 25.60919299, 107.064541, 25.55952,
        107.07011, 25.511737, 106.963737, 25.437331, 107.014307, 25.34480599,
        106.997628, 25.240643, 106.921837, 25.24836, 106.888311, 25.18219499,
        106.653668, 25.168306, 106.590655, 25.08778, 106.438513, 25.017484,
        106.153045, 24.96096501, 106.19732, 24.81345, 106.023202, 24.63237399,
        105.93883, 24.72655201, 105.805468, 24.70154199, 105.599063, 24.807056,
        105.500323, 24.80798801, 105.443162, 24.92186601, 105.267322,
        24.92954799, 105.209768, 24.99560801, 105.075159, 24.916711, 105.033355,
        24.787809, 104.875744, 24.73985899, 104.738226, 24.619846, 104.529438,
        24.73139699, 104.539792, 24.81386601, 104.713744, 24.997201, 104.683802,
        25.05483899, 104.616624, 25.058775, 104.686476, 25.076213, 104.747383,
        25.21410899, 104.815253, 25.156189, 104.827788, 25.23824399, 104.639944,
        25.295611, 104.645803, 25.35679801, 104.539442, 25.40454601, 104.556052,
        25.525169, 104.434721, 25.472214, 104.428394, 25.576048, 104.331464,
        25.59980101, 104.308519, 25.66053801, 104.327485, 25.75907001,
        104.373102, 25.731581, 104.430938, 25.82162099, 104.455832, 26.077926,
        104.521359, 26.09051399, 104.542025, 26.253125, 104.683135, 26.367466,
        104.665785, 26.43405699, 104.564524, 26.585851, 104.463983, 26.595145,
        104.421288, 26.712052, 104.348776, 26.61945499, 104.128623, 26.64394399,
        104.052568, 26.507184, 103.820807, 26.52862601, 103.763747, 26.58505699,
        103.771724, 26.726511, 103.702765, 26.813376, 103.779441, 26.874199,
        103.777431, 26.94576701, 103.705123, 27.04910799, 103.614389,
        27.00537301, 103.601439, 27.06218499, 103.659837, 27.06578199,
        103.627809, 27.116929, 103.709471, 27.13588, 103.802347, 27.26730199,
        103.865455, 27.28172701, 103.932446, 27.44394801, 104.015275,
        27.42924999, 104.020947, 27.37586601, 104.174143, 27.263022, 104.254283,
        27.294119, 104.356975, 27.46754899, 104.497064, 27.414489, 104.540461,
        27.326251, 104.61132, 27.30701499, 104.752277, 27.34527799, 104.779651,
        27.31771899, 104.808996, 27.35470501, 104.87125, 27.29089599,
        105.077619, 27.42009401, 105.165777, 27.40977599, 105.186847,
        27.36850501, 105.308413, 27.70514201, 105.483104, 27.77411001,
        105.641062, 27.65915799, 106.063731, 27.777145, 106.22465, 27.760958,
        106.341818, 27.82016899, 106.300332, 27.908696, 106.328349, 27.957953,
        106.245455, 28.01292401, 106.266696, 28.06653701, 106.207077, 28.134265,
        106.122091, 28.167049, 106.033887, 28.10696401, 105.945267, 28.14354401,
        105.879497, 28.123144, 105.889019, 28.23847101, 105.785694, 28.336499,
        105.731923, 28.27207, 105.64152, 28.30940199, 105.609351, 28.47219301,
        105.737721, 28.61419801, 105.904772, 28.60638101, 105.889474, 28.670513,
        105.967787, 28.761634, 106.330709, 28.48215501, 106.379094, 28.48005401,
        106.374298, 28.525625, 106.399105, 28.57116501, 106.502483, 28.53518899,
        106.474372, 28.59923401, 106.525146, 28.57536099, 106.469778,
        28.83429499, 106.651139, 28.651266, 106.564762, 28.48526299, 106.632756,
        28.50353501, 106.726951, 28.45469601, 106.7282, 28.54199999, 106.782341,
        28.56817199, 106.759046, 28.61109999, 106.812602, 28.58919799,
        106.867177, 28.62482699, 106.889558, 28.69494499, 106.828345, 28.737694,
        106.843562, 28.780017, 106.924448, 28.81004699, 106.986178, 28.773606,
        107.016101, 28.8825, 107.1479224, 28.89789145, 107.194167, 28.888262,
        107.248419, 28.76182199, 107.385449, 28.84924, 107.441041, 28.94377,
        107.364155, 29.00892701, 107.369753, 29.09344901, 107.427568,
        29.12734099, 107.401581, 29.18470099, 107.473089, 29.170995, 107.561973,
        29.22127599, 107.598423, 29.14777999, 107.700465, 29.14144, 107.751604,
        29.19962501, 107.810667, 29.13939099, 107.810438, 28.984306, 107.866976,
        28.95856799, 107.883455, 29.00762201, 108.068497, 29.08649701,
        108.19639, 29.07162299, 108.232045, 29.027397, 108.270614, 29.09090599,
        108.306576, 29.07917499, 108.389197, 28.79236001, 108.333589,
        28.67629101, 108.473769, 28.627268, 108.566481, 28.662565, 108.634336,
        28.636798, 108.57325, 28.52899, 108.611124, 28.420987, 108.579395,
        28.344202, 108.66887, 28.335386, 108.697018, 28.40382501, 108.640768,
        28.45761399, 108.72337, 28.49196099, 108.782632, 28.42759399,
        108.724999, 28.27516001, 108.762639, 28.191075, 108.821756, 28.245125,
        108.846046, 28.20073101, 108.922604, 28.21738701, 108.987446,
        28.16029799, 109.026206, 28.22014201, 109.086363, 28.184696, 109.153215,
        28.41664, 109.2743678, 28.49465684, 109.273081, 28.30944804,
        109.4003479, 28.27334911, 109.298573, 28.03627099, 109.338431,
        28.062888, 109.379162, 28.03309799, 109.3018052, 27.95847414,
        109.3329687, 27.78055681, 109.470465, 27.680258, 109.461938, 27.565617,
        109.312361, 27.48616201, 109.299897, 27.42394499, 109.2078133,
        27.45039272, 109.156216, 27.416518, 109.1414787, 27.4478142, 109.103703,
        27.33688601, 109.045922, 27.3347, 109.041426, 27.27659047, 108.9079364,
        27.20790457, 108.8847256, 27.10846347, 108.7908495, 27.08443541,
        108.8755521, 26.99943292, 109.0269183, 27.10283715, 109.1287426,
        27.12247051, 109.101164, 27.06896594, 109.1635956, 27.06581807,
        109.2480421, 27.15339886, 109.2805127, 27.12710455, 109.400089,
        27.15966199, 109.4726623, 27.13489937, 109.455774, 27.06580623,
        109.5213148, 27.07072867, 109.5550378, 26.94695291, 109.4359661,
        26.88828036, 109.439773, 26.85992792, 109.48191, 26.897075, 109.5138,
        26.87427399, 109.5222044, 26.74918702, 109.283744, 26.698584,
        109.3565101, 26.65766135, 109.4087482, 26.53713368, 109.274487,
        26.31541101, 109.3397993, 26.26408347, 109.4678719, 26.31264186,
        109.439614, 26.23884799, 109.514111, 26.128275, 109.4497111,
        26.10184076,
      ],
    ],
  },
  {
    id: "530000",
    name: "云南",
    fullname: "云南省",
    pinyin: ["yun", "nan"],
    location: {
      lat: 25.046432,
      lng: 100.709372,
    },
    polygon: [
      [
        105.5426533, 23.18427673, 105.32552, 23.390045, 105.2384361,
        23.26217495, 105.081873, 23.26042599, 104.948863, 23.152199, 104.893245,
        23.171427, 104.874315, 23.12344499, 104.809131, 23.11646301, 104.864632,
        22.950637, 104.735732, 22.82235099, 104.600416, 22.813583, 104.579472,
        22.84637699, 104.369563, 22.688879, 104.271696, 22.73902899, 104.261494,
        22.84199601, 104.117354, 22.80894001, 104.043594, 22.72469901,
        103.977783, 22.50335199, 103.643451, 22.79460099, 103.529631,
        22.59294999, 103.435525, 22.698755, 103.443348, 22.75089699, 103.323341,
        22.80790199, 103.282782, 22.67850201, 103.187645, 22.64192899,
        103.18276, 22.554959, 103.081659, 22.50667701, 103.07203, 22.44531601,
        102.930749, 22.48253099, 102.86171, 22.605779, 102.607359, 22.73071901,
        102.570421, 22.70030001, 102.495002, 22.77790499, 102.385171,
        22.68072001, 102.4205, 22.63415399, 102.302517, 22.527691, 102.269071,
        22.41443199, 102.179424, 22.43052501, 102.145305, 22.397784, 102.040065,
        22.45814, 101.907931, 22.43720401, 101.879347, 22.384243, 101.774356,
        22.50621199, 101.672509, 22.47490301, 101.669826, 22.36540199,
        101.547654, 22.23115601, 101.596485, 22.16108099, 101.607328,
        21.96431401, 101.701314, 21.93878701, 101.774485, 21.83154699,
        101.747303, 21.730017, 101.82851, 21.61712301, 101.745797, 21.576056,
        101.772465, 21.513205, 101.730299, 21.33694799, 101.835428, 21.20806499,
        101.756122, 21.14331699, 101.666588, 21.19650299, 101.600329, 21.173208,
        101.604627, 21.22989701, 101.532649, 21.25239101, 101.290232,
        21.17826601, 101.225591, 21.226157, 101.249678, 21.29688, 101.142882,
        21.40915601, 101.189775, 21.41843501, 101.225467, 21.501914, 101.208903,
        21.55730401, 101.139978, 21.56894001, 101.171285, 21.59971701,
        101.120131, 21.77462001, 100.885103, 21.68422099, 100.730606,
        21.51872099, 100.578883, 21.45188599, 100.482859, 21.459219, 100.428772,
        21.54009699, 100.247715, 21.46372301, 100.199483, 21.51277501,
        100.129818, 21.50531401, 100.108193, 21.59280201, 100.169136, 21.664035,
        100.118976, 21.703489, 100.030732, 21.67444099, 99.944208, 21.821323,
        99.99983899, 21.972287, 99.97239899, 22.05298199, 99.871461,
        22.06690099, 99.84302599, 22.025445, 99.753021, 22.06935599, 99.704152,
        22.037403, 99.64309701, 22.10282201, 99.47194499, 22.13092099,
        99.26380101, 22.10097999, 99.156237, 22.159871, 99.20093499,
        22.16913399, 99.17481299, 22.187042, 99.27776101, 22.34499099,
        99.251578, 22.39345401, 99.382479, 22.49474899, 99.31483501,
        22.73709699, 99.462928, 22.84476299, 99.44269001, 22.93303401,
        99.53145399, 22.897092, 99.56330798, 22.92561801, 99.510428, 23.074596,
        99.331838, 23.133501, 99.25540199, 23.07793701, 99.20070099,
        23.10918599, 99.10653701, 23.086605, 99.04844201, 23.114718, 99.057533,
        23.16446799, 98.88539499, 23.17933, 98.93691801, 23.31010599,
        98.87280199, 23.32984399, 98.92059, 23.353891, 98.91253199, 23.42633999,
        98.802757, 23.53054, 98.88564099, 23.613604, 98.79277001, 23.77786799,
        98.66886301, 23.80076199, 98.701465, 23.83432699, 98.67321702,
        23.96066601, 98.85286799, 24.05748801, 98.89994801, 24.10697,
        98.88060399, 24.149214, 98.59376801, 24.08053499, 98.54555899,
        24.12864999, 98.11046902, 24.092733, 97.90216899, 24.01423701,
        97.895774, 23.974571, 97.821178, 23.96900699, 97.64894199, 23.84102099,
        97.52808701, 23.92730299, 97.754419, 24.16342201, 97.72626399, 24.19974,
        97.76562301, 24.262183, 97.665237, 24.296308, 97.662307, 24.33908799,
        97.716271, 24.360856, 97.67714699, 24.44432599, 97.53000699,
        24.44187099, 97.57501501, 24.656062, 97.547272, 24.73886299,
        97.70212499, 24.84275599, 97.79801401, 24.84682301, 97.72944199,
        24.90934199, 97.72014999, 25.080724, 97.83737901, 25.26980601,
        97.94249299, 25.21547799, 98.01175399, 25.303392, 98.06976301,
        25.311839, 98.102949, 25.39210799, 98.14328301, 25.38370301, 98.170607,
        25.620323, 98.314558, 25.54323899, 98.40211201, 25.59401, 98.52900301,
        25.84075601, 98.640787, 25.79883699, 98.706225, 25.85634599,
        98.68125901, 25.93507401, 98.61435499, 25.96873501, 98.57530101,
        26.11788801, 98.65123701, 26.142366, 98.66514299, 26.087007,
        98.72005399, 26.12705401, 98.68111999, 26.308011, 98.73337099,
        26.35014101, 98.78111101, 26.60107, 98.73060899, 26.850269, 98.76622899,
        27.04944101, 98.71264801, 27.075833, 98.692031, 27.19869799, 98.740802,
        27.328376, 98.68707801, 27.42520799, 98.70661999, 27.55463699,
        98.664576, 27.58671301, 98.58764401, 27.566522, 98.558824, 27.64436801,
        98.52651501, 27.61979899, 98.43858902, 27.665472, 98.42921399,
        27.548816, 98.31745501, 27.519578, 98.30219401, 27.628055, 98.22066799,
        27.711633, 98.22348, 27.81145801, 98.172817, 27.83965801, 98.18812701,
        27.938992, 98.138197, 27.95929301, 98.13936201, 28.142405, 98.17648199,
        28.212132, 98.266135, 28.240982, 98.20428299, 28.34848199, 98.28256899,
        28.39446201, 98.377506, 28.24924301, 98.40009101, 28.104719,
        98.55948699, 28.18291201, 98.62652799, 28.165522, 98.71233801,
        28.22945001, 98.75360599, 28.336927, 98.673912, 28.47872201, 98.624453,
        28.49234799, 98.59421601, 28.66952001, 98.68369299, 28.740295,
        98.62327999, 28.96522399, 98.78574501, 28.99882599, 98.84133501,
        28.80344399, 98.97069, 28.828746, 98.91142599, 28.90988501, 98.92607002,
        28.978487, 99.01535399, 29.04246399, 98.96199601, 29.174104,
        99.11377401, 29.221362, 99.10432399, 28.814229, 99.18376699, 28.588909,
        99.16514899, 28.42521199, 99.30671399, 28.22760801, 99.40238101,
        28.15070101, 99.39297001, 28.31861399, 99.437373, 28.402044, 99.402927,
        28.54573799, 99.54077001, 28.62352199, 99.53168, 28.67998999, 99.614734,
        28.74024599, 99.62558301, 28.814674, 99.71780699, 28.846288, 99.733159,
        28.719507, 99.94867099, 28.578151, 99.99320601, 28.47246899, 100.074793,
        28.42528101, 100.055418, 28.370452, 100.176489, 28.3248, 100.16816,
        28.220717, 100.033298, 28.18461099, 100.021131, 28.14741401, 100.191931,
        27.94646099, 100.168749, 27.896924, 100.308787, 27.860886, 100.327695,
        27.720463, 100.432531, 27.86515199, 100.546392, 27.80859401, 100.680726,
        27.923506, 100.707047, 27.80136901, 100.78921, 27.684174, 100.848237,
        27.672006, 100.827906, 27.614609, 100.912034, 27.52170001, 100.901292,
        27.45338201, 100.936753, 27.469216, 101.020052, 27.34480899, 101.042393,
        27.221659, 101.170422, 27.19561601, 101.137425, 27.02234499, 101.264465,
        26.955517, 101.267756, 26.90300199, 101.365406, 26.88367699, 101.389689,
        26.723177, 101.457078, 26.729752, 101.460605, 26.78657699, 101.513317,
        26.768584, 101.399229, 26.54242601, 101.636749, 26.395035, 101.661079,
        26.30938499, 101.597455, 26.30344801, 101.594434, 26.26152799,
        101.808141, 26.15968199, 101.8354, 26.045866, 101.917587, 26.10799301,
        102.107791, 26.06824401, 102.247963, 26.215176, 102.551606, 26.36214001,
        102.629412, 26.33686, 102.600856, 26.250378, 102.674533, 26.20539199,
        102.880265, 26.36611801, 102.987477, 26.34849399, 102.989862,
        26.47993801, 103.056464, 26.54570901, 102.970139, 26.83243001,
        102.896638, 26.911408, 102.88254, 27.29651199, 102.948834, 27.41272699,
        102.985324, 27.36737999, 103.142902, 27.42192499, 103.221901, 27.566317,
        103.294891, 27.56572801, 103.279645, 27.632748, 103.489533, 27.797414,
        103.502969, 27.91179899, 103.564246, 27.97612499, 103.515563,
        27.96554399, 103.489522, 28.03144001, 103.432505, 28.04126501,
        103.452394, 28.12303301, 103.632403, 28.259998, 103.711577, 28.19711601,
        103.874826, 28.30619701, 103.781992, 28.524566, 103.851123, 28.66708301,
        103.950411, 28.601082, 104.09612, 28.603451, 104.245914, 28.663721,
        104.308107, 28.614909, 104.374093, 28.649329, 104.425286, 28.62670999,
        104.356813, 28.55568699, 104.24828, 28.528353, 104.254311, 28.403572,
        104.296603, 28.30812899, 104.370664, 28.33714, 104.449364, 28.26996599,
        104.401161, 28.197709, 104.457402, 28.169399, 104.45104, 28.11635301,
        104.298099, 28.045332, 104.50675, 27.878896, 104.576858, 27.84070901,
        104.872958, 27.90594199, 104.977121, 28.061083, 105.059145, 28.098113,
        105.177056, 28.066861, 105.186367, 27.995498, 105.271548, 27.99662301,
        105.284184, 27.934445, 105.232684, 27.89292399, 105.244204, 27.82297399,
        105.315972, 27.818239, 105.273854, 27.795232, 105.308413, 27.70514201,
        105.186847, 27.36850501, 105.165777, 27.40977599, 105.077619,
        27.42009401, 104.87125, 27.29089599, 104.808996, 27.35470501,
        104.779651, 27.31771899, 104.752277, 27.34527799, 104.61132,
        27.30701499, 104.540461, 27.326251, 104.497064, 27.414489, 104.356975,
        27.46754899, 104.254283, 27.294119, 104.174143, 27.263022, 104.020947,
        27.37586601, 104.015275, 27.42924999, 103.932446, 27.44394801,
        103.865455, 27.28172701, 103.802347, 27.26730199, 103.709471, 27.13588,
        103.627809, 27.116929, 103.659837, 27.06578199, 103.601439, 27.06218499,
        103.614389, 27.00537301, 103.705123, 27.04910799, 103.777431,
        26.94576701, 103.779441, 26.874199, 103.702765, 26.813376, 103.771724,
        26.726511, 103.763747, 26.58505699, 103.820807, 26.52862601, 104.052568,
        26.507184, 104.128623, 26.64394399, 104.348776, 26.61945499, 104.421288,
        26.712052, 104.463983, 26.595145, 104.564524, 26.585851, 104.665785,
        26.43405699, 104.683135, 26.367466, 104.542025, 26.253125, 104.521359,
        26.09051399, 104.455832, 26.077926, 104.430938, 25.82162099, 104.373102,
        25.731581, 104.327485, 25.75907001, 104.308519, 25.66053801, 104.331464,
        25.59980101, 104.428394, 25.576048, 104.434721, 25.472214, 104.556052,
        25.525169, 104.539442, 25.40454601, 104.645803, 25.35679801, 104.639944,
        25.295611, 104.827788, 25.23824399, 104.815253, 25.156189, 104.747383,
        25.21410899, 104.686476, 25.076213, 104.616624, 25.058775, 104.683802,
        25.05483899, 104.713744, 24.997201, 104.539792, 24.81386601, 104.529438,
        24.73139699, 104.45086, 24.63828099, 104.504989, 24.62837001,
        104.578008, 24.42017401, 104.69512, 24.32277799, 104.703756,
        24.42136101, 104.755614, 24.45752, 104.935181, 24.408363, 105.052835,
        24.44066801, 105.196231, 24.33705701, 105.159568, 24.28008599,
        105.243065, 24.20672, 105.181884, 24.13286999, 105.258389, 24.06184699,
        105.31938, 24.116781, 105.493031, 24.01723199, 105.528837, 24.12824,
        105.598003, 24.13798099, 105.64942, 24.03308501, 105.772634,
        24.07328699, 105.799097, 24.02357, 105.898774, 24.043413, 105.919457,
        24.122193, 106.007667, 24.12487843, 106.196206, 23.87523301, 106.136878,
        23.79542501, 106.141459, 23.569672, 106.067968, 23.49054, 105.994313,
        23.44789401, 105.986223, 23.48959599, 105.852408, 23.52693199,
        105.69987, 23.40143301, 105.63141, 23.40209999, 105.700604, 23.32840299,
        105.593254, 23.31254901, 105.527653, 23.24448201,
      ],
    ],
  },
  {
    id: "540000",
    name: "西藏",
    fullname: "西藏自治区",
    pinyin: ["xi", "zang"],
    location: {
      lat: 30.648694,
      lng: 88.117449,
    },
    polygon: [
      [
        98.13936201, 28.142405, 98.08540901, 28.19920801, 98.01273099,
        28.20250299, 98.01354199, 28.26609199, 97.91323499, 28.36051001,
        97.79606, 28.32767201, 97.68593099, 28.51989299, 97.56954199, 28.541557,
        97.508028, 28.49497599, 97.51241899, 28.31589601, 97.34960399,
        28.23481299, 97.306239, 28.07129699, 97.41295201, 28.01356601,
        97.38653199, 27.88290501, 97.260682, 27.89531599, 97.09353099,
        27.73781499, 96.973657, 27.860586, 96.80997101, 27.89043401,
        96.57068699, 28.06025199, 96.499756, 28.06733301, 96.451743, 28.152398,
        96.39840201, 28.11870001, 96.29748399, 28.14072901, 96.275458,
        28.22813799, 96.01441799, 28.19000901, 95.87432399, 28.29759299,
        95.740206, 28.27530399, 95.39689, 28.142368, 95.286519, 27.940024,
        94.885845, 27.743097, 94.52505301, 27.59611101, 94.27742901,
        27.58120899, 93.86221301, 27.18720501, 93.81709301, 27.025413,
        93.58358946, 26.9454813, 93.07596427, 26.88938899, 92.90231215,
        26.92526406, 92.79101253, 26.89888306, 92.64301082, 26.95714005,
        92.10914901, 26.854816, 92.12474699, 26.96004699, 92.02673401,
        27.108421, 92.12594901, 27.27321501, 92.0151285, 27.47797736,
        91.74682069, 27.46221982, 91.59334029, 27.53838671, 91.56358067,
        27.63828172, 91.64391955, 27.76877954, 91.61310971, 27.82168911,
        91.54398901, 27.825561, 91.61962646, 27.88325453, 91.336378,
        28.06116999, 91.25174601, 27.97039201, 91.16099001, 27.96808201,
        91.153962, 27.89145301, 91.077914, 27.84651501, 90.964889, 27.90051399,
        90.96177999, 27.95349899, 90.84693399, 27.96887, 90.79850301,
        28.04357899, 90.701861, 28.07607999, 90.59189701, 28.02129101,
        90.463157, 28.04495701, 90.37894, 28.062105, 90.29668601, 28.154272,
        90.124862, 28.190711, 90.102975, 28.14177101, 90.03341299, 28.13713199,
        89.794681, 28.244624, 89.77167799, 28.192107, 89.60716499, 28.16253599,
        89.508267, 28.08400499, 89.375633, 27.876049, 89.23908799, 27.79683899,
        89.13173299, 27.63363099, 89.16328201, 27.57451099, 89.102681,
        27.52687501, 89.18306101, 27.37385601, 89.068042, 27.24038601,
        88.984417, 27.20905599, 88.78333401, 27.46327701, 88.77072601,
        27.567857, 88.851883, 27.671246, 88.88845101, 27.846653, 88.84286001,
        28.00628601, 88.64514399, 28.111344, 88.565053, 28.083085, 88.55403999,
        28.02784, 88.48727401, 28.037557, 88.40575199, 27.976814, 88.14393901,
        27.95513199, 88.111653, 27.86486301, 87.833187, 27.94795301, 87.72775,
        27.80295499, 87.604573, 27.81162201, 87.56882699, 27.86365701, 87.22813,
        27.81317199, 87.12124801, 27.837196, 87.034909, 27.946381, 86.75685599,
        28.03300301, 86.76854301, 28.069276, 86.71025399, 28.10080599,
        86.62183999, 28.06590799, 86.569041, 28.10315, 86.51449399, 27.955012,
        86.41516101, 27.904586, 86.335978, 27.96453101, 86.231111, 27.97473799,
        86.18494099, 28.16808699, 86.12879699, 28.08674699, 86.08269898,
        28.08630301, 86.12647499, 27.923984, 86.00172601, 27.90743399,
        85.94932999, 27.937461, 85.97837999, 27.992623, 85.85422299,
        28.17243601, 85.753623, 28.22779001, 85.71671699, 28.38005301,
        85.60533099, 28.251635, 85.60008299, 28.298819, 85.50536501, 28.329983,
        85.417599, 28.32346499, 85.379593, 28.27436401, 85.12513901,
        28.33258699, 85.108517, 28.461217, 85.18983499, 28.54481201, 85.180364,
        28.64161101, 85.05679901, 28.674474, 84.981496, 28.58637399,
        84.85717101, 28.56776901, 84.69824301, 28.633469, 84.64119901,
        28.723998, 84.483127, 28.73508, 84.408376, 28.853964, 84.22720899,
        28.89702601, 84.24872302, 29.030544, 84.190973, 29.04619401, 84.167534,
        29.169854, 84.20282201, 29.24013299, 84.13008499, 29.24007399,
        84.105157, 29.292871, 83.91734399, 29.324599, 83.71953001, 29.240703,
        83.64816001, 29.16247, 83.58483001, 29.17809899, 83.45405099,
        29.29761999, 83.424283, 29.41133799, 83.27594699, 29.50599401,
        83.26654099, 29.571208, 83.08217101, 29.6058, 83.015893, 29.66738999,
        82.95813701, 29.66058799, 82.946763, 29.70891401, 82.830681, 29.687573,
        82.757236, 29.762105, 82.69447401, 29.76210299, 82.731154, 29.82579599,
        82.62391101, 29.834557, 82.643606, 29.868696, 82.56118001, 29.95535199,
        82.17800199, 30.068115, 82.20951399, 30.150987, 82.11051999,
        30.23019599, 82.10481299, 30.346433, 81.99748399, 30.32078, 81.63017999,
        30.44659601, 81.61314599, 30.412731, 81.56648299, 30.428915, 81.555753,
        30.36955301, 81.41844601, 30.420294, 81.39378999, 30.19938, 81.26326101,
        30.150297, 81.293211, 30.094928, 81.25627999, 30.01199799, 81.14072499,
        30.01312999, 81.01642201, 30.25942899, 80.81714201, 30.321357,
        80.63312601, 30.458474, 80.54544598, 30.450094, 80.308713, 30.56883601,
        80.10272599, 30.56886099, 79.96595301, 30.68971601, 79.890625,
        30.85510999, 79.826866, 30.85291299, 79.67140599, 30.98053401,
        79.60130999, 30.922698, 79.50480402, 31.02839399, 79.427273, 31.018103,
        79.40474001, 31.07161699, 79.31647799, 31.01787199, 79.32939001,
        30.962656, 79.22753099, 30.949207, 79.19098401, 31.01191599,
        79.09722999, 30.99212901, 79.01008001, 31.04417099, 78.994692,
        31.170793, 78.868393, 31.312644, 78.79509101, 31.30111399, 78.75537,
        31.355748, 78.79234801, 31.440235, 78.725792, 31.507408, 78.84570599,
        31.60992601, 78.65381399, 31.82180801, 78.77099001, 31.935146,
        78.59591501, 32.023098, 78.60317301, 32.06215001, 78.53874699,
        32.055304, 78.51031801, 32.147339, 78.45808599, 32.13327401,
        78.43070499, 32.21215901, 78.51092301, 32.308073, 78.395545,
        32.53056499, 78.74167399, 32.70361001, 78.782403, 32.48062599,
        78.970451, 32.33205, 79.006062, 32.375222, 79.1028, 32.369885,
        79.13580201, 32.4721, 79.251878, 32.51661099, 79.30951801, 32.598778,
        79.30118699, 32.728984, 79.224662, 32.784734, 79.25575, 32.94191501,
        79.16283399, 33.01254599, 79.157422, 33.178989, 79.022436, 33.323546,
        78.84178699, 33.42529101, 78.74198399, 33.55337801, 78.75809101,
        33.622111, 78.68499499, 33.654418, 78.78087401, 33.73559201,
        78.74217401, 33.99282899, 78.65626599, 34.03030499, 78.66155599,
        34.086822, 78.87145499, 34.163884, 78.91661, 34.146204, 78.97647901,
        34.31309401, 79.03951099, 34.334503, 79.01046599, 34.40150299,
        79.05310701, 34.42751199, 79.372806, 34.42806301, 79.545295, 34.476326,
        79.64617601, 34.44666501, 79.81986201, 34.49260099, 79.92789299,
        34.85323699, 80.03665, 34.90318701, 80.03102699, 35.03884799,
        80.20955001, 35.12275299, 80.25783301, 35.2034, 80.36000499, 35.201449,
        80.26798199, 35.295775, 80.30446599, 35.37973, 80.43313288, 35.44951608,
        80.51322899, 35.392121, 80.65648201, 35.393949, 80.689256, 35.33897001,
        80.96378701, 35.31104301, 81.02627401, 35.311663, 81.03158801,
        35.380648, 81.099249, 35.40755101, 81.21571999, 35.320274, 81.36392401,
        35.35459199, 81.49415291, 35.29225021, 81.51326501, 35.23489799,
        81.675006, 35.23351, 82.01341899, 35.30995101, 82.054685, 35.355191,
        82.03306701, 35.45109801, 82.319698, 35.553432, 82.335987, 35.65136999,
        82.457123, 35.71854699, 82.73135901, 35.637859, 82.797044, 35.688618,
        82.96093599, 35.67153099, 82.99905, 35.48450199, 83.126799, 35.39851601,
        83.24232, 35.41985601, 83.62227099, 35.33542699, 83.87516901,
        35.36339101, 84.00591299, 35.42239301, 84.17472099, 35.35803899,
        84.45312299, 35.473333, 84.44894801, 35.550349, 84.76252099,
        35.62454299, 85.03111, 35.74768501, 85.283267, 35.78724401, 85.37346699,
        35.70062001, 85.59722701, 35.64476399, 85.69288601, 35.75182199,
        85.94908399, 35.77887, 86.05987601, 35.845869, 86.20001399, 36.03304899,
        86.19055501, 36.132589, 86.45514699, 36.22120199, 86.69997499,
        36.24478301, 86.75853601, 36.294647, 86.862711, 36.29993401,
        86.89181399, 36.262023, 86.99615399, 36.30868601, 87.149411, 36.297425,
        87.19346199, 36.34939399, 87.423998, 36.42588, 87.47043801, 36.35428801,
        87.562014, 36.34185201, 87.919442, 36.39365799, 87.98294699,
        36.43782299, 88.47063399, 36.48223699, 88.498679, 36.44623499,
        88.588715, 36.454345, 88.767621, 36.29212099, 88.925963, 36.364407,
        89.12780199, 36.249673, 89.23671301, 36.29483099, 89.29506199,
        36.22986001, 89.71151499, 36.093172, 89.60521001, 36.038264,
        89.40442498, 36.019408, 89.425574, 35.919574, 89.550572, 35.85713001,
        89.801882, 35.84806199, 89.72532, 35.649819, 89.76544499, 35.58943801,
        89.7, 35.54514999, 89.765701, 35.48322499, 89.68643601, 35.41471701,
        89.50528601, 35.371504, 89.533209, 35.27857099, 89.449818, 35.22677401,
        89.59198599, 35.10849401, 89.57973098, 34.894095, 89.78752201,
        34.921748, 89.869203, 34.81426, 89.723524, 34.70404799, 89.79965099,
        34.62839701, 89.799587, 34.39328901, 89.87197399, 34.335854,
        89.81698699, 34.16946601, 89.693247, 34.126062, 89.63602099,
        34.04213599, 89.794959, 33.865504, 89.94227001, 33.801701, 89.90607901,
        33.74263501, 89.98380299, 33.72581601, 89.98811499, 33.583634,
        90.081088, 33.530576, 90.09188199, 33.46980101, 90.24358201,
        33.42661199, 90.40532599, 33.26061699, 90.48755599, 33.26638799,
        90.701014, 33.137299, 90.88285401, 33.12044399, 90.90228799,
        33.08311601, 91.00827499, 33.12168301, 91.14713401, 33.07224,
        91.24066201, 33.14301599, 91.43593099, 33.06615399, 91.53335899,
        33.10069299, 91.58331699, 33.03767599, 91.838381, 32.949368,
        91.95778799, 32.81963001, 92.14146101, 32.88506799, 92.203637,
        32.86734501, 92.198663, 32.75480699, 92.255321, 32.72076101, 92.355861,
        32.76462399, 92.64951501, 32.721518, 92.687953, 32.766041, 92.877,
        32.696978, 93.00937398, 32.74175001, 93.06561099, 32.62714699,
        93.242381, 32.66161299, 93.38258399, 32.52590899, 93.462096, 32.556617,
        93.52207699, 32.47319301, 93.72202801, 32.57812, 93.82123501,
        32.54878899, 93.858709, 32.46469101, 93.95993401, 32.48495999,
        94.13760799, 32.433877, 94.196815, 32.516155, 94.36726501, 32.521765,
        94.39324199, 32.603956, 94.454436, 32.56772501, 94.614582, 32.67359101,
        94.918705, 32.41123599, 95.074975, 32.376045, 95.21852075, 32.39683872,
        95.261711, 32.348087, 95.09707699, 32.322301, 95.079622, 32.27995699,
        95.132389, 32.264004, 95.24102501, 32.320509, 95.31285901, 32.14887401,
        95.38905401, 32.18608499, 95.43939401, 32.15892, 95.45470001,
        32.06251699, 95.41529599, 32.04595401, 95.45443799, 32.00784999,
        95.37787701, 31.991979, 95.360935, 31.938088, 95.51291801, 31.74914299,
        95.68280201, 31.77636999, 95.779619, 31.74909301, 95.82497801,
        31.68203899, 95.89931, 31.81713401, 95.98291199, 31.81664199,
        96.04143999, 31.734367, 96.147023, 31.69006799, 96.16037899,
        31.60107599, 96.21192099, 31.60131699, 96.24897401, 31.661417,
        96.17557101, 31.824242, 96.188806, 31.90466201, 96.25112799,
        31.92960099, 96.38680901, 31.92283, 96.43584401, 31.79648701, 96.568281,
        31.711974, 96.67632599, 31.72781901, 96.75378999, 31.67393599,
        96.821915, 31.700298, 96.76051801, 31.85684999, 96.81031799,
        31.89670501, 96.72390799, 32.02274601, 96.86655799, 31.964356,
        96.89333399, 32.013505, 96.95533801, 31.99402, 96.95278801, 32.06148201,
        97.169156, 32.02404201, 97.23413199, 32.04698899, 97.219776, 32.109112,
        97.30789901, 32.073879, 97.264183, 32.18259001, 97.28994899,
        32.28061599, 97.371004, 32.27320501, 97.424652, 32.32281099,
        97.39136999, 32.42273899, 97.34153699, 32.439789, 97.389034, 32.500823,
        97.328417, 32.517796, 97.34138301, 32.55705701, 97.44796199, 32.587241,
        97.52075001, 32.525872, 97.73106799, 32.527308, 98.02353901, 32.452506,
        98.21886401, 32.34235501, 98.219147, 32.23421101, 98.434794,
        32.00782699, 98.41571101, 31.830295, 98.61910599, 31.591585,
        98.88701101, 31.374601, 98.77595001, 31.248673, 98.637613, 31.336169,
        98.60155101, 31.189594, 98.79844201, 31.008358, 98.78032699,
        30.89334899, 98.95781001, 30.76492, 98.90475599, 30.69123299,
        98.98630701, 30.157535, 99.069016, 29.92727999, 98.99186301, 29.6609,
        99.05203801, 29.56398799, 99.06653101, 29.30053601, 99.11377401,
        29.221362, 98.96199601, 29.174104, 99.01535399, 29.04246399,
        98.92607002, 28.978487, 98.91142599, 28.90988501, 98.97069, 28.828746,
        98.84133501, 28.80344399, 98.78574501, 28.99882599, 98.62327999,
        28.96522399, 98.68369299, 28.740295, 98.59421601, 28.66952001,
        98.624453, 28.49234799, 98.673912, 28.47872201, 98.75360599, 28.336927,
        98.71233801, 28.22945001, 98.62652799, 28.165522, 98.55948699,
        28.18291201, 98.40009101, 28.104719, 98.377506, 28.24924301,
        98.28256899, 28.39446201, 98.20428299, 28.34848199, 98.266135,
        28.240982, 98.17648199, 28.212132,
      ],
    ],
  },
  {
    id: "610000",
    name: "陕西",
    fullname: "陕西省",
    pinyin: ["shan", "xi"],
    location: {
      lat: 34,
      lng: 108.953939,
    },
    polygon: [
      [
        110.9951358, 33.25214333, 110.8289214, 33.20235978, 110.822334,
        33.15376091, 110.7384216, 33.14226104, 110.7110177, 33.09509313,
        110.655234, 33.16151232, 110.5946019, 33.15529736, 110.5878243,
        33.23434882, 110.5368285, 33.25684304, 110.4721658, 33.17322019,
        110.3394968, 33.15879555, 109.6269442, 33.2713101, 109.611106,
        33.2314873, 109.5033277, 33.22781609, 109.444334, 33.14645011,
        109.8020253, 33.06731757, 109.7896565, 32.88319911, 109.8665438,
        32.91330635, 110.1051334, 32.83290631, 110.2067017, 32.63596767,
        110.1537765, 32.58987487, 110.0919291, 32.61977105, 110.0292383,
        32.54257583, 109.9265692, 32.59072559, 109.6193419, 32.58988052,
        109.6387231, 32.53490978, 109.5519096, 32.4804075, 109.4916125,
        32.28845064, 109.6110366, 32.20173514, 109.5865821, 32.02850269,
        109.6328377, 31.9408675, 109.5838508, 31.89421818, 109.6410881,
        31.81046373, 109.5843741, 31.727101, 109.281591, 31.71688801,
        109.275694, 31.799616, 109.194061, 31.81848501, 109.039046, 31.96086,
        108.890695, 31.98989299, 108.5097471, 32.20120777, 108.4718007,
        32.27060568, 108.312045, 32.232194, 108.241933, 32.27488801, 108.185493,
        32.22115099, 108.031321, 32.22073901, 107.978235, 32.14626901,
        107.832161, 32.225819, 107.679451, 32.39829701, 107.52525, 32.38243099,
        107.457005, 32.41776499, 107.429056, 32.532905, 107.302074, 32.48232199,
        107.263932, 32.403273, 107.220961, 32.42174101, 107.08534, 32.529505,
        107.099063, 32.649475, 107.012571, 32.72155099, 106.785838, 32.696781,
        106.783371, 32.735981, 106.733257, 32.73937899, 106.403336, 32.617188,
        106.3506008, 32.67213633, 106.111786, 32.721858, 106.042155,
        32.86508799, 106.011564, 32.82971401, 105.8257, 32.82538899, 105.822516,
        32.770143, 105.596357, 32.699265, 105.499054, 32.911883, 105.638276,
        32.879291, 105.861718, 32.938938, 105.917441, 32.993824, 105.937996,
        33.12008701, 105.897228, 33.14660699, 105.930189, 33.19930199,
        105.962567, 33.14606, 105.965279, 33.204539, 105.796076, 33.26000501,
        105.708982, 33.38071999, 105.827211, 33.38007399, 105.841982, 33.490087,
        105.956343, 33.613245, 106.086648, 33.61712899, 106.187188, 33.546509,
        106.4439949, 33.61820008, 106.457085, 33.53288501, 106.514714,
        33.513198, 106.580579, 33.57592999, 106.539737, 33.691188, 106.48079,
        33.712417, 106.489643, 33.85446701, 106.410491, 33.908022, 106.4760032,
        33.97376901, 106.501852, 34.10490199, 106.584848, 34.149438, 106.551264,
        34.24264199, 106.496532, 34.25510999, 106.526425, 34.291953, 106.673117,
        34.247438, 106.717369, 34.36925599, 106.486073, 34.524705, 106.33445,
        34.51788, 106.313336, 34.57982999, 106.422168, 34.65529701, 106.470996,
        34.633945, 106.447321, 34.69493, 106.5756466, 34.76978796, 106.483195,
        34.97996899, 106.543521, 35.08504899, 106.988963, 35.06974101,
        107.214121, 34.87720601, 107.295477, 34.93636601, 107.5324, 34.91129299,
        107.57217, 34.97138499, 107.639878, 34.91878999, 107.864083, 34.99556,
        107.651965, 35.239726, 107.718671, 35.24471701, 107.718698, 35.303264,
        107.969893, 35.24154201, 108.193748, 35.30663999, 108.240349,
        35.25599099, 108.349586, 35.30221, 108.485258, 35.274383, 108.615309,
        35.330023, 108.625704, 35.53708901, 108.539744, 35.605613, 108.498336,
        35.87611199, 108.580733, 35.947816, 108.652232, 35.94825001, 108.68862,
        36.021104, 108.712336, 36.13873101, 108.629121, 36.42077301, 108.510642,
        36.47446199, 108.496812, 36.42272699, 108.401812, 36.446488, 108.359637,
        36.54727301, 108.21662, 36.57135199, 108.211384, 36.63515701,
        108.163606, 36.563739, 108.082407, 36.61537199, 108.046192, 36.59623399,
        108.012412, 36.68140599, 107.938872, 36.65563399, 107.90668, 36.752369,
        107.830653, 36.78608301, 107.542155, 36.82768199, 107.477944,
        36.90843499, 107.308544, 36.91283301, 107.268692, 37.09945699,
        107.338359, 37.159152, 107.257494, 37.33149301, 107.284305, 37.48192,
        107.3721309, 37.57991901, 107.311298, 37.60979, 107.419889, 37.650222,
        107.386867, 37.69111399, 107.465424, 37.69123199, 107.499175,
        37.76566999, 107.620697, 37.775957, 107.650221, 37.864609, 107.692705,
        37.88812901, 107.981971, 37.78724899, 108.022373, 37.65225499,
        108.133918, 37.62201001, 108.246498, 37.66576399, 108.332977,
        37.63304399, 108.532666, 37.690617, 108.619254, 37.652022, 108.777708,
        37.683536, 108.797628, 38.04789501, 108.871138, 38.02727901, 108.938372,
        37.92087999, 109.069533, 38.02303201, 109.063578, 38.10018501,
        108.938506, 38.20743801, 109.052058, 38.432058, 109.276717, 38.62299899,
        109.362531, 38.62241699, 109.336137, 38.69895599, 109.511178,
        38.83374599, 109.549751, 38.80570601, 109.625749, 38.847144, 109.672035,
        38.99705401, 109.851503, 39.122806, 109.922102, 39.107038, 109.893339,
        39.14694299, 109.961235, 39.191178, 109.884038, 39.26295001, 110.010524,
        39.208498, 110.217957, 39.27974201, 110.130457, 39.398255, 110.145527,
        39.45720901, 110.275546, 39.4248, 110.385231, 39.31051601, 110.430059,
        39.3793309, 110.521731, 39.38318399, 110.60414, 39.277539, 110.688357,
        39.267494, 110.868272, 39.493281, 111.136796, 39.58701101, 111.144685,
        39.523114, 111.053843, 39.42992699, 111.125579, 39.36645, 111.2479258,
        39.30068792, 111.138341, 39.06429399, 110.981042, 38.98014901,
        111.010108, 38.84159799, 110.880796, 38.62693901, 110.920655,
        38.57800699, 110.877045, 38.45688, 110.786495, 38.44986201, 110.746761,
        38.36612601, 110.584129, 38.30372, 110.567256, 38.217556, 110.507865,
        38.184914, 110.518712, 37.96045701, 110.75868, 37.752641, 110.70354,
        37.71174401, 110.796149, 37.66304601, 110.795678, 37.562192, 110.748192,
        37.452461, 110.64245, 37.429991, 110.690037, 37.28706001, 110.418016,
        37.03513201, 110.447904, 37.00933699, 110.376818, 37.015128, 110.42575,
        36.95723401, 110.382865, 36.770229, 110.452453, 36.73267501, 110.435758,
        36.681487, 110.390629, 36.68522599, 110.50218, 36.53096199, 110.450067,
        36.13481101, 110.609331, 35.63215499, 110.389536, 35.26229001,
        110.233708, 34.83739301, 110.2601051, 34.64105273, 110.37918, 34.600531,
        110.367487, 34.495128, 110.502691, 34.34518899, 110.42854, 34.287964,
        110.438041, 34.245579, 110.641965, 34.16087499, 110.582005, 34.042816,
        110.676916, 33.94925099, 110.587859, 33.88581, 110.782195, 33.79606601,
        110.837986, 33.666884, 111.0037, 33.57867099, 111.029973, 33.33940801,
      ],
    ],
  },
  {
    id: "620000",
    name: "甘肃",
    fullname: "甘肃省",
    pinyin: ["gan", "su"],
    location: {
      lat: 35.060634,
      lng: 103.826777,
    },
    polygon: [
      [
        105.499054, 32.911883, 105.425533, 32.93112299, 105.381001, 32.87930699,
        105.455224, 32.73724699, 105.110848, 32.59411101, 105.030251, 32.650453,
        104.881774, 32.601178, 104.82915, 32.66261001, 104.644788, 32.66119601,
        104.571726, 32.72865299, 104.300247, 32.83090499, 104.286771,
        32.94091401, 104.366559, 32.943654, 104.426915, 33.01445999, 104.335642,
        33.04189199, 104.377865, 33.10914099, 104.302819, 33.30257401,
        104.412581, 33.281768, 104.393529, 33.310927, 104.437445, 33.32254699,
        104.295709, 33.33288801, 104.220772, 33.40461399, 104.168589, 33.611739,
        104.052296, 33.685282, 103.768654, 33.65871301, 103.625892, 33.72735099,
        103.536715, 33.670941, 103.5065, 33.81014301, 103.349618, 33.743506,
        103.272278, 33.80956201, 103.157748, 33.80914301, 103.181184, 33.900721,
        103.123059, 33.941601, 103.159237, 34.00652201, 103.118918, 34.03645299,
        103.179117, 34.079947, 103.109319, 34.177038, 102.977038, 34.19834199,
        102.91182, 34.31294699, 102.607931, 34.160391, 102.657725, 34.08139601,
        102.437602, 34.08719301, 102.39138, 33.970834, 102.139636, 33.97126001,
        102.253329, 33.86151101, 102.235533, 33.792272, 102.32314, 33.75753501,
        102.285674, 33.71762, 102.34127, 33.727555, 102.339449, 33.61190201,
        102.478124, 33.54220499, 102.445831, 33.529427, 102.482578, 33.457439,
        102.368686, 33.39338399, 102.263408, 33.417117, 102.186988, 33.332739,
        102.209317, 33.22518401, 102.107696, 33.298491, 102.08339, 33.194631,
        101.935639, 33.186869, 101.867311, 33.10381599, 101.825518, 33.119461,
        101.841331, 33.18910501, 101.767605, 33.26339999, 101.877336,
        33.31470401, 101.946323, 33.442776, 101.844346, 33.60245199, 101.769177,
        33.538671, 101.735832, 33.496333, 101.631446, 33.492264, 101.612281,
        33.616031, 101.542149, 33.69521101, 101.391136, 33.64524401, 101.237019,
        33.68563001, 101.1709, 33.656191, 101.155225, 33.84249001, 100.995282,
        33.890862, 100.764429, 34.178915, 100.805286, 34.28696999, 100.950913,
        34.383777, 101.054599, 34.32297001, 101.226732, 34.330046, 101.323812,
        34.245541, 101.622721, 34.17825799, 101.736186, 34.080097, 101.848994,
        34.152903, 101.956848, 34.11015899, 102.067937, 34.29350101, 102.148299,
        34.271305, 102.187329, 34.353395, 102.258905, 34.354231, 102.155825,
        34.50757499, 101.934532, 34.587489, 101.917278, 34.87511299, 102.068434,
        34.88769399, 102.095095, 34.98676001, 102.281679, 35.05770501,
        102.343494, 35.161727, 102.403442, 35.17819501, 102.369997, 35.26396,
        102.279966, 35.304191, 102.317878, 35.339821, 102.283132, 35.39299201,
        102.314809, 35.43437801, 102.406974, 35.40842001, 102.447592,
        35.43732901, 102.505279, 35.58762199, 102.742366, 35.495296, 102.777412,
        35.52088801, 102.728782, 35.524582, 102.808724, 35.56044, 102.694829,
        35.79643301, 102.78406, 35.86232099, 102.946874, 35.83127601,
        102.975641, 36.036427, 102.881953, 36.083438, 102.987442, 36.193795,
        103.070971, 36.206936, 102.839836, 36.33878, 102.764517, 36.575484,
        102.606703, 36.68189001, 102.613456, 36.738674, 102.721011, 36.769706,
        102.450326, 36.96849999, 102.506096, 37.01923499, 102.497966, 37.090407,
        102.642069, 37.099665, 102.603523, 37.17207201, 102.104078, 37.48245399,
        102.130793, 37.547355, 101.999066, 37.72506701, 101.8161, 37.654354,
        101.659183, 37.733326, 101.574633, 37.83534199, 101.441512, 37.86792,
        101.357216, 37.79136101, 101.202749, 37.84753801, 101.103024, 37.946537,
        100.894761, 38.013545, 100.929801, 38.169281, 100.844759, 38.148698,
        100.714882, 38.253623, 100.437181, 38.26803901, 100.424161, 38.30714301,
        100.318867, 38.327724, 100.300789, 38.389145, 100.251417, 38.37138999,
        100.236484, 38.44423, 100.060077, 38.519968, 100.001224, 38.46698899,
        100.168856, 38.321572, 100.182863, 38.22203801, 100.127912, 38.230657,
        99.67106601, 38.44094201, 99.50809201, 38.609816, 99.44795301,
        38.60545401, 99.362163, 38.71821699, 99.06875499, 38.897082, 99.107334,
        38.951887, 98.93612799, 38.99444901, 98.81320301, 39.087664,
        98.74382701, 39.08688199, 98.58264599, 38.92983301, 98.29001701,
        39.03707701, 98.249326, 38.88701699, 98.09332599, 38.78632099,
        97.34738398, 39.16737901, 96.962998, 39.19836601, 97.011869,
        39.14118401, 96.940822, 38.90787601, 96.99306899, 38.884737,
        97.05595598, 38.594502, 96.960752, 38.557841, 96.79440293, 38.60801045,
        96.79506998, 38.510292, 96.66636099, 38.489722, 96.701806, 38.43392501,
        96.623773, 38.345162, 96.665199, 38.23018601, 96.378335, 38.27731501,
        96.26637, 38.14539901, 96.05283001, 38.17644201, 95.703229, 38.40058999,
        95.45492199, 38.29168301, 95.26837499, 38.297418, 95.121649,
        38.41739301, 94.969436, 38.43045601, 94.55196341, 38.35025321,
        94.37012999, 38.762798, 93.88273701, 38.720652, 93.75602001,
        38.80637401, 93.88418599, 38.82620301, 93.88469799, 38.86765599,
        93.731512, 38.92477701, 93.18968999, 38.91961101, 93.19815799,
        39.04620599, 93.12000202, 39.17888099, 92.49398499, 39.102154,
        92.41075801, 39.038578, 92.35376501, 39.06947801, 92.33914599,
        39.236621, 92.63984501, 39.514329, 92.796526, 40.15385101, 92.90688225,
        40.31077859, 92.92844394, 40.57262689, 93.76080701, 40.66487499,
        93.80993399, 40.879512, 94.01080199, 41.11482801, 94.534157,
        41.50596001, 94.750114, 41.53831899, 94.86199801, 41.66851801,
        95.13616999, 41.77284301, 95.29887801, 41.56534301, 95.359508,
        41.66806199, 95.57194199, 41.79619101, 96.038344, 41.92479601,
        96.13750899, 42.01960299, 96.07723399, 42.14966101, 96.17844699,
        42.21797001, 96.041091, 42.32652301, 96.066152, 42.41452501, 95.978723,
        42.43674501, 96.02380801, 42.54250099, 96.386194, 42.72777201,
        97.17276299, 42.79516301, 97.846956, 41.656602, 97.61648099, 41.464275,
        97.97206, 41.097929, 98.33319401, 40.91912301, 98.34429701, 40.56836801,
        98.627986, 40.67792399, 98.57006201, 40.74687399, 98.66857999,
        40.77291499, 98.69760902, 40.68026301, 98.80273301, 40.60708299,
        98.79095199, 40.705541, 98.985034, 40.78287401, 99.10208899,
        40.67638901, 99.17311101, 40.747287, 99.17474499, 40.85831999,
        99.56601101, 40.84675801, 99.67320401, 40.932951, 100.108021,
        40.87554501, 100.237267, 40.71682399, 100.242573, 40.618667, 100.169713,
        40.54132201, 100.169624, 40.277601, 99.98830401, 40.18695601, 99.87324,
        40.02594101, 99.44107199, 39.885888, 99.82198999, 39.86007701,
        99.90438999, 39.78579401, 100.250052, 39.68507901, 100.326254,
        39.50911701, 100.500543, 39.48137201, 100.499105, 39.40053899,
        100.84209, 39.40574099, 100.829624, 39.035559, 100.961556, 39.00608399,
        100.969248, 38.946989, 101.227118, 39.02178399, 101.198553, 38.94325101,
        101.242123, 38.86122199, 101.335446, 38.846903, 101.31664, 38.783714,
        101.561021, 38.713777, 101.605805, 38.65312001, 101.672083, 38.691056,
        101.777154, 38.66058899, 102.075015, 38.89153901, 101.833439,
        39.09490299, 102.453633, 39.25527399, 102.605032, 39.171313, 103.009954,
        39.10003699, 103.356899, 39.336581, 103.83893, 39.460372, 104.090074,
        39.41984301, 104.047635, 39.29769199, 104.177535, 39.152157, 104.196351,
        38.98823699, 104.044397, 38.89506499, 103.859736, 38.64448299,
        103.415599, 38.404553, 103.534877, 38.156705, 103.368751, 38.08891599,
        103.368223, 37.98561799, 103.402308, 37.861617, 103.676298, 37.78407099,
        104.183248, 37.40677999, 104.287231, 37.42784301, 104.679895, 37.407838,
        104.66828, 37.35606501, 104.718075, 37.338228, 104.620474, 37.29561,
        104.626062, 37.20584199, 104.823539, 37.229393, 104.965258, 37.03175001,
        105.165229, 36.99498801, 105.178878, 36.892455, 105.278902, 36.86977401,
        105.341101, 36.76798201, 105.20097, 36.70218801, 105.270081, 36.5944,
        105.248962, 36.54674499, 105.361408, 36.49817601, 105.514989,
        36.14732299, 105.341579, 36.02795699, 105.327129, 35.90472599,
        105.397649, 35.85733001, 105.37912, 35.79553301, 105.426778, 35.820111,
        105.4375693, 35.73780396, 105.561258, 35.724625, 105.564342, 35.687653,
        105.667876, 35.749938, 105.755039, 35.730568, 105.690479, 35.683998,
        105.816448, 35.57509499, 105.84225, 35.48979701, 105.900448, 35.547157,
        106.02053, 35.49388801, 106.09188, 35.510031, 106.10895, 35.363129,
        106.174703, 35.438353, 106.237496, 35.409462, 106.357656, 35.238685,
        106.509285, 35.358327, 106.434975, 35.688535, 106.601386, 35.745118,
        106.750729, 35.689622, 106.751532, 35.72608301, 106.806255, 35.70964799,
        106.927514, 35.808971, 106.850121, 35.88755301, 106.944789, 35.940264,
        106.891849, 35.966144, 106.916213, 35.944679, 106.949286, 35.992034,
        106.946337, 36.12241099, 106.833976, 36.22920399, 106.763395, 36.214949,
        106.605358, 36.29576299, 106.504723, 36.26610299, 106.47031, 36.311916,
        106.512063, 36.28628, 106.520983, 36.47909999, 106.361457, 36.57362801,
        106.455921, 36.55991801, 106.444689, 36.624936, 106.492311, 36.62935799,
        106.532246, 36.73048, 106.645567, 36.723242, 106.639766, 36.88259999,
        106.539019, 36.97784399, 106.579918, 36.934805, 106.643123, 36.96267,
        106.666761, 37.01677701, 106.605224, 37.12742899, 106.728476,
        37.12144099, 106.760412, 37.08090999, 106.776575, 37.15812599,
        106.892196, 37.097936, 106.905678, 37.151391, 106.996758, 37.106321,
        107.181503, 37.14356199, 107.268692, 37.09945699, 107.308544,
        36.91283301, 107.477944, 36.90843499, 107.542155, 36.82768199,
        107.830653, 36.78608301, 107.90668, 36.752369, 107.938872, 36.65563399,
        108.012412, 36.68140599, 108.046192, 36.59623399, 108.082407,
        36.61537199, 108.163606, 36.563739, 108.211384, 36.63515701, 108.21662,
        36.57135199, 108.359637, 36.54727301, 108.401812, 36.446488, 108.496812,
        36.42272699, 108.510642, 36.47446199, 108.629121, 36.42077301,
        108.712336, 36.13873101, 108.68862, 36.021104, 108.652232, 35.94825001,
        108.580733, 35.947816, 108.498336, 35.87611199, 108.539744, 35.605613,
        108.625704, 35.53708901, 108.615309, 35.330023, 108.485258, 35.274383,
        108.349586, 35.30221, 108.240349, 35.25599099, 108.193748, 35.30663999,
        107.969893, 35.24154201, 107.718698, 35.303264, 107.718671, 35.24471701,
        107.651965, 35.239726, 107.864083, 34.99556, 107.639878, 34.91878999,
        107.57217, 34.97138499, 107.5324, 34.91129299, 107.295477, 34.93636601,
        107.214121, 34.87720601, 106.988963, 35.06974101, 106.543521,
        35.08504899, 106.483195, 34.97996899, 106.5756466, 34.76978796,
        106.447321, 34.69493, 106.470996, 34.633945, 106.422168, 34.65529701,
        106.313336, 34.57982999, 106.33445, 34.51788, 106.486073, 34.524705,
        106.717369, 34.36925599, 106.673117, 34.247438, 106.526425, 34.291953,
        106.496532, 34.25510999, 106.551264, 34.24264199, 106.584848, 34.149438,
        106.501852, 34.10490199, 106.4760032, 33.97376901, 106.410491,
        33.908022, 106.489643, 33.85446701, 106.48079, 33.712417, 106.539737,
        33.691188, 106.580579, 33.57592999, 106.514714, 33.513198, 106.457085,
        33.53288501, 106.4439949, 33.61820008, 106.187188, 33.546509,
        106.086648, 33.61712899, 105.956343, 33.613245, 105.841982, 33.490087,
        105.827211, 33.38007399, 105.708982, 33.38071999, 105.796076,
        33.26000501, 105.965279, 33.204539, 105.962567, 33.14606, 105.930189,
        33.19930199, 105.897228, 33.14660699, 105.937996, 33.12008701,
        105.917441, 32.993824, 105.861718, 32.938938, 105.638276, 32.879291,
      ],
      [
        106.054031, 35.45474699, 106.028562, 35.48698701, 105.897585,
        35.45150701, 105.893946, 35.415387,
      ],
    ],
  },
  {
    id: "630000",
    name: "青海",
    fullname: "青海省",
    pinyin: ["qing", "hai"],
    location: {
      lat: 35,
      lng: 95.780482,
    },
    polygon: [
      [
        97.73106799, 32.527308, 97.52075001, 32.525872, 97.44796199, 32.587241,
        97.34138301, 32.55705701, 97.328417, 32.517796, 97.389034, 32.500823,
        97.34153699, 32.439789, 97.39136999, 32.42273899, 97.424652,
        32.32281099, 97.371004, 32.27320501, 97.28994899, 32.28061599,
        97.264183, 32.18259001, 97.30789901, 32.073879, 97.219776, 32.109112,
        97.23413199, 32.04698899, 97.169156, 32.02404201, 96.95278801,
        32.06148201, 96.95533801, 31.99402, 96.89333399, 32.013505, 96.86655799,
        31.964356, 96.72390799, 32.02274601, 96.81031799, 31.89670501,
        96.76051801, 31.85684999, 96.821915, 31.700298, 96.75378999,
        31.67393599, 96.67632599, 31.72781901, 96.568281, 31.711974,
        96.43584401, 31.79648701, 96.38680901, 31.92283, 96.25112799,
        31.92960099, 96.188806, 31.90466201, 96.17557101, 31.824242,
        96.24897401, 31.661417, 96.21192099, 31.60131699, 96.16037899,
        31.60107599, 96.147023, 31.69006799, 96.04143999, 31.734367,
        95.98291199, 31.81664199, 95.89931, 31.81713401, 95.82497801,
        31.68203899, 95.779619, 31.74909301, 95.68280201, 31.77636999,
        95.51291801, 31.74914299, 95.360935, 31.938088, 95.37787701, 31.991979,
        95.45443799, 32.00784999, 95.41529599, 32.04595401, 95.45470001,
        32.06251699, 95.43939401, 32.15892, 95.38905401, 32.18608499,
        95.31285901, 32.14887401, 95.24102501, 32.320509, 95.132389, 32.264004,
        95.079622, 32.27995699, 95.09707699, 32.322301, 95.261711, 32.348087,
        95.21852075, 32.39683872, 95.074975, 32.376045, 94.918705, 32.41123599,
        94.614582, 32.67359101, 94.454436, 32.56772501, 94.39324199, 32.603956,
        94.36726501, 32.521765, 94.196815, 32.516155, 94.13760799, 32.433877,
        93.95993401, 32.48495999, 93.858709, 32.46469101, 93.82123501,
        32.54878899, 93.72202801, 32.57812, 93.52207699, 32.47319301, 93.462096,
        32.556617, 93.38258399, 32.52590899, 93.242381, 32.66161299,
        93.06561099, 32.62714699, 93.00937398, 32.74175001, 92.877, 32.696978,
        92.687953, 32.766041, 92.64951501, 32.721518, 92.355861, 32.76462399,
        92.255321, 32.72076101, 92.198663, 32.75480699, 92.203637, 32.86734501,
        92.14146101, 32.88506799, 91.95778799, 32.81963001, 91.838381,
        32.949368, 91.58331699, 33.03767599, 91.53335899, 33.10069299,
        91.43593099, 33.06615399, 91.24066201, 33.14301599, 91.14713401,
        33.07224, 91.00827499, 33.12168301, 90.90228799, 33.08311601,
        90.88285401, 33.12044399, 90.701014, 33.137299, 90.48755599,
        33.26638799, 90.40532599, 33.26061699, 90.24358201, 33.42661199,
        90.09188199, 33.46980101, 90.081088, 33.530576, 89.98811499, 33.583634,
        89.98380299, 33.72581601, 89.90607901, 33.74263501, 89.94227001,
        33.801701, 89.794959, 33.865504, 89.63602099, 34.04213599, 89.693247,
        34.126062, 89.81698699, 34.16946601, 89.87197399, 34.335854, 89.799587,
        34.39328901, 89.79965099, 34.62839701, 89.723524, 34.70404799,
        89.869203, 34.81426, 89.78752201, 34.921748, 89.57973098, 34.894095,
        89.59198599, 35.10849401, 89.449818, 35.22677401, 89.533209,
        35.27857099, 89.50528601, 35.371504, 89.68643601, 35.41471701,
        89.765701, 35.48322499, 89.7, 35.54514999, 89.76544499, 35.58943801,
        89.72532, 35.649819, 89.801882, 35.84806199, 89.550572, 35.85713001,
        89.425574, 35.919574, 89.40442498, 36.019408, 89.60521001, 36.038264,
        89.71151499, 36.093172, 89.94658102, 36.069428, 90.005095, 36.279697,
        90.24164401, 36.159352, 90.66299999, 36.13394999, 90.84678801, 36.01687,
        90.92606199, 36.03032099, 90.97762499, 36.10640201, 91.11197301,
        36.09498799, 91.09796801, 36.21797101, 91.047839, 36.24438199,
        91.07669399, 36.29363499, 91.02577399, 36.326211, 91.03582701,
        36.52993399, 90.74101901, 36.585865, 90.72241799, 36.798203,
        90.86085401, 36.918224, 91.03704899, 36.929514, 91.18120799, 37.025367,
        91.31414099, 37.01991699, 91.28027101, 37.16380499, 91.190947,
        37.20574599, 91.07475799, 37.475191, 90.94080201, 37.480456,
        90.77613001, 37.65056001, 90.520762, 37.72861401, 90.53082499,
        38.319835, 90.361668, 38.30016801, 90.35289601, 38.23349899,
        90.28080399, 38.238085, 90.13779401, 38.340588, 90.17922599,
        38.39702201, 90.114942, 38.41323701, 90.116426, 38.48270299, 90.420324,
        38.49097899, 90.46362099, 38.55671101, 90.610641, 38.59584701,
        90.62413199, 38.668367, 90.803681, 38.655117, 91.11390399, 38.707739,
        92.259692, 39.001722, 92.38260001, 39.00027901, 92.41075801, 39.038578,
        92.49398499, 39.102154, 93.12000202, 39.17888099, 93.19815799,
        39.04620599, 93.18968999, 38.91961101, 93.731512, 38.92477701,
        93.88469799, 38.86765599, 93.88418599, 38.82620301, 93.75602001,
        38.80637401, 93.88273701, 38.720652, 94.37012999, 38.762798,
        94.55196341, 38.35025321, 94.969436, 38.43045601, 95.121649,
        38.41739301, 95.26837499, 38.297418, 95.45492199, 38.29168301,
        95.703229, 38.40058999, 96.05283001, 38.17644201, 96.26637, 38.14539901,
        96.378335, 38.27731501, 96.665199, 38.23018601, 96.623773, 38.345162,
        96.701806, 38.43392501, 96.66636099, 38.489722, 96.79506998, 38.510292,
        96.79440293, 38.60801045, 96.960752, 38.557841, 97.05595598, 38.594502,
        96.99306899, 38.884737, 96.940822, 38.90787601, 97.011869, 39.14118401,
        96.962998, 39.19836601, 97.34738398, 39.16737901, 98.09332599,
        38.78632099, 98.249326, 38.88701699, 98.29001701, 39.03707701,
        98.58264599, 38.92983301, 98.74382701, 39.08688199, 98.81320301,
        39.087664, 98.93612799, 38.99444901, 99.107334, 38.951887, 99.06875499,
        38.897082, 99.362163, 38.71821699, 99.44795301, 38.60545401,
        99.50809201, 38.609816, 99.67106601, 38.44094201, 100.127912, 38.230657,
        100.182863, 38.22203801, 100.168856, 38.321572, 100.001224, 38.46698899,
        100.060077, 38.519968, 100.236484, 38.44423, 100.251417, 38.37138999,
        100.300789, 38.389145, 100.318867, 38.327724, 100.424161, 38.30714301,
        100.437181, 38.26803901, 100.714882, 38.253623, 100.844759, 38.148698,
        100.929801, 38.169281, 100.894761, 38.013545, 101.103024, 37.946537,
        101.202749, 37.84753801, 101.357216, 37.79136101, 101.441512, 37.86792,
        101.574633, 37.83534199, 101.659183, 37.733326, 101.8161, 37.654354,
        101.999066, 37.72506701, 102.130793, 37.547355, 102.104078, 37.48245399,
        102.603523, 37.17207201, 102.642069, 37.099665, 102.497966, 37.090407,
        102.506096, 37.01923499, 102.450326, 36.96849999, 102.721011, 36.769706,
        102.613456, 36.738674, 102.606703, 36.68189001, 102.764517, 36.575484,
        102.839836, 36.33878, 103.070971, 36.206936, 102.987442, 36.193795,
        102.881953, 36.083438, 102.975641, 36.036427, 102.946874, 35.83127601,
        102.78406, 35.86232099, 102.694829, 35.79643301, 102.808724, 35.56044,
        102.728782, 35.524582, 102.777412, 35.52088801, 102.742366, 35.495296,
        102.505279, 35.58762199, 102.447592, 35.43732901, 102.406974,
        35.40842001, 102.314809, 35.43437801, 102.283132, 35.39299201,
        102.317878, 35.339821, 102.279966, 35.304191, 102.369997, 35.26396,
        102.403442, 35.17819501, 102.343494, 35.161727, 102.281679, 35.05770501,
        102.095095, 34.98676001, 102.068434, 34.88769399, 101.917278,
        34.87511299, 101.934532, 34.587489, 102.155825, 34.50757499, 102.258905,
        34.354231, 102.187329, 34.353395, 102.148299, 34.271305, 102.067937,
        34.29350101, 101.956848, 34.11015899, 101.848994, 34.152903, 101.736186,
        34.080097, 101.622721, 34.17825799, 101.323812, 34.245541, 101.226732,
        34.330046, 101.054599, 34.32297001, 100.950913, 34.383777, 100.805286,
        34.28696999, 100.764429, 34.178915, 100.995282, 33.890862, 101.155225,
        33.84249001, 101.1709, 33.656191, 101.237019, 33.68563001, 101.391136,
        33.64524401, 101.542149, 33.69521101, 101.612281, 33.616031, 101.631446,
        33.492264, 101.735832, 33.496333, 101.769177, 33.538671, 101.776171,
        33.45193601, 101.662766, 33.381632, 101.654317, 33.31538, 101.740401,
        33.269124, 101.631954, 33.10061999, 101.487733, 33.22689901, 101.40232,
        33.224627, 101.3827, 33.15287901, 101.296976, 33.26223, 101.183514,
        33.270078, 101.125312, 33.22185999, 101.185888, 32.986044, 101.129113,
        32.98951701, 101.12315, 32.91133701, 101.237748, 32.82772099,
        101.190151, 32.686583, 101.120957, 32.63274399, 101.067747, 32.68109599,
        100.930017, 32.600019, 100.687152, 32.67713999, 100.718395, 32.621494,
        100.663727, 32.62075501, 100.663601, 32.52242, 100.544799, 32.57006899,
        100.420326, 32.731775, 100.378736, 32.69844799, 100.400097, 32.75634001,
        100.334503, 32.71826401, 100.258778, 32.742315, 100.214955, 32.60652301,
        100.110153, 32.63926601, 100.123185, 32.837277, 100.037225, 32.93020099,
        99.95657102, 32.948096, 99.90306099, 33.04704401, 99.852818,
        32.94329201, 99.77040701, 32.94542901, 99.79203701, 32.883101,
        99.73038601, 32.72519401, 99.38572399, 32.90020899, 99.268187,
        32.878719, 99.196092, 33.035425, 98.90492001, 33.12452401, 98.758352,
        33.27905099, 98.742927, 33.47645099, 98.61770499, 33.624366, 98.656961,
        33.65145399, 98.40683199, 33.86717499, 98.44060801, 33.99222499,
        98.40518, 34.08524201, 98.20723701, 34.078773, 98.02859201, 34.122938,
        97.944855, 34.19859, 97.81069999, 34.207453, 97.66561401, 34.12650399,
        97.702336, 34.036747, 97.63836001, 33.92103401, 97.39214101,
        33.88893301, 97.368769, 33.82411999, 97.42323701, 33.75492601,
        97.41577301, 33.60550299, 97.52521599, 33.57578399, 97.55258301,
        33.46566701, 97.75878301, 33.40320999, 97.62489199, 33.339159,
        97.57622498, 33.221912, 97.48722499, 33.167294, 97.52373399, 32.988613,
        97.373862, 32.95589, 97.348109, 32.895111, 97.42930399, 32.699083,
      ],
    ],
  },
  {
    id: "640000",
    name: "宁夏",
    fullname: "宁夏回族自治区",
    pinyin: ["ning", "xia"],
    location: {
      lat: 37.472273,
      lng: 106.258889,
    },
    polygon: [
      [
        105.8921, 35.44746799, 106.058838, 35.48619501, 106.053569, 35.44824999,
        105.965573, 35.42564699, 105.897217, 35.41205099,
      ],
      [
        107.268692, 37.09945699, 107.181503, 37.14356199, 106.996758, 37.106321,
        106.905678, 37.151391, 106.892196, 37.097936, 106.776575, 37.15812599,
        106.760412, 37.08090999, 106.728476, 37.12144099, 106.605224,
        37.12742899, 106.666761, 37.01677701, 106.643123, 36.96267, 106.579918,
        36.934805, 106.539019, 36.97784399, 106.639766, 36.88259999, 106.645567,
        36.723242, 106.532246, 36.73048, 106.492311, 36.62935799, 106.444689,
        36.624936, 106.455921, 36.55991801, 106.361457, 36.57362801, 106.520983,
        36.47909999, 106.512063, 36.28628, 106.47031, 36.311916, 106.504723,
        36.26610299, 106.605358, 36.29576299, 106.763395, 36.214949, 106.833976,
        36.22920399, 106.946337, 36.12241099, 106.949286, 35.992034, 106.916213,
        35.944679, 106.891849, 35.966144, 106.944789, 35.940264, 106.850121,
        35.88755301, 106.927514, 35.808971, 106.806255, 35.70964799, 106.751532,
        35.72608301, 106.750729, 35.689622, 106.601386, 35.745118, 106.434975,
        35.688535, 106.509285, 35.358327, 106.357656, 35.238685, 106.237496,
        35.409462, 106.174703, 35.438353, 106.10895, 35.363129, 106.09188,
        35.510031, 106.02053, 35.49388801, 105.900448, 35.547157, 105.84225,
        35.48979701, 105.816448, 35.57509499, 105.690479, 35.683998, 105.755039,
        35.730568, 105.667876, 35.749938, 105.564342, 35.687653, 105.561258,
        35.724625, 105.4375693, 35.73780396, 105.426778, 35.820111, 105.37912,
        35.79553301, 105.397649, 35.85733001, 105.327129, 35.90472599,
        105.341579, 36.02795699, 105.514989, 36.14732299, 105.361408,
        36.49817601, 105.248962, 36.54674499, 105.270081, 36.5944, 105.20097,
        36.70218801, 105.341101, 36.76798201, 105.278902, 36.86977401,
        105.178878, 36.892455, 105.165229, 36.99498801, 104.965258, 37.03175001,
        104.823539, 37.229393, 104.626062, 37.20584199, 104.620474, 37.29561,
        104.718075, 37.338228, 104.66828, 37.35606501, 104.679895, 37.407838,
        104.287231, 37.42784301, 104.407574, 37.464642, 104.41966, 37.511821,
        105.024199, 37.579889, 105.314785, 37.70205799, 105.598948, 37.699392,
        105.623118, 37.77860701, 105.76068, 37.79990901, 105.840122,
        38.00400801, 105.76793, 38.13444899, 105.866546, 38.29499801,
        105.821023, 38.364372, 105.897439, 38.78870901, 106.002449, 38.871902,
        105.968876, 38.90508, 106.060788, 38.96851199, 106.146072, 39.153225,
        106.285072, 39.146314, 106.284605, 39.27030701, 106.509072, 39.270722,
        106.602614, 39.375329, 106.787197, 39.366932, 106.795933, 39.21431999,
        106.971383, 39.016986, 106.954323, 38.94117599, 106.702837, 38.708252,
        106.601851, 38.39250599, 106.482788, 38.31681699, 106.945096, 38.131733,
        107.1881, 38.15534, 107.432844, 38.00507499, 107.419398, 37.940688,
        107.650221, 37.864609, 107.620697, 37.775957, 107.499175, 37.76566999,
        107.465424, 37.69123199, 107.386867, 37.69111399, 107.419889, 37.650222,
        107.311298, 37.60979, 107.3721309, 37.57991901, 107.284305, 37.48192,
        107.257494, 37.33149301, 107.338359, 37.159152,
      ],
    ],
  },
  {
    id: "650000",
    name: "新疆",
    fullname: "新疆维吾尔自治区",
    pinyin: ["xin", "jiang"],
    location: {
      lat: 40.793301,
      lng: 85.628579,
    },
    polygon: [
      [
        89.71151499, 36.093172, 89.29506199, 36.22986001, 89.23671301,
        36.29483099, 89.12780199, 36.249673, 88.925963, 36.364407, 88.767621,
        36.29212099, 88.588715, 36.454345, 88.498679, 36.44623499, 88.47063399,
        36.48223699, 87.98294699, 36.43782299, 87.919442, 36.39365799,
        87.562014, 36.34185201, 87.47043801, 36.35428801, 87.423998, 36.42588,
        87.19346199, 36.34939399, 87.149411, 36.297425, 86.99615399,
        36.30868601, 86.89181399, 36.262023, 86.862711, 36.29993401,
        86.75853601, 36.294647, 86.69997499, 36.24478301, 86.45514699,
        36.22120199, 86.19055501, 36.132589, 86.20001399, 36.03304899,
        86.05987601, 35.845869, 85.94908399, 35.77887, 85.69288601, 35.75182199,
        85.59722701, 35.64476399, 85.37346699, 35.70062001, 85.283267,
        35.78724401, 85.03111, 35.74768501, 84.76252099, 35.62454299,
        84.44894801, 35.550349, 84.45312299, 35.473333, 84.17472099,
        35.35803899, 84.00591299, 35.42239301, 83.87516901, 35.36339101,
        83.62227099, 35.33542699, 83.24232, 35.41985601, 83.126799, 35.39851601,
        82.99905, 35.48450199, 82.96093599, 35.67153099, 82.797044, 35.688618,
        82.73135901, 35.637859, 82.457123, 35.71854699, 82.335987, 35.65136999,
        82.319698, 35.553432, 82.03306701, 35.45109801, 82.054685, 35.355191,
        82.01341899, 35.30995101, 81.675006, 35.23351, 81.51326501, 35.23489799,
        81.49415291, 35.29225021, 81.36392401, 35.35459199, 81.21571999,
        35.320274, 81.099249, 35.40755101, 81.03158801, 35.380648, 81.02627401,
        35.311663, 80.96378701, 35.31104301, 80.689256, 35.33897001,
        80.65648201, 35.393949, 80.51322899, 35.392121, 80.43313288,
        35.44951608, 80.30446599, 35.37973, 80.26798199, 35.295775, 80.36000499,
        35.201449, 80.25783301, 35.2034, 80.20955001, 35.12275299, 80.03102699,
        35.03884799, 80.03665, 34.90318701, 79.92789299, 34.85323699,
        79.81986201, 34.49260099, 79.64617601, 34.44666501, 79.545295,
        34.476326, 79.372806, 34.42806301, 79.05310701, 34.42751199,
        79.01046599, 34.40150299, 79.03951099, 34.334503, 78.95870401,
        34.386284, 78.892386, 34.35586599, 78.70845099, 34.52209799,
        78.57528901, 34.50657899, 78.54233801, 34.57470701, 78.439476,
        34.54224101, 78.42768799, 34.59405001, 78.26848301, 34.63536301,
        78.26730399, 34.70526801, 78.20778301, 34.72833699, 78.23788099,
        34.881286, 78.18299602, 34.92550701, 78.20168401, 34.97447699,
        78.12293699, 35.03691, 78.13096799, 35.106431, 78.06540699, 35.10829901,
        78.01433501, 35.236993, 78.01332599, 35.365821, 78.140435, 35.49478501,
        77.92106801, 35.493767, 77.90726101, 35.464339, 77.81652999, 35.518558,
        77.69054601, 35.44824601, 77.64849899, 35.478381, 77.39716799,
        35.467873, 77.30773801, 35.54053601, 77.19579601, 35.51929499, 76.84891,
        35.66807999, 76.76132801, 35.65590001, 76.69314199, 35.74760599,
        76.59359999, 35.77205999, 76.576036, 35.920843, 76.431803, 35.85178,
        76.369728, 35.86333701, 76.36590801, 35.824234, 76.16035501,
        35.82448599, 76.10415099, 36.01882199, 76.014543, 36.018106, 75.949642,
        36.07025299, 75.93633701, 36.13466899, 76.01647201, 36.165478,
        76.01156601, 36.22912901, 76.06511099, 36.230408, 75.99125999,
        36.33185099, 76.01896401, 36.45645701, 75.87178201, 36.66621601,
        75.648241, 36.77104201, 75.537339, 36.77306001, 75.53214801,
        36.72661699, 75.458433, 36.721005, 75.413615, 36.954594, 75.235942,
        36.965084, 75.14815899, 37.02604, 74.93496599, 36.982639, 74.902262,
        36.934206, 74.84700399, 37.056955, 74.75655701, 37.02563901,
        74.70236401, 37.08915799, 74.56816699, 37.03273601, 74.50042001,
        37.06952601, 74.46350399, 37.133617, 74.511126, 37.239946, 74.63465401,
        37.26493501, 74.66569701, 37.235763, 74.738976, 37.285752, 74.866417,
        37.22146899, 75.14016599, 37.35546901, 75.129615, 37.459149,
        74.94047301, 37.55913399, 74.89298001, 37.66110901, 75.00835999,
        37.776367, 74.91747401, 37.84506901, 74.92580401, 38.01729901,
        74.879375, 38.021179, 74.806443, 38.15575999, 74.790825, 38.33290899,
        74.86821599, 38.40389599, 74.86271499, 38.48404899, 74.64027101,
        38.599594, 74.377796, 38.65484301, 74.148087, 38.67685001, 74.06863901,
        38.58558699, 74.090654, 38.542162, 74.00063001, 38.52376, 73.79916,
        38.61106901, 73.77084399, 38.771123, 73.699342, 38.85770299,
        73.74232899, 38.93382001, 73.82644399, 38.91692801, 73.84011001,
        39.00836701, 73.81582799, 39.043749, 73.742912, 39.02935899,
        73.63949199, 39.22048999, 73.53940501, 39.272382, 73.555956,
        39.34666699, 73.502355, 39.383778, 73.59260999, 39.41227999, 73.623474,
        39.470151, 73.868118, 39.482865, 73.948423, 39.59252201, 73.925716,
        39.719465, 73.83818399, 39.800118, 73.97891199, 40.00021901,
        73.94669499, 40.02273501, 74.030814, 40.089368, 74.211304, 40.12124901,
        74.35666801, 40.08928, 74.586516, 40.26949999, 74.67349599, 40.278684,
        74.70062399, 40.34638399, 74.908308, 40.338881, 74.79323001,
        40.44776299, 74.83682101, 40.52087299, 74.98548399, 40.45657601,
        75.242427, 40.44882801, 75.599357, 40.659672, 75.64619699, 40.516689,
        75.73307301, 40.474252, 75.669922, 40.36382301, 75.701056, 40.28315899,
        75.831049, 40.32739199, 75.92153401, 40.29125001, 75.97414401,
        40.379098, 76.17636299, 40.381508, 76.27984499, 40.43923001, 76.343332,
        40.341317, 76.53948499, 40.464003, 76.56596599, 40.554983, 76.65996601,
        40.62244799, 76.64665899, 40.76013401, 76.73150498, 40.819082, 76.76201,
        40.953981, 76.85355799, 40.97637699, 76.87756599, 41.02520801,
        77.002191, 41.07345399, 77.16934399, 41.00909801, 77.36858201,
        41.040969, 77.59157999, 40.992302, 77.780487, 41.02301101, 77.83634699,
        41.15308501, 78.129129, 41.22823099, 78.163817, 41.38335101,
        78.65049701, 41.467272, 78.706168, 41.54825601, 78.82519, 41.560306,
        79.325941, 41.80943601, 79.77660201, 41.892556, 79.89287199,
        42.03377099, 80.16256001, 42.03893801, 80.16567799, 42.197001,
        80.292439, 42.25966001, 80.20594299, 42.430536, 80.27365101, 42.511813,
        80.16393899, 42.62793001, 80.26015801, 42.824829, 80.60298199,
        42.894383, 80.38512801, 43.03777301, 80.63027501, 43.14389901,
        80.79454601, 43.137372, 80.80433399, 43.17839, 80.77693999, 43.308013,
        80.68671001, 43.32692, 80.75491401, 43.49417599, 80.52205201,
        43.81659601, 80.39552499, 44.11906, 80.349819, 44.48240901, 80.413153,
        44.610924, 80.220898, 44.733256, 80.170048, 44.84454901, 79.99941601,
        44.79385399, 79.96913101, 44.877593, 79.88783701, 44.90921101,
        80.10405101, 45.053155, 80.39113601, 45.04423701, 80.480854, 45.122979,
        80.59914901, 45.10573799, 80.72655001, 45.17702601, 80.89811499,
        45.127607, 81.11674498, 45.22129899, 81.47512901, 45.26682801,
        81.78000401, 45.38348799, 81.92070901, 45.23333399, 82.09191899,
        45.253012, 82.10947299, 45.21138301, 82.29441801, 45.247796, 82.478789,
        45.182347, 82.56282301, 45.204542, 82.60101401, 45.34621, 82.546751,
        45.425873, 82.28203501, 45.53889699, 82.266753, 45.62015301,
        82.34613101, 45.94374001, 82.46151599, 45.979995, 82.87848801,
        46.797233, 83.029959, 47.21957299, 83.15481699, 47.23608901,
        83.24778601, 47.17587699, 83.37045199, 47.178563, 83.57116101,
        47.06056801, 83.92477199, 46.96991101, 84.74802701, 47.009848,
        84.95508799, 46.86109001, 84.993291, 46.923197, 85.27643199, 47.068922,
        85.54572, 47.05796701, 85.70111201, 47.28848699, 85.52934101,
        48.02731501, 85.58629699, 48.19079899, 85.79004001, 48.41850999,
        86.22618896, 48.43237946, 86.30282099, 48.491746, 86.41305325,
        48.4812101, 86.585678, 48.54215099, 86.78096601, 48.73203999, 86.754817,
        48.786011, 86.82254301, 48.84576401, 86.73046501, 48.98829199,
        86.88199001, 49.12944699, 87.14766899, 49.15414599, 87.43664299,
        49.07525301, 87.511595, 49.10170599, 87.5056, 49.14281601, 87.820887,
        49.17379699, 87.83339001, 49.05018399, 87.911866, 48.980023,
        87.75988301, 48.92598499, 87.744153, 48.873916, 87.826443, 48.800946,
        88.09710801, 48.717079, 87.97308901, 48.575997, 88.34955801,
        48.46774101, 88.443749, 48.391472, 88.513509, 48.409441, 88.606067,
        48.337828, 88.57509899, 48.27759099, 88.63824101, 48.18361099,
        88.824468, 48.106807, 88.933593, 48.11847301, 89.07843701, 47.986985,
        89.23218756, 47.98037885, 89.38415601, 48.04663501, 89.568844,
        48.037756, 89.76800401, 47.83251899, 90.06634099, 47.883684, 90.135031,
        47.723164, 90.38481801, 47.644288, 90.35011701, 47.62811, 90.468182,
        47.49765899, 90.56155199, 47.207059, 90.76722999, 46.992538, 90.901466,
        46.96076199, 90.94268301, 46.825798, 91.05406399, 46.71740999,
        91.017855, 46.58231499, 91.07595601, 46.54913199, 90.90046101,
        46.31224601, 91.02827401, 46.02308699, 90.71480401, 45.72890899,
        90.67026499, 45.54799, 90.671298, 45.48768999, 90.772604, 45.43228,
        90.80506602, 45.29493077, 90.89743, 45.24980401, 90.88193799,
        45.19182701, 91.12967101, 45.215999, 91.242011, 45.137361, 91.37739,
        45.11107, 91.44914301, 45.15664, 91.61834301, 45.06858999, 92.05661299,
        45.08675499, 92.24033501, 45.015976, 92.49680199, 45.001174,
        92.88431701, 45.046835, 92.98545401, 45.00204999, 93.509411, 44.968223,
        94.215435, 44.66810101, 94.35899201, 44.51564601, 94.60611499,
        44.448317, 94.72277999, 44.34075199, 94.998825, 44.25312001,
        95.41355201, 44.29858901, 95.326665, 44.02874099, 95.52683102,
        44.00741201, 95.85805101, 43.417653, 95.88546899, 43.271265,
        96.36363099, 42.900534, 96.386194, 42.72777201, 96.02380801,
        42.54250099, 95.978723, 42.43674501, 96.066152, 42.41452501, 96.041091,
        42.32652301, 96.17844699, 42.21797001, 96.07723399, 42.14966101,
        96.13750899, 42.01960299, 96.038344, 41.92479601, 95.57194199,
        41.79619101, 95.359508, 41.66806199, 95.29887801, 41.56534301,
        95.13616999, 41.77284301, 94.86199801, 41.66851801, 94.750114,
        41.53831899, 94.534157, 41.50596001, 94.01080199, 41.11482801,
        93.80993399, 40.879512, 93.76080701, 40.66487499, 92.92844394,
        40.57262689, 92.90688225, 40.31077859, 92.796526, 40.15385101,
        92.63984501, 39.514329, 92.33914599, 39.236621, 92.35376501,
        39.06947801, 92.41075801, 39.038578, 92.38260001, 39.00027901,
        92.259692, 39.001722, 91.11390399, 38.707739, 90.803681, 38.655117,
        90.62413199, 38.668367, 90.610641, 38.59584701, 90.46362099,
        38.55671101, 90.420324, 38.49097899, 90.116426, 38.48270299, 90.114942,
        38.41323701, 90.17922599, 38.39702201, 90.13779401, 38.340588,
        90.28080399, 38.238085, 90.35289601, 38.23349899, 90.361668,
        38.30016801, 90.53082499, 38.319835, 90.520762, 37.72861401,
        90.77613001, 37.65056001, 90.94080201, 37.480456, 91.07475799,
        37.475191, 91.190947, 37.20574599, 91.28027101, 37.16380499,
        91.31414099, 37.01991699, 91.18120799, 37.025367, 91.03704899,
        36.929514, 90.86085401, 36.918224, 90.72241799, 36.798203, 90.74101901,
        36.585865, 91.03582701, 36.52993399, 91.02577399, 36.326211,
        91.07669399, 36.29363499, 91.047839, 36.24438199, 91.09796801,
        36.21797101, 91.11197301, 36.09498799, 90.97762499, 36.10640201,
        90.92606199, 36.03032099, 90.84678801, 36.01687, 90.66299999,
        36.13394999, 90.24164401, 36.159352, 90.005095, 36.279697, 89.94658102,
        36.069428,
      ],
    ],
  },
  {
    id: "710000",
    name: "台湾",
    fullname: "台湾省",
    pinyin: ["tai", "wan"],
    location: {
      lat: 24.037525,
      lng: 121.563782,
    },
    polygon: [
      [121.505172, 22.085673, 121.567841, 22.08670199, 121.592685, 22.00291099],
      [121.467577, 22.667872, 121.5093, 22.67874999, 121.508638, 22.63434101],
      [119.410843, 23.215629, 119.447819, 23.21999201, 119.430436, 23.18661199],
      [
        119.636574, 23.551966, 119.605883, 23.50791301, 119.540194, 23.54125601,
        119.595429, 23.52617301, 119.561657, 23.58504599, 119.597319, 23.568994,
        119.625221, 23.60884399, 119.620086, 23.57077401, 119.651, 23.60993999,
        119.69481, 23.55418,
      ],
      [
        119.467404, 23.56186, 119.51159, 23.64812999, 119.544011, 23.641054,
        119.516435, 23.564897,
      ],
      [
        120.844036, 21.97886901, 120.862355, 21.89767401, 120.766114,
        21.95921599, 120.711232, 21.93421301, 120.716084, 22.105293, 120.641426,
        22.30499199, 120.255173, 22.61890501, 120.27488, 22.70675201,
        120.164474, 22.96291301, 120.034188, 23.063238, 120.116392, 23.322023,
        120.118206, 23.522883, 120.043992, 23.42225699, 119.982492, 23.44700201,
        120.138269, 23.557704, 120.20046, 23.75964001, 120.163018, 23.75852701,
        120.170674, 23.806731, 120.2883, 23.87461001, 120.407195, 24.05701299,
        120.377494, 24.076491, 120.434511, 24.089641, 120.437381, 24.12076599,
        120.376726, 24.09462099, 120.485296, 24.195943, 120.49279, 24.29725,
        120.54628, 24.30626001, 120.723378, 24.605886, 120.902931, 24.74783601,
        121.031896, 25.03243801, 121.381715, 25.142799, 121.34711, 25.154852,
        121.452016, 25.249419, 121.578063, 25.29844801, 121.662794, 25.207337,
        121.70192, 25.21699401, 121.742065, 25.13042699, 121.924154, 25.129225,
        121.93299, 25.032666, 122.006405, 25.010069, 121.827552, 24.843324,
        121.876098, 24.53389, 121.617697, 24.08415899, 121.647323, 24.00740199,
        121.465946, 23.321759, 121.396241, 23.204798, 121.412369, 23.12199201,
        121.189908, 22.848599, 121.177391, 22.761626, 121.023368, 22.65165601,
        120.893245, 22.331372, 120.899825, 22.034457,
      ],
      [119.568137, 23.67177399, 119.609736, 23.664312, 119.59913, 23.61962],
    ],
  },
  {
    id: "810000",
    name: "香港",
    fullname: "香港特别行政区",
    pinyin: ["xiang", "gang"],
    location: {
      lat: 22.277468,
      lng: 117.171203,
    },
    polygon: [
      [
        114.235933, 22.54500301, 114.45996, 22.54225, 114.50439, 22.14570001,
        113.92719, 22.13377, 113.83732, 22.18095999, 113.87399, 22.42579999,
        114.034943, 22.50384699, 114.03697, 22.50336499, 114.066796,
        22.51227001, 114.068256, 22.513208, 114.088507, 22.51682601, 114.089283,
        22.51781999, 114.083485, 22.526113, 114.083571, 22.52689801, 114.099228,
        22.533142, 114.116713, 22.52704801, 114.120694, 22.52863699, 114.131492,
        22.53704201, 114.132064, 22.536921, 114.13222, 22.53691401, 114.156185,
        22.54824101, 114.156401, 22.54839899, 114.156722, 22.548961, 114.156774,
        22.54942301, 114.156739, 22.550866, 114.156774, 22.55123301, 114.15738,
        22.552016, 114.157674, 22.55207401, 114.158349, 22.55208301, 114.159925,
        22.55203099, 114.160592, 22.552203, 114.161077, 22.552626, 114.162237,
        22.554049, 114.16342, 22.55617799, 114.164003, 22.556773, 114.164436,
        22.557572, 114.166675, 22.55909299, 114.167094, 22.55877499, 114.167412,
        22.55826999, 114.167872, 22.557163, 114.168033, 22.557009, 114.16819,
        22.55657501, 114.168534, 22.556422, 114.168954, 22.556373, 114.169522,
        22.55634, 114.170993, 22.55714801, 114.17158, 22.55787101, 114.171984,
        22.55826, 114.1728, 22.558514, 114.173305, 22.55848799, 114.173687,
        22.558451, 114.173964, 22.55837601, 114.174222, 22.558222, 114.174439,
        22.55805, 114.174783, 22.557436, 114.175138, 22.557122, 114.175509,
        22.55689801, 114.17726, 22.556905, 114.178236, 22.55684199, 114.179168,
        22.55704401, 114.179739, 22.557245, 114.180688, 22.55721201, 114.180945,
        22.557268, 114.182161, 22.556946, 114.182573, 22.555331, 114.182578,
        22.554804, 114.182754, 22.55337799, 114.182626, 22.55291799, 114.18271,
        22.55280101, 114.184492, 22.55214099, 114.185301, 22.551711, 114.185666,
        22.55145399, 114.18613, 22.55144701, 114.186112, 22.55175399,
        114.186408, 22.552367, 114.186595, 22.55258801, 114.186797, 22.55267401,
        114.187848, 22.55250601, 114.188649, 22.552449, 114.189139, 22.552416,
        114.191152, 22.551985, 114.192252, 22.55239999, 114.192468, 22.552625,
        114.193368, 22.55233701, 114.193767, 22.551926, 114.196277, 22.552449,
        114.196823, 22.552884, 114.197897, 22.55292801, 114.198494, 22.552816,
        114.20007, 22.55294, 114.200521, 22.553139, 114.200893, 22.553498,
        114.201248, 22.554108, 114.202209, 22.55414099, 114.202521, 22.55398,
        114.203066, 22.55390201, 114.20356, 22.554008, 114.205595, 22.55473401,
        114.205829, 22.55475099, 114.206037, 22.55467299, 114.206158, 22.55459,
        114.206314, 22.554331, 114.206548, 22.553871, 114.206807, 22.55358401,
        114.207032, 22.55353, 114.207292, 22.55352001, 114.207985, 22.55359599,
        114.209414, 22.55373199, 114.210835, 22.55383, 114.212142, 22.55361501,
        114.212619, 22.55362299, 114.213286, 22.553739, 114.214507, 22.55397699,
        114.214975, 22.55399099, 114.215322, 22.55394701, 114.216309,
        22.55326901, 114.217019, 22.55244499, 114.217504, 22.55194999,
        114.22032, 22.55188999, 114.22071, 22.551935, 114.221082, 22.552076,
        114.221641, 22.55227199, 114.221876, 22.55254499, 114.221927,
        22.55288199, 114.222196, 22.552999, 114.223007, 22.55298301, 114.223285,
        22.55276399, 114.223976, 22.55178999, 114.225033, 22.55078599,
        114.225519, 22.550484, 114.225761, 22.55028, 114.225868, 22.55008099,
        114.225939, 22.54986401, 114.226041, 22.54929601, 114.22756,
        22.54848099, 114.227823, 22.54834601, 114.22815, 22.54801699,
        114.228361, 22.54800799, 114.228994, 22.54798899, 114.22917,
        22.54784799, 114.229408, 22.54728399, 114.22975, 22.546906, 114.230163,
        22.546588, 114.230964, 22.54374699, 114.230511, 22.54304199, 114.235153,
        22.54283901, 114.235226, 22.54291201, 114.235492, 22.54342899,
        114.235881, 22.54474401,
      ],
    ],
  },
  {
    id: "820000",
    name: "澳门",
    fullname: "澳门特别行政区",
    pinyin: ["ao", "men"],
    location: {
      lat: 20.586927,
      lng: 113.543076,
    },
    polygon: [
      [
        113.552133, 22.21382701, 113.63516, 22.16267001, 113.61523, 22.07372,
        113.55472, 22.10594999, 113.539386, 22.205763, 113.540832, 22.210632,
        113.546643, 22.21038399, 113.549457, 22.21400401,
      ],
    ],
  },
];
