<template>
  <div class="do-pages-hour-hot">
    <img
      class="do-com-map-logo"
      src="https://mapapi.qq.com/web/jsapi/logo/logo_def.png"
    />
    <BaseMap
      @init="initMap"
      :config="{
        minZoom: size.min,
        maxZoom: size.max,
        mapStyleId: 'style2',
      }"
    />
    <div class="foot">
      <div @click="handleGetLocation" class="location">
        <img :src="require('@/assets/datamap/location.png')" />
      </div>
      <foot-btn @back="updataHot"></foot-btn>
    </div>

    <!-- <div
      :style="{
        position: 'absolute',
        zIndex: 99,
        left: 0,
        top: '2rem',
        padding: '0.05rem',
        background: '#fff',
      }"
    >
      <div>
        当前地图level:{{ curZoom.toFixed(2) }} 请求地图的level:{{ heatLevel }}
      </div>
      <div>
        请求时间：{{ (axiosTime / 1000).toFixed(2) }} 渲染时间：{{
          (totleTime / 1000).toFixed(2)
        }}
        格子数: {{ cellNum }}
      </div>
    </div> -->
  </div>
</template>

<script setup>
import { getCurrentInstance, ref } from "vue";
import { getHeat, getHeatConfig } from "@/axios/heat.js";
import FootBtn from "./components/foot-btn.vue";
import { debounce } from "@/util";
import { getAppLocation } from "@/js_sdk/permission.js";
const { appContext } = getCurrentInstance();
const { $ComJs } = appContext.config.globalProperties; //全局属性解构

let map = null;
let mapHat = null;

// 休息日热力数据
let holiday = [];
// 工作日热力数据
let workday = [];
let typeObj = { hour: new Date().getHours(), type: 1 };
let size = ref({ min: 11, max: 18 }); // 地图缩放层级

/** 当前定位的icon */
let locationMarker = null;

// 热力配置
let heatConfig = null;
let maxCount = 50;
let heatLevel = 17;
let heatRadius = 78;

let curZoom = ref(16);

// 测试
let axiosTime = ref(0);
let totleTime = ref(0);
let cellNum = ref(0);

/**
 * 地图初始化回调
 * @param {TMap.map} m - 返回的地图实例
 */
async function initMap(m) {
  map = m;
  // map.setZoom(18);
  handleGetLocation();

  const res = await getHeatConfig();
  if (res.code === 0) {
    heatConfig = res.data;
  }
  console.log("initMap", res);
  var heat = new window.TMap.visualization.Heat({
    max: maxCount, // 热力最强阈值
    min: 0, // 热力最弱阈值
    height: 0, // 峰值高度
    radius: 10,
    distanceUnit: "meter",
    enableAggregation: true,
    transitAnimation: {
      duration: 500,
    },
    gradientColor: {
      // 渐变颜色
      // 0.5: "#0000ff",
      // 0.65: "#75d3f8",
      // 0.7: "#00ff00",
      // 0.9: "#ffea00",
      // 1.0: "#ff0000",

      0.1: "#0000ff",
      0.25: "#75d3f8",
      0.4: "#00ff00",
      0.55: "#ffea00",
      0.7: "#ff0000",
    },
  }).addTo(map);

  mapHat = heat;
  map.on("bounds_changed", boundsChangedFn);
  boundsChangedFn();
}

/** 请求poi点 */
const boundsChangedFn = debounce(() => {
  try {
    const zoom =
      map.getZoom() > size.value.max
        ? size.value.max
        : map.getZoom() < size.value.min
        ? size.value.min
        : map.getZoom();
    // console.log(zoom);
    curZoom.value = zoom;
    const bounds = map.getBounds();
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    const neLat = ne.getLat();
    const neLng = ne.getLng();
    const swLat = sw.getLat();
    const swLng = sw.getLng();
    const nw = [neLat, swLng]; // 西北
    const se = [swLat, neLng]; // 东南

    let time = new Date().getTime();

    getHeat({
      top_left: nw.join(","),
      bottom_right: se.join(","),
      level: Math.floor(zoom * 100) / 100,
    })
      .then((res) => {
        axiosTime.value = new Date().getTime() - time;
        const { holiday_heat, workday_heat, level } = res.data;
        console.log("level", zoom);
        console.log("workday_heat", workday_heat.length);
        console.log("holiday_heat", holiday_heat.length);
        heatLevel = level;
        // heatRadius = heatConfig.level[level];
        holiday = holiday_heat.map((item) => {
          const { coord, heat } = item;
          const latLng = coord.split(",");
          return {
            lng: +latLng[1],
            lat: +latLng[0],
            heat,
            // maxCount,
          };
        });
        workday = workday_heat.map((item) => {
          const { coord, heat } = item;
          const latLng = coord.split(",");
          return {
            lng: +latLng[1],
            lat: +latLng[0],
            heat,
            // maxCount,
          };
        });

        handleRenderHeat(time);
      })
      .catch((err) => {
        console.log("getHeat", err);
      });
  } catch (error) {
    console.log("boundsChangedFn", error);
  }
}, 500);

const updataHot = (obj) => {
  typeObj = obj;
  handleRenderHeat(Date.now());
};

/** 渲染热力图 */
const handleRenderHeat = async (time) => {
  try {
    const geocoder = new window.TMap.service.Geocoder(); // 新建一个正逆地址解析类
    const addRes = await geocoder.getAddress({ location: map.getCenter() }); // 将给定的坐标位置转换为地址
    if (addRes.status === 0) {
      const { city } = addRes.result.address_component;
      const district1 = new window.TMap.service.District();
      const codeRes = await district1.search({
        keyword: city,
      });
      let cityCode = codeRes.result[0][0].id;
      // const cityNum = cityCode / 1000;
      // let rep = /[\\.]/;
      // if (!rep.test(cityNum)) {
      //   cityCode = +cityCode + 100;
      // }
      const { hour, type } = typeObj;
      workday = workday.map((item) => {
        return {
          ...item,
          count: item.heat[hour],
        };
      });
      holiday = holiday.map((item) => {
        return {
          ...item,
          count: item.heat[hour],
        };
      });
      let orderTmp = [];
      const { workday_heat, holiday_heat } = heatConfig.heat_standard[cityCode];
      if (type === 2) {
        orderTmp = workday;
        maxCount = workday_heat > holiday_heat ? workday_heat : holiday_heat;
      } else if (type === 3) {
        orderTmp = holiday;
        maxCount = workday_heat > holiday_heat ? workday_heat : holiday_heat;
      } else {
        maxCount = workday_heat > holiday_heat ? workday_heat : holiday_heat;
        orderTmp = [...workday, ...holiday];
        orderTmp = orderTmp.map((item) => {
          return {
            ...item,
            count: item.count / 2,
          };
        });
      }
      console.log("--maxCount11", maxCount, orderTmp);
      // console.log("maxCount", maxCount);
      if (maxCount < 10) {
        maxCount = 10;
      }
      let r = 1;
      for (let i = 0; i < 20 - heatLevel; i++) {
        r *= 4;
      }
      maxCount *= r;
      console.log("--maxCount", maxCount, r, heatLevel);
      mapHat.setMax((maxCount / 24) * 0.7 || 10);
      heatRadius = heatConfig.level[heatLevel];
      // console.log(heatRadius);
      mapHat.setRadius(heatRadius);
      mapHat.setData(orderTmp);

      cellNum.value = orderTmp?.length || 0;
      totleTime.value = new Date().getTime() - time;
    }
  } catch (error) {
    console.log("handleRenderHeat", error);
  }
};

let isAppLocation = true;
/** 获取当前位置 */
async function handleGetLocation() {
  getAppLocation({
    succeed: getWatchPosition,
    isAlert: isAppLocation,
    fail: getWatchPosition,
  });
  isAppLocation = false;
}
/** 监听定位  */
function getWatchPosition(lat, lng) {
  try {
    const centerPoint = new window.TMap.LatLng(lat, lng);
    const zoom = map.getZoom();
    if (zoom < 11) {
      map.setZoom(16);
    }
    map.setOffset({
      x: 0,
      y: -($ComJs.bili * 2),
    });
    map.panTo(centerPoint);
    if (locationMarker) {
      locationMarker.position = centerPoint;
      locationMarker.setMap(map);
      locationMarker.updateDOM();
    } else {
      import("@/util/domOverlay").then(({ LocationDom }) => {
        locationMarker = new LocationDom({
          map,
          position: centerPoint,
          radius: 16,
        });
      });
    }
  } catch (error) {
    console.log("getWatchPosition", error);
  }
}
</script>
<style lang="scss" scoped>
.do-pages-hour-hot {
  width: 100%;
  height: 100%;
  .map-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .foot {
    width: 100%;
    position: absolute;
    background-color: #fff;
    bottom: calc($tabbar_hegiht + constant(safe-area-inset-bottom));
    bottom: calc($tabbar_hegiht + env(safe-area-inset-bottom));
    z-index: 10;
    border-radius: 0.32rem 0.32rem 0 0;
    border-bottom: 0.02rem solid rgba(2, 4, 15, 0.12);
  }

  .location {
    position: absolute;
    right: 0.32rem;
    top: -0.88rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0.64rem;
    height: 0.64rem;
    background: #ffffff;
    box-shadow: 0 0.04rem 0.08rem 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.16rem;

    img {
      width: 0.36rem;
      height: 0.36rem;
    }
  }
}
</style>
