export const placeholder = {
  1: "输入店铺名称，如“星巴克”",
  2: "输入商场名称，如“朝阳大悦城”",
  3: "输入品牌名称，如“奈雪的茶”",
};

export const live = [
  {
    poi_id: 3490619964,
    poi_name: "安居高新花园项目",
    coord: [113.950019, 22.534066],
    distance: "179.2",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 1835746816,
    poi_name: "中兴员工宿舍",
    coord: [113.946715, 22.53566],
    distance: "303.3",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 1407700394,
    poi_name: "金地商置",
    coord: [113.949754, 22.529318],
    distance: "475.1",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 982554275,
    poi_name: "高新园人才公寓",
    coord: [113.95322, 22.534702],
    distance: "513.4",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 2713414439,
    poi_name: "南山公共自行车深大沧海校区宿舍站",
    coord: [113.942988, 22.530703],
    distance: "635.9",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 2894499986,
    poi_name: "金地草莓社区",
    coord: [113.948374, 22.527638],
    distance: "643.2",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 342650313,
    poi_name: "深圳大学沧海校区-低音",
    coord: [113.942263, 22.531128],
    distance: "682.9",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 3734462116,
    poi_name: "深圳Home Home Sweet Home普通公寓",
    coord: [113.950825, 22.52765],
    distance: "685.3",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 772790442,
    poi_name: "金地威新国际公寓",
    coord: [113.950729, 22.527497],
    distance: "697.9",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 4117445950,
    poi_name: "高新东片区楼宇自治联盟",
    coord: [113.952437, 22.538562],
    distance: "707.8",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 938670615,
    poi_name: "春笛",
    coord: [113.94172, 22.531295],
    distance: "728.5",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 138371836,
    poi_name: "冬筑",
    coord: [113.942257, 22.529539],
    distance: "767.9",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 2761317751,
    poi_name: "阳光粤海",
    coord: [113.940762, 22.533276],
    distance: "786.4",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 4085892860,
    poi_name: "粤海门村",
    coord: [113.940656, 22.533461],
    distance: "797.0",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 2498768998,
    poi_name: "珑御府",
    coord: [113.949434, 22.52625],
    distance: "803.2",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 896249175,
    poi_name: "高新区社区",
    coord: [113.95413, 22.528361],
    distance: "807.3",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 3589431864,
    poi_name: "深圳悦家服务公寓(深大店)",
    coord: [113.94044, 22.532757],
    distance: "822.9",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 3286487196,
    poi_name: "滨福庭园",
    coord: [113.948468, 22.525971],
    distance: "828.5",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 1450724390,
    poi_name: "熙美行政公寓(后海科技园店)",
    coord: [113.940383, 22.532774],
    distance: "828.6",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 1600019693,
    poi_name: "阳光带海滨城彩虹之岸",
    coord: [113.94672, 22.525999],
    distance: "844.9",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 1810039180,
    poi_name: "海怡东方花园",
    coord: [113.951292, 22.526098],
    distance: "863.2",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 968784079,
    poi_name: "朗景园",
    coord: [113.950541, 22.540996],
    distance: "871.8",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 1277146678,
    poi_name: "深圳市软件产业基地公寓",
    coord: [113.942656, 22.527574],
    distance: "881.6",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 2730973845,
    poi_name: "91年后男大学生公寓",
    coord: [113.943896, 22.540454],
    distance: "906.9",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 3092144442,
    poi_name: "中信海阔天空",
    coord: [113.94461, 22.526083],
    distance: "907.2",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 2206283864,
    poi_name: "中信·海阔天空雅居",
    coord: [113.944901, 22.5259],
    distance: "913.3",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 2066758356,
    poi_name: "深南花园",
    coord: [113.944615, 22.540933],
    distance: "919.7",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 2116738195,
    poi_name: "滨福世纪",
    coord: [113.949204, 22.525055],
    distance: "933.0",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 324074275,
    poi_name: "熙湾俊庭",
    coord: [113.948427, 22.524974],
    distance: "939.4",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 3935321662,
    poi_name: "朗景阁",
    coord: [113.949094, 22.542023],
    distance: "959.6",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 2142074513,
    poi_name: "汇景豪苑海悦阁",
    coord: [113.9439, 22.54104],
    distance: "963.3",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 2454412903,
    poi_name: "风槐斋",
    coord: [113.938874, 22.533042],
    distance: "981.2",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 2698170334,
    poi_name: "阳光带海滨城一期",
    coord: [113.953574, 22.52586],
    distance: "988.1",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 869596535,
    poi_name: "阳光带海滨城锦缎之滨",
    coord: [113.953574, 22.52586],
    distance: "988.1",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 3916049356,
    poi_name: "深圳思橙公寓",
    coord: [113.951934, 22.541673],
    distance: "989.1",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 2383164795,
    poi_name: "汇景豪苑",
    coord: [113.943179, 22.540927],
    distance: "990.2",
    avg_sale: "",
    avg_rent_price: "",
  },
  {
    poi_id: 1051769148,
    poi_name: "银桦斋",
    coord: [113.938742, 22.532714],
    distance: "997.0",
    avg_sale: "",
    avg_rent_price: "",
  },
];
