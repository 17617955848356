<template>
  <div class="do-page-brand-map-list">
    <base-nav-bar
      @click-left="handleGoBack"
      left-arrow
      title="店铺列表"
    ></base-nav-bar>
    <van-popup v-model:show="cityShowPicker" round position="bottom">
      <van-picker
        class="do-com-picker"
        :columns="cityColumns"
        :columns-field-names="{
          text: 'name',
          values: 'code',
          children: 'child',
        }"
        title="选择地区"
        @cancel="cityShowPicker = false"
        @confirm="onCityConfirm"
      />
    </van-popup>
    <div class="select-text">
      <div @click="cityShowPicker = true">
        <span>选择范围：</span>
        <span>{{ city.name }}</span>
        <span><van-icon name="play" /></span>
      </div>
    </div>
    <div class="table table-title">
      <div class="table-cell table-title-cell__id">名称</div>
      <div class="table-cell table-title-cell__brand">月人流量</div>
    </div>
    <base-bscroll
      class="bscroll"
      :options="{ bounce: true, pullUpLoad: true }"
      @refScroll="handleCallbacScroll"
    >
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoadList"
      >
        <!-- <van-cell v-for="item in list" :key="item" :title="item" /> -->
        <div
          class="table"
          v-for="item in list"
          :key="item"
          @click="() => goAround(item)"
        >
          <div class="table-cell table-cell__id">{{ item.poi_name }}</div>
          <div class="table-cell table-cell__brand">{{ item.flow }}</div>
        </div>
      </van-list>
    </base-bscroll>
  </div>
</template>

<script setup>
import { onMounted, computed, ref, nextTick, onActivated } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useState } from "@/hook/useState";
import { getBrands } from "@/axios/search";
import { useStore } from "vuex";

// 获取 store 的 common/user 模块
const route = useRoute();
const store = useStore();
const storeState = useState(["common"]);
const router = useRouter();
let page = 1;
let query = null;

const city = ref({
  name: "全国",
  code: "",
});
const cityShowPicker = ref(false);

const list = ref([]);
const loading = ref(false);
const finished = ref(false);
let isGo = false; // 判断当前页面是否跳转到下个页面

let betterScroll = null;

onMounted(() => {
  store.dispatch("getCity");
  query = route?.query;
});

// 判断是否回退不刷新
onActivated(() => {
  query = route?.query;
  if (!isGo) {
    city.value = {
      name: "全国",
      code: "",
    };
    page = 1;
    finished.value = false;
    loading.value = true;
    list.value = [];
    onLoadList();
  }
});

// 跳转查看poi详情
const goAround = (data) => {
  isGo = true;
  router.push({
    path: "/datamap",
    query: { list: JSON.stringify(data) },
  });
};

/** 城市递归 */
const loop = (list, level) => {
  let data = [];
  if (level === 1) {
    data.push({ code: "9999", name: "全国", child: [] });
  } else {
    data.push({ code: "", name: "", child: null });
  }
  let levels = ++level;

  let cope = list.map((item) => {
    if (level < 3) {
      return { ...item, child: loop(item.child, levels) };
    } else {
      return { ...item, child: null };
    }
  });
  return [...data, ...cope];
};

// 获取省市
const cityColumns = computed(() => {
  // store 城市数据
  const citys = storeState.common.value.citys;
  return loop(citys, 1);
});

/** 返回上一级 */
const handleGoBack = () => {
  router.back();
  isGo = false;
};

/** 城市选择完成回调 */
const onCityConfirm = (selectedOptions) => {
  selectedOptions = selectedOptions.filter((el) => el && el?.code);

  cityShowPicker.value = false;
  city.value = {
    name: selectedOptions.map((el) => el.name).toString(),
    code: selectedOptions.map((el) => el.code).toString(),
  };
  page = 1;
  list.value = [];
  loading.value = true;
  nextTick(() => {
    onLoadList();
  });
};

/** 加载数据 */
const onLoadList = async () => {
  const res = await getBrands({
    pb_id: query.pb_id,
    region: city.value.code !== "9999" ? city.value.code : "",
    page: page,
    page_size: 20,
  });
  // 异步更新数据

  if (res.code === 0) {
    list.value.push(...res.data.items);
    // 加载状态结束
    loading.value = false;
    page = ++page;
    // 数据全部加载完成
    finished.value = page * 20 >= res.data.total;
    nextTick(() => {
      betterScroll?.refresh();
    });
  }
};

/** 返回 betterScroll 实例 */
const handleCallbacScroll = (scroll) => {
  betterScroll = scroll;
  scroll.on("pullingUp", () => {
    if (finished.value) return;
    loading.value = true;
    onLoadList();

    nextTick(() => {
      betterScroll?.finishPullUp();
      betterScroll?.refresh();
    });
  });
};
</script>

<style lang="scss" scoped>
.do-page-brand-map-list {
  background-color: #fff;
  .bscroll {
    height: calc(
      100vh - $navbar_hegiht - constant(safe-area-inset-top) - 2 *
        (2 * var(--van-cell-vertical-padding) + var(--van-cell-line-height))
    );
    height: calc(
      100vh - $navbar_hegiht - env(safe-area-inset-top) - 2 *
        (2 * var(--van-cell-vertical-padding) + var(--van-cell-line-height))
    );
    overflow: hidden;
    padding: 0 0.24rem;
  }
  .select-text {
    display: flex;
    align-items: center;
    padding: 0 0.32rem;
    height: 0.84rem;
    font-size: 0.28rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #03050f;
    @include border-bottom(rgba(2, 4, 15, 0.12));
    i {
      margin-left: 0.16rem;
      transform: rotate(90deg);
    }
  }

  .van-row {
    @include border-bottom(rgba(2, 4, 15, 0.12));
  }

  .table {
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
    color: var(--van-cell-text-color);
    font-size: var(--van-cell-font-size);
    line-height: var(--van-cell-line-height);
    // height: 0.72rem;
    background: #f5f8fc;
    border-radius: 0.08rem;
    padding: var(--van-cell-vertical-padding) 0.16rem;
    .table-cell {
      padding: 0 0.1rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-cell__id {
      width: 60%;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(3, 5, 15, 0.85);
    }
    &-cell__brand {
      width: 40%;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(3, 5, 15, 0.85);
    }
  }
  .table + .table {
    margin-top: 0.16rem;
  }
  .table-title {
    background-color: #fff;
    padding: var(--van-cell-vertical-padding) var(--van-cell-horizontal-padding);
    .table-title-cell__id {
      width: 60%;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(3, 5, 15, 0.25);
      font-size: 0.28rem;
    }
    .table-title-cell__brand {
      width: 40%;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(3, 5, 15, 0.25);
      font-size: 0.24rem;
    }
  }
}
</style>
