<template>
  <div class="do-pages-brand-map" ref="pagewrapRef">
    <BaseMap @init="initMap" />
    <div
      class="nav-bar"
      :style="{ paddingTop: $Hbuild.getStatusBarHeight() + 'px' }"
      @click="onbacks"
    >
      <van-icon name="arrow-left" size="0.45rem" />
    </div>
    <base-bottom-drawer
      ref="poiRef"
      v-if="drawerOptions"
      :options="drawerOptions"
      :loader="loader"
      loaderText="数据获取中"
    >
      <template v-slot:head>
        <Header
          :info="{
            title: brandInfo?.name,
            logo: brandInfo?.logo,
            poi_id: brandInfo?.id,
          }"
          @back="onbacks"
          :collect="collect"
          :close="true"
          :imgRadius="true"
        ></Header>
      </template>
      <div>
        <info
          :info="brandInfo"
          @refresh="(listData) => openDrawer(listData)"
        ></info>
      </div>
    </base-bottom-drawer>
  </div>
</template>

<script setup>
import { getCurrentInstance, onMounted, ref, onActivated, nextTick } from "vue";
import { useRouter, useRoute } from "vue-router";
import Info from "./component/info";
import Drawer from "@/util/drawer";
import { province } from "./province";
import Header from "@/views/datamap/component/header/index.vue";

const { appContext } = getCurrentInstance();
const { $ComJs, $Hbuild } = appContext.config.globalProperties; //全局属性解构
const drawerOptions = ref(false);
const router = useRouter();
const route = useRoute();
const brandInfo = ref({}); // 品牌信息
const loader = ref(false); // 抽屉加载
let area = null;

/** 获取页面DOM */
const pagewrapRef = ref(null);

/** 信息抽屉 */
const poiRef = ref(null);

/** 地图实例 */
let map = null;

/** 创建抽屉切换实例 */
const drawer = new Drawer();
/** 添加抽屉组件和id */
drawer.add(1, poiRef);
let dataList = null;

// // 判断当前页面是否回退销毁重新刷新数据
let destroy = false;
let brandList = [];

onMounted(() => {
  const height =
    pagewrapRef.value.clientHeight -
    $ComJs.bili * 0.88 -
    $Hbuild.getStatusBarHeight();
  const { top, middle, bottom } = {
    top: height, // 高度
    middle: Math.abs(height * 0.5 - height), // 最小高度
    bottom: Math.abs(height - $ComJs.bili * 1.75), // 最小高度
  };

  // 抽屉配置
  drawerOptions.value = {
    height: top, // 高度
    shrinkMiddle: middle, // 最小高度
    shrink: bottom, // 最小高度
  };
  drawer.setPositionOption({
    top: 0,
    middle,
    bottom,
    hidden: Math.abs(height) + $ComJs.bili * 0.88,
  });
  setTimeout(() => {
    brandInfo.value = route?.query;
    loader.value = true;
    drawer.open(1, "middle");
    drawer.scrollTo(0, 0);
  }, 500);
});

onActivated(() => {
  if (destroy) {
    destroy = false;
    brandInfo.value = route?.query;
    loader.value = true;
    drawer.open(1, "middle");
    drawer.scrollTo(0, 0);
  } else {
    area = null;
    destroy = false;
    onLoadMapColor(brandList);
  }
});

const onbacks = () => {
  area?.destroy();
  map?.destroy();
  destroy = true;
  area = null;
  brandList = [];
  dataList = null;
  router.back();
};

const openDrawer = (colorList) => {
  drawer.scrollRefresh();
  loader.value = false;
  if (!map) {
    dataList = colorList;
    return;
  }
  if (colorList) {
    onLoadMapColor(colorList);
  }
};

/**
 * 地图初始化回调
 * @param {TMap.map} m - 返回的地图实例
 */
function initMap(m) {
  map = m;
  map.setZoom(3);
  const latLng = new window.TMap.LatLng(16.790724118097163, 104.30976109822575);
  map.setDraggable(false);
  map.setScrollable(false);
  map.setDoubleClickZoom(false);
  map.setMaxZoom(3);
  map.setMinZoom(3);
  map.setBaseMap({
    type: "vector",
    features: ["base", "building3d"], // 展示道路和底面、建筑物
  });

  map.panTo(latLng);
  if (dataList) {
    openDrawer(dataList);
  }
}

// 渲染地图省份名称
const setLabel = (position, text) => {
  var center = new window.TMap.LatLng(position.lat, position.lng); // 设置中心点坐标
  window.amap = map;
  new window.TMap.MultiLabel({
    map: map,
    styles: {
      label: new window.TMap.LabelStyle({
        color: "rgba(2, 4, 15, 0.85)", // 颜色属性
        size: 12, // 文字大小属性
        offset: { x: 0, y: 0 }, // 文字偏移属性单位为像素
        angle: 0, // 文字旋转属性
        alignment: "center", // 文字水平对齐属性
        verticalAlignment: "middle", // 文字垂直对齐属性
      }),
    },
    geometries: [
      {
        id: "label", // 点图形数据的标志信息
        styleId: "label", // 样式id
        position: center, // 标注点位置
        content: text, // 标注文本
        properties: {
          // 标注点的属性数据
          title: "label",
        },
      },
    ],
  });
};

// 渲染地图省份颜色
const onLoadMapColor = (colorlist) => {
  brandList = colorlist;
  if (area || !colorlist.length) return;
  colorlist = colorlist.sort((a, b) => b.num - a.num);
  let size = [colorlist[0].num, colorlist[colorlist.length - 1].num];
  let colorObj = {};
  colorlist.forEach((el) => {
    colorObj[el.province] = el.num;
  });
  let stylesNmae = [];
  for (let index = 1; index < 5; ++index) {
    stylesNmae.push(size[0] * (index * 0.25));
  }

  let copeProvince = JSON.parse(JSON.stringify(province));
  var paths = [];
  copeProvince.forEach((item, index) => {
    //
    let province = colorlist.findIndex((el) => item.fullname === el.province);
    let styleId = null; //数据所有的id值
    if (province !== -1) {
      let num = colorlist[province].num;
      let mate = stylesNmae.filter((el) => el <= num);
      let colorIndex = stylesNmae.indexOf(mate[mate.length - 1]);
      colorIndex = colorIndex === -1 ? 0 : colorIndex;
      styleId = `styel${colorIndex + 1}`;
    } else {
      styleId = "styel0";
    }

    const path = []; // TODO
    setLabel(item.location, item.name);
    item.polygon.forEach((p) => {
      path.push([p.reverse()]);
    }); //进行纬经度进行调换位置

    paths.push({
      path, //经纬度点串
      styleId, //id=styleId
    });
  });

  area = new window.TMap.visualization.Area({
    styles: {
      styel0: {
        fillColor: "rgba(0,0,0,0)", //设置区域颜色
        strokeColor: "rgba(0,0,0,0)", //设置区域边线颜色
      },
      //设置区域图样式
      styel1: {
        fillColor: "#c2daff", //设置区域颜色
        strokeColor: "rgba(2, 4, 15, 0.45)", //设置区域边线颜色
      },
      styel2: {
        fillColor: "#8ab8ff", //设置区域颜色
        strokeColor: "rgba(2, 4, 15, 0.45)", //设置区域边线颜色
      },
      styel3: {
        fillColor: "#609fff", //设置区域颜色
        strokeColor: "rgba(2, 4, 15, 0.45)", //设置区域边线颜色
      },
      styel4: {
        fillColor: "#3585ff", //设置区域颜色
        strokeColor: "rgba(2, 4, 15, 0.45)", //设置区域边线颜色
      },
    },
  })
    .setData(paths)
    .addTo(map);
};
</script>

<style lang="scss">
.do-com-safearea {
  bottom: 0 !important;
}
.asfsafa {
  position: relative;
}
.content {
  height: 900px;
}
.vantIInpui {
  position: fixed;
}
.do-input {
  background: red;
}
.search {
  height: 100px;
  width: 100%;
}
.do-pages-brand-map {
  // position: relative;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  bottom: 0;
  .nav-bar {
    background: rgba(0, 0, 0, 0);
    position: absolute;
    top: 0;
    z-index: 999;
    width: 1.17rem;
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
    padding-left: 0.32rem;
    padding-right: 0.32rem;
    i {
      display: flex;
      align-items: center;
      height: 0.88rem;
    }
  }
  .do-com-drawer {
    background-color: #f5f8fc;
  }
}
.location {
  position: absolute;
  right: 0.32rem;
  top: -0.88rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.64rem;
  height: 0.64rem;
  background: #ffffff;
  box-shadow: 0 0.04rem 0.08rem 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.16rem;

  img {
    width: 0.36rem;
    height: 0.36rem;
  }
}

@keyframes opacityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.label-animation {
  animation: opacityAnimation 0.3s forwards;
}
</style>
