<template>
  <div class="do-page-collect-row">
    <div class="date">{{ props?.info?.create_ts }}</div>
    <div class="content">
      <div class="title">
        <div class="img">
          <img :src="props?.info?.logo" v-if="props?.info?.logo" alt="" />
        </div>
        <div class="box">
          <div>
            <div class="name">{{ props?.info?.poi_name || "-" }}</div>
            <div class="address">{{ props?.info?.address || "-" }}</div>
          </div>
          <img
            :src="require('@/assets/images/my/del.png')"
            @click="setCollect"
            class="del"
            alt=""
          />
        </div>
      </div>
      <div class="map">
        <img :src="props?.info?.poi_static_map" alt="" @click="goPoiInfo" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import { delFavorites } from "@/axios/poiSearch";
import { Toast } from "vant";
import { useRouter } from "vue-router";
const props = defineProps(["info"]);
const emit = defineEmits("cb");
const router = useRouter();

const goPoiInfo = () => {
  router.push({
    path: "/datamap",
    query: { list: JSON.stringify({ ...props.info, poi_id: props.info.p_id }) },
  });
};

// 收藏
const setCollect = async (e) => {
  e.stopPropagation();
  try {
    let res = await delFavorites(props.info.id);
    if (res.code === 0) {
      Toast({
        position: "bottom",
        message: "取消收藏成功",
      });
      emit("cb", props.info);
    }
  } catch (error) {
    if (error) {
      Toast({
        position: "bottom",
        message: error.msg || "获取信息失败",
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.do-page-collect-row {
  padding: 0.24rem 0.24rem 0;
  .date {
    // padding-top: 0.24rem;
    font-size: 0.24rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(2, 4, 15, 0.45);
  }
  .content {
    margin-top: 0.16rem;
    padding: 0.16rem;
    height: 100%;
    background: #ffffff;
    border-radius: 0.16rem;
    .title {
      display: flex;
      align-items: center;
      .img {
        width: 0.72rem;
        height: 0.72rem;
        img {
          width: 0.72rem;
          height: 0.72rem;
          border-radius: 50%;
        }
      }
      .box {
        position: unset;
        display: grid;
        margin-left: 0.16rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        & > div {
          width: calc(100% - 0.64rem);
        }
        .name {
          font-size: 0.32rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(2, 4, 15, 0.85);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .address {
          font-size: 0.24rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(2, 4, 15, 0.45);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .del {
          width: 0.64rem;
          height: 0.64rem;
        }
      }
    }
    .map {
      margin-top: 0.24rem;
      widows: 100%;
      height: 2.56rem;
      border-radius: 0.16rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
