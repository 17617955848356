<template>
  <div class="do-page-search-brand-end">
    <!-- <div class="bg" v-if="loading"><van-loading size="50" /></div> -->
    <base-nav-bar
      :backText="true"
      title="搜索品牌结果"
      @back="() => router.back()"
    ></base-nav-bar>
    <div class="search-box">
      <div class="text">
        <img :src="require('@/assets/datamap/search-data.png')" alt="" />
        <span>{{ query?.value }}</span>
      </div>
      <div class="text">找到{{ total }}个</div>
    </div>
    <div class="scroll-box">
      <!-- $ComJs.bili * 2.8 === search-box 高度 -->

      <base-bscroll
        :options="{ bounce: true, pullUpLoad: true }"
        class="safearea-tabbar"
        @refScroll="refScroll"
        :style="{
          top: $Hbuild.getStatusBarHeight() + $ComJs.bili * 2.38 + 'px',
          height: `calc(100vh - ${
            $Hbuild.getStatusBarHeight() + $ComJs.bili * 2.38 + 'px'
          })`,
        }"
      >
        <div class="bscroll-box" v-if="classify?.length">
          <Row
            v-for="(user, i) in classify || []"
            :key="i"
            :info="user"
            title="name"
            :skeleton="user.skeleton"
            img="logo"
            :row-style="{ height: '1.08rem' }"
            @submt="clickShop"
          ></Row>
        </div>
        <base-nodata v-else description="暂无数据"></base-nodata>
        <div v-if="loading" class="information-loding">
          <div v-if="total > classify.length" class="information-loding-more">
            加载中
          </div>
          <div v-else class="information-loding-more">没有更多了</div>
        </div>
      </base-bscroll>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, getCurrentInstance, nextTick } from "vue";
import Row from "@/views/datamap/poi-info/component/row";
import { useRouter, useRoute } from "vue-router";
import { search } from "@/axios/home";
import { Toast } from "vant";

const { appContext } = getCurrentInstance();
const { $Hbuild } = appContext?.config?.globalProperties || {}; //全局属性解构

const router = useRouter();
const route = useRoute();

let loading = ref(false);
let query = ref(null); // 跳转传的参数
let page = 1;
let total = ref(0);

// 列表
const classify = ref([
  { skeleton: true },
  { skeleton: true },
  { skeleton: true },
  { skeleton: true },
  { skeleton: true },
  { skeleton: true },
  { skeleton: true },
]);
const bscroll = ref(null); // 内容实例

onMounted(async () => {
  query.value = route?.query;
  page = 1;
  getData(true);
});

function refScroll(refSc) {
  bscroll.value = refSc;
  refSc.on("pullingUp", () => {
    if (!total.value === classify.length) return;
    loading.value = true;
    nextTick(() => {
      bscroll?.value?.finishPullUp();
      bscroll?.value?.refresh();
    });
    getData();
  });
}

const getData = async (init) => {
  try {
    const res = await search({
      search: query.value.value,
      page_size: 20,
      type: query.value.type,
      page: page,
    });
    if (init) {
      classify.value = res.data.items;
    } else {
      classify.value = [...classify.value, ...res.data.items];
    }
    total.value = res.data.total;

    page = ++page;
    loading.value = false;

    nextTick(() => {
      bscroll?.value?.finishPullUp();
      bscroll?.value?.refresh();
    });
  } catch (error) {
    if (error) {
      Toast({
        position: "bottom",
        message: error.msg || "获取信息失败",
      });
    }
    loading.value = false;
  }
};

const clickShop = (e) => {
  let obj = { ...e, logo: null, img: e.logo };
  router.push({
    path: "/search/brand-map",
    query: {
      ...obj,
      type: query.value.type,
    },
  });
};
</script>
<style lang="scss">
.do-page-search-brand-end {
  width: 100%;
  height: 100%;
  background-color: #fff;

  .information-loding {
    width: 100%;
    height: 0.72rem;
    text-align: center;
    font-weight: 400;
    color: rgba(2, 4, 15, 0.45);
  }
  & > .search-box {
    padding: 0;

    .text {
      display: flex;
      align-items: center;
      height: 0.88rem;
      font-weight: 500;
      font-size: 0.28rem;
      color: rgba(2, 4, 15, 0.45);
      padding: 0.26rem 0.32rem 0.26rem;
      img {
        width: 0.56rem;
        height: 0.56rem;
        margin-right: 0.24rem;
      }
      span {
        color: rgba(2, 4, 15, 0.85);
        font-weight: bold;
        margin-right: 0.16rem;
      }
    }
    .text:first-child {
      @include border-bottom(rgba(2, 4, 15, 0.12));
    }
  }
  .bscroll-box {
    padding: 0.24rem;
  }
  .scroll-box {
    position: relative;
    height: 100%;
    & > .do-com-scroll-wrapper {
      overflow: hidden;
    }
    .radius {
      img {
        border-radius: 50%;
      }
    }
  }
  .safearea-tabbar {
    @include fixedCom(0, 0, 0, 0);
  }
  .do-row-tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 1.28rem;
    .img {
      width: 0.64rem;
      height: 0.64rem;
      img {
        width: 0.64rem;
        height: 0.64rem;
        border-radius: 0.08rem;
      }
    }
    & > .content {
      margin-left: 0.24rem;
      height: initial;
      width: calc(100% - 0.6rem);
      .title {
        font-weight: bold;
        color: rgba(2, 4, 15, 0.85);
        height: 0.4rem;
        line-height: 0.4rem;
        font-size: 0.28rem;
        white-space: nowrap;
        width: 5rem;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .address {
        font-weight: 500;
        color: rgba(2, 4, 15, 0.45);
        height: 0.4rem;
        line-height: 0.4rem;
        font-size: 0.28rem;
        width: 5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
