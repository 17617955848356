<!-- 获取验证码 -->
<template>
  <div class="do-page-authcode">
    <base-nav-bar @click-left="router.go(-1)" left-arrow title="获取验证码">
    </base-nav-bar>
    <base-bscroll :safeareaNavbar="true" :options="{ bounce: true }">
      <div class="do-page-authcode-content">
        <div class="title">请输入四位验证码</div>
        <div class="tip">验证码已发送至： {{ phone }}</div>
        <div class="code-wrap" @click="getFocus">
          <span
            v-for="(item, index) in 4"
            :class="{
              curr: String(codeInput).length === index && index && index !== 0,
            }"
            :key="item"
          >
            {{
              String(codeInput).length > index
                ? String(codeInput).charAt(index)
                : ""
            }}
          </span>
          <input
            type="number"
            v-model="codeInput"
            class="inputPart"
            id="authCode"
            maxlength="4"
            @focus="$ComJs.android"
            :disabled="String(codeInput).length == 4"
          />
        </div>
        <div
          @click="anewAuthCode"
          :class="`time-text  ${forbidTime <= 0 ? 'time-text-curr' : ''}`"
        >
          {{ forbidTime !== 0 ? forbidTime + "s" : "" }} 重新获取
        </div>
      </div>
    </base-bscroll>
  </div>
</template>
<script setup>
import { useRouter, useRoute } from "vue-router";
import { nextTick, ref, onMounted, onUnmounted, watch } from "vue";
import { getAccountCaptcha, sendLogin, sendUpdataPhpme } from "@/axios/user";
import { Toast } from "vant";
import { useStore } from "vuex";
const store = useStore();
const router = useRouter();
const route = useRoute();
// 验证码
const codeInput = ref("");
// 禁止重新获取验证码时间dsssds
const forbidTime = ref(60);
// 手机号
const phone = route.params.id.split("-")[0];
// 登陆 login  /  更改手机 updataPhone
const type = route.params.id.split("-")[1];

let timer = null;

onMounted(() => {
  computeTime();
});

// 验证码输入完毕
watch(codeInput, async (newQuestion) => {
  if (String(newQuestion).length === 4) {
    submit();
  }
});

// 获取验证码
async function getAuthCode() {
  const toast = Toast.loading({ closeOnClick: false, duration: 0 });
  try {
    await getAccountCaptcha({ phone: phone });
    toast.clear();
    setTimeout(() => {
      Toast({
        message: "已重新发送验证码，请注意查收",
      });
    }, 200);
  } catch (error) {
    toast.clear();
    Toast({
      message: error.msg || "发送失败，未知错误",
    });
  }
}

// 提交方法
async function submit() {
  // 登陆类型
  if (type === "login") {
    try {
      // 登录类型，写死 1
      const res = await sendLogin({
        type: 1,
        phone: phone,
        captcha: String(codeInput.value),
      });
      // 修改token 和 手机号
      store.commit("setToken", res.data.token);
      store.commit("setPhone", res.data.phone);
      store.dispatch("getCity");
      store.dispatch("getIndustry");
      router.replace("/");
    } catch (error) {
      if (error) {
        Toast({
          message: error.msg || "登陆失败，未知错误",
        });
      }
      codeInput.value = "";
    }
  } else if (type === "updataPhone") {
    try {
      await sendUpdataPhpme({
        phone: phone,
        captcha: String(codeInput.value),
      });
      // 修改token 和 手机号
      store.commit("setPhone", phone);
      store.dispatch("getAccountInfo");
      Toast({
        message: "手机号更改成功",
      });
      router.replace("/my");
    } catch (error) {
      if (error) {
        Toast({
          message: error.msg || "更改失败，未知错误",
        });
      }
      codeInput.value = "";
    }
  }
}

function getFocus() {
  nextTick(() => {
    document.getElementById("authCode").focus();
  });
}
onUnmounted(() => {
  // 获取验证码
  if (timer) clearInterval(timer);
});

// 重新获取验证码
function anewAuthCode() {
  if (forbidTime.value !== 0) {
    return;
  }
  forbidTime.value = 60;
  computeTime();
  getAuthCode();
}

function computeTime() {
  timer = setInterval(() => {
    if (forbidTime.value > 0) {
      forbidTime.value -= 1;
    } else {
      clearInterval(timer);
    }
  }, 1000);
}
</script>

<style lang="scss">
.do-page-authcode {
  .do-com-navbar {
    &::after {
      border-bottom-width: 0px !important;
    }
  }
  .do-com-scroll-wrapper {
    background: #ffffff;
  }
  &-content {
    padding: 0.82rem 0.32rem 0 0.32rem;
    display: flex;
    align-items: center;
    flex-flow: column;
    .title {
      font-size: 0.42rem;
      font-weight: 500;
      color: #03050f;
      margin-bottom: 0.08rem;
    }
    .tip {
      font-size: 0.28rem;
      font-weight: 400;
      color: rgba(3, 5, 15, 0.45);
      margin-bottom: 0.8rem;
    }
    .time-text {
      margin-top: 0.8rem;
      font-size: 0.28rem;
      font-weight: 400;
      color: rgba(3, 5, 15, 0.45);
      text-decoration: underline;
    }
    .time-text-curr {
      color: #0057ff;
      transition: all 0.3s;
    }
    .code-wrap {
      position: relative;
      width: 100%;
      justify-content: space-between;
      display: flex;

      span {
        height: 1.14rem;
        width: 1.4rem;
        padding-bottom: 0.16rem;
        border-bottom: 0.03rem solid #d8d8d8;
        @include flex();
        margin-right: 0.39rem;
        font-size: 0.8rem;
        font-weight: bold;
        color: #03050f;
        transition: all 0.3s;
      }
      .curr {
        border-bottom: 0.03rem solid #0057ff;
        transition: all 0.3s;
      }
      input {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
        caret-color: transparent;
        opacity: 0;
      }
    }
  }
  .right-text {
    font-size: 0.28rem;
    font-weight: 400;
    color: rgba(3, 5, 15, 0.45);
    transition: all 0.3s;
  }
}
</style>
