<template>
  <div class="do-com-btn-group">
    <div class="subject">
      <span
        v-for="item in props.list"
        :key="item.value"
        :style="{
          width: `calc(100% / ${props.list.length})`,
        }"
        @click="() => emit('update:modelValue', item.value)"
      >
        {{ item.name }}
      </span>
    </div>
    <div
      :style="{
        width: `calc(100% / ${props.list.length})`,
        left: `calc(100% / ${props.list.length} * ${action})`,
      }"
      class="action"
    >
      {{ props.list[action].name }}
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, watch, ref } from "vue";
let props = defineProps(["list", "modelValue"]);
let emit = defineEmits(["update:modelValue"]);
let action = ref(
  props.list.findIndex((el) => el.value === props.modelValue) || 0
);

watch(
  () => props.modelValue,
  (end, sta) => {
    action.value = props.list.findIndex((el) => el.value === end) || 0;
  }
);
</script>
<style lang="scss" scoped>
.do-com-btn-group {
  display: flex;
  position: relative;
  width: 100%;
  .subject {
    height: 0.68rem;
    width: 100%;
    display: flex;
    span {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.28rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(2, 4, 15, 0.65);
    }
  }
  .action {
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    box-shadow: 0rem 0.04rem 0.08rem 0rem rgba(0, 0, 0, 0.08);
    border-radius: 0.08rem;
    transition: 0.5s;
    height: 0.68rem;
    color: #0057ff;
    font-size: 0.28rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
  }
}
</style>
