import { cancelRepetitionRequest } from "./http";

// 热力图
export const getHeat = (params) => {
  return cancelRepetitionRequest({
    method: "get",
    url: "/api/v1/poi/heat",
    params,
  });
};

// 获取热力栅格配置
export const getHeatConfig = () => {
  return cancelRepetitionRequest({
    method: "get",
    url: "/api/v1/common/consts",
  });
};
