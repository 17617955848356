<template>
  <div class="do-com-hour-hot-foot-btn">
    <div class="btn">
      <btn-group :list="list" v-model="btn"></btn-group>
    </div>
    <div class="hour">
      <div class="vue-slider-wrap">
        <div class="title">选择时间: {{ hour }}h</div>
        <div class="slider-wrap">
          <div class="play-btn" @click="handlePlayPauseBtn">
            <img v-show="!isPlay" src="@/assets/slider/play.png" />
            <img v-show="isPlay" src="@/assets/slider/pause.png" />
          </div>
          <div class="wrap">
            <vue-slider
              v-model="hour"
              :min="-1"
              :max="24"
              height="0.24rem"
              :dotSize="[]"
              adsorb
              lazy
              dragOnClick
              tooltip="focus"
              @change="handleChangeSlider"
              @drag-start="handleDragStart"
              @drag-end="handleDragEnd"
              :marks="{
                0: '0h',
                6: '6h',
                12: '12h',
                18: '18h',
                23: '23h',
              }"
              :dotOptions="{
                min: 0,
                max: 23,
              }"
              :tooltipFormatter="'{value}h'"
              :railStyle="{
                background: '#F5F8FC',
              }"
              :processStyle="{
                background: '#0057FF',
              }"
              :tooltipStyle="{
                background: '#0057FF',
                borderColor: '#0057FF',
              }"
              :labelStyle="{
                marginTop: '0.16rem',
                fontSize: '0.24rem',
                fontFamily: 'PingFangSC-Regular, PingFang SC',
                fontWeight: '400',
                color: 'rgba(2,4,15,0.25)',
                lineHeight: '0.34rem',
              }"
              :stepStyle="{
                backgroundColor: 'transparent',
              }"
            >
              <template v-slot:dot>
                <div class="custom-button">
                  <span class="vertical"></span>
                  <span class="vertical"></span>
                  <span class="vertical"></span>
                </div>
              </template>
            </vue-slider>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineEmits, onDeactivated, onUnmounted } from "vue";
import BtnGroup from "./btn-group.vue";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

let emit = defineEmits(["back"]);
let hour = ref(new Date().getHours()); //小时
let btn = ref(1); // 当前按钮切换
let isPlay = ref(false);

// 定时器的编号
let timeoutID = null;

let list = [
  {
    name: "全部",
    value: 1,
  },
  {
    name: "工作日",
    value: 2,
  },
  {
    name: "休息日",
    value: 3,
  },
];

/** 滑块值改变时触发事件 */
const handleChangeSlider = (value) => {
  emit("back", {
    hour: value,
    type: btn.value,
  });
};

// 当鼠标/手指按下滑块时触发的事件
const handleDragStart = () => {
  clearTimeout(timeoutID);
  timeoutID = null;
};
// 拖拽结束触发的事件。
const handleDragEnd = () => {
  if (isPlay.value && !timeoutID) {
    loopPlay();
  }
};

// 播放按钮
const handlePlayPauseBtn = () => {
  isPlay.value = !isPlay.value;
  if (!isPlay.value) {
    clearTimeout(timeoutID);
    timeoutID = null;
  } else {
    loopPlay();
  }
};

// 循环播放
const loopPlay = () => {
  timeoutID = setTimeout(() => {
    hour.value += 1;
    if (hour.value > 23) {
      hour.value = 0;
    }
    emit("back", {
      hour: hour.value,
      type: btn.value,
    });
    loopPlay();
  }, 1000);
};

watch(btn, (end) => {
  emit("back", {
    hour: hour.value,
    type: end,
  });
});

onUnmounted(() => {
  clearTimeout(timeoutID);
  timeoutID = null;
  isPlay.value = false;
});

onDeactivated(() => {
  clearTimeout(timeoutID);
  timeoutID = null;
  isPlay.value = false;
});
</script>
<style lang="scss" scoped>
.do-com-hour-hot-foot-btn {
  padding: 0.24rem 0.24rem 0.2rem;
  .btn {
    height: 0.84rem;
    background: rgba(2, 4, 15, 0.04);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.08rem;
    border-radius: 0.16rem;
  }
  .hour {
    margin-top: 0.24rem;

    .vue-slider-wrap {
      margin-bottom: 0.5rem;
      .title {
        margin-bottom: 0.26rem;
        font-size: 0.24rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(2, 4, 15, 0.25);
      }
    }

    .slider-wrap {
      display: flex;
      align-items: center;
      gap: 0.22rem;

      .play-btn {
        width: 0.44rem;
        height: 0.44rem;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .wrap {
        flex: 1;
      }
    }
  }
  .custom-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.08rem;
    width: 0.48rem;
    height: 0.44rem;
    background: linear-gradient(180deg, #f7f7f7 0%, #ffffff 100%);
    box-shadow: 0px 0.04rem 0.08rem 0px rgba(0, 0, 0, 0.12);
    border-radius: 0.08rem;
    border: 0.04rem solid #0057ff;

    .vertical {
      width: 0.02rem;
      height: 0.2rem;
      background: rgba(2, 4, 15, 0.08);
      border-radius: 0.02rem;
    }
  }
}
</style>
