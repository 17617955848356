<template>
  <div class="do-pages-around" ref="pagewrapRef">
    <img
      class="do-com-map-logo"
      src="https://mapapi.qq.com/web/jsapi/logo/logo_def.png"
    />
    <BaseMap
      @init="initMap"
      :config="{ coord: route?.query.coord, zoom: 14.32 }"
    />
    <div
      class="nav-bar"
      :style="{ paddingTop: $Hbuild.getStatusBarHeight() + 'px' }"
      @click="rowClick"
    >
      <van-icon name="arrow-left" size="0.45rem" />
    </div>
    <base-bottom-drawer
      @onChangeTransition="onChangeTransition"
      ref="industryRef"
      v-if="drawerOptions"
      :options="drawerOptions"
      :loader="loader"
      @pullingUp="getDownUp"
      loaderText="搜索中"
    >
      <template v-slot:local>
        <div
          @click="getWatchPosition"
          class="location"
          :style="{ display: locate.industryRef ? '' : 'none' }"
        >
          <img :src="require('@/assets/datamap/location.png')" />
        </div>
      </template>
      <div>
        <PoiList
          :info="calssInfo"
          @refresh="showMapPOiList"
          @back="loadPoiList"
          ref="categoryExample"
        ></PoiList>
      </div>
    </base-bottom-drawer>
    <base-bottom-drawer
      @onChangeTransition="onChangeTransition"
      ref="poiRef"
      v-if="drawerOptions"
      :options="drawerOptions"
      :loader="loader"
      loaderText="数据获取中"
    >
      <template v-slot:head>
        <div>
          <Header
            :info="headData"
            @back="rowClick"
            :close="true"
            :collect="true"
            :imgRadius="true"
          ></Header>
        </div>
      </template>
      <template v-slot:local>
        <div
          @click="getWatchPosition"
          class="location"
          :style="{ display: locate.poiRef ? '' : 'none' }"
        >
          <img :src="require('@/assets/datamap/location.png')" />
        </div>
      </template>
      <div>
        <poi-info
          :info="shopInfo"
          @refresh="openDrawer"
          :disable="true"
        ></poi-info>
      </div>
    </base-bottom-drawer>
  </div>
</template>

<script setup>
import {
  getCurrentInstance,
  onMounted,
  ref,
  nextTick,
  watch,
  reactive,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import PoiInfo from "@/views/datamap//poi-info";
import Drawer from "@/util/drawer";
import Cluster from "@/util/cluster";
import PoiList from "./component/poiList";
import Header from "@/views/datamap/component/header/index.vue";

const { appContext } = getCurrentInstance();
const { $ComJs, $Hbuild } = appContext.config.globalProperties; //全局属性解构
const drawerOptions = ref(false);
const router = useRouter();
const route = useRoute();
const calssInfo = ref([]); // 商店列表
const shopInfo = ref({}); // 商店信息
const loader = ref(false); // 抽屉加载
const categoryExample = ref(null);

/** 获取页面DOM */
const pagewrapRef = ref(null);

/** 业态抽屉 */
const industryRef = ref(null);
/** POI抽屉 */
const poiRef = ref(null);

/** 当前抽屉在屏幕上的高度 */
let offset = 0;
/** 地图实例 */
let map = null;
/** 当前定位的icon */
let locationMarker = null;

let mapPoiList = null; // 地图poi列表

/** 创建抽屉切换实例 */
const drawer = new Drawer();
/** 添加抽屉组件和id */
drawer.add(1, industryRef);
drawer.add(2, poiRef);

/** 点聚合 */
let cluster = new Cluster();
let circle = null; // 圆

let locate = reactive({ industryRef: 0, poiRef: 0 }); // 地图定位点位
let query = null;
let headData = ref(null);

onMounted(() => {
  const height =
    pagewrapRef.value.clientHeight -
    $ComJs.bili * 0.88 -
    $Hbuild.getStatusBarHeight();
  const { top, middle, bottom } = {
    top: height, // 高度
    middle: Math.abs(height * 0.5 - height), // 最小高度
    bottom: Math.abs(height - $ComJs.bili * 1.75), // 最小高度
  };
  // 抽屉配置
  drawerOptions.value = {
    height: top, // 高度
    shrinkMiddle: middle, // 最小高度
    shrink: bottom, // 最小高度
  };

  drawer.setPositionOption({
    top: 0,
    middle,
    bottom,
    hidden: Math.abs(height) + $ComJs.bili * 0.88,
  });
  let list = JSON.parse(route?.query?.list || null);

  setTimeout(() => {
    query = route?.query;
    if (list) {
      calssInfo.value = JSON.parse(route?.query.list);

      loader.value = true;
      drawer.open(1, "middle");
      drawer.scrollTo(0, 0);
    }
  }, 500);
});

watch(
  () => route?.query,
  (end, sta) => {
    let list = JSON.parse(end?.list || null);

    query = end;
    if (list) {
      calssInfo.value = list;
      loader.value = true;
      drawer.open(1, "middle");
      drawer.scrollTo(0, 0);
    }
  }
);

watch(
  () => industryRef?.value?.transitionY,
  (end, sta) => {
    locate.industryRef = !!end;
  }
);

watch(
  () => poiRef?.value?.transitionY,
  (end, sta) => {
    locate.poiRef = !!end;
  }
);

// 渲染半径一公里圆
const setCircle = () => {
  let coord = query.coord;
  let poiType = JSON.parse(query.list).poiType;
  let position = coord?.split(",");
  var center = new window.TMap.LatLng(+position[0], +position[1]);

  map.setZoom(poiType === 2 ? 12.5 : 14);
  cluster.updateBounds(coord);
  circle = new window.TMap.MultiCircle({
    map,
    styles: {
      // 设置圆形样式
      circle: new window.TMap.CircleStyle({
        color: "rgba(78, 106, 255, 0.08)",
        showBorder: true,
        borderColor: "#4E6AFF", // 边线颜
        borderWidth: 2, // 边线宽度
        borderDashArray: [4, 4], // 虚线数组
      }),
    },
    geometries: [
      {
        styleId: "circle",
        center: center,
        radius: poiType === 2 ? 3000 : 1000,
      },
    ],
  });
};

const showMapPOiList = (data = []) => {
  if (!map) {
    mapPoiList = data;
    return;
  }
  loader.value = false;
  cluster?.setActivePoi(data, true);
  nextTick(() => {
    if (circle) {
      circle.destroy();
    }
    setCircle();
    drawer.scrollRefresh();
  });
  cluster?.on("click", (e) => {
    drawer.open(2, "middle");
    drawer.scrollTo(0, 0);
    loader.value = true;
    shopInfo.value = {
      ...e,
      poi_id: e.id || e.poi_id,
      poi_name: e.poi_name || e.content,
    };
  });
};

const getDownUp = () => {
  nextTick(() => {
    categoryExample.value.addPage();
  });
};

const rowClick = () => {
  if (drawer.isOpenId === 1) {
    router.back();
  } else {
    drawer.open(1, "middle");
    cluster.recover();
    nextTick(() => {
      let poiType = JSON.parse(query.list).poiType;
      map.setZoom(poiType === 2 ? 12.5 : 14);
      cluster.updateBounds(query.coord);
    });
  }
};

const loadPoiList = (item) => {
  if (item.poi_id !== shopInfo.value.poi_id) {
    loader.value = true;
    shopInfo.value = item;
  }
  drawer.open(2, "middle");
  drawer.scrollTo(0, 0);
  cluster.focus([item]);
};

const openDrawer = (is) => {
  drawer.scrollRefresh();
  loader.value = false;
  headData.value = is;
};

/**
 * 抽屉高度变更时回调
 * @param {number} v - 当前抽屉的transitionY值
 */
function onChangeTransition(v) {
  const o = drawerOptions.value.height - v;
  offset = o;
}

/**
 * 地图初始化回调
 * @param {TMap.map} m - 返回的地图实例
 */
function initMap(m) {
  map = m;
  cluster.onInit(map, drawer);

  m.on("touchstart", () => {
    const position = drawer.getPosition();
    if (position === "top") {
      drawer.update("middle");
    }
  });
  cluster.on("click", (e, type) => {
    drawer.open(2, "middle");
    drawer.scrollTo(0, 0);
    loader.value = true;
    shopInfo.value = {
      ...e,
      poi_id: e.id || e.poi_id,
      poi_name: e.poi_name || e.content,
    };
  });
  if (mapPoiList) {
    showMapPOiList(mapPoiList);
  }
}

/** 监听定位  */
function getWatchPosition() {
  /** 是否是点击定位 */
  let flag = true;
  let position = query.coord?.split(",");
  var centerPoint = new window.TMap.LatLng(+position[0], +position[1]);
  if (flag) {
    const zoom = map.getZoom();
    if (zoom < 11) {
      map.setZoom(16);
    }
    map.setOffset({
      x: 0,
      y: (-offset + $Hbuild.getStatusBarHeight() + $ComJs.bili * 1) / 2,
    });
    map.panTo(centerPoint);
    flag = false;
  }
  if (locationMarker) {
    locationMarker.position = centerPoint;
    locationMarker.setMap(map);
    locationMarker.updateDOM();
  } else {
    import("@/util/domOverlay").then(({ LocationDom }) => {
      locationMarker = new LocationDom({
        map,
        position: centerPoint,
        radius: 16,
      });
    });
  }
}
</script>

<style lang="scss">
.do-com-safearea {
  bottom: 0 !important;
}
.asfsafa {
  position: relative;
}
.content {
  height: 900px;
}
.vantIInpui {
  position: fixed;
}
.do-input {
  background: red;
}
.search {
  height: 100px;
  width: 100%;
}
.do-pages-around {
  // position: relative;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  bottom: 0;
  .nav-bar {
    background: rgba(0, 0, 0, 0);
    position: absolute;
    top: 0;
    z-index: 999;
    width: 1.17rem;
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
    padding-left: 0.32rem;
    padding-right: 0.32rem;
    i {
      display: flex;
      align-items: center;
      height: 0.88rem;
    }
  }
  .do-page-poi-info {
    .item-range-data {
      .c85 {
        color: rgba(2, 4, 15, 0.85) !important;
        text-decoration: unset !important;
      }
    }
    .do-page-class-tag {
      .row {
        .fn {
          color: rgba(2, 4, 15, 0.25);
          text-decoration: unset;
        }
      }
    }
    .do-fn-pictureFrame {
      .title {
        span + span {
          color: rgba(2, 4, 15, 0.25);
          text-decoration: unset;
        }
      }
    }
  }
}
.location {
  position: absolute;
  right: 0.32rem;
  top: -0.88rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0.64rem;
  height: 0.64rem;
  background: #ffffff;
  box-shadow: 0 0.04rem 0.08rem 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.16rem;

  img {
    width: 0.36rem;
    height: 0.36rem;
  }
}

@keyframes opacityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.label-animation {
  animation: opacityAnimation 0.3s forwards;
}
</style>
