<template>
  <div class="do-page-collect">
    <base-nav-bar
      :backText="true"
      title="我的收藏"
      @back="() => router.back()"
    ></base-nav-bar>
    <!-- $ComJs.bili * 0.88 ===  高度 -->
    <base-bscroll
      :options="{ bounce: true, pullUpLoad: true }"
      class="safearea-tabbar"
      @refScroll="refScroll"
      :style="{
        height: `calc(100vh - ${
          $Hbuild.getStatusBarHeight() + $ComJs.bili * 0.88 + 'px'
        })`,
      }"
    >
      <div v-if="list.length">
        <Row v-for="(user, i) in list" :info="user" :key="i" @cb="setDel"></Row>
      </div>
      <base-nodata v-else description="暂无收藏数据"></base-nodata>
    </base-bscroll>
  </div>
</template>

<script setup>
import { onMounted, ref, getCurrentInstance, nextTick } from "vue";
import { useRouter } from "vue-router";
import Row from "./components/row";
import { getFavorites } from "@/axios/home";
import { Toast } from "vant";

const { appContext } = getCurrentInstance();
const { $Hbuild } = appContext?.config?.globalProperties || {}; //全局属性解构

const router = useRouter();
const list = ref([]);
const scrollCeontent = ref(); // base-bscroll 实例（内部滚动容器）
let next = "";

onMounted(async () => {
  list.value = [];
  getData();
});

const setDel = (info) => {
  list.value = list.value.filter((el) => el.id !== info.id);
  finishPullUp();
};

const getData = async () => {
  try {
    const res = await getFavorites({ cursor: next });
    if (res.code === 0) {
      let item = res.data?.items?.map((el) => ({
        ...el,
        create_ts: getDateForm(el.create_ts),
      }));
      list.value.push(...(item || []));
      finishPullUp();
      next = res.data.next;
    }
  } catch (error) {
    if (error) {
      Toast({
        position: "bottom",
        message: error.msg || "获取信息失败",
      });
    }
  }
};

function getDateForm(time) {
  let date = new Date(time);

  let Y = date.getFullYear() + "-";

  let M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  let D = date.getDate() + " ";
  let h = date.getHours() + ":";
  let m = date.getMinutes() + ":";
  let s = date.getSeconds();

  return Y + M + D + h + m + s;
}

function refScroll(refSc) {
  scrollCeontent.value = refSc;
  refSc.on("pullingUp", () => {
    if (!next) return;
    getData();
  });
  finishPullUp();
}

// 停止上拉加载
function finishPullUp() {
  nextTick(() => {
    scrollCeontent?.value?.finishPullUp();
    scrollCeontent?.value?.refresh();
  });
}
</script>
<style lang="scss" scoped>
.do-page-collect {
  width: 100%;
  height: 100%;
  background-color: #fff;
  .safearea-tabbar {
    background-color: #f5f8fc;
  }
  :deep(.van-nav-bar) {
    position: relative;
    z-index: 11;
  }
}
</style>
