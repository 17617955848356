<template>
  <div class="do-page-poi-list">
    <div class="phone-status">
      <div></div>
    </div>
    <div class="box">
      <div class="number">
        {{ typeLabel[props?.info?.type] }}数量：{{ list.length || 0 }}个
      </div>
      <div class="category-tabbar">
        <div
          class="row-tab"
          v-for="user in list || []"
          :key="user"
          @click="() => clickItem(user)"
        >
          <span>{{ user.poi_name }}</span>
          <van-icon name="arrow" size="0.2rem" color="rgba(2,4,13,0.2)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, watch, ref } from "vue";
import { Toast } from "vant";
import { getAurrounding } from "@/axios/home";
let props = defineProps(["info"]);
let emit = defineEmits(["back", "refresh"]);
let page = ref(1);
let list = ref([]);
let typeLabel = {
  1: "住宅区",
  2: "办工区",
  3: "街铺",
  4: "地铁站",
  5: "公交站",
  6: "停车场",
};

const clickItem = (user) => {
  if (!user?.poi_id) {
    Toast({
      position: "bottom",
      message: "暂无数据",
    });
    return;
  }
  emit("back", user);
};

const getLoaderData = async (info) => {
  try {
    let res = await getAurrounding({
      type: info.type,
      p_id: info.id,
    });
    list.value = res.data.items || [];

    emit("refresh", res.data.items || []);
  } catch (error) {
    if (error) {
      Toast({
        position: "bottom",
        message: error.msg || "获取信息失败",
      });
    }
    emit("refresh", []);
  }
};

watch(
  () => props.info,
  (end, start) => {
    page.value = 1;
    list.value = [];
    getLoaderData(end);
  }
);
</script>

<style lang="scss" scoped>
.do-page-poi-list {
  .phone-status {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 0.42rem;
    width: 100%;

    & > div {
      width: 0.56rem;
      height: 0.1rem;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 0.05rem;
    }
  }
  & > .box {
    padding: 0.26rem 0.32rem;
    bottom: 0;
    position: relative;
    top: 0rem;
    .number {
      margin: 0 0 0.16rem;
      font-size: 0.24rem;
      font-weight: 400;
      color: rgba(2, 4, 15, 0.45);
    }
    .category-tabbar {
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      overflow: hidden;
      bottom: 0.56rem;
      .row-tab + .row-tab {
        margin-top: 0.24rem;
      }
      .row-tab {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 0.24rem;
        background: rgba(2, 4, 15, 0.04);
        border-radius: 0.16rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(2, 4, 15, 0.85);
        font-size: 0.28rem;
      }
    }
  }
}
</style>
