import axios from "./http";

//城市树
export const getCity = () => {
  return axios({
    method: "get",
    url: "/api/v1/common/city",
  });
};

//品牌店铺列表
export const getBrands = (params) => {
  return axios({
    method: "get",
    url: "/api/v1/poi/detail/brands",
    params,
  });
};
