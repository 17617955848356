<template>
  <div class="do-page-home-header">
    <div
      class="title"
      :style="{
        paddingTop: `calc(${$Hbuild.getStatusBarHeight()}px + 0.2rem)`,
        backgroundImage: require('@/assets/home/bg-color.png'),
      }"
    >
      <div class="logo">
        <img :src="require('@/assets/logo2x.png')" alt="" />
      </div>
      <div class="type">
        <span
          v-for="item in searchTitle"
          :key="item.id"
          :class="{ 'curr-type': item.id === props.action }"
          @click="() => emit('cb', item)"
          >{{ item.name }}</span
        >
      </div>
      <div class="search-box">
        <van-field
          :placeholder="placeholder[props.action]"
          center
          :left-icon="require('@/assets/datamap/search.png')"
          readonly
          @click="() => emit('back')"
          v-model="value"
        >
          <template #button v-if="value">
            <div class="right-user-icon">
              <img :src="require('@/assets/datamap/del.png')" alt="" />
            </div>
          </template>
        </van-field>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  onMounted,
  ref,
  getCurrentInstance,
  defineProps,
  defineEmits,
} from "vue";
import { useRouter } from "vue-router";
import { placeholder } from "../config";

const router = useRouter();
const { appContext } = getCurrentInstance();
const { $Hbuild } = appContext.config.globalProperties; //全局属性解构
let props = defineProps(["action", "back"]);
let emit = defineEmits(["cb"]);
let value = ref(null);
const searchTitle = [
  {
    name: "查店铺",
    id: 1,
  },
  {
    name: "查商场",
    id: 2,
  },
  {
    name: "查品牌",
    id: 3,
  },
];

// 启动 plusready
onMounted(() => {
  console.log("$ComJs");
});
</script>

<style lang="scss">
.do-page-home-header {
  .title {
    display: flow-root;
    height: 100%;
    background: url("../../../assets/home/bg-color.png") center no-repeat;
    background-size: cover;
    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.28rem 0 0.4rem;
      width: 100%;

      img {
        width: 1.86rem;
      }
    }
    .type {
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 0.3rem;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.65);
      margin-bottom: 0.24rem;
      cursor: pointer;
    }

    .curr-type {
      font-size: 0.3rem;
      font-weight: 500;
      color: #ffffff;
    }

    .search-box {
      margin: 0 0.24rem 0.4rem 0.24rem;
      width: calc(100% - 0.48rem);
      background: #ffffff;
      box-shadow: 0px 0.06rem 0.12rem 0px rgba(0, 0, 0, 0.2);
      border-radius: 0.16rem;
      .van-field__control {
        &::-webkit-input-placeholder {
          font-size: 0.28rem;
          font-weight: 400;
          color: rgba(2, 4, 15, 0.25);
        }
      }
      .van-cell {
        padding: 0.2rem 0.18rem;
        height: 0.8rem;
        border-radius: 0.16rem;
        width: calc(100vw - 0.48rem);

        .van-field__left-icon {
          display: flex;
          align-items: center;
          margin-right: 0.12rem !important;
        }
        .van-field__body {
          height: 100%;
        }
        .right-user-icon {
          width: 0.64rem;
          height: 0.64rem;
          border-radius: 0.16rem;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 0.28rem;
            height: 0.28rem;
          }
        }
        .user-icon-bg {
          background: rgba(2, 4, 15, 0.04);
        }
      }
    }
  }
}
</style>
