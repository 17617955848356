import axios from "./http";

//附近搜索_列表
export const getSearchNearby = (params) => {
  return axios({
    method: "get",
    url: "/api/v1/poi/search/nearby",
    params,
  });
};

//poi 详情
export const search = (params) => {
  return axios({
    method: "get",
    url: "/api/v1/poi/search",
    params,
  });
};

//收藏 POI
export const getFavorites = (params) => {
  return axios({
    method: "get",
    url: "/api/v1/account/favorites",
    params,
  });
};

//品牌详情
export const getBrandsInfo = (id) => {
  return axios({
    method: "get",
    url: `/api/v1/poi/detail/brands/${id}`,
  });
};

// 首页  最近的poi
export const getClosest = () => {
  return axios({
    method: "get",
    url: `/api/v1/poi/closest`,
  });
};

// 周边详情数据列表
export const getAurrounding = (params) => {
  return axios({
    method: "get",
    url: `/api/v1/poi/detail/surrounding`,
    params,
  });
};
