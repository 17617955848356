<template>
  <div class="do-page-home">
    <!-- <van-dialog
      class-name="home-location-dialog"
      v-model:show="locationShow"
      title="查看周边数据信息"
      show-cancel-button
      @confirm="confirmLocationShow"
      confirm-button-text="获取授权"
      @cancel="cancelLocationShow"
    >
      <div>
        将获取你的具体位置信息，在首页中实时为你提供当前位置周边的数据情况
      </div>
      <img :src="require('@/assets/home/location-show.png')" />
    </van-dialog> -->
    <div class="fixed-search" @click="goSearch" v-show="ceil">
      <van-field
        :placeholder="placeholder[action]"
        center
        :left-icon="require('@/assets/datamap/search.png')"
        readonly
      >
      </van-field>
    </div>
    <Header
      :action="action"
      @back="goSearch"
      @cb="(item) => (action = item.id)"
    ></Header>
    <base-bscroll
      :options="{
        bounce: {
          top: false,
        },
        probeType: 3,
      }"
      @refScroll="refScroll"
      class="do-com-safearea-tabbar"
      :style="{
        // position: 'relative',
        zIndex: 1,
        // height: `calc(100vh - 3.6rem)`,
        top: `calc(${$Hbuild.getStatusBarHeight()}px + 3.6rem)`,
      }"
    >
      <div>
        <div
          class="position"
          :style="{ width: locationBtnBol ? '85%' : '100%' }"
        >
          位置：
          <van-skeleton
            :loading="skeletonShow"
            class="position-skeleton"
            :row="1"
          >
            {{ info.current_addr || "-" }}
          </van-skeleton>
          <div class="loca-btn" @click="getLocation">获取当前位置</div>
        </div>
        <Box title="周边数据" subtitle="范围：1km">
          <div class="fn-row">
            <div class="item-range-data">
              <div>
                <div class="c65">
                  <van-skeleton
                    :loading="skeletonShow"
                    class="around-skeleton"
                    :row="1"
                  >
                    {{ info?.around?.live_cnt || "-" }}
                  </van-skeleton>
                </div>
                <div class="c45">居住人群</div>
              </div>
              <div>
                <div class="c65">
                  <van-skeleton
                    :loading="skeletonShow"
                    class="around-skeleton"
                    :row="1"
                  >
                    {{ info?.around?.work_cnt || "-" }}
                  </van-skeleton>
                </div>
                <div class="c45">工作人群</div>
              </div>
            </div>
            <div class="item-range-data item-reange-num">
              <div @click="() => info?.around?.live && goAround(1)">
                <div
                  :style="{
                    textDecoration: info?.around?.live ? 'underline' : '',
                  }"
                  class="c65"
                >
                  <van-skeleton
                    :loading="skeletonShow"
                    class="around-skeleton"
                    :row="1"
                  >
                    {{ info?.around?.live || "-" }}
                  </van-skeleton>
                </div>
                <div
                  :class="`c45 ${
                    info?.around?.live?.length ? 'underline' : ''
                  }`"
                >
                  住宅区数量
                </div>
              </div>
              <div @click="() => info?.around?.work && goAround(2)">
                <div
                  :style="{
                    textDecoration: info?.around?.work ? 'underline' : '',
                  }"
                  class="c65"
                >
                  <van-skeleton
                    :loading="skeletonShow"
                    class="around-skeleton"
                    :row="1"
                  >
                    {{ info?.around?.work || "-" }}
                  </van-skeleton>
                </div>
                <div
                  :class="`c45 ${
                    info?.around?.live?.length ? 'underline' : ''
                  }`"
                >
                  办公区数量
                </div>
              </div>
              <div @click="() => info?.around?.street_shop && goAround(3)">
                <div
                  :style="{
                    textDecoration: info?.around?.street_shop
                      ? 'underline'
                      : '',
                  }"
                  class="c65"
                >
                  <van-skeleton
                    :loading="skeletonShow"
                    class="around-skeleton"
                    :row="1"
                  >
                    {{ info?.around?.street_shop || "-" }}
                  </van-skeleton>
                </div>
                <div
                  :class="`c45 ${
                    info?.around?.live?.length ? 'underline' : ''
                  }`"
                >
                  街铺数量
                </div>
              </div>
            </div>
            <calss-tag
              :style="{ marginTop: '0.4rem' }"
              title="住宅情况"
              titleValue="title"
              :skeletonRow="2"
              :config="liveCalssTag"
              :tglWidth="$ComJs.bili * 1.78"
            ></calss-tag>
            <calss-tag
              :style="{ marginTop: '0.4rem' }"
              title="办公情况"
              :skeletonRow="1"
              titleValue="title"
              :config="workCalssTag"
              :tglWidth="$ComJs.bili * 1.78"
            ></calss-tag>
            <calss-tag
              :style="{ marginTop: '0.4rem' }"
              title="街铺情况"
              :skeletonRow="1"
              titleValue="title"
              :config="shopCalssTag"
              :tglWidth="$ComJs.bili * 1.78"
            ></calss-tag>
          </div>
        </Box>
        <!-- <Box
          :style="{ marginTop: '0.24rem' }"
          title="品牌指数（美食类）"
          :subtitle="`范围：${info?.city_name || '-'} `"
        >
          <van-skeleton
            class="ranking-skeleton"
            :loading="skeletonShow"
            :row="6"
          >
            <bar-ranking :list="rank"></bar-ranking>
          </van-skeleton>
        </Box> -->
      </div>
    </base-bscroll>
  </div>
</template>

<script setup>
import { onActivated, ref, getCurrentInstance, nextTick, onMounted } from "vue";
import { getClosest } from "@/axios/home";
import Header from "./components/header.vue";
import Box from "./components/box.vue";
import CalssTag from "./components/calssTag.vue";
import BarRanking from "./components/bar-ranking.vue";
import { placeholder } from "./config";
import {
  getAppLocation,
  checkoutPermissionLocation,
  isAndroid,
  isIos,
} from "@/js_sdk/permission.js";
import { Toast } from "vant";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getTextWidth } from "@/util";
const { appContext } = getCurrentInstance();
const { $Hbuild, $ComJs } = appContext?.config?.globalProperties || {}; //全局属性解构
const dataInfo = ref({});
const scrollCeontent = ref(null);
let ceilingNum = 0;
let ceil = ref(false);
let action = ref(1);
let info = ref({});
// let locationShow = ref(false);
let locationBtnBol = ref(true);
const router = useRouter();
const store = useStore();
const faillng = `22.543473`;
const faillat = `114.055182`;
const skeletonShow = ref(true);
// 住宅情况
const liveCalssTag = ref([]);
// 办公情况
const workCalssTag = ref([]);
// 街铺情况
const shopCalssTag = ref([]);
const rank = ref([]);

// 获取首页数据
async function getData() {
  try {
    const { code, data } = await getClosest();
    if (code === 0 && data) {
      info.value = data || {};
      skeletonShow.value = false;
      liveCalssTag.value = [
        {
          name: "平均售价：",
          value: data?.around?.price?.live?.avg_sale_price
            ? `${data?.around?.price?.live?.avg_sale_price}元/㎡`
            : "",
          tgl: data?.around?.price?.live?.tgi_sale_price
            ? (
                (data?.around?.price?.live?.tgi_sale_price > 9.99
                  ? 9.99
                  : data?.around?.price?.live?.tgi_sale_price || 0) * 100
              ).toFixed(2)
            : "",
        },
        {
          name: "平均租金：",
          value: data?.around?.price?.live?.avg_rent_price
            ? `${data?.around?.price?.live?.avg_rent_price}元/㎡/月`
            : "",
          tgl: data?.around?.price?.live?.tgi_rent_price
            ? (
                (data?.around?.price?.live?.tgi_rent_price > 9.99
                  ? 9.99
                  : data?.around?.price?.live?.tgi_rent_price || 0) * 100
              ).toFixed(2)
            : "",
        },
      ];
      workCalssTag.value = [
        {
          name: "平均租金：",
          value: data?.around?.price?.work?.avg_rent_price
            ? `${data?.around?.price?.work?.avg_rent_price}元/㎡/月`
            : "",
          tgl: data?.around?.price?.work?.tgi_rent_price
            ? (
                (data?.around?.price?.work?.tgi_rent_price > 9.99
                  ? 9.99
                  : data?.around?.price?.work?.tgi_rent_price || 0) * 100
              ).toFixed(2)
            : "",
        },
      ];
      shopCalssTag.value = [
        {
          name: "平均租金：",
          value: data?.around?.price?.street_shop?.avg_rent_price
            ? `${data?.around?.price?.street_shop?.avg_rent_price}元/㎡/月`
            : "",
          tgl: data?.around?.price?.street_shop?.tgi_rent_price
            ? (
                (data?.around?.price?.street_shop?.tgi_rent_price > 9.99
                  ? 9.99
                  : data?.around?.price?.street_shop?.tgi_rent_price || 0) * 100
              ).toFixed(2)
            : "",
        },
      ];

      const brandReta = data?.brand_reta || [];
      rank.value = brandReta.map((item) => {
        return {
          name: item.name,
          value: item.reta ? parseInt(Number(item.reta) * 100) : 0,
        };
      });

      localStorage.setItem(
        "homeData",
        JSON.stringify({
          info: info.value,
          liveCalssTag: liveCalssTag.value,
          workCalssTag: workCalssTag.value,
          shopCalssTag: shopCalssTag.value,
          rank: rank.value,
        })
      );
      nextTick(() => {
        if (scrollCeontent.value) scrollCeontent.value.refresh();
      });
      if (isAndroid() && locationBtnBol.value) {
        const isOpenLocation = checkoutPermissionLocation();
        if (isOpenLocation) {
          locationBtnBol.value = false;
        }
      }
    }
  } catch (error) {
    if (error) {
      Toast({
        position: "bottom",
        message: error.msg || "服务端错误 ",
      });
    }
  }
}

onMounted(async () => {
  if (localStorage.getItem("homeData")) {
    let data = JSON.parse(localStorage.getItem("homeData"));
    skeletonShow.value = false;
    info.value = data.info;
    liveCalssTag.value = data.liveCalssTag;
    workCalssTag.value = data.workCalssTag;
    shopCalssTag.value = data.shopCalssTag;
    rank.value = data.rank;
  }
  const checkPerLoca = checkoutPermissionLocation();
  // 安卓, 系统是否授权，如果未授权，使用默认经纬度请求数据
  if (isAndroid() && !checkPerLoca) {
    if (!store.state.common.coordinate) {
      store.commit("setCoordinate", `${faillng},${faillat}`);
    }
    // 显示获取定位按钮
    locationBtnBol.value = true;
  }
});

function getLocation(isAlert = true) {
  getAppLocation({
    succeed: () => {
      getData();
      // 成功获取定位，关闭获取定位按钮
    },
    isAlert: isAlert,
    fail: getData,
  });
}

// 启动 plusready
onActivated(() => {
  console.log();
  if (scrollCeontent.value) scrollCeontent.value.refresh();
  getData();
  // } else if (isAndroid() && !locationBtnBol.value) {
  //   getLocation();
  // } else if (checkoutPermissionLocation()) {
  //   getLocation();
  //   locationBtnBol.value = false;
  // } else {
  //   getData();
  // }

  const title = document.querySelector(".do-page-home-header");
  const distance = document.querySelector(".do-page-home-header .search-box");
  ceilingNum =
    $Hbuild.getStatusBarHeight() +
    $ComJs.bili * 0.4 +
    distance.clientHeight -
    title.clientHeight +
    $ComJs.bili * 0.24;
});

// 去查看搜索页面
const goSearch = () => {
  router.push({
    name: "search",
    query: { type: action.value },
  });
};
// 去查看周边数据
const goAround = (type) => {
  router.push({
    path: "/home/around",
    query: {
      coord: info.value.coord,
      list: JSON.stringify({
        type,
        id: info.value.poi_id,
        poiType: info.value.type,
      }),
    },
  });
};

function refScroll(refSc) {
  scrollCeontent.value = refSc;
}
</script>

<style lang="scss">
.do-page-home {
  position: fixed;
  top: 0;
  z-index: 10;
  .home-location-dialog {
    img {
      height: 1.2rem;
    }
    .van-dialog__cancel {
      color: #323233 !important;
    }
    .van-dialog__content {
      display: flex;
      justify-content: space-around;
      padding: 0.2rem;
      font-size: 0.28rem;
      color: rgba(3, 5, 15, 0.85);
      div {
        flex: 0.8;
      }
    }
    .van-dialog__confirm {
      color: #0057ff !important;
    }
  }
  // .do-com-safearea-tabbar
  .position-skeleton {
    display: inline-block;
    width: 70%;
    padding: 0;
    .van-skeleton__row {
      background: #e9e9e9;
    }
  }
  .around-skeleton {
    margin-bottom: 0.1rem;
    padding: 0;
    .van-skeleton__row {
      width: 100% !important;
    }
  }

  .ranking-skeleton {
    .van-skeleton__content {
      padding: 0.16rem 0 0.4rem 0;
    }
  }

  .do-com-scroll-wrapper {
    background: #f5f8fc;
  }
  .do-com-scroll-content {
    padding-bottom: 0.4rem;
  }
  .fixed-search {
    position: fixed;
    display: flex;
    justify-content: center;
    width: 100%;
    background: #0057ff;
    padding: 0.24rem 0 0.24rem 0;
    top: 0px;
    z-index: 9999;
    .van-cell {
      padding: 0.12rem 0.18rem;
      border-radius: 0.16rem;
      width: calc(100vw - 0.48rem);
      .van-field__left-icon {
        display: flex;
        align-items: center;
      }
      .van-field__body {
        height: 100%;
      }
      .right-user-icon {
        width: 0.64rem;
        height: 0.64rem;
        border-radius: 0.16rem;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 0.28rem;
          height: 0.28rem;
        }
      }
      .user-icon-bg {
        background: rgba(2, 4, 15, 0.04);
      }
    }
  }
  .position {
    padding: 0.24rem;
    font-size: 0.28rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .loca-btn {
    position: absolute;
    top: 0.24rem;
    right: 0.24rem;
    color: #0057ff;
    font-size: 0.28rem;
    font-weight: 400;
  }
  .underline {
  }
  .fn-row {
    padding: 0.24rem 0.24rem 0.4rem 0.24rem;
    .item-range-data {
      display: flex;
      align-content: center;
      justify-content: space-between;
      border-radius: 0.08rem;
      padding: 0.32rem;
      & > div {
        display: grid;
        align-content: center;
        justify-content: center;
        width: 50%;
        font-size: 0.28rem;
        font-weight: 400;
        text-align: center;
        & > div + div {
          margin-top: 0.08rem;
        }
      }
      & > div + div {
        border-left: 0.02rem solid rgba(3, 5, 15, 0.12);
      }
      .c65 {
        font-size: 0.4rem;
        color: rgba(2, 4, 15, 0.85);
        font-weight: bold;
      }
      .c45 {
        font-size: 0.24rem;
        color: rgba(2, 4, 15, 0.45);

        font-weight: 400;
      }
    }
    .item-reange-num {
      background: none;
      padding: 0.24rem 0 0;
      .van-skeleton__row {
        background: #e9e9e9 !important;
      }
      .c65 {
        color: #0057ff;
        font-size: 0.4rem;
      }
      .c45 {
        font-size: 0.24rem;
      }
      & > div {
        width: calc(33% - 0.1rem);
        background: #f5f8fc;
        height: 1.58rem;
        border-radius: 0.08rem;
        border: 0px;
        border-left: 0px !important;
      }
    }
  }
}
</style>
