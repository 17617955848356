<template>
  <div class="do-page-brand-map-info">
    <div :style="{ height: '1.74rem' }"></div>
    <div class="box">
      <div class="category-tabbar">
        <div
          class="item-title"
          :style="{ marginTop: '0.32rem', marginBottom: '0.24rem' }"
        >
          <div class="left">覆盖人流量</div>
          <div class="right">更新：月度</div>
        </div>
        <div class="item-type">
          <div>
            <div class="c45">业态：</div>
            <div class="c85">
              {{
                (stopInfo?.category_list?.slice(0, 2) || []).join("-") || "-"
              }}
            </div>
          </div>
          <div>
            <div class="c45">全国·月人流量指数：</div>
            <div
              class="c85"
              :style="{
                fontWeight: 'bold',
              }"
            >
              {{ stopInfo?.month || "-" }}
            </div>
          </div>
        </div>
        <picture-frame
          title="品牌信息"
          :prop-stype="{ height: '100%', margin: '0.24rem 0' }"
          v-if="
            stopInfo?.establish_date ||
            stopInfo?.avr_price ||
            stopInfo?.open_method ||
            stopInfo?.area_require ||
            stopInfo?.store_count ||
            stopInfo?.mall_store_count
          "
        >
          <Explain type="brand" :dataInfo="stopInfo"></Explain>
        </picture-frame>
        <picture-frame
          title="门店数量"
          :prop-stype="{ height: '100%', margin: '0.24rem 0' }"
          :right-text="`总数量： ${stopInfo?.store_count || 0}家`"
          class="store-frame"
          v-if="stopInfo?.list?.length"
        >
          <div class="store-num">
            <div
              v-for="item in stopInfo?.list || []"
              @click="() => goAround(item)"
            >
              <span>{{ item.poi_name }}</span>
              <van-icon name="arrow" size="0.2rem" color="rgba(2,4,13,0.2)" />
            </div>
            <div
              v-if="stopInfo?.store_count > stopInfo?.list?.length"
              class="more"
              @click="() => goBrandList()"
            >
              查看更多
            </div>
          </div>
        </picture-frame>
        <!-- <div
          class="item-title"
          :style="{ marginTop: '0.32rem', marginBottom: '0.24rem' }"
        >
          <div class="left">及刻指数排名</div>
          <div class="right">详情</div>
        </div>
        <div class="bg-fff">
          <calss-tag
            :config="[
              {
                name: '全国排名：',
                value: 'a',
                icon: require('@/assets/datamap/brand-sort.png'),
              },
            ]"
            :info="{
              a: '-',
              b: '-',
            }"
            @click="() => goJkRanking()"
          >
          </calss-tag>
          <calss-tag
            :style="{ paddingTop: '0' }"
            :config="[
              {
                name: 'xx省排名：',
                value: 'a',
                icon: require('@/assets/datamap/brand-sort.png'),
              },
            ]"
            :info="{
              a: '-',
            }"
            @click="() => goJkRanking()"
          >
          </calss-tag>
          <calss-tag
            :style="{ paddingTop: '0' }"
            :config="[
              {
                name: 'xx市排名：',
                value: 'a',
                icon: require('@/assets/datamap/brand-sort.png'),
              },
            ]"
            :info="{
              a: '-',
            }"
            @click="() => goJkRanking()"
          >
          </calss-tag>
        </div> -->

        <div class="date-info">以上数据由梦享网络提供</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, watch } from "vue";
import Row from "@/views/datamap/poi-info/component/row";
import PictureFrame from "@/views/datamap/poi-info/component/picture-frame";
import CalssTag from "@/views/datamap/poi-info/component/calssTag";
import { setFavorites, delFavorites } from "@/axios/poiSearch";
import { getBrandsInfo } from "@/axios/home";
import { Toast } from "vant";
import { useRouter } from "vue-router";
import Explain from "@/views/datamap/poi-info/component/explain";

const router = useRouter();

const emit = defineEmits(["back", "refresh"]);
const props = defineProps(["info"]);
const stopInfo = ref({}); // 基础信息
const collect = ref(false); // 是否收藏

let hour = []; //时间小时数组
for (let index = 0; index < 24; index++) {
  hour.push(index);
}
// 跳转及刻排名
const goJkRanking = () => {
  router.push({
    path: "/jk-index",
  });
};

// 跳转查看品牌店铺列表
const goBrandList = () => {
  console.log("stopInfo", stopInfo);
  router.push({
    path: "/search/brand-map/brand-list",
    query: { pb_id: stopInfo.value.pb_id },
  });
};

// 跳转查看poi详情
const goAround = (data) => {
  console.log("goAround", data);
  router.push({
    path: "/datamap",
    query: { list: JSON.stringify(data) },
  });
};

// 收藏
const setCollect = async () => {
  if (!stopInfo.value?.id) return;
  try {
    let res = null;
    if (!collect.value) {
      res = await setFavorites({
        p_id: stopInfo.value.id,
      });
    } else {
      res = await delFavorites(collect.value);
    }
    if (res.code === 0) {
      Toast({
        position: "bottom",
        message: !collect.value ? "收藏成功" : "取消收藏成功",
      });
      collect.value = res?.data?.favorite_id || null;
    }
  } catch (error) {
    if (error) {
      Toast({
        position: "bottom",
        message: error.msg || "获取信息失败",
      });
    }
  }
};

// 获取商店数据
const xShopInfo = async (info) => {
  try {
    let res = await getBrandsInfo(info.id);

    if (res.code === 0) {
      stopInfo.value = {
        ...res.data,
        name: res.data.pb_name,
        arr: info.address || res.data.address,
        logo: res.data.logo,
        category_list: res.data.category_list.map((el) => el || "缺失"),
        id: info.id,
      };
      collect.value = res.data?.self?.favorite_id;
      emit("refresh", res?.data?.map || []);
    }
  } catch (error) {
    if (error) {
      Toast({
        position: "bottom",
        message: error.msg || "获取信息失败",
      });
    }
    emit("refresh");
  }
};

watch(
  () => props.info,
  (end, start) => {
    collect.value = false;
    stopInfo.value = {
      name: end.name,
      logo: end.logo,
      id: end.id,
    };
    if (!end?.id) {
      Toast({
        position: "bottom",
        message: "暂无数据",
      });
      emit("refresh");
      return;
    }
    xShopInfo(end);
  }
);
</script>

<style lang="scss" scoped>
.do-page-brand-map-info {
  .underline {
    text-decoration: underline;
  }

  .phone-status {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 0.66rem;
    width: 100%;
    position: relative;
    background-color: #fff;
    border-radius: 0.32rem 0.32rem 0 0;

    & > div {
      width: 0.56rem;
      height: 0.1rem;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 0.05rem;
    }
    & > .close {
      width: 0.36rem;
      height: 0.36rem;
      position: absolute;
      bottom: 0;
      right: 0.32rem;
    }
  }
  & > .title {
    display: flex;
    align-items: center;
    padding: 0.18rem 0.32rem;
    position: relative;
    background-color: #fff;
    justify-content: space-between;
    img {
      width: 0.36rem;
      height: 0.36rem;
    }
    // .title-rigth {
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-between;
    //   padding: 0.16rem;
    //   width: 1.28rem;
    //   height: 0.56rem;
    //   background: rgba(0, 87, 255, 0.08);
    //   border-radius: 0.08rem;
    //   font-size: 0.28rem;
    //   font-family: PingFangSC-Regular, PingFang SC;
    //   font-weight: 400;
    //   color: #0057ff;
    //   line-height: 0.28rem;
    //   img {
    //     width: 0.28rem;
    //     height: 0.28rem;
    //   }
    // }
  }
  ::v-deep(.do-row-tab) {
    display: flex;
    align-items: center;
    width: 100%;
    .img {
      width: 0.72rem;
      height: 0.72rem;
      img {
        width: 0.72rem;
        height: 0.72rem;
        border-radius: 0.08rem;
      }
    }
    & > .content {
      margin-left: 0.24rem;
      height: initial;
      width: calc(100% - 1.3rem);
      .title {
        font-weight: bold;
        font-size: 0.32rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }
      .address {
        margin-top: 0.02rem;
        font-weight: 400;
        color: rgba(2, 4, 15, 0.45);
        height: 0.34rem;
        line-height: 0.34rem;
        font-size: 0.24rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }
    }
  }

  .category-tabbar {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    bottom: 0.56rem;
  }

  & > .box {
    padding: 0.32rem;
    bottom: 0;
    position: relative;
    top: 0rem;

    .store-frame {
      ::v-deep(.title > span + span) {
        font-weight: 400;
        color: rgba(2, 4, 15, 0.25);
        text-decoration: unset;
      }
      .store-num {
        div {
          display: flex;
          align-items: center;
          justify-content: space-between;

          padding: 0.16rem 0.24rem;
          height: 0.72rem;
          background: #f5f8fc;
          border-radius: 0.08rem;
          font-size: 0.28rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 400;
          color: rgba(2, 4, 13, 0.85);

          span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
          }
        }
        div + div {
          margin-top: 0.24rem;
        }
        .more {
          width: 100%;
          text-align: center;
          color: #0057ff;
          text-decoration: underline;
          background-color: #fff;
          justify-content: center;
        }
      }
    }

    .bg-fff {
      background-color: #fff;
      border-radius: 0.08rem;
    }
    .item-title {
      display: flex;
      align-content: center;
      justify-content: space-between;
      .left {
        display: flex;
        align-items: center;
        font-size: 0.32rem;
        font-weight: bold;
        color: rgba(2, 4, 15, 0.85);
        &::before {
          content: " ";
          padding: 0 0.04rem;
          height: 0.28rem;
          background: #0057ff;
          margin-right: 0.16rem;
        }
      }
      .right {
        font-size: 0.28rem;
        font-weight: 400;
        color: rgba(2, 4, 15, 0.25);
      }
    }

    .item-type {
      display: flex;
      align-content: center;
      justify-content: space-between;
      & > div {
        width: calc(50% - 0.08rem);
        padding: 0.16rem;
        border-radius: 0.08rem;
        font-size: 0.28rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        background-color: #fff;

        div + div {
          margin-top: 0.08rem;
          color: rgba(2, 4, 15, 0.85);
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
        }
        div {
          display: inline-block;
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 500;
          color: rgba(2, 4, 15, 0.45);
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
        }
        .c85 {
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: bold;
        }
      }
    }
    .date-info {
      margin: 0.4rem 0 0 0;
      text-align: center;
      font-size: 0.28rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(2, 4, 15, 0.25);
    }
  }
  ::v-deep(.do-page-class-tag > .row > .col > span) {
    font-weight: 400;
  }
}
</style>
