<template>
  <div class="do-page-poi-search">
    <!-- <div class="bg" v-if="loading"><van-loading size="50" /></div> -->
    <base-nav-bar
      :backText="true"
      title="查询"
      @back="() => clickShop(null)"
    ></base-nav-bar>
    <div class="search-box">
      <van-field
        v-model="value"
        :left-icon="require('@/assets/datamap/search.png')"
        :clearable="true"
        clear-trigger="always"
        :lear-icon="require('@/assets/datamap/del.png')"
        @keydown="enter"
        @clear="() => (value = '')"
        :placeholder="placeholder[query?.type]"
      />
      <div class="text">查询附近</div>
    </div>
    <div class="scroll-box">
      <!-- $ComJs.bili * 2.8 === search-box 高度 -->

      <base-bscroll
        v-if="!value || +query.type === 3"
        :options="{ bounce: true }"
        class="safearea-tabbar"
        @refScroll="(refSc) => (bscroll = refSc)"
        :style="{
          position: 'fixed',
          left: 0,
          right: 0,
          bottom: 0,
          height: `calc(100vh - ${
            $Hbuild.getStatusBarHeight() + $ComJs.bili * 2.8 + 'px'
          })`,
        }"
      >
        <div class="bscroll-box" v-if="classify?.length">
          <Row
            v-for="(user, i) in classify || []"
            :key="i"
            :info="user"
            title="name"
            img="logo"
            :skeleton="user.skeleton"
            :row-style="{ height: '1.08rem' }"
            @submt="clickShop"
          ></Row>
        </div>
        <base-nodata v-else description="暂无数据"></base-nodata>
      </base-bscroll>
      <base-bscroll
        :options="{ bounce: true }"
        class="safearea-tabbar"
        v-else-if="!loading"
        :style="{
          // top: $Hbuild.getStatusBarHeight() + $ComJs.bili * 2.8 + 'px',
          position: 'fixed',
          left: 0,
          right: 0,
          bottom: 0,
          height: `calc(100vh - ${
            $Hbuild.getStatusBarHeight() + $ComJs.bili * 2.8 + 'px'
          })`,
          // height: `calc(100vh - ${
          //   $Hbuild.getStatusBarHeight() + $ComJs.bili * 2.8 + 'px'
          // })`,
        }"
      >
        <div class="bscroll-box radius" v-if="cofing?.length">
          <Row
            v-for="(user, i) in cofing || []"
            :key="i"
            :info="user"
            title="poi_name"
            content="addre"
            img="logo"
            :skeleton="user.skeleton"
            @submt="(e) => clickShop(e, true)"
          ></Row>
        </div>
        <base-nodata v-else description="暂无数据"></base-nodata>
      </base-bscroll>
    </div>
  </div>
</template>

<script setup>
import {
  onMounted,
  ref,
  getCurrentInstance,
  onActivated,
  watch,
  nextTick,
} from "vue";
import Row from "@/views/datamap/poi-info/component/row";
import { placeholder } from "@/views/home/config";
import { useRouter, useRoute } from "vue-router";
import { getSearchNearby, search } from "@/axios/home";
import util from "@/util";
import { Toast } from "vant";

const { appContext } = getCurrentInstance();
const { $Hbuild } = appContext?.config?.globalProperties || {}; //全局属性解构

const router = useRouter();
const route = useRoute();

let query = ref(null); // 跳转传的参数
const loading = ref(true);
const value = ref("");
let destroy = true; // 是否销毁页面重新渲染

// 列表
const cofing = ref([
  { skeleton: true },
  { skeleton: true },
  { skeleton: true },
  { skeleton: true },
  { skeleton: true },
  { skeleton: true },
  { skeleton: true },
]);
const classify = ref([
  { skeleton: true },
  { skeleton: true },
  { skeleton: true },
  { skeleton: true },
  { skeleton: true },
  { skeleton: true },
  { skeleton: true },
]);
const bscroll = ref(null); // 内容实例

const classIcon = {
  便利店: require("@/assets/datamap/convenience-store.png"),
  咖啡: require("@/assets/datamap/cafe.png"),

  // 没logo
  KTV: require("@/assets/datamap/ktv.png"),
  "按摩/足疗": require("@/assets/datamap/therapy.png"),
  酒吧: require("@/assets/datamap/bar.png"),

  百货: require("@/assets/datamap/stores.png"),
  购物中心: require("@/assets/datamap/Shop.png"),
  奥特莱斯: require("@/assets/datamap/outlet.png"),
  商业街: require("@/assets/datamap/street.png"),
};

onMounted(async () => {
  initClassData();
  $Hbuild.initNativeObjects();
  $Hbuild.showSoftInput(".van-field__control");
});
const enter = (evt) => {
  var charCode = evt.charCode
    ? evt.charCode
    : evt.which
    ? evt.which
    : evt.keyCode;

  if (charCode == 13 || charCode == 3) {
    // 这里添加定时器解决了 安卓在下一个页面onMounted获取视图高度问题
    evt.srcElement.blur();

    if (!value.value)
      return Toast({
        position: "bottom",
        message: "请求输入内容",
      });
    if (+query.value.type === 3) {
      router.push({
        path: "/search/brand-end",
        query: {
          type: query.value.type,
          value: value.value,
        },
      });
      return;
    }
    clickShop(
      {
        name: value.value,
        search: value.value,
        logo: require("@/assets/datamap/search-data.png"),
        id: "9999999", // 那边做了没id不请求处理
      },
      false
    );
  }
};

onActivated(() => {
  $Hbuild.initNativeObjects();
  $Hbuild.showSoftInput(".van-field__control");
  // 调用时机为首次挂载
  // 以及每次从缓存中被重新插入时
  if (destroy) {
    value.value = "";
    cofing.value = [
      { skeleton: true },
      { skeleton: true },
      { skeleton: true },
      { skeleton: true },
      { skeleton: true },
      { skeleton: true },
      { skeleton: true },
    ];
    classify.value = [
      { skeleton: true },
      { skeleton: true },
      { skeleton: true },
      { skeleton: true },
      { skeleton: true },
      { skeleton: true },
      { skeleton: true },
    ];
    nextTick(() => {
      initClassData();
    });
  }
});

watch(value, (newData, oldData) => {
  if (!newData) return;

  util.setDebounce(async () => {
    loading.value = true;

    try {
      const res = await search({
        search: newData,
        page_size: 10,
        type: query.value.type,
      });
      if (+query.value.type === 3) {
        classify.value = res.data.items;
        nextTick(() => {
          bscroll.value.refresh();
        });
      } else {
        cofing.value =
          res.data.items.map((el) => ({
            ...el,
            addre: `${el.distance.toFixed(0)}m·${el.address}`,
            category_list: el.category_list.filter((el) => el),
          })) || [];
      }

      loading.value = false;
    } catch (error) {
      if (error) {
        Toast({
          position: "bottom",
          message: error.msg || "获取信息失败",
        });
      }
      cofing.value = [];
      loading.value = false;
    }
  }, 500);
});

const initClassData = async () => {
  query.value = route?.query;
  const res = await getSearchNearby({ type: query.value.type });
  try {
    if (res.code === 0) {
      const list = res.data.items.map((el) => ({
        ...el,
        logo: +query.value.type === 3 ? el.logo : classIcon[el.name],
      }));
      classify.value = list;
      loading.value = false;
      nextTick(() => {
        bscroll?.value?.refresh();
      });
    }
  } catch (error) {
    if (error) {
      Toast({
        position: "bottom",
        message: error.msg || "获取信息失败",
      });
    }
    loading.value = false;
  }
};

const clickShop = (e, type) => {
  if (!e) {
    destroy = true;
    router.back();
    return;
  }
  // 这里添加渲染完成事件后再进行解决 安卓在下一个页面onMounted获取视图高度问题
  setTimeout(() => {
    let obj = { ...e, img: e.logo };
    if (!type) {
      if (+query.value.type === 3) {
        router.push({
          path: "/search/brand-map",
          query: {
            ...obj,
            type: query.value.type,
          },
        });
        return;
      }
      router.push({
        path: "/datamap",
        query: {
          list: JSON.stringify({ ...obj, calss: true, type: query.value.type }),
        },
      });
    } else {
      router.push({
        path: "/datamap",
        query: { list: JSON.stringify(obj), type: query.value.type },
      });
    }
    destroy = false;
  }, 500);
};
</script>
<style lang="scss">
.do-page-poi-search {
  width: 100%;
  height: 100%;
  background-color: #fff;

  & > .search-box {
    padding: 0.24rem 0.32rem 0;

    .van-cell {
      border-radius: 0.08rem;
      background: rgba(2, 4, 15, 0.04);
      .van-field__left-icon {
        display: flex;
        align-items: center;
      }
      .van-icon-clear {
        // position: absolute;
        // right: 0;
      }
    }
    .text {
      font-weight: bold;
      font-size: 0.28rem;
      color: rgba(2, 4, 15, 0.45);
      padding: 0.24rem 0 0.16rem 0;
      @include border-bottom(rgba(2, 4, 15, 0.12));
    }
  }
  .bscroll-box {
    padding: 0.24rem;
  }
  .scroll-box {
    position: relative;
    height: 100%;
    & > .do-com-scroll-wrapper {
      overflow: hidden;
      top: unset;
    }
    .radius {
      img {
        border-radius: 50%;
      }
      .do-row-tab > .content > .title {
        font-weight: bold;
      }
    }
  }
  .safearea-tabbar {
    // @include fixedCom(0, 0, 0, 0);
    .van-skeleton {
      padding: 0;
    }
  }
  .do-row-tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 1.28rem;
    .img {
      width: 0.64rem;
      height: 0.64rem;
      img {
        width: 0.64rem;
        height: 0.64rem;
        border-radius: 0.08rem;
      }
    }
    & > .content {
      margin-left: 0.24rem;
      height: initial;
      width: calc(100% - 0.6rem);
      .title {
        font-weight: 400;
        color: rgba(2, 4, 15, 0.85);
        height: 0.4rem;
        line-height: 0.4rem;
        font-size: 0.28rem;
        white-space: nowrap;
        width: 5rem;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .address {
        font-weight: 500;
        color: rgba(2, 4, 15, 0.45);
        height: 0.4rem;
        line-height: 0.4rem;
        font-size: 0.28rem;
        width: 5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
